import useScript from './useScript'

const gtagId = 'UA-213812784-1'

const useAttachTrackerScripts = (): void => {
  /* eslint-disable */
  if (window.SDFEConfig.REACT_APP_ENVIRONMENT !== 'production') return

  useScript({
    src: `https://www.googletagmanager.com/gtag/js?id=${gtagId}`,
    async: true,
    onload: () => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || []

      // @ts-ignore
      function gtag() { dataLayer.push(arguments) }
      // @ts-ignore
      gtag('js', new Date())
      // @ts-ignore
      gtag('config', gtagId)
    },
  })

  useScript({
    type: 'text/javascript',
    innerHTML: `
      _linkedin_partner_id = "3470362";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);

      (function (l) {
        if (!l) {
          window.lintrk = function (a, b) {window.lintrk.q.push([a, b])};
          window.lintrk.q = []
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `,
  })

  useScript({
    type: 'text/javascript',
    innerHTML: `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2970881,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `,
  })
}

export default useAttachTrackerScripts
