import { ReactElement, useState } from 'react'
import { observer } from 'mobx-react'

import tableFilteringStore from '@store/table-filtering'

interface FilterLogicOperatorProps {
  filterId: string
  filterIndex: number
  filters: IndexSearchParamsPropertyFilter[]
}

const FilterLogicOperator = observer(({
  filterId,
}: FilterLogicOperatorProps): ReactElement => {
  const [operatorIndex, setOperatorIndex] = useState<number>(0)
  const operators: FilterOperators[] = ['AND', 'OR', 'NOT']

  const clickHandler = () => {
    const newOperatorIndex = (operatorIndex + 1) % operators.length
    setOperatorIndex(newOperatorIndex)
    tableFilteringStore.changeFilterLogicOperator(filterId, operators[newOperatorIndex])
  }
  return (
    <div
      role="button"
      style={{ marginBottom: -3 }}
      className="flex justify-center relative mt-1 w-8 h-5 rounded-sm font-semibold
       bg-primary text-white cursor-pointer"
      onClick={clickHandler}
    >
      {operators[operatorIndex]}
    </div>
  )
})

export default FilterLogicOperator
