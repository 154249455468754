/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Stop = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM9 15C5.69175 15 3 12.3082 3 9C3 5.69175 5.69175 3 9 3C12.3082 3 15 5.69175 15 9C15 12.3082 12.3082 15 9 15Z" />
    <path d="M6.75 6.75H11.25V11.25H6.75V6.75Z" />
  </svg>
)

export default Stop
