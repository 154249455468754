import { Rule } from 'antd/lib/form'

const FILE_NAME_FORBIDDEN_SYMBOLS_REG_EXP = new RegExp(/[/\\?%*:|"<>]/, 'g')

const fileNameValidationRules: Rule[] = [
  {
    max: 100,
    message: 'File name cannot be longer than 100 characters',
  },
  {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    validator(_, value: string) {
      const trimmedValue = value.trim()

      if (trimmedValue.length === 0) return Promise.reject(new Error('Please enter file name'))

      const forbiddenSymbols = trimmedValue.match(FILE_NAME_FORBIDDEN_SYMBOLS_REG_EXP)

      if (!forbiddenSymbols) return Promise.resolve()

      if (forbiddenSymbols.length > 1) return Promise.reject(new Error(`Symbols ${forbiddenSymbols.join(', ')} are not allowed`))

      return Promise.reject(new Error(`Symbol ${forbiddenSymbols[0]} is not allowed`))
    },
  },
]

export default fileNameValidationRules
