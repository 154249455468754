import { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'antd'

import routes from '@router/guest-routes'
import BigLogo from './BigLogo'

const OneButtonCtaSection = ({ label, path }: {label: string, path: string}): ReactElement => (
  <Button className="my-6" size="large" type="primary">
    <Link to={path}><span className="px-8">{label}</span></Link>
  </Button>
)

const useHeaderPresenter = () => {
  const location = useLocation()
  const [rightSection, setRightSection] = useState<ReactElement>(<></>)

  useEffect(() => {
    if (location.pathname.includes(routes.SignUp.path)) {
      setRightSection(<OneButtonCtaSection label="Sign In" path={routes.LogIn.path} />)
    } else if (location.pathname.includes(routes.LogIn.path)
      || location.pathname.includes(routes.PasswordRecovery.path)) {
      setRightSection(<OneButtonCtaSection label="Sign Up" path={routes.SignUp.path} />)
    } else {
      setRightSection(<></>)
    }
  }, [location, setRightSection])

  return {
    RightSection: rightSection,
  }
}

export default function GuestHeader(): ReactElement {
  const { RightSection } = useHeaderPresenter()

  return (
    <header className="flex justify-between items-center">
      <div className="my-8">
        <Link to="/">
          <BigLogo />
        </Link>
      </div>

      <div className="flex justify-end">
        { RightSection }
      </div>
    </header>
  )
}
