/* eslint-disable max-len */
import { useEffect } from 'react'
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'

import appStore from '@store/app'
import subscriptionStore from '@store/subscription'
import useFetchAcademicPlans from '@shared/hooks/useFetchAcademicPlans'
import AcademicPlanCardButtonts from './AcademicPlanCardButtonts'
import AcademicPlanCardView from './AcademicPlanCardView'
import SpinnerArea from './SpinnerArea'
import VATFootnote from './VATFootnote'

interface PlansOverviewProps {
  className?: Argument
}

const AcademicPlansOverview = ({ className }: PlansOverviewProps): JSX.Element => {
  const { theme } = appStore
  const plans = useFetchAcademicPlans()

  const { fetchingSubscriptionData } = subscriptionStore

  useEffect(() => {
    subscriptionStore.fetchSubscriptionDetails()
  }, [])

  if (plans.loading || fetchingSubscriptionData) return <SpinnerArea className="h-full w-full mt-24" />
  return (
    <div className={cn('flex flex-col', className)}>
      <div className="pt-10 flex items-stretch">
        {(plans.monthly).map((plan, index) => (
          <AcademicPlanCardView
            key={plan.id}
            plan={plan}
            header={(index % 3) + 1}
            className="mr-5 last:mr-0"
            badge={index === 2}
            buttons={<AcademicPlanCardButtonts plan={plan} />}
            theme={theme}
          />
        ))}
      </div>

      <div>
        <VATFootnote className="mt-6 mb-6" />
      </div>

      <p className="max-w-[985px] leading-8 mt-5 mb-10">The Academic pricing is reserved exclusively for public domain research and education only. This discounted rate is reserved research that will be in the public domain within 2 years, and education. Any publication of results from work that used REPOSITORY from Adroit DI must acknowledge its use. For commercial use a full priced license must be purchased.</p>
    </div>
  )
}

export default observer(AcademicPlansOverview)
