import LogIn from '@pages/guest/LogIn'
import PasswordRecovery from '@pages/guest/PasswordRecovery'
import SignUp from '@pages/guest/SignUp'

export default {
  Home: {
    path: '/',
    component: LogIn,
    label: 'Sign In',
  },
  LogIn: {
    path: '/log-in',
    component: LogIn,
    label: 'Sign In',
  },
  SignUp: {
    path: '/sign-up',
    component: SignUp,
    label: 'Sign Up',
  },
  PasswordRecovery: {
    path: '/password-recovery',
    component: PasswordRecovery,
    label: 'Password Recovery',
  },
}
