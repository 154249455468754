import { ReactElement, useCallback } from 'react'
import { Input } from 'antd'
import { PasswordProps } from 'antd/es/input/Password'

import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons'

const PasswordInput = (props: PasswordProps): ReactElement => {
  const iconRender = useCallback(
    (visible: boolean): ReactElement => (visible ? <EyeFilled /> : <EyeInvisibleFilled />),
    [],
  )

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Input.Password iconRender={iconRender} {...props} />
}

export default PasswordInput
