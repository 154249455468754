import '@assets/ag-grid.css'

import {
  ReactElement, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import {
  CellValueChangedEvent, ColumnApi, GridApi, GridReadyEvent, SelectionChangedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useSize } from 'ahooks'
import cn from 'classnames'
import { observer } from 'mobx-react'

import AddRowLineNumberCell from '@components/add-row/AddRowLineNumberCell'
import AddRowLineNumberHeader from '@components/add-row/AddRowLineNumberHeader'
import AddRowStructureRenderer from '@components/add-row/AddRowStructureRenderer'
import AgGridColumnHeader from '@components/table/AgGridColumnHeader'
import NumericalCellEditor from '@components/table/NumericalCellEditor'
import StringCellEditor from '@components/table/StringCellEditor'
import StructureCellEditor from '@components/table/StructureCellEditor'
import { useAddRowsStore, useFileStore } from '@contexts/add-rows-context'
import { useAgGridApiService } from '@contexts/file-edit-context'
import { AG_GRID_ANCHOR_FIELD_NAME, AG_GRID_TABLE_ROW_HEIGHT_PX } from '@shared/constants'
import useAgGridColDefs from '@shared/hooks/ag-grid/useAgGridColDefs'
import useAgGridThemeClassName from '@shared/hooks/ag-grid/useAgGridThemeClassName'
import { getRowId, mapAgSortToIndexSortingParams } from '@utils/ag-grid-utils'

interface AddRowTableProps {
  parentHeight: number
}

const AddRowTable = observer<React.FC<AddRowTableProps>>(({ parentHeight }) => {
  const fileStore = useFileStore()
  const addRowsStore = useAddRowsStore()
  const agGridApiService = useAgGridApiService()

  const [gridApi, setGridApi] = useState<GridApi>()
  const [columnApi, setColumnApi] = useState<ColumnApi>()

  const [sorting, setSort] = useState<IndexSortingParams[]>()

  const [columnDefs, onColumnMoved] = useAgGridColDefs(
    fileStore.extendedProperties,
    fileStore.indexProperties,
    { type: 'all', sorting },
  )

  const context = useMemo<AgGridCellRenderingContext>(() => ({
    queryStructure: fileStore.queryStructure,
  }), [fileStore.queryStructure])

  const themeClassName = useAgGridThemeClassName()

  const handleGridReady = useCallback((evt: GridReadyEvent) => {
    agGridApiService.setGridApi(evt.api)
    setGridApi(evt.api)
    setColumnApi(evt.columnApi)
  }, [agGridApiService])

  const handleSelectionChange = useCallback((event: SelectionChangedEvent) => {
    addRowsStore.selectedRowCustomOrder = event.api.getSelectedRows().map((r: MoleculeRow) => r.customOrder)
  }, [addRowsStore])

  const handleCellValueChange = useCallback((evt: CellValueChangedEvent) => {
    const field = evt.column.getColId()
    addRowsStore.setMolData(evt.data.customOrder, field, evt.newValue || null)
  }, [addRowsStore])

  const saveSort = useCallback(() => {
    const colState = columnApi?.getColumnState()
    const sortState = colState?.filter(s => s.sort != null)
      .map(s => ({ colId: s.colId, sort: s.sort, sortIndex: s.sortIndex }))

    setSort(mapAgSortToIndexSortingParams(sortState))
  }, [columnApi])

  useEffect(() => {
    // Update rowIndex column
    gridApi?.refreshCells({ columns: [AG_GRID_ANCHOR_FIELD_NAME] })
  }, [addRowsStore.moleculeRows, gridApi])

  return (
    <div className={cn('ag-grid', themeClassName)} style={{ height: parentHeight }}>
      <AgGridReact
        rowData={addRowsStore.moleculeRows}
        columnDefs={columnDefs}
        context={context}
        rowHeight={AG_GRID_TABLE_ROW_HEIGHT_PX}
        singleClickEdit
        suppressRowClickSelection
        rowSelection="multiple"
        components={{
          lineNumberCellRenderer: AddRowLineNumberCell,
          columnHeader: AgGridColumnHeader,
          lineNumberFieldHeader: AddRowLineNumberHeader,
          stringCellEditor: StringCellEditor,
          numericCellEditor: NumericalCellEditor,
          structureCellEditor: StructureCellEditor,
          structureRenderer: AddRowStructureRenderer,
        }}
        getRowId={getRowId}
        onGridReady={handleGridReady}
        onSelectionChanged={handleSelectionChange}
        onCellValueChanged={handleCellValueChange}
        onSortChanged={saveSort}
        onColumnMoved={onColumnMoved}
      />
    </div>
  )
})

const AddRowTableWrapper = (): ReactElement => {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const size = useSize(rootRef)

  return (
    <div ref={rootRef} className="h-full">
      {size.height && (
        <AddRowTable
          parentHeight={size.height - 1}
        />
      )}
    </div>
  )
}

export default AddRowTableWrapper
