import { Button, Modal } from 'antd'
import { observer } from 'mobx-react'

interface PostNewMoleculesButtonProps {
  disabled?: boolean
  postMolecules(): void
  postingMolecules?: boolean
}

const PostNewMoleculesButton = observer<React.FC<PostNewMoleculesButtonProps>>(({
  disabled,
  postMolecules,
  postingMolecules,
}) => {
  const confirmPostNewMolecules = () => {
    Modal.confirm({
      title: 'Add the rows to the dataset?',
      content:
        'The rows will be added to the dataset  and displayed in accordance with the selected sorting and filters',
      okText: 'Add',
      okType: 'primary',
      cancelButtonProps: { type: 'text' },
      onOk: postMolecules,
      icon: null,
    })
  }

  return (
    <Button
      ghost
      onClick={confirmPostNewMolecules}
      disabled={disabled}
      loading={postingMolecules}
      className="ml-6 md:ml-8"
    >
      Add rows to the dataset
    </Button>
  )
})

export default PostNewMoleculesButton
