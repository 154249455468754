import { useCallback, useEffect, useRef } from 'react'
import { Button, notification } from 'antd'

interface UseUnActualSearchParamsNoticeProps {
  refreshCallback: () => void
  disabled: boolean
}

const notificationKey = 'unActualSearchParamsNotice'

const closeNotice = (): void => notification.destroy('unActualSearchParamsNotice')

const showNotice = (onRefreshClick: () => void): void => {
  notification.open({
    key: notificationKey,
    message: 'Some values in the dataset have been changed. Please refresh.',
    duration: 0,
    placement: 'bottomRight',
    style: { width: 270, bottom: 0 },
    className: 'notice-message-no-padding',
    closeIcon: <div />,
    description: (
      <div className="flex gap-4 mt-2">
        <Button
          className="flex-1"
          ghost
          onClick={closeNotice}
        >
          Close
        </Button>

        <Button
          className="flex-1"
          type="primary"
          onClick={() => {
            onRefreshClick()
            closeNotice()
          }}
        >
          Refresh all
        </Button>
      </div>
    ),
  })
}

const useUnActualSearchParamsNotice = ({
  refreshCallback,
  disabled,
}: UseUnActualSearchParamsNoticeProps): () => void => {
  const showNotification = useRef(true)

  useEffect(() => () => {
    showNotification.current = false
    closeNotice()
  }, [])

  const show = useCallback(() => {
    if (showNotification.current && !disabled) {
      showNotice(refreshCallback)
    }
  }, [refreshCallback, disabled])

  return show
}

export default useUnActualSearchParamsNotice
