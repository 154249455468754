import { useMemo } from 'react'
import { ColDef, ColumnMovedEvent } from 'ag-grid-community'
import { useDebounceFn } from 'ahooks'
import isNil from 'lodash/isNil'

import { useFileStore, useTableManagementStore } from '@contexts/file-edit-context'
import {
  AG_GRID_ANCHOR_FIELD_NAME,
  AG_GRID_TABLE_DEFAULT_ROW_WIDTH,
} from '@shared/constants'
import { mapIndexPropertyColDefToAgGridColDef } from '@utils/ag-grid-utils'
import { showErrorMessage } from '@utils/messages'

const anchorColumn: ColDef = {
  field: AG_GRID_ANCHOR_FIELD_NAME,
  headerName: '#',
  valueGetter: 'node.rowIndex + 1',
  headerComponent: 'lineNumberFieldHeader',
  cellRenderer: 'lineNumberCellRenderer',
  suppressMenu: true,
  pinned: true,
  width: AG_GRID_TABLE_DEFAULT_ROW_WIDTH,
  lockPosition: true,
}

type OnColumnMovedCallback = (evt: ColumnMovedEvent) => void

export default function useAgGridColDefs(
  extendedPropertiesToShow: IndexProperty[],
  indexProperties: IndexProperty[],
  searchParams: Partial<IndexSearchParams>,
  readOnly = false,
): [ColDef[], OnColumnMovedCallback] {
  const tableManagement = useTableManagementStore()
  const fileStore = useFileStore()

  const colDefList = useMemo<ColDef[]>(() => [
    anchorColumn,
    ...mapIndexPropertyColDefToAgGridColDef(extendedPropertiesToShow, searchParams, readOnly),
  ], [extendedPropertiesToShow, searchParams, readOnly])

  const { run: onColumnMoved } = useDebounceFn(async (event: ColumnMovedEvent) => {
    const lengthDiff = extendedPropertiesToShow.length - indexProperties.length
    const colField = event.column?.getColId()
    const oldIndex = indexProperties.findIndex(c => c.name === colField)

    if (!isNil(event.toIndex) && oldIndex >= 0) {
      const newIndex = event.toIndex - lengthDiff
      const items = Array.from(indexProperties)
      const [reorderItem] = items.splice(oldIndex, 1)

      items.splice(newIndex, 0, reorderItem)

      try {
        await fileStore.updateIndexProperties(items)
        tableManagement.indexProperties = items
      } catch {
        showErrorMessage()
      }
    }
  }, { wait: 1000 })

  return [colDefList, onColumnMoved]
}
