import { useEffect } from 'react'
import { Spin } from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react'

import { useFileStore, useTableManagementStore } from '@contexts/file-edit-context'
import pluralEnd from '@utils/plural-end'

type FilterResultsProps = ElementProps & { showInactive?: boolean }

const FilterResults = ({ className, showInactive }: FilterResultsProps) => {
  const fileStore = useFileStore()
  const tableManagementStore = useTableManagementStore()

  const filterPropertys = fileStore.searchParams.filters?.map(filter => filter.property)

  useEffect(() => {
    if (filterPropertys?.length) {
      tableManagementStore.filteredIndexProperties.forEach(property => {
        if (!filterPropertys?.includes(property.name)) {
          tableManagementStore.updateColumn(property, 'hidden', true)
        }
      })
      fileStore.updateIndexProperties(tableManagementStore.indexProperties)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (fileStore.hasFilterResult) {
    return (
      <span className={cn(className)}>
        <span className="text-gray-50">Filter Result:</span>
        {fileStore.isSearchInProgress && (
          <Spin className="align-bottom mx-1.5" size="small" />
        )}
        <span> {fileStore.matched} Row{pluralEnd(fileStore.matched)}</span>
      </span>
    )
  }

  if (showInactive) {
    return (
      <span className={cn(className)}>
        <span className="text-gray-50">Filter Result:</span>
        <span className="ml-1">No Filters</span>
      </span>
    )
  }

  return <span />
}

export default observer(FilterResults)
