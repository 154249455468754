import {
  forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { useDebounceFn, useEventListener } from 'ahooks'
import { Input } from 'antd'
import isNil from 'lodash/isNil'

import selectInputOnFocus from '@utils/selectInputOnFocus'
import useAutoFocusAntdInputInAgGrid from './useAutoFocusAntdInputInAgGrid'

const MAX_CELL_PART = 4

const StringCellEditor = forwardRef(({
  stopEditing,
  value: initialValue,
  cellStartedEdit,
  columnApi,
  column,
}: ICellEditorParams, ref) => {
  const [internalValue, setInternalValue] = useState<string>(initialValue)
  const antInputRef = useRef<Input>(null)

  // Use ref callback instead of useRef in order to get most recent ref object of TextArea
  const textAreaRefCallback = useCallback(node => {
    if (node) {
      node.focus()
    }
  }, [])

  const [requiredWidth, setRequiredWidth] = useState<number | undefined>()
  const [maxWidthForCell, setMaxWidthForCell] = useState(document.body.offsetWidth / MAX_CELL_PART)
  const [isTextArea, setIsTextArea] = useState(false)

  useAutoFocusAntdInputInAgGrid(antInputRef, cellStartedEdit)

  const commit = () => stopEditing()

  const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (evt.key === 'Escape') commit()
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const targetValue = String(evt.target.value || '')
    setInternalValue(targetValue)
  }

  useImperativeHandle(ref, () => ({
    getValue() {
      return internalValue
    },
  }))

  const { run: handleWindowResize } = useDebounceFn(() => setMaxWidthForCell(
    document.body.offsetWidth / MAX_CELL_PART,
  ), { wait: 50, leading: true })

  useEventListener('resize', handleWindowResize)

  useEffect(() => {
    if (!isNil(antInputRef.current)) {
      setRequiredWidth(antInputRef?.current?.input?.scrollWidth)
    }
  }, [antInputRef])

  useEffect(() => {
    const initialWidth = column.getActualWidth()

    if (isNil(requiredWidth)) {
      return undefined
    }

    const colId = column.getColId()

    if ((requiredWidth > initialWidth && requiredWidth <= maxWidthForCell)) {
      columnApi.setColumnWidth(colId, requiredWidth)
    } else if (requiredWidth > initialWidth && requiredWidth > maxWidthForCell) {
      setIsTextArea(true)
      columnApi.setColumnWidth(colId, Math.max(maxWidthForCell, initialWidth))
    }

    return () => columnApi.setColumnWidth(colId, initialWidth)
  }, [requiredWidth, maxWidthForCell, columnApi, column])

  if (!isTextArea) {
    return (
      <Input
        ref={antInputRef}
        type="text"
        value={internalValue}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onPressEnter={commit}
        onBlur={commit}
        onFocus={selectInputOnFocus}
        bordered
      />
    )
  }

  return (
    <Input.TextArea
      ref={textAreaRefCallback}
      className="resize-none !h-full"
      value={internalValue}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onPressEnter={commit}
      onBlur={commit}
      onFocus={selectInputOnFocus}
      bordered
    />
  )
})

StringCellEditor.displayName = 'StringCellEditor'

export default StringCellEditor
