import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import Chevron from '@components/icons/Chevron'

interface CtaContainerProps {
  children: ReactElement[] | ReactElement
  path?: string
  onClick?: () => void
}

const CtaContainer = ({ path, onClick, children }: CtaContainerProps): ReactElement => {
  const hasProtocol = path && /https?:\/\//.test(path)

  if (hasProtocol) {
    return (
      <a href={path} className="flex items-center">
        {children}
      </a>
    )
  }

  if (path) {
    return (
      <Link to={path} className="flex items-center">
        { children }
      </Link>
    )
  }

  return (
    <div role="button" className="flex items-center" onClick={onClick} tabIndex={0}>
      { children }
    </div>
  )
}

const BackButton = ({
  path, label, onClick,
}: BackButton): ReactElement => (
  <div className="text-primary font-semibold inline-block">
    <CtaContainer path={path} onClick={onClick}>
      <Chevron className="transform rotate-90" />
      <span className="pl-2">{label}</span>
    </CtaContainer>
  </div>
)

export default BackButton
