import { match } from 'ts-pattern'

import { BackgroundTask } from '@store/background-tasks'

export type NewOldNamesMap = Map<string, string | null>

const getRenamedSearchParam = <T, K extends keyof T>(
  newNames: NewOldNamesMap,
  propertyName: K,
  searchParam?: T[],
) => {
  const newSearchParam = []

  if (searchParam && searchParam.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const param of searchParam) {
      const newName = newNames.get(param[propertyName] as unknown as string)

      if (newName !== null) {
        if (newName) {
          newSearchParam.push({ ...param, [propertyName]: newName })
        } else {
          newSearchParam.push(param)
        }
      }
    }
  }

  return newSearchParam
}

export const getRenamedIndexPropertiesWithMap = (indexProperties: IndexProperty[]):
  [IndexProperty[], NewOldNamesMap] => {
  const filteredIndexProperties = []
  const newNames: NewOldNamesMap = new Map()

  for (let i = 0; i < indexProperties.length; i += 1) {
    const indexProperty = indexProperties[i]

    if (!indexProperty.deleted) {
      const { newName } = indexProperty

      if (newName) {
        filteredIndexProperties.push({
          ...indexProperty,
          name: newName,
          newName: undefined,
        })
        newNames.set(indexProperty.name, newName)
      } else {
        filteredIndexProperties.push(indexProperty)
      }
    } else {
      newNames.set(indexProperty.name, null)
    }
  }

  return [filteredIndexProperties, newNames]
}

export const getRenamedSearchParams = (
  searchParams: IndexSearchParams,
  newOldNamesMap: NewOldNamesMap,
): IndexSearchParams => {
  const { sorting, filters } = searchParams

  const anyIndexParamWasRenamed = !!newOldNamesMap.size

  const newSorting = anyIndexParamWasRenamed
    ? getRenamedSearchParam(newOldNamesMap, 'fieldName', sorting)
    : sorting
  const newFilters = anyIndexParamWasRenamed ? getRenamedSearchParam(newOldNamesMap, 'property', filters) : filters

  return {
    ...searchParams,
    sorting: newSorting,
    filters: newFilters,
  }
}

export const getFilesIdsFromTask = (task: BackgroundTask): string[] => match(task)
  .with({ type: 'export' }, ({ fileId: indexId }) => [indexId])
  .with({ type: 'merge' }, ({ indexes }) => indexes)
  .with({ type: 'add-column' }, ({ fileId }) => [fileId])
  .with({ type: 'bulk-edit' }, ({ indexId }) => [indexId])
  .with({ type: 'remove-duplications' }, ({ fileId }) => [fileId])
  .with({ type: 'range-selection' }, ({ fileId }) => [fileId])
  .with({ type: 'data-export' }, ({ fileId }) => [fileId])
  .with({ type: 'convert' }, ({ fileId }) => [fileId])
  .with({ type: 'copy' }, ({ fileId }) => [fileId])
  .exhaustive()

export const getAmountTasksByFileId = (tasks: BackgroundTask[]): Map<string, number> => {
  const readOnlyFiles = new Map<string, number>()

  tasks.forEach(task => {
    getFilesIdsFromTask(task).forEach(id => {
      const prevValue = readOnlyFiles.has(id) ? readOnlyFiles.get(id)! : 0

      readOnlyFiles.set(id, prevValue + 1)
    })
  })

  return readOnlyFiles
}
