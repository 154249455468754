/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

import apiService from '@shared/services/api-service'

interface PlansState {
  monthly: AcademicSubscriptionPlan[]
  yearly: AcademicSubscriptionPlan[]
  loading: boolean
}

interface UseFetchPaddlePlans {
  onError?: (error: any) => void
}

// eslint-disable-next-line max-len
const sortPlans = (plans: AcademicSubscriptionPlan[]) => plans.sort((p1, p2) => p1.recurringPrices.USD - p2.recurringPrices.USD)

// eslint-disable-next-line max-len
const useFetchPaddlePlans = ({ onError }: UseFetchPaddlePlans = {}): PlansState => {
  const [plans, setPlans] = useState<PlansState>({ monthly: [], yearly: [], loading: true })

  useEffect(() => {
    const abortController = new AbortController()

    apiService.get<AcademicSubscriptionPlan[]>('/subscription/paddle/plans', { signal: abortController.signal })
      .then(availablePlans => {
        // hack
        // TODO: remove after deleting unnecessary plans from paddle
        const validPlans = availablePlans.slice(-1)

        const monthly = sortPlans(validPlans.filter(plan => plan.billingType === 'month'))
        const yearly = sortPlans(validPlans.filter(plan => plan.billingType === 'year'))

        setPlans({ yearly, monthly, loading: false })
      })
      .catch(error => {
        if (error?.name === 'AbortError') return
        onError?.(error)
      })

    return () => abortController.abort()
  }, [onError])

  return plans
}

export default useFetchPaddlePlans
