import {
  Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useRef, useState,
} from 'react'
import {
  Button, Checkbox, Form, Input, Modal, Radio,
  Tooltip,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import FileStore from '@store/file'
import subscriptionStore from '@store/subscription'
import useBackgroundTasksContext from '@contexts/background-tasks'
import { fileNameValidationRules } from '@utils/validators'

export type ExportSettings = 'NoSettings' | 'WithMatches' | 'WithFiltersAndSelectedRows'

interface FormFields {
  fileName: string
  zip: boolean
  searchType?: SearchType
}

interface DownloadFileProps {
  target: React.ComponentType<ElementProps & {disabled: boolean}>
  disabled?: boolean
  disabledClassName?: Argument
  className?: Argument
  exportTaskData: ExportTaskData
  exportSettings?: ExportSettings
  fileStore?: FileStore
  isInsideIndex: boolean
}

interface DownloadFileModalProps {
  visible: boolean
  setModalVisible: Dispatch<SetStateAction<boolean>>
  exportTaskData: ExportTaskData
  exportSettings: ExportSettings
  fileStore?: FileStore
}

interface RadioGroupData {
  text: string
  value: SearchType
  disabled: boolean
  visible: boolean
}

interface SearchTypeRadioGroupProps {
  items: RadioGroupData[]
  itemClassName?: string
}

const SearchTypeRadioGroup = ({ items, itemClassName }: SearchTypeRadioGroupProps) => (
  <Form.Item name="searchType">
    <Radio.Group>
      {
        items.map(item => (item.visible && (
          <Radio
            className={itemClassName}
            key={item.value}
            disabled={item.disabled}
            value={item.value}
          >
            {item.text}
          </Radio>
        )))
      }
    </Radio.Group>
  </Form.Item>
)

const DownloadFileModal = ({
  visible, setModalVisible, exportTaskData, exportSettings, fileStore,
}: DownloadFileModalProps): ReactElement => {
  const backgroundTasksStore = useBackgroundTasksContext()
  const [form] = Form.useForm()
  const [extension, setExtension] = useState<'.zip' | '.sdf'>('.zip')
  const {
    fileName, indexId, selectedMoleculesParams, searchParams,
  } = exportTaskData
  const formRef = useRef(null)
  const { subscriptionPlanSettings } = subscriptionStore

  const initialValues: FormFields = {
    fileName,
    zip: extension === '.zip',
    searchType: 'all',
  }

  const closeModal = useCallback(() => setModalVisible(false), [setModalVisible])
  const afterClose = useCallback(() => form.resetFields(), [form])
  const handleCancel = useCallback(() => closeModal(), [closeModal])

  const exportFile = useCallback(async (options: FormFields) => {
    closeModal()

    let params

    if (options?.searchType === 'filter' || options?.searchType === 'matched') {
      params = { searchParams }
    }

    if (options?.searchType === 'selected') {
      params = { selectedMoleculesParams }
    }

    const searchType = options.searchType || 'all'

    backgroundTasksStore.createTask({
      type: 'export',
      data: {
        searchType,
        ...params,
        fileId: indexId,
        fileName: options.fileName,
        zip: options.zip,
      },
    })
  }, [closeModal, indexId, selectedMoleculesParams, backgroundTasksStore, searchParams])

  useEffect(
    () => {
      if (formRef.current) {
        form.setFieldsValue({ fileName })
      }
    },
    [form, fileName],
  )

  const matchesRadioGroupItems: RadioGroupData[] = [
    {
      text: 'All rows',
      value: 'all',
      visible: true,
      disabled: false,
    },
    {
      text: 'Matched rows',
      value: 'matched',
      visible: true,
      disabled: false,
    },
  ]

  const isFilterEnabled = searchParams?.queryStructure
    || searchParams?.filters?.length
    || searchParams?.sorting?.length

  const filtersAndSelectedRowsRadioGroupItems: RadioGroupData[] = [
    {
      text: 'All rows',
      value: 'all',
      visible: true,
      disabled: false,
    },
    {
      text: 'Filter result',
      value: 'filter',
      visible: subscriptionPlanSettings.downloadSearchFilterResults,
      disabled: !isFilterEnabled,
    },
    {
      text: 'Selected rows',
      value: 'selected',
      visible: subscriptionPlanSettings.downloadSelectedRows,
      disabled: fileStore?.selectedMoleculeCustomOrdersCount === 0,
    },
  ]

  return (
    <Modal
      width={400}
      open={visible}
      closable={false}
      afterClose={afterClose}
      centered
      destroyOnClose
      title={`Download ${extension} file`}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel-button"
          ghost
          size="large"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="export-button"
          type="primary"
          size="large"
          htmlType="submit"
          form="exportFile"
        >
          Export
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="exportFile"
        initialValues={initialValues}
        onFinish={exportFile}
        ref={formRef}
      >
        <Form.Item
          name="fileName"
          rules={fileNameValidationRules}
        >
          <Input
            addonAfter={extension}
            size="large"
          />
        </Form.Item>
        {
          match(exportSettings)
            .with('NoSettings', () => null)
            .otherwise(() => <div className="text-gray-40 font-sm">Export settings</div>)
        }
        {
          match(exportSettings)
            .with('NoSettings', () => null)
            .with('WithMatches', () => (
              <SearchTypeRadioGroup
                items={matchesRadioGroupItems}
              />
            ))
            .with('WithFiltersAndSelectedRows', () => (
              <SearchTypeRadioGroup
                items={filtersAndSelectedRowsRadioGroupItems}
                itemClassName="block"
              />
            ))
            .exhaustive()
        }
        <Form.Item
          name="zip"
          valuePropName="checked"
          className="mb-0"
        >
          <Checkbox
            onChange={(e: CheckboxChangeEvent) => setExtension(e.target.checked ? '.zip' : '.sdf')}
          >
            Archive as a ZIP file
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ObservedDownloadFileModal = observer(DownloadFileModal)

const DownloadFile = ({
  target: Target,
  className,
  exportTaskData,
  disabled = false,
  disabledClassName,
  exportSettings = 'NoSettings',
  fileStore,
  isInsideIndex,
}: DownloadFileProps): JSX.Element => {
  const [isModalVisible, setModalVisible] = useState(false)

  const onClick = useCallback(() => {
    setModalVisible(true)
  }, [setModalVisible])

  return (
    <>
      {isInsideIndex ? (
        <Target
          disabled={disabled}
          className={cn(className, disabled ? `${disabledClassName}` : '')}
          onClick={onClick}
        />
      )
        : (
          <Tooltip title="Download">
            <span className="file-card-icon">
              <Target
                disabled={disabled}
                className={cn(className, disabled ? `${disabledClassName}` : '')}
                onClick={onClick}
              />
            </span>
          </Tooltip>
        )}
      <ObservedDownloadFileModal
        fileStore={fileStore}
        visible={isModalVisible}
        setModalVisible={setModalVisible}
        exportTaskData={exportTaskData}
        exportSettings={exportSettings}
      />
    </>
  )
}

export default observer(DownloadFile)
