/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Download = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M10.3934 2.06609C10.1866 2.14833 9.98662 2.3598 9.89354 2.59868L9.8108 2.80623L9.80046 7.14131L9.79356 11.4803H8.79722C7.82156 11.4803 7.80087 11.4803 7.6871 11.5704C7.6216 11.6213 7.55265 11.7153 7.53196 11.7858C7.44577 12.0873 7.37337 11.9933 9.6005 14.527C10.7382 15.8271 11.7139 16.9158 11.7656 16.9471C11.8862 17.0176 12.1138 17.0176 12.2344 16.9471C12.2861 16.9158 13.2618 15.8271 14.3995 14.527C16.6266 11.9933 16.5542 12.0873 16.468 11.7858C16.4474 11.7153 16.3784 11.6213 16.3129 11.5704C16.1991 11.4803 16.1784 11.4803 15.2028 11.4803H14.2064L14.1995 7.14131L14.1892 2.80623L14.1065 2.59868C14.0099 2.35588 13.8134 2.14833 13.5928 2.06609C13.3687 1.97602 10.6106 1.97994 10.3934 2.06609Z" />
    <path d="M2.11571 17.0627C1.81353 17.1419 1.45774 17.3637 1.28228 17.5855C0.994728 17.9539 0.989854 17.9895 1.00448 20.0927C1.0191 21.99 1.0191 22.0137 1.13119 22.2039C1.31153 22.5207 1.54547 22.7267 1.911 22.8693L2.23755 23H12.0095H21.7815L22.1081 22.8693C22.4736 22.7267 22.7076 22.5207 22.8879 22.2039C23 22.0098 23 22.0058 23 20.0096V18.0093L22.8392 17.7439C22.576 17.3083 22.113 17.0468 21.5379 17.0072C20.8214 16.9637 20.2073 17.2686 19.8905 17.8232C19.7882 17.9974 19.7833 18.0727 19.7687 19.1857L19.7492 20.366H12.0095H4.26993L4.25044 19.1857C4.23581 18.0727 4.23094 17.9974 4.12859 17.8232C3.97263 17.5459 3.79717 17.3756 3.51449 17.2251C3.08072 16.9993 2.58846 16.9399 2.11571 17.0627Z" />
  </svg>
)

export default Download
