import { observer } from 'mobx-react'

import subscriptionStore from '@store/subscription'
import AcademicPlansOverveiw from '@components/common/AcademicPlansOverview'
import usePaddle from '@shared/hooks/usePaddle'
import { CheckoutEventsCallbacks } from '@shared/services/paddle'

const checkoutCallbacks: CheckoutEventsCallbacks = {
  'checkout.complete': () => subscriptionStore.fetchSubscriptionDetails(),
}

const Subscription = (): JSX.Element => {
  usePaddle(checkoutCallbacks)
  return (
    <div className="flex-col-grow overflow-hidden -mt-12">
      <AcademicPlansOverveiw
        className="mt-6"
      />
    </div>
  )
}

export default observer(Subscription)
