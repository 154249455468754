import {
  useCallback,
} from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { Checkbox } from 'antd'
import { observer } from 'mobx-react'

interface AgGridHeaderLineNumberCheckboxProps {
  displayName: IHeaderParams['displayName']
  loadedRowsCount: number
  isAllSelected: boolean
  selectedRowsCount: number
  onChange: (isAllSelected: boolean) => void
}

const AgGridHeaderLineNumberCheckbox: React.FC<AgGridHeaderLineNumberCheckboxProps> = observer(({
  displayName,

  loadedRowsCount,
  isAllSelected,
  selectedRowsCount,
  onChange,
}) => {
  const toggleSelection = useCallback(() => {
    onChange(!isAllSelected)
  }, [onChange, isAllSelected])

  return (
    <Checkbox
      checked={isAllSelected}
      onClick={toggleSelection}
      disabled={loadedRowsCount === 0}
      indeterminate={selectedRowsCount > 0 && !isAllSelected}
    >
      {displayName}
    </Checkbox>
  )
})

export default AgGridHeaderLineNumberCheckbox
