/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const MoonIcon = ({ className }: ElementProps): ReactElement => (
  <svg
    className={cn(className)}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_77835_82247)">
      <rect x="0.5" width="16" height="16" rx="8" fill="#EFF2F7" />
      <path d="M8.58276 15.9453C9.67133 15.9453 10.6961 15.737 11.657 15.3203C12.6179 14.9036 13.4656 14.3281 14.1999 13.5938C14.9343 12.8594 15.5098 12.0117 15.9265 11.0508C16.3432 10.0898 16.5515 9.06509 16.5515 7.97656C16.5515 6.88802 16.3432 5.86328 15.9265 4.90234C15.5098 3.94141 14.9343 3.09375 14.1999 2.35938C13.4656 1.625 12.6166 1.04948 11.6531 0.632813C10.6895 0.216146 9.6635 0.0078125 8.57495 0.0078125C7.48641 0.0078125 6.46167 0.216146 5.50073 0.632813C4.53979 1.04948 3.69344 1.625 2.96167 2.35938C2.2299 3.09375 1.65568 3.94141 1.23901 4.90234C0.822347 5.86328 0.614014 6.88802 0.614014 7.97656C0.614014 9.06509 0.822347 10.0898 1.23901 11.0508C1.65568 12.0117 2.2312 12.8594 2.96557 13.5938C3.69995 14.3281 4.54761 14.9036 5.50854 15.3203C6.46948 15.737 7.49422 15.9453 8.58276 15.9453Z" fill="#5856D6" />
      <path d="M8.80154 12.6641C8.14006 12.6641 7.52547 12.5534 6.95777 12.332C6.39006 12.1107 5.89526 11.7969 5.47339 11.3906C5.05151 10.9844 4.72209 10.5039 4.48511 9.9492C4.24813 9.39453 4.12964 8.78645 4.12964 8.12496C4.12964 7.48957 4.24552 6.88151 4.4773 6.30078C4.70907 5.72005 5.03459 5.20833 5.45386 4.76562C5.87313 4.32291 6.36141 3.98958 6.9187 3.76562C6.96558 3.74479 7.01375 3.73177 7.06323 3.72656C7.11271 3.72135 7.15047 3.71875 7.17651 3.71875C7.25985 3.71875 7.32496 3.74609 7.37183 3.80078C7.41871 3.85547 7.44214 3.91406 7.44214 3.97656C7.44214 3.9974 7.43954 4.02344 7.43433 4.05469C7.42912 4.08593 7.4161 4.13021 7.39526 4.1875C7.31714 4.375 7.24943 4.625 7.19214 4.9375C7.13485 5.25 7.1062 5.53385 7.1062 5.78906C7.1062 6.60156 7.27026 7.30469 7.59839 7.89844C7.92651 8.49217 8.39007 8.9505 8.98906 9.27344C9.58799 9.59637 10.2963 9.75784 11.114 9.75784C11.3901 9.75784 11.6596 9.73309 11.9226 9.6836C12.1856 9.6341 12.4005 9.58072 12.5671 9.52344C12.6505 9.50258 12.7156 9.49216 12.7624 9.49216C12.8302 9.49216 12.8914 9.5169 12.946 9.5664C13.0007 9.61589 13.0281 9.6849 13.0281 9.77344C13.0281 9.78906 13.0255 9.82032 13.0203 9.8672C13.015 9.91408 13.002 9.96354 12.9812 10.0156C12.7885 10.5052 12.4864 10.9505 12.075 11.3516C11.6635 11.7526 11.1765 12.0716 10.614 12.3086C10.0515 12.5456 9.44735 12.6641 8.80154 12.6641Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_77835_82247">
        <rect x="0.5" width="16" height="16" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>

)

export default MoonIcon
