import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Form, Input, message,
} from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import guestRoutes from '@router/guest-routes'
import AcceptLicenseAgreement from '@components/common/AcceptLicenseAgreement'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { emailRegEx } from '@shared/constants'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import isApiValidationError from '@utils/is-api-validation-error'

async function execute<T>(function_: () => Promise<T>): Promise<T> {
  try {
    return await function_()
  } catch (error) {
    if (isErrorHasMessage(error)) {
      message.error(error.message)
    }
    return Promise.reject(error)
  }
}

const SignUpForm = observer((): ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const handleSubmit = useCallback(async ({ email }: { email: string }) => {
    try {
      const { userExist } = await execute(() => userStore.signUp(email))

      if (userExist) {
        history.push(guestRoutes.LogIn.path)
      }
    } catch (error) {
      if (isApiCustomException(error, 'UsernameExistsException')) {
        await execute(async () => {
          await userStore.restoreSignUpFlowForUncreatedUser(email)
          await userStore.resendSignUpEmail()
        })
      }

      if (isApiValidationError(error)) {
        handleApiValidation(form, error.data)
      }
    }
  }, [form, history])

  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Email is required' },
          {
            pattern: emailRegEx, message: 'Please use valid email', validateTrigger: 'onSubmit',
          },
        ]}
      >
        <Input placeholder="Enter your email" size="large" />
      </Form.Item>
      <AcceptLicenseAgreement />
      <SubmitButton label="Continue" />
    </Form>
  )
})

export default SignUpForm
