import { ReactElement } from 'react'

import StructureImage from '@components/file/StructureImage'
import useRenderStructure from '@shared/hooks/useRenderStructure'

const QueryStructurePreview = ({ queryStructure }: { queryStructure: string }): ReactElement => {
  const imageAsText = useRenderStructure(queryStructure, 'MOL')

  return (
    <div
      className="bg-white dark:bg-gray-70 rounded p-4 flex items-center justify-center"
    >
      <StructureImage
        imageAsText={imageAsText}
        width={250}
        height={138}
      />
    </div>
  )
}

export default QueryStructurePreview
