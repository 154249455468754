/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const DragHandle = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M10.125 3.375H12.375V5.625H10.125V3.375ZM10.125 10.125V7.875H12.375V10.125H10.125ZM10.125 14.625V12.375H12.375V14.625H10.125ZM5.625 5.625V3.375H7.875V5.625H5.625ZM5.625 10.125V7.875H7.875V10.125H5.625ZM5.625 14.625V12.375H7.875V14.625H5.625Z" />
  </svg>
)

export default DragHandle
