import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import routes from '@router/authorized-routes'

export default function Aside(): ReactElement {
  const userMenuItems = [
    routes.SettingsAccount,
    routes.SettingsSubscription,
    // routes.AcademicPlans,
  ]

  return (
    // eslint-disable-next-line
    <div className="capitalize justify-start flex flex-shrink-0 w-[1040px] border-b mb-7 border-gray-30 dark:border-gray-70 z-10">
      {userMenuItems.map(route => (
        <NavLink
          key={route.path}
          to={route.path}
          className="text-sm py-2 px-2 mr-10 text-right"
          activeClassName="text-primary font-medium border-b"
        >
          {route.label}
        </NavLink>
      ))}
    </div>
  )
}
