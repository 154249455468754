import { ReactElement, useState } from 'react'
import { Button, Form, Modal } from 'antd'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import UserStore from '@store/user'
import authorizedRoutes from '@router/authorized-routes'
import { ExclamationCircleFilled } from '@ant-design/icons'
import PasswordInput from '@components/common/PasswordInput'
import { LandingPath } from '@shared/constants'
import { isErrorHasMessage } from '@shared/services/api-service'
import { showErrorMessage } from '@utils/messages'

interface DeleteAccountModalProps {
  visible: boolean
  handleCancel: () => void
}

const ModalTitle = () => (
  <div className="flex items-center">
    <ExclamationCircleFilled className="text-danger text-lg" />
    <span className="ml-2">Delete account</span>
  </div>
)

const DeleteAccountModal = ({ visible = false, handleCancel }: DeleteAccountModalProps): ReactElement => {
  const [modalStatus, setModalStatus] = useState<'initial' | 'loading' | 'error' | 'subscription-error'>('initial')
  const [form] = Form.useForm()

  const handleDelete = async ({ password }: { password: string }) => {
    setModalStatus('loading')

    try {
      await UserStore.deleteAccount(password)
      // eslint-disable-next-line no-restricted-globals
      location.href = LandingPath
    } catch (error) {
      // TODO: better error messages naming?
      if (isErrorHasMessage(error) && error.message === 'INCORRECT_PASSWORD') {
        form.setFields([
          {
            name: 'password',
            errors: ['Incorrect password'],
          },
        ])

        setModalStatus('error')
        return
      }

      if (isErrorHasMessage(error) && error.message === 'SUBSCRIPTION_IS_ACTIVE') {
        setModalStatus('subscription-error')
        return
      }

      setModalStatus('error')
      showErrorMessage()
    }
  }

  return (
    <Modal
      width={430}
      afterClose={() => setModalStatus('initial')}
      open={visible}
      closable={false}
      title={<ModalTitle />}
      footer={[
        <div key="footer" className="flex">
          {
            match(modalStatus)
              .with('subscription-error', () => (
                <>
                  <Button
                    key="new-button-1"
                    className="flex-grow"
                    ghost
                    size="large"
                    href={authorizedRoutes.SettingsSubscription.path}
                  >
                    Subscription settings
                  </Button>
                  <Button
                    key="new-button-2"
                    className="flex-grow"
                    size="large"
                    type="primary"
                    onClick={handleCancel}
                  >
                    Ok
                  </Button>
                </>
              ))
              .otherwise(() => (
                <>
                  <Button
                    className="flex-grow"
                    form="deleteAccount"
                    ghost
                    size="large"
                    htmlType="submit"
                    loading={modalStatus === 'loading'}
                  >
                    Delete account
                  </Button>
                  <Button
                    className="flex-grow"
                    size="large"
                    type="primary"
                    disabled={modalStatus === 'loading'}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              ))
          }
        </div>,
      ]}
    >
      {
        match(modalStatus)
          .with('subscription-error', () => (
            <div className="text-base">
              You cannot delete your account because
              you have an active subscription.
              Please cancel your subscription and try again
            </div>
          ))
          .otherwise(() => (
            <>
              <div className="text-base">
                If you delete your account,
                you will not be able to recover it.
                You will not be eligible for a refund of your remaining subscription period.
                Please enter your password to confirm this action
              </div>
              <Form
                name="deleteAccount"
                form={form}
                onFinish={handleDelete}
              >
                <Form.Item
                  className="mt-6"
                  name="password"
                  rules={[{ required: true, message: 'Please enter current password' }]}
                >
                  <PasswordInput
                    size="large"
                    placeholder="Enter your password"
                  />
                </Form.Item>
              </Form>
            </>
          ))
      }
    </Modal>
  )
}

export default observer(DeleteAccountModal)
