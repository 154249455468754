import { makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'

import { getTaskResult, removeDuplicateMolecules } from '@utils/http'

export interface RemoveDuplicationsTaskDetails {
  type: 'Deduplication'
  details: {
    fileId: string
    fileName: string
  }
}

export interface RemoveDuplicationsTaskInitData {
    fileId: string
    fileName: string
}

export type RemoveDuplicationsTaskProps = {
  type: 'remove-duplications'
  data: RemoveDuplicationsTaskInitData
}

class RemoveDuplicationsTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'remove-duplications'

  readonly fileId: string
  readonly fileName: string

  remained = 0
  removed = 0
  displayed = false

  taskId = nanoid()
  progress = 0
  status: BackgroundTaskStatus = 'Initial'

  abortController = new AbortController()

  constructor({ fileId, fileName }: RemoveDuplicationsTaskInitData) {
    this.fileId = fileId
    this.fileName = fileName

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
      fileId: observable,
      fileName: observable,
      remained: observable,
      removed: observable,
      displayed: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      fileId,
    } = this

    const { taskId, status } = await removeDuplicateMolecules(fileId)

    runInAction(() => {
      this.taskId = taskId
    })

    return { taskId, status }
  }

  async fetchResult(): Promise<void> {
    const { removed, remained } = await getTaskResult<RemoveDuplicationsTask>(this.taskId)

    runInAction(() => {
      this.remained = remained
      this.removed = removed
    })
  }
}

export default RemoveDuplicationsTask
