import { ReactElement } from 'react'
import { Button } from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'

const SubmitButton = observer(({ label }: { label: string }): ReactElement => (
  <Button
    type="primary"
    htmlType="submit"
    size="large"
    loading={userStore.authorizing}
    block
  >
    {label}
  </Button>
))

export default SubmitButton
