import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, message, Modal } from 'antd'
import { observer } from 'mobx-react'

import { SubscriptionLimitErrorData } from '@store/background-tasks'
import subscriptionStore from '@store/subscription'
import authorizedRoutes from '@router/authorized-routes'
import { ExclamationCircleFilled } from '@ant-design/icons'
import DotSeparator from '@components/common/DotSeparator'
import { useAddRowsStore, useFileStore } from '@contexts/add-rows-context'
import { isApiCustomException } from '@shared/services/api-service'
import pluralEnd from '@utils/plural-end'
import BackToFileEditorButton from './BackToFileEditorButton'
import PostNewMoleculesButton from './PostNewMoleculesButton'

interface AddRowPanelProps {
  addNewRow(): void
  onSuccessfulMoleculesAdd(): void
}

const AddRowPanel = observer<React.FC<AddRowPanelProps>>(({ addNewRow, onSuccessfulMoleculesAdd }) => {
  const history = useHistory()
  const fileStore = useFileStore()
  const addRowsStore = useAddRowsStore()
  const isAddRowsDisabled = !subscriptionStore.subscriptionPlanSettings.addRows

  const deleteConfirm = () => {
    const count = addRowsStore.selectedRowsCount

    Modal.confirm({
      title: 'Delete rows',
      icon: <ExclamationCircleFilled />,
      content: `Are you sure you want to delete ${count} selected row${pluralEnd(count)}?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'Cancel',
      cancelButtonProps: { type: 'text' },
      onOk() {
        addRowsStore.deleteSelectedRows()
      },
    })
  }

  const handleStorageLimitError = useCallback(() => {
    const { planName } = subscriptionStore.subscriptionPlanSettings
    const isKnowledgeEdition = planName === 'Knowledge Edition'

    Modal[isKnowledgeEdition ? 'error' : 'confirm']({
      title: 'Record limit exceeded',
      width: 415,
      icon: <ExclamationCircleFilled />,
      content: (
        <div className="mt-6">
          The added rows exceed the record limit for {planName}.
          Please decrease the number of structures in the file
          {isKnowledgeEdition ? '' : ' or upgrade your subscription'}.<br />
          Record limit: {subscriptionStore.subscriptionPlanSettings.storageLimit}
        </div>
      ),
      okText: isKnowledgeEdition ? 'Ok' : 'Change subscription',
      okType: 'primary',
      okButtonProps: { size: 'large' },
      cancelText: isKnowledgeEdition ? undefined : 'Cancel',
      cancelButtonProps: isKnowledgeEdition ? undefined : { ghost: true, size: 'large' },
      onOk: isKnowledgeEdition ? undefined : () => history.push(authorizedRoutes.SettingsSubscription.path),
    })
  }, [history])

  const postMolecules = async () => {
    try {
      const { added, failed } = await addRowsStore.postMolecules()

      if (added > 0) {
        message.success(`${added} row${pluralEnd(added)} successfully added to the dataset`)
      }

      if (failed > 0) {
        message.error(`${failed} row${pluralEnd(failed)} were not added. Please change or remove them`)
      } else {
        onSuccessfulMoleculesAdd()
      }
    } catch (error) {
      if (isApiCustomException<SubscriptionLimitErrorData>(error, 'SubscriptionLimitException')) {
        handleStorageLimitError()
      } else {
        message.error('An error occured on added in rows')
      }
    }
  }

  return (
    <div className="mb-5 flex justify-between">
      <div className="flex items-center">
        <div className="mr-4 flex items-center">
          <h3 className="text-xl">Bulk Add Rows</h3>
        </div>

        <div className="text-sm dark:text-gray-20">
          <span className="text-gray-40 dark:text-gray-50">Displaying: </span>
          <span>{fileStore.extendedProperties.length} Column{pluralEnd(fileStore.extendedProperties.length)}</span>
          <span> / </span>
          <span>{addRowsStore.rowsCount} Row{pluralEnd(addRowsStore.rowsCount)}</span>
        </div>
        {addRowsStore.selectedRowsCount > 0 && (
          <>
            <DotSeparator />
            {`${addRowsStore.selectedRowsCount} Selected`}
            <DotSeparator />
            <span role="button" className="text-primary" onClick={deleteConfirm}>Delete</span>
          </>
        )}
      </div>

      <div className="flex justify-end items-center ml-6">
        <BackToFileEditorButton fileId={addRowsStore.fileId} />

        <PostNewMoleculesButton
          postMolecules={postMolecules}
          postingMolecules={addRowsStore.postingNewRows}
          disabled={addRowsStore.rowsCount < 1 || fileStore.readOnly || isAddRowsDisabled}
        />

        <Button className="ml-3" type="primary" onClick={addNewRow}>
          + Create a new row
        </Button>
      </div>
    </div>
  )
})

export default AddRowPanel
