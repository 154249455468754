import { columnNameBlacklist } from '@shared/constants'
import { sanitizeString } from '../string-utils'

interface Store {
  isPropertyExists(propertyName: string): boolean
}

const MAX_COLUMN_NAME_LENGTH = 99

const ValidSymbolsRegExp = /^[a-zA-Z][a-zA-Z0-9_(){}[\] ]*$/

const columnNameValidator = (store: Store, str: string): Validation<string> => {
  const value = sanitizeString(str)

  if (value.length === 0) {
    return {
      status: 'error',
      value,
      errorMsg: 'Please set a column name',
    }
  }

  if (value.length > MAX_COLUMN_NAME_LENGTH) {
    return {
      status: 'error',
      value,
      errorMsg: 'The name is too long',
    }
  }

  if (!ValidSymbolsRegExp.test(value)) {
    return {
      status: 'error',
      value,
      errorMsg: 'Invalid symbol is used',
    }
  }

  if (store.isPropertyExists(value)) {
    return {
      status: 'error',
      value,
      errorMsg: 'This name is already used',
    }
  }

  if (columnNameBlacklist.some(forbiddenName => value.toLowerCase() === forbiddenName)) {
    return {
      status: 'error',
      value,
      errorMsg: 'This name cannot be used',
    }
  }

  return {
    status: 'success',
    value,
  }
}

export default columnNameValidator
