import FileStore from '@store/file'
import TableManagementStore from '@store/table-management'
import AgGridApiService from '@shared/services/AgGridApiService'
import createStoreContext from './stores-context'

const context = createStoreContext({
  fileStore: new FileStore('fake-file-id'),
  agGridApiService: new AgGridApiService(),
  tableManagementStore: new TableManagementStore(),
})

export const { useFileStore, useAgGridApiService, useTableManagementStore } = context

export default context.Provider
