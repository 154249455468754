import {
  Component, ErrorInfo, ReactElement, ReactNode,
} from 'react'
import { notification } from 'antd'

import { ExclamationCircleFilled } from '@ant-design/icons'
import * as Sentry from '@sentry/react'

export default class AppErrorBoundary extends Component<{ children?: ReactElement }> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { name, message } = error
    const namePrefix = name ? `${name}: ` : ''
    // eslint-disable-next-line no-console
    console.error(error)
    // eslint-disable-next-line no-console
    console.log(errorInfo)

    Sentry.captureException(error)

    notification.error({
      message: 'We have some error',
      description: `${namePrefix}${message}`,
      icon: <ExclamationCircleFilled />,
    })
  }

  render(): ReactNode {
    const { children } = this.props
    return children
  }
}
