import {
  makeAutoObservable, runInAction,
} from 'mobx'
import { nanoid } from 'nanoid'

import { addMolecules } from '@utils/http'

export default class AddRows {
  private _moleculeRows: Molecule[] = []
  private _selectedRowCustomOrders: number[] = []
  private _postingNewRows = false
  private _molCustomOrder = 1

  constructor(
    private readonly _fileId: string,
  ) {
    makeAutoObservable(this)
  }

  addNewRow(structure: string): void {
    this._moleculeRows.push({
      id: nanoid(),
      customOrder: this.getNewCustomOrder(),
      structure,
      molproperties: {},
    })
  }

  private getNewCustomOrder(): number {
    const customOrder = this._molCustomOrder
    this._molCustomOrder += 1
    return customOrder
  }

  async postMolecules(): Promise<{ added: number, failed: number}> {
    try {
      this._postingNewRows = true
      const newMolecules = this.mapNewMoleculesToServerFormat()
      const notAdded = await addMolecules(this._fileId, newMolecules)

      let invalidRowCustomOrders: number[] = []

      if (notAdded != null && Array.isArray(notAdded) && notAdded.length) {
        invalidRowCustomOrders = notAdded.map(newMol => newMol.customOrder)
      }

      const addedMoleculeCustomOrders = newMolecules
        .map(mol => mol.customOrder)
        .filter(customOrder => !invalidRowCustomOrders.includes(customOrder))

      this.deleteMolecules(addedMoleculeCustomOrders)

      return {
        failed: invalidRowCustomOrders.length,
        added: addedMoleculeCustomOrders.length,
      }
    } finally {
      runInAction(() => {
        this._postingNewRows = false
      })
    }
  }

  deleteSelectedRows(): void {
    this.deleteMolecules(this._selectedRowCustomOrders)
    this._selectedRowCustomOrders = []
  }

  setMolProperty(rowCustomOrder: number, key: string | number, value: MolpropertyValue | null): void {
    const foundRawMol = this.getRowByCustomOrder(rowCustomOrder)
    if (!foundRawMol) return

    if (value == null) {
      delete foundRawMol.molproperties[key]
    } else {
      foundRawMol.molproperties[key] = value
    }
  }

  setMolStructure(rowCustomOrder: number, structure: string): void {
    const foundRawMol = this.getRowByCustomOrder(rowCustomOrder)
    if (!foundRawMol) return

    foundRawMol.structure = structure
  }

  setMolData(rowCustomOrder: number, key: string, value: MolpropertyValue | null): void {
    if (key === 'Structure') {
      this.setMolStructure(rowCustomOrder, value as string || '')
    } else {
      this.setMolProperty(rowCustomOrder, key, value)
    }
  }

  get moleculeRows(): MoleculeRow[] {
    return this._moleculeRows
      .map(({
        id, customOrder, structure, molproperties,
      }) => ({
        id,
        customOrder,
        Structure: structure,
        ...molproperties,
      }))
  }

  set moleculeRows(rows: MoleculeRow[]) {
    this._moleculeRows = rows.map(({
      id, customOrder, Structure, ...molproperties
    }) => ({
      id,
      customOrder,
      structure: Structure,
      molproperties,
    }))
  }

  private mapNewMoleculesToServerFormat(): Molecule[] {
    return this._moleculeRows
      .map(({
        id, customOrder, structure, molproperties,
      }) => ({
        id,
        customOrder,
        structure,
        molproperties,
      }))
  }

  private deleteMolecules(customOrders: number[]) {
    this._moleculeRows = this._moleculeRows
      .filter(mol => !customOrders.includes(mol.customOrder))
  }

  get rowsCount(): number {
    return this._moleculeRows.length
  }

  get postingNewRows(): boolean {
    return this._postingNewRows
  }

  get fileId(): string {
    return this._fileId
  }

  get selectedRowCustomOrder(): number[] {
    return this._selectedRowCustomOrders.slice()
  }

  set selectedRowCustomOrder(selectedRowCustomOrders: number[]) {
    this._selectedRowCustomOrders = selectedRowCustomOrders
  }

  get selectedRowsCount(): number {
    return this._selectedRowCustomOrders.length
  }

  getRowByCustomOrder(customOrder: number): Molecule | undefined {
    return this._moleculeRows.find(mr => mr.customOrder === customOrder)
  }
}
