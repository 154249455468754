import { Modal } from 'antd'
import { makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import FileStore from '@store/file'
import apiService from '@shared/services/api-service'
import {
  getTaskResult,
} from '@utils/http'

interface FetchedDataType {
  errorMap: { [key: string]: string }
}

export interface ConvertTaskDetails {
  type: 'Convert'
  details: {
    fileId: string
    column: IndexProperty
    fileName: string
    fileStore: FileStore
  }
}

interface ConvertTaskInitData {
  fileId: string
  column: IndexProperty
  fileName: string
  fileStore: FileStore
}

export type ConvertTaskProps = {
  type: 'convert'
  data: ConvertTaskInitData
}

class ConvertTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'convert'

  readonly fileId: string
  readonly fileName: string
  readonly column: IndexProperty
  readonly fileStore: FileStore

  taskId = ''
  progress = 0
  status: BackgroundTaskStatus = 'Initial'

  abortController = new AbortController()

  constructor({
    fileId, column, fileName, fileStore,
  }: ConvertTaskInitData) {
    this.fileId = fileId
    this.column = column
    this.fileName = fileName
    this.fileStore = fileStore

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      column,
      fileId,
      abortController,
    } = this

    const { taskId, status } = await apiService.post<TaskProgress>(`/index/${fileId}/property/convert`, {
      json: {
        propertyId: column.id,
      },
      signal: abortController.signal,
    })

    return { taskId, status }
  }

  async fetchResult(): Promise<void> {
    if (!this.taskId) return
    const { errorMap } = await getTaskResult<FetchedDataType>(this.taskId)
    const errorMessage = Object.values(errorMap)[0]

    if (errorMessage) {
      Modal.info({
        title: 'Alert',
        content: `${errorMessage}`,
      })
    }
    this.fileStore.fetchIndexProperties()
  }
}

export default ConvertTask
