import { makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import { defaultSearchParams } from '@shared/constants'
import apiService from '@shared/services/api-service'

export interface ExportTaskDetails {
  type: 'Export'
  details: {
    fileId: string
    fileName: string
    zip: boolean
    searchType: SearchType
  }
}

interface ExportTaskInitData {
  fileId: string
  fileName: string
  zip: boolean
  searchType: SearchType
  searchParams?: IndexSearchParams
  selectedMoleculesParams?: SelectedMoleculesParams
}

export type ExportTaskProps = {
  type: 'export'
  data: ExportTaskInitData
}

class ExportTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'export'

  readonly fileId: string
  readonly fileName: string
  readonly zip: boolean

  searchParams: IndexSearchParams
  taskId = ''
  progress = 0
  status: BackgroundTaskStatus = 'Initial'
  selectedMoleculesParams: SelectedMoleculesParams | undefined
  searchType: SearchType

  abortController = new AbortController()

  constructor({
    fileId, searchParams = defaultSearchParams, fileName, zip, selectedMoleculesParams, searchType,
  }: ExportTaskInitData) {
    this.fileId = fileId
    this.searchParams = searchParams
    this.fileName = fileName.trim()
    this.zip = zip
    this.selectedMoleculesParams = selectedMoleculesParams
    this.searchType = searchType

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      zip, fileName, searchParams, selectedMoleculesParams, fileId, abortController, searchType,
    } = this

    const data = searchType === 'selected'
      ? { moleculesRequest: selectedMoleculesParams }
      : {
        moleculesRequest: {
          searchStructure: searchParams,
        },
      }

    const { taskId, status } = await apiService.post<TaskProgress>('/index/download', {
      json: {
        fileId,
        fileName,
        zip,
        ...data,
      },
      signal: abortController.signal,
    })

    return { taskId, status }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async fetchResult(): Promise<void> {}
}

export default ExportTask
