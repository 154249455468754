import { message } from 'antd'

const showErrorMessage = (msg?: string, {
  duration,
} = {
  duration: 1.5,
}): void => {
  message.error({
    content: msg || 'Something went wrong. Try again later',
    duration,
  })
}

const showSuccessMessage = (msg?: string): void => {
  message.success({
    content: msg || 'Ok',
  })
}

export {
  showErrorMessage,
  showSuccessMessage,
}
