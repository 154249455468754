import { useState } from 'react'
import { Button, Modal, Table } from 'antd'
import { observer } from 'mobx-react'

import appStore from '@store/app'
import AboutIcon from '@components/icons/AboutIcon'
import packageInfo from '../../../package.json'

interface BrowserInfo {
  name: string
  version: string
}

function getBrowserInfo(): BrowserInfo {
  const { userAgent } = window.navigator
  let name = 'Unknown'
  let version = ''

  if (userAgent.indexOf('Chrome') !== -1) {
    name = 'Google Chrome'
    version = userAgent.match(/Chrome\/([\d.]+)/)?.[1] || ''
  } else if (userAgent.indexOf('Firefox') !== -1) {
    name = 'Mozilla Firefox'
    version = userAgent.match(/Firefox\/([\d.]+)/)?.[1] || ''
  } else if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Version/') !== -1) {
    name = 'Apple Safari'
    version = userAgent.match(/Version\/([\d.]+)/)?.[1] || ''
  } else if (userAgent.indexOf('Edg') !== -1 || userAgent.indexOf('Edge') !== -1) {
    name = 'Microsoft Edge'
    version = userAgent.match(/(Edg|Edge)\/([\d.]+)/)?.[2] || ''
  } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    name = 'Opera'
    version = userAgent.match(/(Opera|OPR)\/([\d.]+)/)?.[2] || ''
  } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
    name = 'Internet Explorer'
    version = userAgent.match(/(MSIE |rv:)([\d.]+)/)?.[2] || ''
  }

  return {
    name,
    version,
  }
}

function getOperatingSystemWithVersion() {
  const { userAgent } = navigator
  let os = 'Unknown'

  if (userAgent.includes('Windows NT 10.0')) {
    os = 'Windows 10'
  } else if (userAgent.includes('Windows NT 6.3')) {
    os = 'Windows 8.1'
  } else if (userAgent.includes('Windows NT 6.2')) {
    os = 'Windows 8'
  } else if (userAgent.includes('Windows NT 6.1')) {
    os = 'Windows 7'
  } else if (userAgent.includes('Windows NT 6.0')) {
    os = 'Windows Vista'
  } else if (userAgent.includes('Windows NT 5.1') || userAgent.includes('Windows NT 5.2')) {
    os = 'Windows XP'
  } else if (userAgent.includes('Windows Phone')) {
    os = 'Windows Phone'
  } else if (userAgent.includes('Android')) {
    os = 'Android'
  } else if (userAgent.includes('Mac OS X')) {
    os = 'macOS'
  } else if (userAgent.includes('Linux')) {
    os = 'Linux'
  }

  return os
}

const userOS = getOperatingSystemWithVersion()
const browserInfo = getBrowserInfo()

const ketcherCoreVersion = packageInfo.dependencies['ketcher-core']

const AboutVersions = (): JSX.Element => {
  const versions = appStore.componentVersions
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { theme } = appStore

  const showAboutModal = () => {
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const columns = [
    {
      title: 'Component',
      dataIndex: 'component',
      key: 'component',
      render: (text: string) => <span style={{ fontWeight: 600, fontSize: 16 }}>{text}</span>,

    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      render: (text: string) => <span style={{ fontWeight: 400 }}>{text}</span>
      ,
    },
  ]
  const data = [
    {
      key: '1',
      component: 'Front-end',
      version: process.env.REACT_APP_FE_VERSION || 'n/a',
    },
    {
      key: '2',
      component: 'Back-end',
      version: `${versions.sdfProBEVersion || 'n/a'}`,
    },
    {
      key: '3',
      component: 'Indigo',
      version: `${versions.indigoVersion || 'n/a'}`,
    },
    {
      key: '4',
      component: 'RDKit',
      version: `${versions.rdKitVersion || 'n/a'}`,
    },
    {
      key: '5',
      component: 'Ketcher',
      version: `${ketcherCoreVersion}`,

    },
    {
      key: '6',
      component: 'Elastic Search',
      version: `${versions.elasticSearchVersion || 'n/a'}`,
    },
    {
      key: '7',
      component: 'Database',
      version: `${versions.databaseVersion || 'n/a'}`,
    },
    {
      key: '8',
      component: 'QDP',
      version: `${versions.qdpApiVersion || 'n/a'}`,
    },
    {
      key: '9',
      component: 'Akka',
      version: `${versions.akkaVersion || 'n/a'}`,
    },
    {
      key: '10',
      component: 'JVM',
      version: `${versions.jvmVersion || 'n/a'}`,
    },
    {
      key: '11',
      component: 'OS',
      version: `${userOS || 'n/a'}`,
    },
    {
      key: '12',
      component: 'Browser',
      version: `${browserInfo.name || 'n/a'} ${browserInfo.version || 'n/a'}`,
    },
  ]
  const tableStyles = {
    borderRadius: 8,
  }

  const cancelButtonStyle = {
    borderColor: '#565962',
    backgroundColor: 'transparent',
    width: '120px',
    height: '40px',
    fontWeight: 600,
    color: `${theme === 'dark' ? 'white' : '#393e48'}`,
  }

  return (
    <div>
      <div
        className="flex items-center font-normal p-0"
        onClick={showAboutModal}
        role="button"
      >
        <AboutIcon
          className="w-4 h-4 mr-2"
          color="var(--color-primary-default)"
        />
        About
      </div>
      <Modal
        title={<div className="text-2xl">About</div>}
        open={isModalVisible}
        onCancel={handleModalClose}
        width={680}
        closable
        footer={(
          <Button key="cancel" onClick={handleModalClose} style={cancelButtonStyle}>
            Cancel
          </Button>
        )}
      >
        <Table columns={columns} dataSource={data} pagination={false} style={tableStyles} />
      </Modal>
    </div>
  )
}

export default observer(AboutVersions)
