import { createContext, useContext } from 'react'
import noop from 'lodash/noop'

interface KetcherContextState {
  modals: KetcherModal[]
  addModal: (props: AddKetcherModalProps) => void
  removeModal: (id: string) => void
  canAddModal: (id: string) => boolean
  clearModals: () => void
}

const initialState: KetcherContextState = {
  modals: [],

  addModal: noop,
  removeModal: noop,
  canAddModal: () => true,
  clearModals: noop,
}

const KetcherContext = createContext<KetcherContextState>(initialState)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useKetcherContext = () => useContext(KetcherContext)

export default KetcherContext
