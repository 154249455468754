import { useEffect, useState } from 'react'
import { Button, Form, message } from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import { showErrorMessage } from '@utils/messages'
import MarketingConsentCheckbox from './MarketingConsentCheckbox'

interface FormFields {
  consent: boolean
}

const KeepInTouchForm = () => {
  const {
    marketingConsent,
  } = userStore

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      consent: marketingConsent,
    })
  }, [form, marketingConsent])

  const initialValues: FormFields = {
    consent: !!marketingConsent,
  }

  const handleSubmit = async ({ consent }: FormFields) => {
    const newConsent = marketingConsent ? false : consent

    if (marketingConsent === newConsent) {
      return
    }

    const text = newConsent
      ? 'You have subscribed to Adroit REPOSITORY updates.'
      : 'You have unsubscribed from Adroit REPOSITORY updates.'

    setLoading(true)

    try {
      await userStore.updateMarketingConsent(newConsent)
      message.success(text)
    } catch (error) {
      showErrorMessage()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="text-base mt-4">Get the latest updates on REPOSITORY by email!</div>
      <Form
        className="flex flex-col mt-4"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <MarketingConsentCheckbox disabled={!!marketingConsent} />
        <Form.Item className="self-end mt-3 mb-0">
          {marketingConsent ? (
            <Button
              key="ghost"
              loading={loading}
              ghost
              htmlType="submit"
            >
              Unsubscribe from emails
            </Button>
          ) : (
            <Button
              key="primary"
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  )
}

KeepInTouchForm.displayName = 'KeepInTouchForm'

export default observer(KeepInTouchForm)
