import { useEffect } from 'react'

interface ScriptProps {
  innerHTML?: string
  src?: string
  async?: boolean
  type?: string
  onload?: () => void
  onerror?: () => void
}

const useScript = ({
  innerHTML,
  src,
  type,
  async = false,
  onload,
  onerror,
}: ScriptProps): void => {
  useEffect(() => {
    const script = document.createElement('script')
    document.body.appendChild(script)

    if (onload) {
      script.onload = () => onload()
    }

    if (onerror) {
      script.onerror = () => onerror()
    }

    if (src) {
      script.src = src
    }

    if (innerHTML) {
      script.innerHTML = innerHTML
    }

    if (type) {
      script.type = type
    }

    script.async = async

    return () => {
      document.body.removeChild(script)
    }
  }, [async, innerHTML, onerror, onload, src, type])
}

export default useScript
