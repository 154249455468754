import { memo } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { observer } from 'mobx-react'

interface LinkRendererProps extends ICellRendererParams {
  value: string
}

const LinkRenderer: React.FC<LinkRendererProps> = observer(({ value }) => {
  const href = value && value.startsWith('https://') ? value : `https://${value || ''}`

  return (
    <a className="underline" href={href} target="_blank" rel="noreferrer">
      {value}
    </a>
  )
})

export default memo(LinkRenderer)
