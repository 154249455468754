import { Modal } from 'antd'
import { makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import FileStore from '@store/file'
import apiService from '@shared/services/api-service'
import { getTaskResult } from '@utils/http'

interface FetchedDataType {
  errorMap: { [key: string]: string }
}

export interface AddColumnTaskDetails {
  type: 'BulkUpdate'
  details: {
    defaultValue: string
    deleted: string
    fileId: string
    fileName: string
    hidden: string
    isFormula: string
    name: string
    type: IndexPropertyType
    id: string
    fileStore: FileStore
  }
}

interface AddColumnTaskInitData {
  fileId: string
  fileName: string
  column: IndexProperty
  formula?: string
  fileStore: FileStore
}

export type AddColumnTaskProps = {
  type: 'add-column'
  data: AddColumnTaskInitData
}

class AddColumnTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'add-column'

  readonly fileId: string
  readonly column: IndexProperty
  readonly fileName: string
  readonly formula: string
  readonly fileStore: FileStore

  taskId = ''
  progress = 0
  status: BackgroundTaskStatus = 'Initial'

  abortController = new AbortController()

  constructor({
    fileId, column, fileName, formula = '', fileStore,
  }: AddColumnTaskInitData) {
    this.fileId = fileId
    this.column = column
    this.fileName = fileName.trim()
    this.formula = formula
    this.fileStore = fileStore

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      column,
      formula,
      fileId,
      abortController,
    } = this

    const { taskStatus, propertyId } = await apiService.post<AddColumnTaskProgress>(`/index/${fileId}/property`, {
      json: {
        isFormula: Boolean(formula),
        ...column,
      },
      signal: abortController.signal,
    })
    const { taskId, status } = taskStatus
    column.id = propertyId
    const index = this.fileStore.indexProperties.findIndex((item: IndexProperty) => item === column)
    this.fileStore.indexProperties[index] = column

    return { taskId, status }
  }

  async fetchResult(): Promise<void> {
    if (!this.taskId) return
    const { errorMap } = await getTaskResult<FetchedDataType>(this.taskId)
    const errorMapLength = Object.keys(errorMap).length

    if (errorMapLength !== 0) {
      Modal.info({
        title: 'Alert',
        content: `During the operation, it appears that ${errorMapLength} molecule(s) may not have complete property values available.`,
      })
    }
  }
}

export default AddColumnTask
