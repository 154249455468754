import { useMemo } from 'react'

import appStore from '@store/app'

export default function useAgGridThemeClassName(): string {
  const { theme } = appStore

  return useMemo<string>(
    () => (theme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'),
    [theme],
  )
}
