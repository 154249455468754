import {
  ReactElement, useCallback,
} from 'react'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import guestRoutes from '@router/guest-routes'
import ConfirmTemporaryForm from '@components/guest-forms/ConfirmTemporaryForm'
import CreateAnAccountForm from '@components/guest-forms/CreateAnAccountForm'
import SignUpForm from '@components/guest-forms/SignUpForm'
import PageFormContainer from '@components/hoc/PageFormContainer'
import { LandingPath } from '@shared/constants'

const HelperBottom = observer((): ReactElement => {
  const resendEmailHandle = useCallback(async () => {
    try {
      await userStore.resendSignUpEmail()

      message.success('Temporary password has been resent')
    } catch (error) {
      message.error('Something went wrong')
    }
  }, [])

  const { email } = userStore.signUpData
  const resendButton = email
    ? (
      <span
        className="text-gray-30 dark:text-gray-40 underline"
        onClick={resendEmailHandle}
        role="button"
      >Resend
      </span>
    )
    : <></>

  return (
    <div className="flex justify-between mt-4">
      <span className="text-gray-70 dark:text-white">Already have an account?
        <Link to={guestRoutes.LogIn.path} className="text-primary font-semibold"> Sign In</Link>
      </span>
      { resendButton }
    </div>
  )
})

const CreateAnAccountContent = (): ReactElement => (
  <>
    <h2 className="text-3xl font-bold text-center mb-14">Welcome to SDF Editor!</h2>
    <CreateAnAccountForm />
  </>
)

const ConfirmTemporaryContent = observer((): ReactElement => (
  <>
    <h2 className="text-3xl font-bold text-center mb-5">Check your email</h2>
    <p className="text-center text-gray-70 dark:text-white mb-8">
      We sent you a temporary password to<br /> { userStore.signUpData.email }
    </p>
    <ConfirmTemporaryForm />
    <HelperBottom />
  </>
))

const SignUpContent = (): ReactElement => (
  <>
    <h2 className="text-3xl font-bold text-center mb-8">Start Your Free Trial!</h2>
    <SignUpForm />
    <HelperBottom />
  </>
)

const SignUp = observer((): ReactElement => {
  const { email, userExist, session } = userStore.signUpData

  const onEditEmailHandle = useCallback(() => {
    userStore.signUpData = {}
  }, [])
  const backHomeButton = { label: 'Back to the homepage', path: LandingPath }
  const editEmailButton = { label: 'Edit email', onClick: onEditEmailHandle }

  let Content
  let backButton: BackButton | undefined

  if (session) {
    Content = CreateAnAccountContent
    backButton = undefined
  } else {
    Content = email && !userExist ? ConfirmTemporaryContent : SignUpContent
    backButton = email ? editEmailButton : backHomeButton
  }

  return (
    <PageFormContainer testId="sign-up-page" backButton={backButton}>
      <Content />
    </PageFormContainer>
  )
})

export default SignUp
