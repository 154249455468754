/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const QuantoriLogo = ({ className, onClick }: ElementProps): ReactElement => (
  <div>
    <svg
      className={cn('sdf-icon fill-current', className)}
      onClick={onClick}
      width="150"
      height="33"
      viewBox="0 0 150 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.25486 12.9799C3.51482 13.0198 3.78029 12.9608 3.99878 12.8144C4.21728 12.668 4.37289 12.4449 4.43486 12.1893C4.43486 12.1293 4.45665 12.0693 4.47762 12.0116C4.48552 11.9899 4.4933 11.9684 4.49976 11.9474C4.98973 10.0939 5.97071 8.40689 7.3393 7.06426C8.70789 5.72163 10.4134 4.77313 12.276 4.31874C12.5331 4.25804 12.7581 4.1032 12.9066 3.88476C13.0551 3.66632 13.1164 3.40015 13.0784 3.13875C13.0578 2.99306 13.007 2.85328 12.9292 2.72838C12.8514 2.60347 12.7484 2.4962 12.6267 2.41343C12.5051 2.33066 12.3675 2.27423 12.2227 2.24775C12.078 2.22126 11.9293 2.22532 11.7863 2.25965C9.55477 2.79914 7.50982 3.9298 5.86633 5.53279C4.22285 7.13578 3.04154 9.1519 2.44656 11.3692L2.37576 11.6406C2.33671 11.7868 2.32897 11.9395 2.35305 12.0888C2.37712 12.2381 2.43247 12.3807 2.51546 12.5071C2.59846 12.6335 2.70723 12.741 2.83465 12.8225C2.96207 12.904 3.10527 12.9576 3.25486 12.9799Z" />
      <path d="M21.8438 6.27528C21.8263 6.00978 21.9091 5.7474 22.0758 5.54004L22.064 5.56364C22.1551 5.44757 22.2693 5.35173 22.3994 5.28224C22.5295 5.21275 22.6727 5.17112 22.8198 5.16002C22.9669 5.14892 23.1147 5.16858 23.2537 5.21776C23.3928 5.26694 23.5201 5.34455 23.6275 5.44564C25.2912 7.03104 26.4977 9.03485 27.1206 11.247C27.7434 13.4591 27.7596 15.798 27.1675 18.0185C27.1598 18.0418 27.1508 18.0651 27.1417 18.0885C27.1165 18.154 27.0908 18.2204 27.0908 18.2899C27.046 18.4285 26.9734 18.5565 26.8775 18.6661C26.7816 18.7757 26.6645 18.8647 26.5331 18.9276C26.4018 18.9906 26.259 19.0261 26.1135 19.0321C25.9679 19.0381 25.8227 19.0144 25.6866 18.9625C25.4412 18.8657 25.2405 18.681 25.1238 18.4443C25.0071 18.2076 24.9827 17.9359 25.0553 17.6822C25.0627 17.6564 25.0719 17.6311 25.0809 17.606C25.1008 17.551 25.1202 17.497 25.1202 17.4403C25.6175 15.5918 25.6058 13.6433 25.0865 11.8008C24.5672 9.95837 23.5596 8.29053 22.1702 6.97374C21.9778 6.79003 21.8613 6.54077 21.8438 6.27528Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44.5962 18.7207L45.7172 19.7296L47.1332 18.1661L46.083 17.2752C46.6842 16.4101 46.9999 15.3786 46.9857 14.3252C46.9996 13.6267 46.8701 12.9329 46.6053 12.2864C46.3404 11.64 45.9458 11.0548 45.4458 10.5669C44.3941 9.57745 43.0046 9.02654 41.5607 9.02654C40.1167 9.02654 38.7272 9.57745 37.6755 10.5669C37.1715 11.0502 36.773 11.6325 36.5049 12.2772C36.2369 12.922 36.1051 13.6152 36.1179 14.3134C36.1057 15.0123 36.2377 15.7062 36.5057 16.3519C36.7737 16.9975 37.1719 17.5809 37.6755 18.0658C38.1854 18.5705 38.7923 18.9666 39.4596 19.2303C40.1268 19.4939 40.8405 19.6196 41.5577 19.5998C42.6348 19.611 43.6915 19.3053 44.5962 18.7207ZM42.9265 17.2457C42.5054 17.434 42.0485 17.5286 41.5872 17.523C41.1697 17.5347 40.7545 17.4577 40.3691 17.297C39.9836 17.1363 39.6366 16.8956 39.3511 16.5908C38.7723 15.9758 38.4586 15.1577 38.4779 14.3134C38.4593 13.4634 38.7774 12.6406 39.3629 12.0242C39.6423 11.72 39.9841 11.4797 40.365 11.3198C40.7458 11.1599 41.1567 11.0842 41.5695 11.0979C41.9843 11.0864 42.3968 11.1636 42.7794 11.3243C43.162 11.4851 43.5058 11.7257 43.7879 12.0301C44.365 12.6482 44.6764 13.468 44.6552 14.3134C44.6649 14.8036 44.5704 15.2902 44.3779 15.7412L42.6964 14.2249L41.2627 15.8297L42.9265 17.2457Z" />
      <path d="M51.6383 19.3245C52.198 19.5272 52.7928 19.6149 53.3872 19.5821C53.99 19.6204 54.5944 19.5367 55.1642 19.336C55.7339 19.1353 56.2573 18.8216 56.703 18.4139C57.1232 17.9497 57.4445 17.4048 57.6474 16.8124C57.8502 16.2201 57.9304 15.5926 57.883 14.9683V9.19806H55.6351V15.0332C55.6769 15.6937 55.466 16.3455 55.0451 16.8563C54.8348 17.0721 54.5806 17.2403 54.2996 17.3492C54.0186 17.4581 53.7175 17.5053 53.4167 17.4876C53.1143 17.505 52.8118 17.456 52.5304 17.3439C52.2491 17.2317 51.9957 17.0592 51.7883 16.8386C51.3666 16.3052 51.1565 15.6351 51.1983 14.9565V9.17446H48.9504V15.0155C48.9094 15.6303 48.9927 16.2471 49.1955 16.8289C49.3982 17.4108 49.7162 17.9458 50.1304 18.4021C50.5658 18.808 51.0787 19.1217 51.6383 19.3245Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M59.1752 19.4227L63.553 9.12724H65.618L70.0017 19.4227H67.6417L66.7272 17.1099H62.4025L61.488 19.4227H59.1752ZM63.2049 15.1452H65.9248L64.5619 11.8294L63.2049 15.1452Z" />
      <path d="M71.7362 9.19804V19.4227H73.9487V12.9327L78.9047 19.4227H80.8163V9.19804H78.5979V15.4933L73.813 9.19804H71.7362Z" />
      <path d="M85.8018 19.4227V11.2748H82.6925V9.19804H91.1649V11.2748H88.0379V19.4227H85.8018Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M97.5694 19.5958C99.0125 19.5958 100.402 19.0467 101.455 18.0599C101.961 17.5793 102.362 16.9997 102.634 16.3572C102.906 15.7146 103.043 15.0229 103.036 14.3252C103.05 13.6262 102.92 12.9317 102.654 12.2851C102.388 11.6385 101.992 11.0536 101.49 10.5669C100.438 9.57745 99.0488 9.02654 97.6048 9.02654C96.1609 9.02654 94.7714 9.57745 93.7197 10.5669C93.2165 11.0505 92.8189 11.633 92.5518 12.2778C92.2848 12.9225 92.1541 13.6156 92.168 14.3134C92.1513 15.0083 92.2772 15.6993 92.538 16.3437C92.7988 16.9881 93.1889 17.5722 93.6843 18.0599C94.7373 19.0467 96.1263 19.5958 97.5694 19.5958ZM96.3802 17.2965C95.9976 17.1357 95.6538 16.8951 95.3717 16.5908C94.7912 15.9764 94.4755 15.1584 94.4926 14.3134C94.4701 13.4672 94.7793 12.6457 95.354 12.0242C95.6339 11.7206 95.9758 11.4807 96.3565 11.3209C96.7372 11.1611 97.1479 11.085 97.5606 11.0979C97.9754 11.0864 98.3879 11.1636 98.7705 11.3243C99.1531 11.4851 99.4969 11.7257 99.779 12.0301C100.363 12.6445 100.679 13.4658 100.658 14.3134C100.679 15.1576 100.37 15.9766 99.7967 16.5967C99.5173 16.9009 99.1755 17.1412 98.7946 17.301C98.4138 17.4609 98.0029 17.5366 97.5901 17.523C97.1753 17.5345 96.7628 17.4573 96.3802 17.2965Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M105.071 19.4227V9.19803H109.744C110.285 9.16178 110.828 9.23506 111.34 9.41349C111.852 9.59192 112.322 9.87182 112.724 10.2364C113.022 10.55 113.254 10.9208 113.405 11.3264C113.556 11.732 113.623 12.1641 113.603 12.5964C113.648 13.2946 113.455 13.9875 113.057 14.5626C112.658 15.1377 112.077 15.5613 111.408 15.7647L113.909 19.4168H111.278L109.06 16.1482H107.319V19.4168L105.071 19.4227ZM107.319 14.1599H109.597C110.056 14.1871 110.51 14.0443 110.871 13.7587C111.02 13.6237 111.137 13.4574 111.215 13.2719C111.292 13.0863 111.328 12.8859 111.319 12.6849C111.333 12.4791 111.298 12.273 111.217 12.0834C111.136 11.8937 111.011 11.7258 110.853 11.5934C110.475 11.3255 110.018 11.1943 109.555 11.2217H107.319V14.1599Z" />
      <path d="M115.88 9.19804V19.4227H118.128V9.19804H115.88Z" />
      <path d="M14.8071 18.9449C17.163 18.9449 19.0728 17.035 19.0728 14.6792C19.0728 12.3233 17.163 10.4135 14.8071 10.4135C12.4512 10.4135 10.5414 12.3233 10.5414 14.6792C10.5414 17.035 12.4512 18.9449 14.8071 18.9449Z" />
      <path d="M18.5634 24.9807C18.8034 25.0974 18.9913 25.2993 19.0905 25.5469H19.0964C19.151 25.6836 19.1765 25.8301 19.1715 25.9771C19.1664 26.1242 19.1309 26.2685 19.0671 26.4011C19.0033 26.5337 18.9126 26.6516 18.8009 26.7473C18.6892 26.843 18.5587 26.9144 18.4179 26.957C16.1626 27.6206 13.7681 27.6501 11.4971 27.0423C9.22613 26.4345 7.16647 25.2129 5.54414 23.5114C5.44304 23.404 5.36543 23.2767 5.31625 23.1376C5.26708 22.9986 5.24742 22.8508 5.25852 22.7037C5.26962 22.5566 5.31124 22.4134 5.38073 22.2833C5.45023 22.1532 5.54606 22.039 5.66214 21.9479C5.87239 21.7836 6.1363 21.7034 6.40244 21.7231C6.66858 21.7427 6.91788 21.8607 7.10174 22.0541C8.45116 23.4639 10.1616 24.4762 12.0468 24.9808C13.9319 25.4853 15.9193 25.4628 17.7925 24.9156C18.0486 24.8408 18.3235 24.864 18.5634 24.9807Z" />
      <path d="M23.0908 26.3317C24.9416 26.3317 26.442 24.8313 26.442 22.9805C26.442 21.1297 24.9416 19.6293 23.0908 19.6293C21.24 19.6293 19.7396 21.1297 19.7396 22.9805C19.7396 24.8313 21.24 26.3317 23.0908 26.3317Z" fill="#F5D010" />
      <path d="M3.47913 21.0806C5.32994 21.0806 6.83033 19.5802 6.83033 17.7294C6.83033 15.8786 5.32994 14.3782 3.47913 14.3782C1.62831 14.3782 0.12793 15.8786 0.12793 17.7294C0.12793 19.5802 1.62831 21.0806 3.47913 21.0806Z" fill="#0094B7" />
      <path d="M17.8397 6.7024C19.6905 6.7024 21.1909 5.20201 21.1909 3.3512C21.1909 1.50038 19.6905 0 17.8397 0C15.9889 0 14.4885 1.50038 14.4885 3.3512C14.4885 5.20201 15.9889 6.7024 17.8397 6.7024Z" fill="#EF413D" />
    </svg>
  </div>
)

export default QuantoriLogo
