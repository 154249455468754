import apiService from '@shared/services/api-service'

// eslint-disable-next-line max-len
const fetchSubscriptionDetails = async (): Promise<SubscriptionDetailsResponse> => apiService.get<SubscriptionDetailsResponse>('/subscription/paddle/')

// TODO: for dev only. remove later
// eslint-disable-next-line
const fetchAll = async (): Promise<any> => apiService.get<any>('/subscription/paddle/all')

export {
  fetchSubscriptionDetails,
  fetchAll,
}
