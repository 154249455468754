import { ReactElement } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import authorizedRoutes from '@router/authorized-routes'
import BackButton from '@components/common/BackButton'
import Footer from '@components/common/Footer'
import Header from '@components/common/Header'
import Academic from '@components/settings/Academic'
import Account from '@components/settings/Account'
import Aside from '@components/settings/Aside'
import Subscription from '@components/settings/Subscription'

export default function Settings({ location }: RouteComponentProps): ReactElement {
  const subroute = location.pathname.split('/')[2]

  return (
    <div className="min-h-full flex flex-col overflow-x-hidden">
      <Header />
      <nav className="flex items-center justify-between h-12 text-primary pl-7 mb-6">
        <BackButton label="Back to file" path={authorizedRoutes.Files.path} />
      </nav>
      <div className="flex flex-col items-center">
        <h2 className="w-[1040px] text-2xl font-bold mb-5">Manage Account</h2>
        <div className="flex flex-grow flex-col items-center">
          <Aside />

          {subroute === 'subscription' && <Subscription />}
          {subroute === 'account' && <Account />}

          {subroute === 'academic' && <Academic />}
        </div>
      </div>
      <Footer />
    </div>
  )
}
