import { AgGridReactProps } from 'ag-grid-react'

export const hasDarkMode = window.SDFEConfig.REACT_APP_HAS_DARK_MODE === 'true'
export const LandingPath = 'https://adroitrepository.com/'
export const termsOfUseUrl = window.SDFEConfig.REACT_APP_TERMS_OF_USE_URL
export const termsOfServiceUrl = window.SDFEConfig.REACT_APP_TERMS_OF_SERVICE_URL
export const productOfService = 'https://adroitrepository.com/#Product'
export const priceOfService = 'https://adroitrepository.com/#Price'
export const privacyPolicyUrl = window.SDFEConfig.REACT_APP_PRIVACY_POLICY_URL
export const contactEmail = window.SDFEConfig.REACT_APP_CONTACT_EMAIL
export const defaultCurrency = ['USD', 'EUR', 'GBP']
export const currencySymbols: Record<typeof defaultCurrency[number], string> = {
  USD: '$',
  EUR: '€',
  GBP: '£',
}

export const fileEditorGridPageSize = Number(
  window.SDFEConfig.REACT_APP_FILE_EDITOR_GRID_PAGE_SIZE || '200',
)
export const fileEditorGridAheadPages = Number(
  window.SDFEConfig.REACT_APP_FILE_EDITOR_GRID_AHEAD_PAGES || '5',
)
export const fileEditorPageLoadMaxAttempts = Number(
  window.SDFEConfig.REACT_APP_FILE_EDITOR_PAGE_LOAD_MAX_ATTEMPTS || '10',
)
export const
  fileEditorGridRequestTimeoutSec = Number(
    window.SDFEConfig.REACT_APP_FILE_EDITOR_GRID_REQUEST_TIMEOUT_SEC || '30',
  )

export const copyrightFooterDates = `2021 - ${new Date(String(window.SDFEBuildEnv.REACT_APP_FOOTER_DATE)).getFullYear()}`
// eslint-disable-next-line max-len
export const emailRegEx = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9_]+\.)+[a-zA-Z]{2,}))$/

export const SystemProperties = Object.freeze<IndexProperty[]>([
  {
    name: '#',
    type: 'DECIMAL',
    hidden: false,
    deleted: false,
    width: 48,
    fixed: 'left',
  },
  {
    name: 'Structure',
    type: 'STRING',
    hidden: false,
    deleted: false,
    width: 80,
  },
])

export const columnNameBlacklist: string[] = [
  ...SystemProperties.map(prop => prop.name.toLowerCase()),
  'customOrder',
  'id',
]

export const defaultSearchParams: Readonly<IndexSearchParams> = Object.freeze({
  fileIds: [],
  type: 'all',
  sorting: [],
})

export const AG_GRID_ANCHOR_FIELD_NAME = '#'

export const AG_GRID_TABLE_ROW_HEIGHT_PX = 65
export const AG_GRID_TABLE_DEFAULT_ROW_WIDTH = 100
export const AG_GRID_TABLE_SHOWING_OVERLAY_DELAY_MS = 150

export const AG_GRID_TABLE_NON_EDITABLE_COLUMNS = new Set([SystemProperties[0].name])
export const AG_GRID_TABLE_NON_SORTABLE_COLUMNS = new Set(SystemProperties.map(sp => sp.name))
export const AG_GRID_TABLE_DEFAULT_SORTING_ORDER: AgGridReactProps['sortingOrder'] = ['asc', 'desc', null]

export const ONE_MEGABYTE = 1048576
export const ONE_GIGABYTE = 1073741824

export const SENTRY_IGNORED_ERROR_LIST = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
]
