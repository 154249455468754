const InvalidStructure: React.FC = () => (
  <svg width="43" height="34" viewBox="0 0 43 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ii_78160_5009)">
      <path
        d="M21.968 2.02854C21.968 1.49042 22.1817 0.974591 22.5623 0.594352C22.9425 0.213717
      23.4583 0 23.9965 0C24.5342 0 25.05 0.213706 25.4307 0.594352C25.8109 0.974559 26.0246 1.49037
      26.0246 2.02854C26.0246 2.56624 25.8109 3.08206 25.4307 3.46274C25.0501 3.84294 24.5342 4.05665
      23.9965 4.05665C23.4584 4.05665 22.9426 3.84294 22.5623 3.46274C22.1817 3.0821
      21.968 2.56627 21.968 2.02854ZM18.2963 29.9533C17.7599 29.9533 17.2454 30.1657 16.8652
      30.5437C16.4854 30.9222 16.2704 31.4355 16.2678 31.9719C16.2678 32.5096 16.4815 33.0254
      16.8622 33.4061C17.2424 33.7863 17.7582 34 18.2964 34C18.8341 34 19.3499 33.7863 19.7306
      33.4061C20.1108 33.0255 20.3245 32.5096 20.3245 31.9719C20.3245 31.4338 20.1108 30.9179 19.7306
      30.5377C19.3499 30.1571 18.8341 29.9434 18.2964 29.9434L18.2963 29.9533ZM17.6715 12.9781L22.891
      21.9945L19.3151 28.1852C18.9846 28.0852 18.6413 28.0329 18.2963 28.0312C17.4144 28.026 16.5562
      28.317 15.8588 28.8568C15.1619 29.3971 14.6663 30.1558 14.4513 31.011H7.25133L2.05111 21.9945L6.7038
       13.9393L7.26124 12.9781L17.6715 12.9781ZM19.9879 21.5139L16.6429 15.7464C16.4721 15.4507 16.1575
       15.2674 15.816 15.2657H12.2593C11.7285 15.2657 11.2981 15.6957 11.2981 16.227C11.2981 16.7578 11.7285
       17.1882 12.2593 17.1882H15.2585L18.3251 22.5039C18.4466 22.7361 18.6572 22.9086 18.9092 22.9816C19.1606
       23.0545 19.431 23.0215 19.658 22.8906C19.8846 22.7593 20.0481 22.5413 20.1103 22.2868C20.1729 22.0323
       20.1283 21.7637 19.988 21.5427L19.9879 21.5139ZM35.0318 21.0333H24.5542L19.3728 12.0648L22.9775 5.8261C23.3079
       5.92609 23.6512 5.97801 23.9967 5.97973C24.8781 5.98316 25.7355 5.69178 26.432 5.15193C27.1289 4.61165 27.6249
       3.85429 27.8417 2.99982H35.0318L39.6746 11.055L40.232 12.0163L39.6746 12.9775L35.5798 20.0715L35.0318
       21.0333ZM32.6094 7.18131L35.6759 12.497C35.8485 12.7957 36.1673 12.9793 36.5123 12.9776C36.6814 12.9759
       36.847 12.9296 36.993 12.8433C37.4504 12.5794 37.6092 11.9953 37.3483 11.5358L34.0032 5.76824C33.8307 5.47
       33.5119 5.28633 33.1668 5.28761H29.6201C29.0893 5.28761 28.6588 5.71804 28.6588 6.24887C28.6588 6.78013
       29.0893 7.21012 29.6201 7.21012L32.6094 7.18131ZM36.6952 21.9945L36.1472 22.9558H35.0229L40.5115 32.4627C40.684
       32.7614 41.0029 32.9442 41.3479 32.9429C41.517 32.9412 41.683 32.8949 41.8285 32.8086C42.286 32.5443 42.4452
       31.9606 42.1843 31.5011L36.6952 21.9945ZM6.15588 11.0551H7.25188L1.77273 1.54856C1.49637 1.11386 0.926487
       0.973954 0.480586 1.23187C0.0342888 1.48978 -0.129212 2.05323 0.109816 2.50984L5.59841 12.0069L6.15588
       11.0551Z"
        fill="white"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_78160_5009"
        x="0"
        y="0"
        width="50.3097"
        height="42"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0352941 0 0 0 0 0.137255 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_78160_5009" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0352941 0 0 0 0 0.137255 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_78160_5009" result="effect2_innerShadow_78160_5009" />
      </filter>
    </defs>
  </svg>

)

export default InvalidStructure
