/* eslint-disable max-len */
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import MoleculeImg1 from '@assets/images/molecule-1.png'
import MoleculeImg2 from '@assets/images/molecule-2.png'
import MoleculeImg3 from '@assets/images/molecule-3.png'
import appStore from '@store/app'
import subscriptionStore from '@store/subscription'
import { currencySymbols, defaultCurrency } from '@shared/constants'
import PlanFunctionalityItem from './PlanFunctionalityItem'

interface PlanCardProps {
  plan: PaddleSubscriptionPlan
  header: number
  badge?: boolean
  className?: Argument
  buttons: JSX.Element
  theme?: typeof appStore.theme
}

type CurrentPlanStatus = Extract<SDFSubscriptionStatus, 'trialing' | 'active' | 'cancelled' | 'paused'>

interface BadgeProps {
  text: string
  className?: Argument
}

const gradientMap: Record<typeof appStore.theme, Record<number, string>> = {
  dark: {
    1: 'linear-gradient(240deg, #2c353e 30%, #1c4e59 80%)',
    2: 'linear-gradient(240deg, #2c353e 30%, #1c3c59 80%)',
    3: 'linear-gradient(240deg, #2c353e 30%, #23416b 80%)',
  },
  light: {
    1: 'linear-gradient(260deg, #8194f5 15%, #8381f5)',
    2: 'linear-gradient(260deg, #81bdf5 15%, #6b86e7)',
    3: 'linear-gradient(260deg, #8194f5 15%, #8381f5)',
  },
}

const moleculeMap: Record<number, Record<string, string>> = {
  1: {
    src: MoleculeImg1,
    className: '-rotate-45 top-[35px] w-[98px]',
  },
  2: {
    src: MoleculeImg2,
    className: 'rotate-[150deg] top-[20px] w-[124px]',
  },
  3: {
    src: MoleculeImg3,
    className: 'rotate-[-25deg] top-[20px] w-[120px]',
  },
}

const badgeMap: Record<CurrentPlanStatus, BadgeProps> = {
  trialing: {
    text: 'Free trial',
    className: 'bg-primary',
  },
  active: {
    text: 'Current plan',
    className: 'bg-primary',
  },
  paused: {
    text: 'Paused',
    className: 'bg-warning',
  },
  cancelled: {
    text: 'Canceled',
    className: 'bg-danger',
  },
}

const Badge = ({ text, className }: BadgeProps) => (
  <div className={cn('text-xs text-white py-0.5 rounded-xl', className)}>
    {text}
  </div>
)

const PlanCardView = ({
  plan, badge, header, className = '', buttons, theme = 'dark',
}: PlanCardProps): JSX.Element => {
  const { status, planId: currentPlanId } = subscriptionStore

  const isCurrentPlan = plan.id === currentPlanId
  let price = 0
  let currency = '$'
  let currencyIndex = 0

  for (let i = 0; i < defaultCurrency.length; i += 1) {
    if (plan.recurringPrices[defaultCurrency[i]] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      price = plan.recurringPrices[defaultCurrency[i]]
      currency = currencySymbols[defaultCurrency[i]]
      currencyIndex = i
      break
    }
  }

  const plansFunctionality: Record<SDFPlanName, string[]> = {
    'Editor Edition': [
      `USD ${price} per user per ${plan.billingType}`,
      'Store up to 50,000 structures',
      'Access all tool features',
      'Cancel at any time',
    ],
    'Library Edition': [
      'Store up to 10,000 structures',
      'Structure Search across all files with integrated Ketcher',
      'Create new files by merging selected files, or from search results',
      'Export selected records',
    ],
    'Knowledge Edition': [
      `USD ${price} per user per ${plan.billingType}`,
      'Store up to 10,000,000 structures',
      'Access all tool features',
      'Cancel at any time',
    ],
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const billingCycleText = match(plan.billingType)
    .with('month', () => 'paid monthly')
    .with('year', () => `paid annually, ${currency}${plan.recurringPrices[defaultCurrency[currencyIndex]]} per year`)
    .exhaustive()

  return (
  // eslint-disable-next-line max-len
    <div className={cn('flex flex-col flex-shrink-0 bg-gray-10 dark:bg-gray-80 rounded-xl w-[315px] min-h-[486px]', className)}>
      <div
        className="relative text-white rounded-t-xl"
        style={{
          backgroundImage: gradientMap[theme][header],
        }}
      >
        {
          badge && (
            <Badge
              // eslint-disable-next-line max-len
              className="absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm text-white font-bold bg-secondary-100 px-5 py-0.5 rounded-xl"
              text="Popular"
            />
          )
        }
        <div className={cn('flex flex-col m-4 h-[90px]')}>
          <h2 className="text-xl font-bold">{plan.name}</h2>
          {/* <div className="mt-1">
            <span className="text-4xl font-bold">{currency}{plan.billingType === 'year' ? Math.round(price / 12) : price}</span>
            <span className="text-sm"> /per month*</span>
          </div>
          <span className="text-xs">{billingCycleText}</span> */}
        </div>
        <img
          src={moleculeMap[header].src}
          className={cn('absolute right-3 mix-blend-soft-light dark:mix-blend-overlay', moleculeMap[header].className)}
          alt="molecule"
        />
        {
          isCurrentPlan && match(status)
            .with('trialing', 'active', 'paused', 'cancelled', currentStatus => (
              <Badge
                text={badgeMap[currentStatus].text}
                className={cn('absolute top-0 right-0 -translate-y-1/2 mr-3 px-3 font-bold', badgeMap[currentStatus].className)}
              />
            ))
            .otherwise(() => null)
        }
      </div>
      <div className="flex flex-col justify-between flex-grow p-6">
        <div>
          {/* {
              match(plan.name)
                .with('Editor Edition', () => (
                  <div className="mb-4 font-semibold">
                    Get the access to all tool features:
                  </div>
                ))
                .with('Library Edition', () => (
                  <div className="mb-4 font-semibold">
                    Everything in Editor Edition, plus:
                  </div>
                ))
                .with('Knowledge Edition', () => (
                  <div className="mb-4 font-semibold">
                    Everything in Library Edition, plus:
                  </div>
                ))
                .exhaustive()
            } */}
          {
              plansFunctionality[plan.name].map(item => (
                <PlanFunctionalityItem
                  key={item}
                  className="mb-2 first:mb-6 last:mb-0"
                >
                  {item}
                </PlanFunctionalityItem>
              ))
            }
        </div>
        {buttons}
      </div>
    </div>
  )
}

export default observer(PlanCardView)
