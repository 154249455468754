import { useEffect, useState } from 'react'
import {
  Button, Form, Input,
} from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import { showErrorMessage, showSuccessMessage } from '@utils/messages'

const AccountDetailsForm = () => {
  const {
    firstName, lastName, email,
  } = userStore

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      firstName,
      lastName,
      email,
    })
  }, [firstName, lastName, email, form])

  const inputsData = [
    {
      label: 'First name',
      name: 'firstName',
      defaultValue: firstName,
      message: 'Please enter first name',
    },
    {
      label: 'Last name',
      name: 'lastName',
      defaultValue: lastName,
      message: 'Please enter last name',
    },
    {
      label: 'Email',
      name: 'email',
      defaultValue: email,
      type: 'email',
    },
  ]

  const initialValues = inputsData
    .reduce((acc, data) => Object.assign(acc, { [data.name]: data.defaultValue }), {})

  const handleSubmit = async (data: { firstName: string, lastName: string, email: string }) => {
    if (data.firstName === firstName && data.lastName === lastName && data.email === email) {
      return
    }

    setLoading(true)

    try {
      await userStore.updateAccountDetails(data)

      showSuccessMessage('Personal data was successfully updated!')
    } catch (error) {
      showErrorMessage()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      className="flex flex-col mt-4"
      initialValues={initialValues}
      form={form}
      requiredMark="optional"
      onFinish={handleSubmit}
      layout="vertical"
    >
      {
        inputsData.map(input => (
          <Form.Item
            className="mb-3"
            key={input.name}
            label={input.label}
            name={input.name}
            rules={[
              {
                required: true,
                message: input.message,
              },
              {
                max: 99,
                message: `${input.label} cannot be longer than 99 characters`,
              },
            ]}
          >
            <Input
              type={input.type}
              size="large"
              disabled={input.type === 'email'}
            />
          </Form.Item>
        ))
      }

      <Form.Item className="self-end mt-3 mb-0">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Save changes
        </Button>
      </Form.Item>

    </Form>
  )
}

export default observer(AccountDetailsForm)
