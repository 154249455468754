import { observer } from 'mobx-react'

import subscriptionStore from '@store/subscription'
import PlansOverview from '@components/common/PlansOverview'
import usePaddle from '@shared/hooks/usePaddle'
import { CheckoutEventsCallbacks } from '@shared/services/paddle'

const checkoutCallbacks: CheckoutEventsCallbacks = {
  'checkout.complete': () => subscriptionStore.fetchSubscriptionDetails(),
}

const Subscription = (): JSX.Element => {
  usePaddle(checkoutCallbacks)
  return (
    <div className="flex-col-grow overflow-hidden -mt-12">
      <PlansOverview
        className="mt-6 plans"
        alignSelf="start"
      />
    </div>
  )
}

export default observer(Subscription)
