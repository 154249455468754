import {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react'
import { ICellEditorParams } from 'ag-grid-community'

import StructureImage from '@components/file/StructureImage'
import { useFileStore } from '@contexts/file-edit-context'
import { useKetcherContext } from '@contexts/ketcher-context'
import useRenderStructure from '@shared/hooks/useRenderStructure'

const StructureCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const fileStore = useFileStore()
  const [structure, setStructure] = useState<string | null>(props.value)
  const imageAsText = useRenderStructure(props.value as string, 'RAW', fileStore.queryStructure)
  const { addModal, removeModal, canAddModal } = useKetcherContext()
  const mol = props?.data
  const id = mol?.customOrder

  const closeKetcherModal = (newMolStruct: string | null) => {
    setStructure(newMolStruct)
    props.stopEditing()
    const rowNode = props.api.getDisplayedRowAtIndex(props.rowIndex)

    if (newMolStruct !== null) {
      rowNode?.setDataValue('Structure', newMolStruct)
    }

    removeModal(id)
  }

  useImperativeHandle(ref, () => ({
    getValue() {
      return structure
    },
    isCancelBeforeStart() {
      return structure == null
    },
    isCancelAfterEnd() {
      return structure == null
    },
  }))

  useEffect(() => {
    if (canAddModal(id)) {
      return
    }
    addModal({
      id,
      structure: structure || '',
      onOk: closeKetcherModal,
      onCancel: () => closeKetcherModal(null),
    }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StructureImage imageAsText={imageAsText} />
  )
})

StructureCellEditor.displayName = 'StructureCellEditor'

export default StructureCellEditor
