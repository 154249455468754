/* eslint-disable react/jsx-props-no-spreading */
import { ComponentType, ReactElement } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { match } from 'ts-pattern'

import authorizedRoutes from '@router/authorized-routes'

const routeWhitelist = [
  authorizedRoutes.SettingsAccount,
  authorizedRoutes.SettingsSubscription,
].map(route => route.path)

const withSubscription = function withSubscription<P>(
  status: SDFSubscriptionStatus,
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const WithSubscriptionWrapper = (props: P): ReactElement => {
    const { pathname } = useLocation()
    const isSubscriptionPlansPage = pathname === authorizedRoutes.SubscriptionPlans.path
    const isSubscriptionSettingsPage = pathname === authorizedRoutes.SettingsSubscription.path

    return match(status)
      .with('none', 'expired', () => {
        if (routeWhitelist.includes(pathname) || isSubscriptionPlansPage) {
          return <WrappedComponent {...props as P} />
        }

        return <Redirect to={authorizedRoutes.SubscriptionPlans.path} />
      })
      .with('paused', () => {
        if (isSubscriptionSettingsPage) {
          return <WrappedComponent {...props as P} />
        }

        return <Redirect to={authorizedRoutes.SettingsSubscription.path} />
      })
      .with('trialing', 'active', 'cancelled', () => {
        if (isSubscriptionPlansPage) {
          return <Redirect to={authorizedRoutes.Files.path} />
        }

        return <WrappedComponent {...props as P} />
      })
      .exhaustive()
  }
  WithSubscriptionWrapper.displayName = `withSubscription(${WrappedComponent.displayName ?? WrappedComponent.name ?? 'Anonymous'})`

  return WithSubscriptionWrapper
}

export default withSubscription
