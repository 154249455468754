import { memo, useEffect, useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { observer } from 'mobx-react'

import StructureImageStore from '@store/structure-image'
import StructureImage from '@components/file/StructureImage'
import { AG_GRID_TABLE_ROW_HEIGHT_PX } from '@shared/constants'
import structureImagesCache from '@utils/structure-images-cache'

interface StructureRendererProps extends ICellRendererParams {
  context: AgGridCellRenderingContext
}

const StructureRenderer: React.FC<StructureRendererProps> = observer(({ value, context }) => {
  const [imageAsText, setImageAsText] = useState('')
  const [errorValue, setErrorValue] = useState('')

  const structure = value || ''
  const highlightSubstructure = context.queryStructure

  useEffect(() => {
    const error = StructureImageStore.imagesErrors[structure]

    const svgAsText = structureImagesCache.get(StructureImageStore.imagesCache, structure, highlightSubstructure)

    if (svgAsText) {
      setImageAsText(svgAsText)
    }
    const hasInvalidSvgText = svgAsText === '\x9Eée'

    if (hasInvalidSvgText || error) {
      setErrorValue(error || 'Image Has Error')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StructureImageStore.imagesCache, highlightSubstructure, structure])

  return (
    <StructureImage
      imageAsText={imageAsText}
      height="100%"
      maxHeight={AG_GRID_TABLE_ROW_HEIGHT_PX}
      width={120}
      errorText={errorValue || ''}
    />
  )
})

export default memo(StructureRenderer)
