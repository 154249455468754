import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'antd'

import { PlusCircleOutlined } from '@ant-design/icons'

const AddRowsNavLinkButton = ({ fileId, disabled }: { fileId: string, disabled: boolean }): ReactElement => (
  <NavLink to={`/file/${fileId}/add-rows`}>
    <Button ghost className="inline-flex items-center font-normal" disabled={disabled}>
      <PlusCircleOutlined className="text-primary" />
      Add rows
    </Button>
  </NavLink>
)

export default AddRowsNavLinkButton
