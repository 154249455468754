/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const LinkedInIcon = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM7.496 5.403C8.338 5.403 8.899 5.964 8.899 6.712C8.899 7.46 8.338 8.021 7.403 8.021C6.561 8.022 6 7.46 6 6.712C6 5.964 6.561 5.403 7.496 5.403ZM9 17H6V9H9V17ZM19 17H16.176V12.628C16.176 11.419 15.423 11.14 15.141 11.14C14.859 11.14 13.917 11.326 13.917 12.628C13.917 12.814 13.917 17 13.917 17H11V9H13.918V10.116C14.294 9.465 15.047 9 16.459 9C17.871 9 19 10.116 19 12.628V17Z" />
  </svg>
)

export default LinkedInIcon
