import { Fragment, memo, ReactElement } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Dropdown, Menu, Space, Switch,
} from 'antd'
import isNil from 'lodash/isNil'
import { observer } from 'mobx-react'

import dnaHelixImg from '@assets/images/dna-helix.png'
import appStore from '@store/app'
import FileStore from '@store/file'
import subscriptionStore from '@store/subscription'
import userStore from '@store/user'
import routes from '@router/authorized-routes'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import Chevron from '@components/icons/Chevron'
import Lock from '@components/icons/Lock'
import MoonIcon from '@components/icons/MoonIcon'
import SunIcon from '@components/icons/SunIcon'
import { hasDarkMode } from '@shared/constants'
import AboutVersions from './AboutVersions'
import BackgroundTasksIndicator from './BackgroundTasksIndicator'
import BigLogo from './BigLogo'

const CurrentPlan = (): ReactElement => {
  const { dueDate } = subscriptionStore

  const text = `Free trial till ${dueDate}`

  return (
    <NavLink
      className="border text-xs border-secondary-100 rounded-3xl py-0.5 px-3 flex items-center"
      to={routes.SettingsSubscription.path}
    >
      <img
        src={dnaHelixImg}
        alt="Dna helix"
        className="mr-2 w-3.5 h-3.5"
      />
      <span>{text}</span>
    </NavLink>
  )
}

const UserMenuDropdown = (): ReactElement => {
  const userMenuItems = [
    {
      content: routes.SettingsAccount.label,
      icon: UserOutlined,
      route: routes.SettingsAccount,
      id: 0,
    },
    {
      content: 'Sign Out',
      icon: LogoutOutlined,
      onClick: () => userStore.signOut(),
      id: 1,
    },
  ]

  return (
    <Menu>
      {userMenuItems.map(({
        id, icon: Icon, content, onClick, route,
      }) => (
        <Fragment key={id}> {/* Use a unique key */}
          {id === 1 && (
          <Menu.Item key={`custom-item-${id}`} className="custom-menu-item">
            <AboutVersions />
          </Menu.Item>
          )}
          <Menu.Item key={`menu-item-${id}`} onClick={onClick && onClick}>
            {route && (
            <NavLink to={route.path} className="flex items-center">
              <Icon className="text-primary" />
              <span className="ml-2">{content}</span>
            </NavLink>
            )}
            {onClick && (
            <div className="flex items-center">
              <Icon className="text-primary" />
              <span className="ml-2">{content}</span>
            </div>
            )}
          </Menu.Item>
        </Fragment>
      ))}
    </Menu>
  )
}

const UserMenu = observer((): ReactElement => {
  const { firstName, lastName } = userStore
  const initials = firstName[0] + lastName[0]

  return (
    <Dropdown overlay={UserMenuDropdown}>
      <Space className="flex items-center">
        <div className="cursor-pointer flex items-center justify-center
         border border-secondary-100 hover:border-primary rounded-full w-8 h-8"
        >
          <span className="text-sm font-semibold">{initials}</span>
        </div>
        <Chevron className="mr-6 cursor-pointer" />
      </Space>
    </Dropdown>
  )
})

const FileNameWrapper = observer(({ fileStore }: { fileStore: FileStore }): ReactElement | null => {
  if (fileStore.fileDescription && !fileStore.isFileDescriptionFetching) {
    return (
      <div className="flex justify-center items-center">
        {fileStore.fileDescription.fileName}
        {fileStore.readOnly && <Lock className="ml-2.5 w-4.5 h-4.5" />}
      </div>
    )
  }

  return null
})

const Header = observer(({ fileStore }: { fileStore?: FileStore }): ReactElement => {
  const location = useLocation()
  const isFilePage = /^\/file\//.test(location.pathname)
  const { status } = subscriptionStore

  return (
    <header className="flex justify-between items-center">
      <div className="flex-1 flex items-center">
        <NavLink className="inline-block p-6 mr-4" to={routes.Files.path}>
          <BigLogo />
        </NavLink>
        {status === 'trialing' && <CurrentPlan />}
      </div>

      {isFilePage && !isNil(fileStore) && (
        <div className="flex-1 text-center">
          <FileNameWrapper fileStore={fileStore} />
        </div>
      )}

      <div className="flex flex-1 justify-end items-center">
        <BackgroundTasksIndicator />
        <Switch
          className="!bg-white mr-6 h-5 w-9" // Add a custom class name
          checkedChildren={<MoonIcon className="w-4 h-4" />}
          unCheckedChildren={<SunIcon className="w-4 h-4" />}
          onClick={() => appStore.toggleTheme()}
          checked={hasDarkMode && appStore.theme === 'dark'}
          disabled={!hasDarkMode}
        />
        <UserMenu />
      </div>
    </header>
  )
})

export default memo(Header)
