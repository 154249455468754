import { ReactElement } from 'react'
import { Spin } from 'antd'
import cn from 'classnames'

const SpinnerArea = ({ className }: ElementProps): ReactElement => (
  <div className={cn('flex-grow flex items-center justify-center', className)}>
    <Spin size="large" />
  </div>
)

export default SpinnerArea
