import { ReactElement, useCallback } from 'react'
import {
  Form, message,
} from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import PasswordInput from '@components/common/PasswordInput'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import isApiValidationError from '@utils/is-api-validation-error'

const ConfirmTemporaryForm = observer((): ReactElement => {
  const [form] = Form.useForm()
  const handleSubmit = useCallback(async ({ temporaryPassword }: { temporaryPassword: string }) => {
    try {
      const { email = '' } = userStore.signUpData
      await userStore.confirmTemporary(email, temporaryPassword)
    } catch (error) {
      if (isApiValidationError(error)) {
        handleApiValidation(form, error.data)
      }

      if (isErrorHasMessage(error)) {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }, [form])

  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      form={form}
    >
      <Form.Item
        label="Temporary password"
        name="temporaryPassword"
        rules={[
          { required: true, message: 'Please enter temporary password' },
        ]}
      >
        <PasswordInput placeholder="Enter the password" size="large" autoComplete="off" />
      </Form.Item>
      <SubmitButton label="Confirm" />
    </Form>
  )
})

export default ConfirmTemporaryForm
