import { useCallback, useEffect, useState } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { observer } from 'mobx-react'

import AgGridHeaderLineNumberCheckbox from '@components/table/AgGridHeaderLineNumberCheckbox'
import { useAddRowsStore } from '@contexts/add-rows-context'
import { useAgGridApiService } from '@contexts/file-edit-context'

const AddRowLineNumberHeader: React.FC<IHeaderParams> = observer(({ displayName }) => {
  const addRowStore = useAddRowsStore()
  const { selectedRowsCount } = addRowStore
  const loadedRowsCount = addRowStore.rowsCount
  const availableRowsCount = loadedRowsCount

  const [isAllSelected, setIsAllSelected] = useState(false)

  const gridApi = useAgGridApiService()

  const handleChangeIsAllSelected = useCallback(selected => {
    setIsAllSelected(selected)

    if (selected) {
      gridApi.selectAllLoaded()
    } else {
      gridApi.deselectAllLoaded()
    }
  }, [gridApi])

  useEffect(
    () => {
      if (selectedRowsCount < availableRowsCount && isAllSelected) {
        setIsAllSelected(false)
      } else if (selectedRowsCount !== 0 && selectedRowsCount === availableRowsCount
        && !isAllSelected) {
        setIsAllSelected(true)
      }
    },
    [
      isAllSelected,
      loadedRowsCount,
      selectedRowsCount,
      availableRowsCount,
    ],
  )

  return (
    <AgGridHeaderLineNumberCheckbox
      displayName={displayName}
      loadedRowsCount={loadedRowsCount}
      isAllSelected={isAllSelected}
      selectedRowsCount={addRowStore.selectedRowsCount}
      onChange={handleChangeIsAllSelected}
    />
  )
})

export default AddRowLineNumberHeader
