import {
  Button,
  Modal,
} from 'antd'

import { RemoveDuplicationsTask } from '@store/background-tasks'
import { CheckCircleFilled } from '@ant-design/icons'
import useBackgroundTasksContext from '@contexts/background-tasks'

const ModalTitle = () => (
  <div className="flex items-center">
    <CheckCircleFilled className="text-lg text-primary" />
    <span className="ml-2">Remove duplicates Alert</span>
  </div>
)

const RemoveDuplicatesModal = ({ task }: { task: RemoveDuplicationsTask }): JSX.Element => {
  const backgroundTasksStore = useBackgroundTasksContext()

  const handleOk = () => {
    backgroundTasksStore.markAsDisplayedDeduplicationTask(task.id)
  }

  return (
    <Modal
      open
      title={<ModalTitle />}
      closable={false}
      width={415}
      footer={(
        <Button
          className="w-full"
          size="large"
          type="primary"
          onClick={handleOk}
        >
          OK
        </Button>
      )}
    >
      {task?.removed} duplicate rows found and removed; {task?.remained} unique rows remain in
      <span className="text-primary font-bold"> {task?.fileName}</span>
    </Modal>
  )
}

export default RemoveDuplicatesModal
