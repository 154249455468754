import AbortError from '@utils/abort-error'

type ActionType<P, T> = (data: P, info?: QueryOptionsWithAbortController) => Promise<T>;

interface PollingOptions<T> {
    validate: (result: T) => boolean
    interval?: number
    maxAttempts?: number
}

interface PollingConstructor<P, T> {
    action: ActionType<P, T>
    getActionParams: () => Parameters<ActionType<P, T>>
    options: PollingOptions<T>
}

export default class Polling<P, T> {
    private action: ActionType<P, T>
    private getActionParams: () => Parameters<ActionType<P, T>>

    private validate: (result: T) => boolean
    private interval = 1000
    private maxAttempts = Infinity

    private _abort?: () => void
    private aborted = false

    constructor(
      action: ActionType<P, T>,
      getActionParams: () => Parameters<ActionType<P, T>>,
      options: PollingOptions<T>,
    ) {
      this.action = action
      this.getActionParams = getActionParams
      this.validate = options.validate
      this.interval = options.interval ?? this.interval
      this.maxAttempts = options.maxAttempts ?? this.maxAttempts
    }

    async run(): Promise<T> {
      let attempts = 0
      let timeoutId: number

      const run = async (resolve: (value: T | PromiseLike<T>) => void, reject: (e: unknown) => unknown) => {
        try {
          if (this.aborted) {
            return
          }
          attempts += 1

          const result = await this.action(...this.getActionParams())

          if (this.validate(result)) {
            resolve(result)
          } else if (attempts >= this.maxAttempts) {
            reject(`Attempts exceeded ${this.maxAttempts}(maxAttempts)`)
          } else {
            timeoutId = setTimeout(run, this.interval, resolve, reject)
          }
        } catch (error) {
          reject(error)
        }
      }

      return new Promise((resolve, reject) => {
        this._abort = () => {
          this.aborted = true
          clearTimeout(timeoutId)
          reject(new AbortError())
        }

        run(resolve, reject)
      })
    }

    abort(): void {
      this._abort?.()
    }
}

export function createPolling<P, T>({ action, getActionParams, options }: PollingConstructor<P, T>): Promise<T> {
  const polling = new Polling(action, getActionParams, options)
  return polling.run()
}

export function createSignalAbortablePolling<P, T>(
  signal: AbortSignal,
  { action, getActionParams, options }: PollingConstructor<P, T>,
): Promise<T> {
  const polling = new Polling(action, getActionParams, options)
  const result = polling.run()

  if (signal.aborted) {
    polling.abort()
  }

  signal.addEventListener('abort', () => polling.abort())

  return result
}
