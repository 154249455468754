import { useCallback, useEffect } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { observer } from 'mobx-react'

import AgGridLineNumberWithCheckbox from '@components/table/AgGridLineNumberWithCheckbox'
import { useFileStore } from '@contexts/file-edit-context'

const FileLineNumberCell: React.FC<ICellRendererParams> = observer(({ value, node }) => {
  const id = node?.data?.id
  const fileStore = useFileStore()
  const isSelected = fileStore.getIsMoleculeSelected(id)
  const handleChangeCellSelection = useCallback((selected: boolean) => {
    if (!node?.data) {
      return
    }
    fileStore.updateSelection(node.data.id, selected)

    if (!node.isSelected()) {
      fileStore.setSelectedDataForExport(node.data)
    } else {
      fileStore.removeSelectedDataForExportById(String(node.data.id))
    }
  }, [fileStore, node])

  useEffect(() => {
    if (node?.id) {
      node.setSelected(isSelected)
    }
  }, [isSelected, node])

  return (
    <AgGridLineNumberWithCheckbox
      isSelected={isSelected}
      lineNumber={value}
      onChange={handleChangeCellSelection}
    />
  )
})

export default FileLineNumberCell
