import { makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'

import { updateMolecules } from '@utils/http'

export interface RangeSelectionTaskDetails {
  type: 'RangeSelection'
  details: {
    fileId: string
    fileName: string
    requestData: MoleculeChangesRequest[]
  }
}

export interface RangeSelectionTaskInitData {
    fileId: string
    fileName: string
    requestData: MoleculeChangesRequest[]
}

export type RangeSelectionTaskProps = {
  type: 'range-selection'
  data: RangeSelectionTaskInitData
}

class RangeSelectionTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'range-selection'

  readonly fileId: string
  readonly fileName: string
  readonly requestData: MoleculeChangesRequest[]

  taskId = nanoid()
  progress = 0
  status: BackgroundTaskStatus = 'Initial'

  abortController = new AbortController()

  constructor({ fileId, fileName, requestData }: RangeSelectionTaskInitData) {
    this.fileId = fileId
    this.fileName = fileName
    this.requestData = requestData

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
      fileId: observable,
      fileName: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      fileId,
    } = this
    const { taskId, status } = await updateMolecules(fileId, this.requestData)

    runInAction(() => {
      this.taskId = taskId
    })

    return { taskId, status }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async fetchResult(): Promise<void> {}
}

export default RangeSelectionTask
