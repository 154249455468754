import { ReactElement } from 'react'
import cn, { Argument } from 'classnames'

import { CheckCircleFilled } from '@ant-design/icons'

interface PlanFunctionalityItemProps {
  className?: Argument
  children: string
}

const PlanFunctionalityItem = ({ className = '', children }: PlanFunctionalityItemProps): ReactElement => (
  <div className={cn('flex items-center', className)}>
    <CheckCircleFilled className="text-primary text-sm flex-shrink-0" />
    <span className="text-base ml-2 font-semibold">{children}</span>
  </div>
)

export default PlanFunctionalityItem
