import { makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'

import { defaultSearchParams } from '@shared/constants'
import apiService from '@shared/services/api-service'

export interface CopyTaskDetails {
  type: 'Copy'
  details: {
    fileIds: string
    fileId: string
    fileName: string
    withDeduplication: boolean
    withProperties: boolean
    searchType: SearchType
  }
}

export interface CopyTaskInitData {
  fileId: string
  fileName: string
  searchType: SearchType
  withProperties: boolean
  withDeduplication: boolean
  searchParams?: IndexSearchParams
  selectedMoleculesParams?: SelectedMoleculesParams
}

export type CopyTaskProps = {
  type: 'copy'
  data: CopyTaskInitData
}

class CopyTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'copy'

  readonly fileId: string
  readonly fileName: string
  readonly withProperties: boolean
  readonly withDeduplication: boolean

  searchParams: IndexSearchParams
  taskId = ''
  progress = 0
  status: BackgroundTaskStatus = 'Initial'
  selectedMoleculesParams: SelectedMoleculesParams | undefined
  searchType: SearchType
  copyResultFileId = ''

  abortController = new AbortController()

  constructor({
    fileId, searchParams = defaultSearchParams, fileName, withDeduplication, selectedMoleculesParams,
    searchType, withProperties,
  }: CopyTaskInitData) {
    this.fileId = fileId
    this.searchParams = searchParams
    this.fileName = fileName.trim()
    this.selectedMoleculesParams = selectedMoleculesParams
    this.searchType = searchType
    this.withProperties = withProperties
    this.withDeduplication = withDeduplication

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
      copyResultFileId: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      withProperties, fileName, searchParams, selectedMoleculesParams,
      abortController, searchType, fileId, withDeduplication,
    } = this

    // eslint-disable-next-line max-len, no-mixed-operators
    const isSearchParamsEmpty = (params: IndexSearchParams): boolean => !params || (Array.isArray(params.fileIds) && params.fileIds.length === 0) && params.type === 'all' && (Array.isArray(params.sorting) && params.sorting.length === 0)

    const data = searchType === 'selected'
      ? { moleculesRequest: selectedMoleculesParams }
      : { moleculesRequest: {} }

    if (searchType !== 'selected' && !isSearchParamsEmpty(searchParams)) {
      data.moleculesRequest = {
        ...data.moleculesRequest,
        searchStructure: searchParams,
      }
    }

    const { taskId, status } = await apiService.post<TaskProgress>('/merge/create-new-file', {
      json: {
        fileId,
        fileName,
        withProperties,
        withDeduplication,
        ...data,
      },
      signal: abortController.signal,
    })
    const file = await apiService.get<UploadedFile>(`/merge/${taskId}`)

    runInAction(() => {
      this.taskId = taskId
      this.copyResultFileId = file.id
    })
    return { taskId, status }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async fetchResult(): Promise<void> {}
}

export default CopyTask
