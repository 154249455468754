import { makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import * as XLSX from 'xlsx'
import { BookType } from 'xlsx'

import { calculateStructure, getTaskResult } from '@utils/http'

export interface DataExportTaskDetails {
  type: 'DataExport'
  details: {
    fileId: string
    fileName: string
    requestData: DataExportRequest
    rangeData: RangeRowData[]
    formula: FormulaType
    fileType: BookType
  }
}
export interface DataExportTaskInitData {
    fileId: string
    fileName: string
    rangeData: RangeRowData[]
    formula: string
    fileType: BookType
  }

export type DataExportTaskProps = {
  type: 'data-export'
  data: DataExportTaskInitData
}

interface FetchedDataType {
  [key: string]: string
}

class DataExportTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'data-export'

  readonly fileId: string
  readonly fileName: string
  readonly rangeData: RangeRowData[]
  readonly formula: string
  readonly fileType: BookType

  taskId = nanoid()
  progress = 0
  status: BackgroundTaskStatus = 'Initial'
  fetchedData: FetchedDataType = {}
  abortController = new AbortController()

  constructor({
    fileId, fileName, rangeData, formula, fileType,
  }: DataExportTaskInitData) {
    this.fileId = fileId
    this.fileName = fileName
    this.rangeData = rangeData
    this.formula = formula
    this.fileType = fileType

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
      fileId: observable,
      fileName: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const structures = [...new Set(this.rangeData.map(rowData => rowData.structure))]

    const { taskId, status } = await calculateStructure({ formula: this.formula, structures })

    runInAction(() => {
      this.taskId = taskId
    })

    return { taskId, status }
  }

  async fetchResult(): Promise<void> {
    try {
      const fetchedData = await getTaskResult<FetchedDataType>(this.taskId)
      runInAction(() => {
        const requestData = this.rangeData.map(rowData => {
          const { '#': hashKey, structure, ...rest } = rowData
          return {
            '#': hashKey,
            Structure: fetchedData[structure],
            ...rest,
          }
        })

        const worksheet = XLSX.utils.json_to_sheet(requestData)
        const workbook = XLSX.utils.book_new()

        // Calculate the maximum width for each column header
        const columnWidths: number[] = []
        const columnHeaders = Object.keys(this.rangeData[0])

        columnHeaders.forEach((header, columnIndex) => {
          const headerWidth = (header || '').toString().length * 1.2 + 4

          columnWidths[columnIndex] = headerWidth
        })

        // Set the widths for all columns in the worksheet
        worksheet['!cols'] = columnWidths.map(width => ({ wch: width }))
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        const workbookBlob = new Blob(
          [XLSX.write(workbook, { bookType: this.fileType, type: 'array' })],
          { type: 'application/octet-stream' },
        )
        const link = document.createElement('a')

        link.href = URL.createObjectURL(workbookBlob)
        link.download = `Export.${this.fileType}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    } catch (error) {
      throw Error
    }
  }
}

export default DataExportTask
