import { useEffect, useState } from 'react'

import StructureImageStore from '@store/structure-image'
import { isAuthError } from '@shared/services/api-service'

const useRenderStructure = (structure: string, type: RenderStructureType, highlightSubstructure?: string): string => {
  const [imageAsText, setImageAsText] = useState('')

  useEffect(
    () => {
      const abortController = new AbortController()

      async function getStructure() {
        if (!structure || abortController.signal.aborted) return

        try {
          const svgAsText = await StructureImageStore.renderStructure(
            structure,
            type,
            {
              highlightSubstructure,
              abortController,
            },
          )

          if (!abortController.signal.aborted) setImageAsText(svgAsText)
        } catch (err) {
          if (abortController.signal.aborted || isAuthError(err)) return

          throw err
        }
      }

      getStructure()

      return () => abortController.abort()
    },
    [structure, highlightSubstructure, type],
  )

  return imageAsText
}

export default useRenderStructure
