const loadScript = (src: string): Promise<void> => new Promise((resolve, reject) => {
  const script = document.createElement('script')

  script.onload = () => resolve()
  script.onerror = () => reject()
  script.src = src

  document.head.appendChild(script)
})

export default loadScript
