import {
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx'

import apiService from '@shared/services/api-service'

export default class TableManagementStore {
  private _indexProperties: IndexProperty[] = []
  private _nameFilter = ''
  private _indexPropertyInEdit: IndexProperty | null = null
  private _validationError = ''
  private _formulas: string[] = []
  private _formulaFilter = ''

  constructor() {
    makeAutoObservable(this)
  }

  async getChemicalProperties(): Promise<void> {
    const formulas = await apiService.get<string[]>('/molecule-formulas')

    runInAction(() => {
      this._formulas = formulas.sort((formulaA, formulaB) => formulaA.localeCompare(formulaB))
    })
  }

  updateColumn(indexProperty: IndexProperty, key: keyof IndexProperty, value: IndexProperty[typeof key]): void {
    this._indexProperties.forEach((property, index) => {
      if (property.name === indexProperty.name) {
        this._indexProperties[index] = { ...property, [key]: value }
      }
    })
  }

  get indexProperties(): IndexProperty[] {
    return [...this._indexProperties]
  }

  set indexProperties(columns: IndexProperty[]) {
    this._indexProperties = columns.map(
      item => observable.object({
        ...item,
        newName: item.newName || undefined,
        hidden: item.hidden || false,
        deleted: item.deleted || false,
        isFormula: item.isFormula
          || this._formulas.includes(item.name)
          || this._formulas.includes(item.newName ?? ''),
      }),
    )
  }

  get hidenIndexProperties(): IndexProperty[] {
    return [...this._indexProperties.filter(index => index.hidden)]
  }

  get filteredIndexProperties(): IndexProperty[] {
    const filter = String(this._nameFilter || '').toLocaleLowerCase()

    if (!filter) return this.indexProperties

    return this.indexProperties.filter(item => (item.newName || item.name).toLocaleLowerCase().includes(filter))
  }

  get nameFilter(): string {
    return this._nameFilter
  }

  set nameFilter(nameFilter: string) {
    this._nameFilter = nameFilter
  }

  get formulas(): string[] {
    return this._formulas
  }

  get filteredFormulas(): string[] {
    const filter = String(this._formulaFilter || '').toLocaleLowerCase()

    if (!filter) return this.formulas.filter(formula => !this.isPropertyExists(formula))

    return this.formulas.filter(formula => formula.toLocaleLowerCase().includes(filter)
      && !this.isPropertyExists(formula))
  }

  get formulaFilter(): string {
    return this._formulaFilter
  }

  set formulaFilter(formulaFilter: string) {
    this._formulaFilter = formulaFilter
  }

  get validationError(): string {
    return this._validationError
  }

  set validationError(error: string) {
    this._validationError = error
  }

  get indexPropertyInEdit(): IndexProperty | null {
    return this._indexPropertyInEdit
  }

  set indexPropertyInEdit(property: IndexProperty | null) {
    this._indexPropertyInEdit = property
  }

  get existingColumnNamesSet(): Set<unknown> {
    return this._indexProperties
      .reduce(
        (existingNamesSet, col) => {
          if (col.name !== this._indexPropertyInEdit?.name) {
            existingNamesSet.add(col.name)
            if (col.newName) existingNamesSet.add(col.newName)
          }

          return existingNamesSet
        },
        new Set(),
      )
  }

  isPropertyExists(propertyName: string): boolean {
    return this.existingColumnNamesSet.has(propertyName)
  }

  reset(): void {
    this._indexProperties.forEach(property => {
      this.updateColumn(property, 'deleted', false)
      this.updateColumn(property, 'hidden', false)
      this.updateColumn(property, 'newName', undefined)
    })

    this._nameFilter = ''
  }
}
