import { ReactElement } from 'react'

const FiltersAmount = ({ filtersAmount }: { filtersAmount: number }): ReactElement => {
  if (filtersAmount === 0) return <></>

  return (
    <div
      // eslint-disable-next-line max-len
      className="w-4 h-4 text-white bg-primary rounded-full flex justify-center ml-2 -mt-px font-semibold text-xs leading-[1.1rem]"
    >
      {filtersAmount}
    </div>
  )
}

export default FiltersAmount
