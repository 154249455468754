import {
  ReactElement, ReactNode,
  useEffect, useState,
} from 'react'
import { Button } from 'antd'
import { observer } from 'mobx-react'

import { UploadMolFileButton } from '@components/files/Upload'
import QueryStructurePreview from '@components/structure-search/QueryStructurePreview'
import SearchTypeConfigurator from '@components/structure-search/SearchTypeConfigurator'
import { useKetcherContext } from '@contexts/ketcher-context'

interface StructureSearchPanelProps {
  queryStructure: string
  onQueryStructureChange?: (queryStructure: string) => void
  isResetButtonDisabled: boolean
  isSearchButtonDisabled?: boolean
  searchParams: IndexSearchParams
  slotPostSearchParams?: ReactNode
  onSearchClick: (searchParams: IndexSearchParams) => void
  onResetClick: (searchParams: IndexSearchParams) => void
  isStructureSearchFilesDisabled: boolean
}

const StructureSearchPanel = ({
  queryStructure: queryStructureOuter,
  onQueryStructureChange,
  isResetButtonDisabled,
  isSearchButtonDisabled,
  searchParams,
  slotPostSearchParams,
  onSearchClick,
  onResetClick,
  isStructureSearchFilesDisabled,
}: StructureSearchPanelProps): ReactElement => {
  const [queryStructure, setQueryStructure] = useState(queryStructureOuter)
  const [triggerDropSearch, setTriggerDropSearch] = useState(false)

  const dropQuery = () => {
    setQueryStructure('')
    setTriggerDropSearch(true)
  }
  const { addModal, removeModal, canAddModal } = useKetcherContext()

  const id = 'structureSearchPanelKetcher'

  const hideModal = () => {
    removeModal(id)
  }

  const setQueryAndClose = async (molString: string | null) => {
    if (molString) setQueryStructure(molString)
    hideModal()
  }

  const showModal = () => {
    if (canAddModal(id)) {
      return
    }

    addModal({
      id,
      structure: queryStructure || '',
      onOk: setQueryAndClose,
      onCancel: hideModal,
    })
  }

  useEffect(() => {
    if (!onQueryStructureChange || queryStructureOuter === queryStructure) return
    onQueryStructureChange(queryStructure)
  }, [onQueryStructureChange, queryStructure, queryStructureOuter])

  return (
    <div>
      <div className="flex gap-5">
        {queryStructure ? (
          <div className="w-full mb-5">
            <QueryStructurePreview queryStructure={queryStructure} />

            <div className="mt-3 text-sm flex justify-between">
              <div role="button" onClick={showModal}>Drawn Structure</div>

              <div>
                <span role="button" className="mr-4 text-primary" onClick={showModal}>Edit</span>
                <span role="button" onClick={dropQuery}>Delete</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Button
              className="flex-1"
              ghost
              size="large"
              onClick={showModal}
              disabled={isStructureSearchFilesDisabled}
            >
              Draw structure
            </Button>

            <UploadMolFileButton
              className="flex-1"
              onGetMolfile={setQueryStructure}
              disabled={isStructureSearchFilesDisabled}
            />
          </>
        )}
      </div>

      <SearchTypeConfigurator
        queryStructure={queryStructure}
        isResetButtonDisabled={isResetButtonDisabled}
        isSearchButtonDisabled={isSearchButtonDisabled}
        searchParams={searchParams}
        onSearchClick={onSearchClick}
        onResetClick={onResetClick}
        triggerDropSearch={triggerDropSearch}
        setTriggerDropSearch={setTriggerDropSearch}
        slotPostSearchParams={slotPostSearchParams}
      />
    </div>
  )
}

export default observer(StructureSearchPanel)
