import { Empty } from 'antd'
import { observer } from 'mobx-react'

import emptyBoxImage from '@assets/images/empty-box.svg'
import emptyBoxImageDark from '@assets/images/empty-box-dark.svg'
import appStore from '@store/app'

export type EmptyFileOverlayType = 'empty-file' | 'no-found'

interface EmptyFileOverlayProps {
  type: EmptyFileOverlayType
}

const descriptions = new Map<EmptyFileOverlayType, string>([
  ['empty-file', 'This file is empty'],
  ['no-found', 'No results were found for your search'],
])

const EmptyFileOverlay: React.FC<EmptyFileOverlayProps> = observer(({ type }) => (
  <Empty
    className="flex-1"
    image={(
      <img
        src={appStore.theme === 'dark' ? emptyBoxImageDark : emptyBoxImage}
        style={{ width: 64, height: 'auto' }}
        alt={descriptions.get(type)}
      />
    )}
    imageStyle={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
    }}
    description={descriptions.get(type)}
  />
))

export default EmptyFileOverlay
