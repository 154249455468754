import { ReactElement } from 'react'
import cn, { Argument } from 'classnames'

interface VATFootnoteProps {
  className?: Argument
}

const VATFootnote = ({ className = '' }: VATFootnoteProps): ReactElement => (
  <div className={cn(className)}>
    <span className="text-base font-semibold">Please contact us for academic pricing
    </span>
  </div>
)

export default VATFootnote
