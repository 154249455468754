import {
  ChangeEvent, forwardRef, useImperativeHandle, useRef, useState,
} from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { Input } from 'antd'

import selectInputOnFocus from '@utils/selectInputOnFocus'
import { inputNumberValidator } from '@utils/validators'
import { checkValidNumberOnSave } from '@utils/validators/inputNumberValidator'
import useAutoFocusAntdInputInAgGrid from './useAutoFocusAntdInputInAgGrid'

const NumericalCellEditor = forwardRef(({
  stopEditing,
  value,
  cellStartedEdit,
}: ICellEditorParams, ref) => {
  const [internalCellValue, setInternalCellValue] = useState<string>(
    typeof value === 'number' ? value : (value || ''),
  )
  const commit = () => stopEditing()
  const antInputRef = useRef<Input>(null)

  useAutoFocusAntdInputInAgGrid(antInputRef, cellStartedEdit)

  useImperativeHandle(ref, () => ({
    getValue() {
      return checkValidNumberOnSave(internalCellValue)
    },
  }))

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    const num = inputNumberValidator(newValue, internalCellValue)
    setInternalCellValue(num)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') stopEditing()
    else if (e.key === 'Enter') commit()
  }

  return (
    <Input
      ref={antInputRef}
      type="text"
      value={internalCellValue}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onBlur={commit}
      onFocus={selectInputOnFocus}
      bordered={false}
    />
  )
})

NumericalCellEditor.displayName = 'NumericalCellEditor'

export default NumericalCellEditor
