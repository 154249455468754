import {
  ReactElement, ReactNode,
  useCallback, useEffect, useState,
} from 'react'
import { Button, InputNumber, Select } from 'antd'

import Label from '@components/common/Label'
import Close from '@components/icons/Close'

const { Option } = Select

const commonSearchTypes: { label: string, value: StructuralSearchType }[] = [
  { label: 'Substructure', value: 'substructure' },
  { label: 'Exact', value: 'exact' },
  { label: 'Similarity', value: 'similarity' },
]

const similarityMetrics: { label: string, value: SimilarityMetric }[] = [
  { label: 'Tanimoto', value: 'tanimoto' },
  { label: 'Tversky', value: 'tversky' },
  { label: 'Euclid-cub', value: 'euclid-cub' },
]

const defaultSearchType = commonSearchTypes[0].value
const defaultMetric = similarityMetrics[0].value
const defaultSimilarityMinMax = { min: '0.8', max: '1' }
const defaultParameters: SimilarityParameters = { alpha: '1', beta: '1' }

interface TverskyConfiguratorProps {
  setParameters: (parameters: SimilarityParameters) => void
  parameters: SimilarityParameters
}

interface SimilarityConfiguratorProps extends TverskyConfiguratorProps {
  handleMetricChange: (newMetric: SimilarityMetric) => void
  setSimilarityMinMax: (parameters: typeof defaultSimilarityMinMax) => void
  similarityMinMax: typeof defaultSimilarityMinMax
  metric: SimilarityMetric
}

interface SearchTypeConfiguratorProps {
  queryStructure: string
  isResetButtonDisabled: boolean
  isSearchButtonDisabled?: boolean
  searchParams: IndexSearchParams
  onSearchClick: (searchParams: IndexSearchParams) => void
  onResetClick: (searchParams: IndexSearchParams) => void
  triggerDropSearch: boolean
  setTriggerDropSearch: (newValue: boolean) => void
  slotPostSearchParams?: ReactNode
}

const TverskyConfigurator = ({ setParameters, parameters }: TverskyConfiguratorProps): ReactElement => (
  <div className="flex gap-5 mb-5">
    <div className="flex-1">
      <Label text="Alpha" />

      <InputNumber
        size="large"
        className="w-full"
        value={parameters.alpha}
        defaultValue={defaultParameters.alpha}
        min="0"
        max="1"
        step="0.1"
        onChange={alpha => { setParameters({ ...parameters, alpha }) }}
        stringMode
      />
    </div>

    <div className="flex-1">
      <Label text="Beta" />

      <InputNumber
        size="large"
        className="w-full"
        value={parameters.beta}
        defaultValue={defaultParameters.beta}
        min="0"
        max="1"
        step="0.1"
        onChange={beta => { setParameters({ ...parameters, beta }) }}
        stringMode
      />
    </div>
  </div>
)

const SimilarityConfigurator = ({
  handleMetricChange,
  setSimilarityMinMax,
  similarityMinMax,
  metric,
  setParameters,
  parameters,
}: SimilarityConfiguratorProps): ReactElement => (
  <>
    <div className="mb-5">
      <Label text="Metric" />

      <Select
        size="large"
        value={metric}
        defaultValue={defaultMetric}
        className="w-full"
        onChange={handleMetricChange}
      >
        {similarityMetrics.map(({ label, value }) => (
          <Option
            key={value}
            value={value}
          >
            {label}
          </Option>
        ))}
      </Select>
    </div>

    <div className="flex gap-5 mb-5">
      <div className="flex-1">
        <Label text="Min" />

        <InputNumber
          size="large"
          className="w-full"
          value={similarityMinMax.min}
          defaultValue={defaultSimilarityMinMax.min}
          min="0"
          max={similarityMinMax.max}
          step="0.1"
          onChange={min => { setSimilarityMinMax({ ...similarityMinMax, min }) }}
          stringMode
        />
      </div>

      <div className="flex-1">
        <Label text="Max" />

        <InputNumber
          size="large"
          className="w-full"
          value={similarityMinMax.max}
          defaultValue={defaultSimilarityMinMax.max}
          min={similarityMinMax.min}
          max="1"
          step="0.1"
          onChange={max => { setSimilarityMinMax({ ...similarityMinMax, max }) }}
          stringMode
        />
      </div>
    </div>

    {metric === 'tversky' && (
      <TverskyConfigurator
        setParameters={setParameters}
        parameters={parameters}
      />
    )}
  </>
)

const SearchTypeConfigurator = ({
  queryStructure,
  isResetButtonDisabled,
  isSearchButtonDisabled,
  searchParams: outerSearchParams,
  onSearchClick,
  triggerDropSearch,
  setTriggerDropSearch,
  slotPostSearchParams,
  onResetClick,
}: SearchTypeConfiguratorProps): ReactElement => {
  const [type, setType] = useState(
    outerSearchParams.type === 'all' ? defaultSearchType : outerSearchParams.type,
  )

  const outerSimilarity = outerSearchParams.similarity

  const [metric, setMetric] = useState(outerSimilarity?.metric || defaultMetric)

  const [similarityMinMax, setSimilarityMinMax] = useState(outerSimilarity?.min ? {
    min: outerSimilarity.min,
    max: outerSimilarity.max,
  } : defaultSimilarityMinMax)

  const [parameters, setParameters] = useState(outerSimilarity?.parameters || defaultParameters)

  const handleTypeChange = useCallback((newType: StructuralSearchType) => {
    setType(newType)

    if (newType === 'similarity') {
      setMetric(defaultMetric)
      setSimilarityMinMax(defaultSimilarityMinMax)
    }
  }, [])

  const handleMetricChange = useCallback((newMetric: SimilarityMetric) => {
    setMetric(newMetric)
    setSimilarityMinMax(defaultSimilarityMinMax)

    if (newMetric === 'tversky') {
      setParameters(defaultParameters)
    }
  }, [])

  const runSearch = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { similarity: excludedSimilarity, ...outerSearchParamsWithoutSimilarity } = outerSearchParams

    const searchParams = {
      ...outerSearchParamsWithoutSimilarity,
      type,
      queryStructure,
    }

    if (type !== 'similarity') {
      onSearchClick(searchParams)
      return
    }

    const similarity: Similarity = {
      metric,
      ...similarityMinMax,
      parameters: metric === 'tversky' ? parameters : undefined,
    }

    const similaritySearchParams = {
      ...searchParams,
      similarity,
    }

    onSearchClick(similaritySearchParams)
  }

  const dropSearch = useCallback(() => {
    setType(defaultSearchType)
    setMetric(defaultMetric)
    setSimilarityMinMax(defaultSimilarityMinMax)
    setParameters(defaultParameters)

    // eslint-disable-next-line max-len, @typescript-eslint/no-unused-vars
    const { similarity: excludedSimilarity, queryStructure: excludedQueryStructure, ...searchParamsWithoutStructureSearch } = outerSearchParams

    onResetClick({
      ...searchParamsWithoutStructureSearch,
      type: 'all' as StructuralSearchType,
    })
  }, [onResetClick, outerSearchParams])

  useEffect(() => {
    if (triggerDropSearch) {
      dropSearch()
      setTriggerDropSearch(false)
    }
  }, [dropSearch, setTriggerDropSearch, triggerDropSearch])

  if (!queryStructure) return <div />

  return (
    <div>
      <div className="mb-4 font-semibold text-base">Search Parameters</div>

      <div className="flex flex-col">
        <div className="mb-5">
          <Label text="Type" />

          <Select
            size="large"
            value={type}
            defaultValue={defaultSearchType}
            className="w-full"
            onChange={handleTypeChange}
          >
            {commonSearchTypes.map(({ label, value }) => (
              <Option
                key={value}
                value={value}
              >
                {label}
              </Option>
            ))}
          </Select>
        </div>

        {type === 'similarity' && (
          <SimilarityConfigurator
            handleMetricChange={handleMetricChange}
            setSimilarityMinMax={setSimilarityMinMax}
            similarityMinMax={similarityMinMax}
            metric={metric}
            setParameters={setParameters}
            parameters={parameters}
          />
        )}

        {slotPostSearchParams}

        <div className="flex gap-5 mt-3">
          <Button
            size="large"
            ghost
            onClick={dropSearch}
            disabled={isResetButtonDisabled}
            className="flex-1 flex justify-center items-center"
          >
            Reset
            <Close className="ml-2 w-4 h-4" />
          </Button>

          <Button
            size="large"
            type="primary"
            onClick={runSearch}
            disabled={isSearchButtonDisabled}
            className="flex-1"
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SearchTypeConfigurator
