import { useEffect } from 'react'
import { Input } from 'antd'

export default function useAutoFocusAntdInputInAgGrid(
  antInputRef: React.RefObject<Input>,
  cellStartedEdit: boolean,
): void {
  useEffect(() => {
    let tid: ReturnType<typeof setImmediate> | null = null

    if (antInputRef.current && cellStartedEdit) {
      tid = setImmediate(
        (currentInput: Input) => currentInput.focus(),
        antInputRef.current,
      )
    }

    return () => {
      if (tid) clearImmediate(tid)
    }
  }, [antInputRef, cellStartedEdit])
}
