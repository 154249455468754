class StructureImagesCache {
  has(imagesCache: Map<string, string>, structure: string, query: string | undefined) {
    const key = this.getKey(structure, query)
    return imagesCache.has(key)
  }

  set(imagesCache: Map<string, string>, structure: string, query: string | undefined, value: string): void {
    const key = this.getKey(structure, query)
    imagesCache.set(key, value)
  }

  get(imagesCache: Map<string, string>, structure: string, query: string | undefined): string | undefined {
    const key = this.getKey(structure, query)
    return imagesCache.get(key)
  }

  clear(imagesCache: Map<string, string>): void {
    imagesCache.clear()
  }

  getKey(structure: string, query: string | undefined): string {
    return `${structure}:${query || '0'}`
  }
}

export default new StructureImagesCache()
