import { ReactElement } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'

interface SimpleInnerDrawerProps {
  visible: boolean
  children: ReactElement
}

const SimpleInnerDrawer = ({
  visible,
  children,
}: SimpleInnerDrawerProps): ReactElement => (
  <div
    className={cn('bg-gray-5 dark:bg-gray-90 w-0 overflow-hidden flex-shrink-0', { 'w-[340px]': visible })}
  >
    <div className="p-5">
      {children}
    </div>
  </div>
)

export default observer(SimpleInnerDrawer)
