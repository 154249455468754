import {
  ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react'
import ReactDOM from 'react-dom'
import { Rnd } from 'react-rnd'
import { Button, message } from 'antd'
import { Ketcher } from 'ketcher-core'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'

import appStore from '@store/app'
import StructureImageStore from '@store/structure-image'
import { CloseOutlined } from '@ant-design/icons'
import SpinnerArea from '@components/common/SpinnerArea'

interface KetcherModalProps {
  visible: boolean
  initialStructure?: string
  saving?: boolean
  onOk: (molString: string | null) => void
  onCancel: () => void
  isActive?: boolean
  setIsActive?: (isActive: boolean) => void
  offsetMultiplier?: number
  extension: string
}

interface KetcherPageCustomMessage {
  eventType: 'ketcherInit' | 'ketcherError'
  message?: string
}

const CHECK_KETCHER_INTERVAL_MS = 50
const OFFSET = 15

// empty molString length ~ 101
function validateMolStringNotEmpty(molString?: string | null): molString is string {
  return molString!.length > 105
}

const KETCHER_EMPTY_STRUCTURE_CHECK_DEBOUNCE_TIMEOUT_MS = 300

const KetcherModal = observer(({
  visible,
  initialStructure = '',
  saving,
  onOk,
  onCancel,
  isActive,
  setIsActive,
  offsetMultiplier = 0,
  extension,
}: KetcherModalProps): ReactElement => {
  const [ketcherInstance, setKetcherInstance] = useState<Ketcher | null>()
  const [checkingKetcherTimeoutId, setCheckingKetcherTimeoutId] = useState<number>()
  const [loadingMolecule, setLoadingMolecule] = useState(false)
  const [deserializedStructure, setDeserializedStructure] = useState('')
  const [isEmptyStructure, setIsEmptyStructure] = useState(Boolean(!initialStructure))
  const { theme } = appStore

  const getMolfile = useCallback(
    async () => ketcherInstance && ketcherInstance.getMolfile(),
    [ketcherInstance],
  )

  const checkIsEmptyKetcherStructure = useCallback(async () => {
    const molString = await getMolfile()
    const isEmpty = !validateMolStringNotEmpty(molString)
    setIsEmptyStructure(isEmpty)
    return isEmpty
  }, [getMolfile])

  const deserializeMolecule = useCallback(async (structure: string) => {
    try {
      setLoadingMolecule(true)
      const molString = await StructureImageStore.deserializeStructure(structure, extension)
      setLoadingMolecule(false)
      return molString
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Cannot deserialize molecule because of error:', err)
      setLoadingMolecule(false)
      throw err
    }
  }, [extension])

  const openKetcher = useCallback(async (ketcher: Ketcher) => {
    let molString = initialStructure

    if (!molString) return

    const structureType = StructureImageStore.getRenderStructureType(molString)

    if (structureType === 'RAW') {
      molString = await deserializeMolecule(molString)
    }

    ketcher.setMolecule(molString)
    setDeserializedStructure(molString)
    setIsEmptyStructure(!validateMolStringNotEmpty(molString))
  }, [deserializeMolecule, initialStructure])

  const closeKetcher = useCallback(() => {
    ketcherInstance!.editor.clear()
  }, [ketcherInstance])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKectherError = (e: any) => {
    if (!e || !e.data || !e.data.eventType) return
    const ketcherMessageData = e.data as KetcherPageCustomMessage

    if (ketcherMessageData.eventType !== 'ketcherError') return

    if (typeof ketcherMessageData.message === 'undefined') {
      message.error('Something went wrong')
    } else {
      message.error(ketcherMessageData.message)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleKectherError)

    return () => {
      window.removeEventListener('message', handleKectherError)
    }
  }, [])

  useEffect(() => {
    if (!ketcherInstance) return
    if (visible) openKetcher(ketcherInstance)
    else closeKetcher()
  }, [closeKetcher, ketcherInstance, openKetcher, visible])

  const onOkLocal = useCallback(async () => {
    if (await checkIsEmptyKetcherStructure()) return

    const molString = await getMolfile()
    const isSameSmiles = deserializedStructure
      && molString
      && deserializedStructure?.split('\n')[0] === molString?.split('\n')[0]
    let okResponse = isSameSmiles ? null : ''

    if (validateMolStringNotEmpty(molString)) okResponse = molString
    onOk(okResponse)
  }, [getMolfile, deserializedStructure, onOk, checkIsEmptyKetcherStructure])

  const onKetcherInit = useCallback((e: React.BaseSyntheticEvent) => {
    const tid = window.setInterval(() => {
      const ketcher = e.target.contentWindow.ketcher as Ketcher
      if (ketcher == null) return
      setKetcherInstance(ketcher)
      clearInterval(tid)
    }, CHECK_KETCHER_INTERVAL_MS)

    setCheckingKetcherTimeoutId(tid)
  }, [])

  useEffect(
    () => () => clearInterval(checkingKetcherTimeoutId),
    [checkingKetcherTimeoutId],
  )

  useEffect(() => {
    if (!ketcherInstance) return
    const handler = debounce(checkIsEmptyKetcherStructure, KETCHER_EMPTY_STRUCTURE_CHECK_DEBOUNCE_TIMEOUT_MS)
    ketcherInstance.editor.subscribe('change', handler)

    // eslint-disable-next-line consistent-return
    return () => {
      handler.cancel()
      ketcherInstance.editor.unsubscribe('change', handler)
    }
  }, [ketcherInstance, checkIsEmptyKetcherStructure])

  // Let's say you want your component to be 80% of the window size

  const windowSize = { width: window.innerWidth, height: window.innerHeight }

  const [isMoving, setIsMoving] = useState(false)

  const componentWidth = windowSize.width * 0.75
  const componentHeight = windowSize.height * 0.9
  const offset = offsetMultiplier * OFFSET
  const x = (windowSize.width / 2) - (componentWidth / 2) + offset
  const y = (windowSize.height / 2) - (componentHeight / 2) + offset

  const componentMaxWidth = windowSize.width * 0.8
  const componentMaxHeight = windowSize.height * 0.9

  useEffect(
    () => { if (visible) setIsMoving(false) },
    [visible],
  )

  const zIndex = isActive ? 1 : 0

  const root = useMemo(() => {
    const element = document.getElementById('portal')!
    element.className = 'fixed top-0 bottom-0 left-0 right-0 pointer-events-none z-10'
    return element
  }, [])

  return visible ? (
    ReactDOM.createPortal(
      <Rnd
        default={{
          x,
          y,
          width: componentWidth,
          height: componentHeight,
        }}
        minHeight={330}
        minWidth={400}
        maxHeight={componentMaxHeight}
        maxWidth={componentMaxWidth}
        className="p-0 pb-[5px] rounded-[5px] bg-white fixed overflow-hidden shadow-outer-all pointer-events-auto"
        style={{
          zIndex: 1000 + zIndex,
        }}
        onDragStart={() => {
          setIsMoving(true)
          setIsActive?.(true)
        }}
        onDragStop={() => {
          setIsMoving(false)
        }}
      >
        {!isActive && (
        <div
          role="button"
          aria-label="overlay button"
          className="absolute top-12 bottom-0 left-0 right-0"
          onClick={() => {
            setIsActive?.(true)
          }}
        />
        )}
        {(!ketcherInstance || loadingMolecule) && (
        <div className="absolute w-full top-12 bottom-0 flex">
          <SpinnerArea />
        </div>
        )}
        <div className={`flex items-center justify-between h-[50px] pl-[15px] ${(theme === 'dark' ? 'bg-[rgba(57,62,72,1)]' : 'ag-theme-alpine')}`}>
          <h2>Draw Structure</h2>
          <CloseOutlined className="text-xl px-[15px] h-full flex items-center" onClick={onCancel} />
        </div>
        <iframe
          style={{
            zIndex: 1,
            display: 'block',
            pointerEvents: isMoving ? 'none' : 'auto',
            borderTopWidth: '1px',
          }}
          title="ketcher"
          src="/ketcher-remote/index.html"
          frameBorder="0"
          className="w-full  h-[calc(100%-90px)]"
          onLoad={onKetcherInit}
        />
        <div className="text-left flex items-center justify-end p-2 h-[40px] py-[15px]">
          {isEmptyStructure && (
          <div className="text-danger flex-auto">
            Please add a structure
          </div>
          )}
          <div
            className="flex gap-2 absolute bottom-2"
          >
            <Button onMouseDown={onCancel} loading={saving}>Cancel</Button>
            <Button onMouseDown={onOkLocal} type="primary" disabled={isEmptyStructure} loading={saving}>Done</Button>
          </div>
        </div>
      </Rnd>,
      root,
    )
  ) : <></>
})

export default KetcherModal
