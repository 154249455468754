import { useEffect } from 'react'
import { reaction } from 'mobx'

import filesStore from '@store/files'
import { ONE_MEGABYTE } from '@shared/constants'
import apiService from '@shared/services/api-service'

const getFileRefetchDelay = (fileBytes: number): number => {
  if (fileBytes < ONE_MEGABYTE * 5) return 2500
  if (fileBytes < ONE_MEGABYTE * 50) return 4000
  return 6000
}

const useRefetchParsingFiles = (): void => {
  useEffect(() => {
    const parsingFiles = new Set()

    const dispose = reaction(
      () => filesStore.uploadedFiles.filter(file => !file.status.includes('PARSING_COMPLETED')),
      files => files.forEach(async ({ id, fileBytes }) => {
        if (parsingFiles.has(id)) return

        parsingFiles.add(id)

        try {
          await apiService.createPollingRequest<UploadedFile>({
            url: `/files/${id}`,
            interval: getFileRefetchDelay(fileBytes),
            validate: newFileDescription => {
              const { status } = newFileDescription
              filesStore.overwriteFile(newFileDescription)
              return status === 'PARSING_COMPLETED' || status === 'PARSING_COMPLETED_WITH_ERRORS'
            },
          })
        } catch (error) {
          if (error?.status === 403 || error?.errorCode === 'FileNotFoundByIdException') return
          throw error
        } finally {
          parsingFiles.delete(id)
        }
      }),
    )

    return () => dispose()
  }, [])
}

export default useRefetchParsingFiles
