/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const QuestionInCircle = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    className={cn('sdf-icon fill-current', className)}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 9.04688C5.74102 9.04688 5.53125 8.83711 5.53125 8.57812C5.53125 8.31914 5.74102 8.10938 6 8.10938C6.25898 8.10938 6.46875 8.31914 6.46875 8.57812C6.46875 8.83711 6.25898 9.04688 6 9.04688ZM6.73711 6.47461C6.63098 6.51559 6.53967 6.5876 6.47507 6.68125C6.41048 6.7749 6.37561 6.88585 6.375 6.99961V7.26562C6.375 7.31719 6.33281 7.35938 6.28125 7.35938H5.71875C5.66719 7.35938 5.625 7.31719 5.625 7.26562V7.01367C5.625 6.74297 5.70352 6.47578 5.8582 6.25312C6.00938 6.03516 6.22031 5.86875 6.46875 5.77383C6.86719 5.62031 7.125 5.28633 7.125 4.92188C7.125 4.40508 6.61992 3.98438 6 3.98438C5.38008 3.98438 4.875 4.40508 4.875 4.92188V5.01094C4.875 5.0625 4.83281 5.10469 4.78125 5.10469H4.21875C4.16719 5.10469 4.125 5.0625 4.125 5.01094V4.92188C4.125 4.46133 4.32656 4.03125 4.69219 3.71133C5.04375 3.40313 5.50781 3.23438 6 3.23438C6.49219 3.23438 6.95625 3.4043 7.30781 3.71133C7.67344 4.03125 7.875 4.46133 7.875 4.92188C7.875 5.59922 7.42852 6.20859 6.73711 6.47461Z" />
  </svg>
)

export default QuestionInCircle
