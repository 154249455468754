import {
  useCallback, useState,
} from 'react'
import { IHeaderParams } from 'ag-grid-community'

import SortableColumnHeader from '@components/common/SortableColumnHeader'
import { useTableManagementStore } from '@contexts/file-edit-context'

const MULTI_SORT_KEYS: Partial<keyof React.MouseEvent>[] = ['ctrlKey', 'metaKey']

const AgGridColumnHeader: React.FC<IHeaderParams> = (({
  displayName,
  progressSort,
  column,
  showColumnMenu,
  api,
}) => {
  const handleSortChanged = useCallback((event: React.MouseEvent) => {
    const isMultiSortEnabled = MULTI_SORT_KEYS.some(key => !!event[key])
    progressSort(isMultiSortEnabled)
  }, [progressSort])
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const { indexProperties } = useTableManagementStore()

  const sortOrder = column.getSort() || null
  const sortIndex = column.getSortIndex()
  const isSortActive = column.isSorting()
  const indexProperty = indexProperties.find(
    item => item.name === column.getColDef().field,
  )

  return (
    <div
      className="flex items-center justify-start w-full"
      onMouseEnter={() => setIsMenuVisible(true)}
      onMouseLeave={() => setIsMenuVisible(false)}
    >
      <SortableColumnHeader
        className="w-full h-full m-0"
        title={displayName}
        isSortActive={isSortActive}
        sortOrder={sortOrder}
        sortIndex={sortIndex}
        withIndex
        onClick={handleSortChanged}
        showColumnMenu={showColumnMenu}
        api={api}
        isMenuVisible={isMenuVisible}
        indexProperty={indexProperty}
      />
    </div>
  )
})

export default AgGridColumnHeader
