/* eslint-disable max-len */
import { ReactElement } from 'react'
import { Checkbox, Form } from 'antd'

import { privacyPolicyUrl, termsOfServiceUrl, termsOfUseUrl } from '@shared/constants'

interface AcceptLicenseAgreementProps {
  type?: 'signup' | 'subscribe'
}

const AcceptLicenseAgreement = ({
  type = 'signup',
}: AcceptLicenseAgreementProps): ReactElement => (
  <>
    <Form.Item
      name="agreement"
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error('You should give your consent'))
            }

            return Promise.resolve()
          },
          validateTrigger: 'onSubmit',
        },
      ]}
    >

      <Checkbox className="mr-2 mt-5">
        <span>
          I agree with
        </span>
        {
      type === 'signup'
        ? (
          <>
            <a
              href={termsOfUseUrl}
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            > Terms of Use
            </a>
            <span> and </span>
            <a
              href={privacyPolicyUrl}
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            > Privacy Policy
            </a>
          </>
        )
        : (
          <a
            href={termsOfServiceUrl}
            target="_blank"
            className="text-primary"
            rel="noreferrer"
          > Terms of Service
          </a>
        )

    }
      </Checkbox>
    </Form.Item>
  </>
)

export default AcceptLicenseAgreement
