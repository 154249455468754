import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import guestRoutes from '@router/guest-routes'
import LogInForm from '@components/guest-forms/LogInForm'
import PageFormContainer from '@components/hoc/PageFormContainer'
import { LandingPath } from '@shared/constants'

const Content = observer((): ReactElement => {
  const { userExist, email } = userStore.signUpData
  const title = userExist ? 'Welcome Back!' : 'Sign In to Adroit REPOSITORY'
  const description = userExist ? 'Enter your password to sign in' : 'Enter your email and password to sign in'

  return (
    <>
      <h2 className="text-3xl font-bold text-center mb-6">{title}</h2>
      <p className="text-center mb-8">{description}</p>
      <LogInForm email={email} />
      <div className="min-h-full flex justify-between text-gray-70 dark:text-white mt-4">
        <div>Don’t have an account?
          <Link className="text-primary font-semibold" to={guestRoutes.SignUp.path}> Sign Up</Link>
        </div>
        <Link
          className="underline font-semibold text-gray-40"
          to={guestRoutes.PasswordRecovery.path}
        >Password Recovery
        </Link>
      </div>
    </>
  )
})

export default function LogIn(): ReactElement {
  const backHomeButton = { label: 'Back to the homepage', path: LandingPath }
  const backButton = userStore.signUpData.email ? undefined : backHomeButton
  return (
    <PageFormContainer testId="auth-page" backButton={backButton}>
      <Content />
    </PageFormContainer>
  )
}
