import { CSSProperties, useEffect, useState } from 'react'
import { Image, Popover } from 'antd'
import { observer } from 'mobx-react'

import appStore from '@store/app'
import InvalidStructure from '@components/icons/InvalidStructure'

/* eslint-disable max-len */
const fallbackImageGray10 = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAABAAEDASEAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9MKK6DI//2Q=='
const fallbackImageGray70 = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAABAAEDASEAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD5Por0DnP/2Q=='

interface InnerImageProps {
  height: CSSProperties['height']
  maxHeight?: CSSProperties['maxHeight']
  width: CSSProperties['width']
  imageAsTextColored: string
  theme: Theme
  errorText?: string
  onClick?: () => void
}

interface PlaceholderImageProps {
  height: CSSProperties['height']
  maxHeight?: CSSProperties['maxHeight']
  width: CSSProperties['width']
  theme: Theme
}

const InnerImage: React.FC<InnerImageProps> = ({
  height,
  maxHeight,
  width,
  imageAsTextColored,
  theme,
  onClick,
  errorText,
}) => {
  if (errorText) {
    return (
      <InvalidStructure />
    )
  }

  return (
    <Image
      height={height}
      width={width}
      style={{ maxHeight }}
      src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(imageAsTextColored)}`}
      preview={false}
      fallback={theme === 'dark' ? fallbackImageGray70 : fallbackImageGray10}
      onClick={onClick}
      className="select-none animate-fade-in cursor-text"
    />
  )
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  height, maxHeight, width, theme,
}) => (
  <Image
    height={height}
    width={width}
    style={{ maxHeight }}
    preview={false}
    src={theme === 'dark' ? fallbackImageGray70 : fallbackImageGray10}
    className="select-none animate-fade-in-out"
  />
)

const StructureImage: React.FC<StructureImageProps> = observer(({
  imageAsText,
  height = 160,
  maxHeight,
  width = 240,
  onClick,
  errorText,
}) => {
  const [imageAsTextColored, setImageAsTextColored] = useState('')

  const { theme } = appStore

  useEffect(() => {
    if (theme === 'dark') {
      setImageAsTextColored(imageAsText.replaceAll(/rgb\(0%, ?0%, ?0%\)/g, 'white'))
    } else {
      // #393e48 is tailwind.gray.70
      setImageAsTextColored(imageAsText.replaceAll(/rgb\(0%, ?0%, ?0%\)/g, '#393e48'))
    }
  }, [imageAsText, theme])

  if (!imageAsTextColored) {
    return <PlaceholderImage width={width} height={height} maxHeight={maxHeight} theme={theme} />
  }

  return (
    <Popover
      content={(
        errorText ? <p>{errorText}</p>
          : (
            <InnerImage
              height={160}
              width={240}
              imageAsTextColored={imageAsTextColored}
              theme={theme}
              onClick={onClick}
              errorText={errorText}
            />
          )
      )}
      trigger="hover"
      placement="right"
      className="justify-center w-full"
    >
      <div className="inline-flex items-center">
        <InnerImage
          height={height}
          maxHeight={maxHeight}
          width={width}
          imageAsTextColored={imageAsTextColored}
          theme={theme}
          onClick={onClick}
          errorText={errorText}
        />
      </div>
    </Popover>
  )
})

export default StructureImage
