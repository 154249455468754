/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const FileIcon = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 12 16"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 0H2.42857C2.35 0 2.28571 0.0642857 2.28571 0.142857V1.14286C2.28571 1.22143 2.35 1.28571 2.42857 1.28571H11.2857V13.5714C11.2857 13.65 11.35 13.7143 11.4286 13.7143H12.4286C12.5071 13.7143 12.5714 13.65 12.5714 13.5714V0.571429C12.5714 0.255357 12.3161 0 12 0ZM9.71429 2.28571H0.571429C0.255357 2.28571 0 2.54107 0 2.85714V12.3339C0 12.4857 0.0607142 12.6304 0.167857 12.7375L3.2625 15.8321C3.30179 15.8714 3.34643 15.9036 3.39464 15.9304V15.9643H3.46964C3.53214 15.9875 3.59821 16 3.66607 16H9.71429C10.0304 16 10.2857 15.7446 10.2857 15.4286V2.85714C10.2857 2.54107 10.0304 2.28571 9.71429 2.28571ZM4.87857 14.8571H4.875L1.28571 11.2679V11.2643H4.87857V14.8571Z" />
  </svg>
)

export default FileIcon
