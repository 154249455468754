const downloadFileByUrl = ({ url, fullName }: {url: string, fullName: string}): void => {
  const a = document.createElement('a')
  a.href = url
  a.download = fullName
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export default downloadFileByUrl
