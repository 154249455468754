/* eslint-disable max-len */
import { ReactElement, useState } from 'react'
import { Button } from 'antd'
import { observer } from 'mobx-react'

import molecule from '@assets/images/molecule.png'
import userStore from '@store/user'
import { privacyPolicyUrl } from '@shared/constants'

const Cookies = observer((): ReactElement => {
  const [cookiesAccepted, setCookiesAccepted] = useState(!!localStorage.getItem('cookiesAccepted'))

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true')
    setCookiesAccepted(true)
  }

  if (userStore.authorized || cookiesAccepted) return <></>

  return (
    <div
      className="px-4 sm:px-6 w-full fixed bottom-4 sm:bottom-6 left-2/4 transform -translate-x-2/4 z-50"
      style={{ maxWidth: 1076 }}
    >
      <div
        className="p-4 sm:p-6 flex items-center flex-col sm:flex-row rounded-xl bg-white dark:bg-gray-60"
        style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)' }}
      >
        <img
          src={molecule}
          alt="Chem molecule"
          className="mb-3 sm:mb-0 sm:mr-6"
          width="60"
        />

        <div className="mb-3.5 sm:mb-0 sm:mr-10 max-w-lg text-sm sm:text-base text-center sm:text-left">
          We may use cookies to personalize content and improve your browsing experience. By using our website, you agree to our Privacy Policy
        </div>

        <a
          href={privacyPolicyUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-3 sm:mb-0 sm:mr-6 w-full sm:w-40"
        >
          <Button
            ghost
            className="w-full"
          >Privacy Policy
          </Button>
        </a>

        <Button
          type="primary"
          className="w-full sm:w-40"
          onClick={acceptCookies}
        >Accept
        </Button>
      </div>
    </div>
  )
})

export default Cookies
