import { useCallback } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { observer } from 'mobx-react'

import AgGridHeaderLineNumberCheckbox from '@components/table/AgGridHeaderLineNumberCheckbox'
import { useFileStore } from '@contexts/file-edit-context'

const FileLineNumberHeader: React.FC<IHeaderParams> = observer(({ displayName }) => {
  const fileStore = useFileStore()

  const handleChangeIsAllSelected = useCallback(isAllSelected => {
    fileStore.setAllSelected(isAllSelected)
  }, [fileStore])

  return (
    <AgGridHeaderLineNumberCheckbox
      displayName={displayName}
      loadedRowsCount={fileStore.loadedRowsCount}
      selectedRowsCount={fileStore.selectedMoleculeCustomOrdersCount}
      isAllSelected={fileStore.isAllSelected}
      onChange={handleChangeIsAllSelected}
    />
  )
})

export default FileLineNumberHeader
