/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Chevron = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M7.9992 12.0002C7.9992 12.0002 7.2857 11.2767 2.92783 6.87597C2.76058 6.70669 2.66663 6.47709 2.66663 6.23769C2.66663 5.99829 2.76058 5.76869 2.92783 5.59941C3.09507 5.43013 3.3219 5.33503 3.55842 5.33503C3.79493 5.33503 4.02177 5.43013 4.18901 5.59941L7.9992 9.46503L11.8094 5.59941C11.892 5.51515 11.9902 5.44827 12.0984 5.40263C12.2067 5.35699 12.3227 5.3335 12.44 5.3335C12.5572 5.3335 12.6733 5.35699 12.7816 5.40263C12.8898 5.44827 12.988 5.51515 13.0706 5.59941C13.1538 5.68299 13.2199 5.78241 13.265 5.89196C13.3101 6.00151 13.3333 6.11901 13.3333 6.23769C13.3333 6.35637 13.3101 6.47387 13.265 6.58342C13.2199 6.69297 13.1538 6.7924 13.0706 6.87597C8.68628 11.3034 7.9992 12.0002 7.9992 12.0002Z" />
  </svg>
)

export default Chevron
