import {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { useToggle } from 'ahooks'
import {
  Button, Form, Input,
  Radio, Tabs,
} from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react'

import { subscribeBackgroundTask } from '@store/background-tasks'
import subscriptionStore from '@store/subscription'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import PopoverAndTriggerButton, {
  PopoverProps,
} from '@components/common/PopoverAndTriggerButton'
import SortableColumnHeader from '@components/common/SortableColumnHeader'
import useBackgroundTasksContext from '@contexts/background-tasks'
import { useFileStore, useTableManagementStore } from '@contexts/file-edit-context'
import { isApiCustomException } from '@shared/services/api-service'
import { sanitizeString } from '@utils/string-utils'
import { columnNameValidator, inputNumberValidator } from '@utils/validators'
import { checkValidNumberOnSave } from '@utils/validators/inputNumberValidator'

const defaultPropertyType = 'STRING'
const ITEM_HEIGHT = 28

interface AddColumnPopoverProps extends PopoverProps {
  onLoading: (newColumnName: string) => void
}

const indexPropertyMainTypesAndLabels: Record<MainIndexPropertyType, string> = {
  STRING: 'Text',
  DECIMAL: 'Numeric',
}

const AddColumnPopFooter = ({
  disabled,
  onOk,
  onCancel,
}: { disabled?: boolean, onCancel: () => void, onOk?: () => void }) => (
  <div className="mb-1 flex justify-end">
    <Button
      type="text"
      className="mr-3"
      onClick={onCancel}
    >
      Cancel
    </Button>

    <Button
      type="primary"
      disabled={disabled}
      htmlType="submit"
      onClick={onOk}
    >
      Apply
    </Button>
  </div>
)

const CalculatedColumnsContent = observer(({
  hidePopUp,
  onLoading,
}: AddColumnPopoverProps) => {
  const fileStore = useFileStore()
  const backgroundTasksStore = useBackgroundTasksContext()
  const [selectedFormula, setSelectedFormula] = useState<string>('')
  const [focusedIndex, setFocusedIndex] = useState<number>(-1)
  const tableManagementStore = useTableManagementStore()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const { filteredFormulas } = tableManagementStore

  const changeFocus = useCallback((step: number) => {
    if (containerRef.current) {
      const newIndex = Math.max(0, Math.min(filteredFormulas.length - 1, focusedIndex + step))
      setFocusedIndex(newIndex)
      containerRef.current.scrollTop = newIndex * ITEM_HEIGHT
    }
  }, [focusedIndex, filteredFormulas.length])

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault()
        changeFocus(-1)
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        changeFocus(1)
      } else if (event.key === 'Enter' && focusedIndex >= 0) {
        event.preventDefault()
        setSelectedFormula(filteredFormulas[focusedIndex])
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [focusedIndex, changeFocus, filteredFormulas])

  const handleHidePopUp = () => {
    tableManagementStore.formulaFilter = ''
    hidePopUp()
  }

  const handleCancel = () => {
    handleHidePopUp()
    fileStore.deleteNewColumn()
  }

  const handleApply = async () => {
    if (!selectedFormula) return

    backgroundTasksStore.createTask({
      type: 'add-column',
      data: {
        column: {
          name: selectedFormula,
          deleted: false,
          hidden: false,
          type: 'STRING',
          weblink: false,
        },
        formula: selectedFormula,
        fileId: fileStore.fileId,
        fileName: fileStore.fileDescription?.fileName || '',
        fileStore,
      },
    })

    onLoading(selectedFormula)

    handleHidePopUp()
  }

  const setSearchValue = useCallback((searchValue: string) => {
    setSelectedFormula('')
    setFocusedIndex(-1)
    tableManagementStore.formulaFilter = searchValue
  }, [tableManagementStore])

  return (
    <div style={{ width: 360 }}>
      <Input
        prefix={<SearchOutlined />}
        suffix={<CloseOutlined onClick={() => setSearchValue('')} />}
        value={tableManagementStore.formulaFilter}
        placeholder="Type a text"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
      />

      <div className="font-bold text-base dark:text-white my-4">Select formula</div>

      <div className="max-h-56 overflow-auto my-4 pt-1" id="formula_list_container" ref={containerRef}>
        {tableManagementStore.filteredFormulas
          .map((formula, index) => (
            <div
              key={formula}
              role="button"
              onClick={() => {
                setSelectedFormula(formula)
              }}
              className={cn(
                `text-base dark:text-white p-1 mr-2
                border-2 rounded-sm border-transparent
                hover:bg-gray-10 dark:hover:bg-gray-80`,
                selectedFormula === formula && 'bg-primary-30 dark:bg-primary-300 !border-primary',
                focusedIndex === index && 'bg-gray-10 dark:bg-gray-80',
              )}
            >{formula}
            </div>
          ))}
      </div>

      <AddColumnPopFooter disabled={!selectedFormula} onOk={handleApply} onCancel={handleCancel} />
    </div>
  )
})

const PresetValueContent = observer(({ hidePopUp, onLoading }: AddColumnPopoverProps) => {
  const fileStore = useFileStore()
  const backgroundTasksStore = useBackgroundTasksContext()
  const tableManagementStore = useTableManagementStore()
  const [form] = Form.useForm()
  const [currentType, setCurrentType] = useState<IndexPropertyType>(defaultPropertyType)

  useEffect(() => subscribeBackgroundTask('background:error', (task, error) => {
    if (task.type !== 'add-column') {
      return
    }

    if (isApiCustomException(error, 'AlreadyExistsException')) {
      form.setFields([
        {
          name: 'name',
          errors: ['Such a column already exists'],
        },
      ])
    }
  }), [backgroundTasksStore, form])

  const resetWrapper = () => {
    form.resetFields()
    setCurrentType(defaultPropertyType)
  }

  const handleHidePopUp = () => {
    resetWrapper()
    hidePopUp()
  }

  const handleCancel = () => {
    handleHidePopUp()
    fileStore.deleteNewColumn()
  }

  const handleSubmit = async (column: IndexProperty) => {
    let { defaultValue } = column

    const hasDefaultValue = Reflect.has(column, 'defaultValue')
      && defaultValue != null

    if (hasDefaultValue) {
      if (column.type === 'STRING') {
        defaultValue = sanitizeString(defaultValue as string)
      } else {
        defaultValue = checkValidNumberOnSave(defaultValue as string)
      }
    }

    const newColumn = {
      ...column,
      name: sanitizeString(column.name),
      defaultValue,
      deleted: false,
      hidden: false,
      newName: undefined,
      weblink: false,
    }

    backgroundTasksStore.createTask({
      type: 'add-column',
      data: {
        column: newColumn,
        fileId: fileStore.fileId,
        fileName: fileStore.fileDescription?.fileName || '',
        fileStore,
      },
    })

    onLoading(newColumn.name)

    handleHidePopUp()
  }

  const handleTypeChange = () => {
    setCurrentType(form.getFieldValue('type'))

    form.setFields([
      { name: 'defaultValue', value: null },
    ])
  }

  return (
    <div style={{ width: 360 }}>
      <Form
        form={form}
        size="middle"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          label="Column name"
          name="name"
          validateFirst
          rules={[
            {
              required: true,
              message: 'Please add any name',
            },
            {
              validator: (_, value) => {
                const validation = columnNameValidator(tableManagementStore, value)

                if (validation.status === 'error') {
                  return Promise.reject(validation.errorMsg)
                }

                return Promise.resolve()
              },
              validateTrigger: 'onChange',
            },
          ]}
        >
          <Input
            placeholder="Enter column name"
            id="hackyEdgeAutoCompleteDisable"
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          label="Type:"
          name="type"
          className="flex items-center mb-4"
          initialValue={defaultPropertyType}
        >
          <Radio.Group
            className="ml-3"
            onChange={handleTypeChange}
          >
            {Object.entries(indexPropertyMainTypesAndLabels).map(([type, label]) => (
              <Radio key={type} value={type}>{label}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Populate all rows with the value below"
          name="defaultValue"
          normalize={currentType !== 'STRING' ? inputNumberValidator : undefined}
          rules={[
            { max: 255, message: 'The value is too long' },
            currentType !== 'STRING' ? {
              pattern: /^[-]?\d+(\.\d+)?$/,
              message: 'This value is not a valid number. Please type a valid number to save.',
            } : {},
          ]}
        >
          <Input
            placeholder="Set value"
            type="text"
            autoComplete="off"
          />
        </Form.Item>

        <AddColumnPopFooter onCancel={handleCancel} />
      </Form>
    </div>
  )
})

const AddColumnPopover = ({ hidePopUp, onLoading }: AddColumnPopoverProps) => {
  const { chemicalPropsAutoGeneration } = subscriptionStore.subscriptionPlanSettings
  const { TabPane } = Tabs

  return (
    <>
      {chemicalPropsAutoGeneration ? (
        <Tabs
          defaultActiveKey="1"
          tabPosition="top"
          className="tab-center"
          tabBarGutter={16}
        >
          <TabPane tab="User defined" key="1">
            <PresetValueContent
              hidePopUp={hidePopUp}
              onLoading={onLoading}
            />
          </TabPane>
          <TabPane tab="Calculated" key="2">
            <CalculatedColumnsContent
              hidePopUp={hidePopUp}
              onLoading={onLoading}
            />
          </TabPane>
        </Tabs>
      ) : (
        <PresetValueContent
          hidePopUp={hidePopUp}
          onLoading={onLoading}
        />
      )}
    </>
  )
}

const AgGridNewColumnHeader: React.FC<IHeaderParams & { status: IndexPropertyStatus }> = observer((({
  displayName,
  column,
  status,
}) => {
  const fileStore = useFileStore()
  const [isPopupVisible, { toggle: toggleIsPopupVisible }] = useToggle(status === 'adding')
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(status === 'pending')

  const sortOrder = column.getSort() || null
  const isSortActive = column.isSorting()

  const handleLoading = useCallback((newColumnName: string) => {
    setLoadingIndicator(true)

    fileStore.indexProperties.every((indexProperty, idx) => {
      if (indexProperty.status === 'adding') {
        fileStore.indexProperties[idx] = {
          ...indexProperty,
          name: newColumnName,
          status: 'pending',
          fixed: undefined,
        }

        return false
      }

      return true
    })
  }, [fileStore])

  useEffect(() => {
    setLoadingIndicator(status === 'pending')
  }, [status])

  return (
    <PopoverAndTriggerButton
      PopoverContent={(
        <AddColumnPopover
          hidePopUp={toggleIsPopupVisible}
          onLoading={handleLoading}
        />
      )}
      ButtonElement={(
        <SortableColumnHeader
          className="w-full h-full m-0"
          title={displayName}
          isSortActive={isSortActive}
          sortOrder={sortOrder}
          loading={loadingIndicator}
        />
      )}
      visible={isPopupVisible}
    />
  )
}))

export default AgGridNewColumnHeader
