import { Tooltip } from 'antd'

import CalculatedColumnIcon from '@components/icons/CalculatedColumnIcon'

const CalculatedColumnHeader: React.FC = () => (
  <Tooltip title="Calculated column" overlayInnerStyle={{ backgroundColor: 'white', color: 'black' }}>
    <div className="w-4 h-4 mr-2">
      <CalculatedColumnIcon />
    </div>
  </Tooltip>
)

export default CalculatedColumnHeader
