import { useEffect } from 'react'

import paddle, { CheckoutEventsCallbacks } from '@shared/services/paddle'

const usePaddle = (callbacks: Partial<CheckoutEventsCallbacks>): void => {
  useEffect(() => {
    const listeners = (Object.keys(callbacks) as Array<keyof typeof callbacks>)
      .map(event => paddle.on(event, callbacks[event]))

    return () => listeners.forEach(removeListener => removeListener())
  }, [callbacks])
}

export default usePaddle
