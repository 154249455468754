import AddRowsStore from '@store/add-rows'
import FileStore from '@store/file'
import createStoreContext from './stores-context'

const context = createStoreContext({
  addRowsStore: new AddRowsStore('mock-file-id'),
  fileStore: new FileStore('fake-file-id'),
})

export const { useAddRowsStore, useFileStore } = context

export default context.Provider
