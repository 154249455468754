import { ReactElement } from 'react'

import Logo from './Logo'

const BigLogo = (): ReactElement => (
  <span
    className="flex"
  >
    <Logo className="dark:fill-[#fff] fill-[#56C999]" />
  </span>
)

export default BigLogo
