import { ReactElement } from 'react'
import { Checkbox, Form } from 'antd'

interface MarketingConsentCheckboxProps {
  disabled?: boolean
}

const MarketingConsentCheckbox = ({ disabled }: MarketingConsentCheckboxProps): ReactElement => (
  <Form.Item
    name="consent"
    valuePropName="checked"
    validateTrigger="submit"
    rules={[
      {
        validator: (_, value) => {
          if (!value) {
            return Promise.reject(new Error('You should give your consent'))
          }

          return Promise.resolve()
        },
      },
    ]}
  >
    <Checkbox disabled={disabled}>
      REPOSITORY may send me product updates and offers via email.
      It is possible to opt-out any time.
    </Checkbox>
  </Form.Item>

)

export default MarketingConsentCheckbox
