import { message } from 'antd'

const NumericalFilterRegExp = /[^0-9,.-]/g

export const checkValidNumberOnSave = (value: string): string => {
  const val = value.trim()

  if (!val.length) return ''

  const num = parseFloat(val)

  if (Number.isNaN(num)) {
    message.error(`This value "${val}" is not a valid number. Please type a valid number to save.`)
    return ''
  }

  return (num).toString(10)
}

const inputNumberValidator = (newValue?: string | number, defaultValue?: string | number): string => {
  let num = String(newValue ?? defaultValue ?? '').replace(NumericalFilterRegExp, '').replace(',', '.')

  if (num.includes('-')) {
    num = num.replace(/-/g, '')
    num = `-${num}`
    num = num.replace(/-\./g, '-')
  }

  if (num.startsWith('.')) {
    num = num.substring(1)
  }

  if ((num.match(/\./g) || []).length > 1) {
    const parts = num.split('.')
    num = `${parts[0]}.${parts.slice(1).join('')}`
  }

  return num
}

export default inputNumberValidator
