import { makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'

import apiService from '@shared/services/api-service'

export interface BulkEditTaskDetails {
  type: 'BulkEdit'
  details: {
    indexId: string
    properties: Molproperties
    selectedMolecules: SelectedMoleculesParams
  }
}

interface BulkEditTaskInitData {
  indexId: string
  properties: Molproperties
  selectedMolecules: SelectedMoleculesParams
}

export type BulkEditTaskProps = {
  type: 'bulk-edit'
  data: BulkEditTaskInitData
}

class BulkEditTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'bulk-edit'

  readonly indexId: string

  taskId = ''
  progress = 0
  status: BackgroundTaskStatus = 'Initial'
  properties: Molproperties
  selectedMolecules: SelectedMoleculesParams

  abortController = new AbortController()

  constructor({
    indexId, properties, selectedMolecules,
  }: BulkEditTaskInitData) {
    this.indexId = indexId
    this.properties = properties || {}
    this.selectedMolecules = selectedMolecules || {}

    makeObservable(this, {
      taskId: observable,
      status: observable,
      progress: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      indexId, abortController, properties, selectedMolecules,
    } = this

    const { taskId, status } = await apiService.put<TaskProgress>(`/index/${indexId}/molecules`, {
      json: {
        properties,
        moleculesRequest: selectedMolecules,
      },
      signal: abortController.signal,
    })

    return { taskId, status }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async fetchResult(): Promise<void> {}
}

export default BulkEditTask
