/* eslint-disable react/jsx-indent */
import {
  DragEvent, ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {
  Checkbox, Form, Input, message, Modal,
  Progress, Radio, RadioChangeEvent, Spin, Tooltip,
} from 'antd'
import { InputRef } from 'antd/es/input'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import { MergeTaskInitData, subscribeBackgroundTask, SubscriptionLimitErrorData } from '@store/background-tasks'
import filesStore from '@store/files'
import subscriptionStore from '@store/subscription'
import user from '@store/user'
import authorizedRoutes from '@router/authorized-routes'
import {
  CloseOutlined, DeleteFilled, ExclamationCircleFilled, SearchOutlined, StopOutlined,
} from '@ant-design/icons'
import DotSeparator from '@components/common/DotSeparator'
import EditorFooter from '@components/common/EditorFooter'
import Header from '@components/common/Header'
import SimpleInnerDrawer from '@components/common/SimpleInnerDrawer'
import SortableColumnHeader from '@components/common/SortableColumnHeader'
import SpinnerArea from '@components/common/SpinnerArea'
import CopyFile from '@components/file/CopyFile'
import DownloadFile, { ExportSettings } from '@components/file/DownloadFile'
import ReadOnlyState from '@components/file/ReadOnlyState'
import RemoveDuplicatesModal from '@components/file/RemoveDuplicatesModal'
import MarketingConsentModal from '@components/files/MarketingConsentModal'
import MergeOverviewModal from '@components/files/MergeOverviewModal'
import MergeSettingsModal, { MergeSettings } from '@components/files/MergeSettingsModal'
import { UploadButton, UploadZone } from '@components/files/Upload'
import DownloadIcon from '@components/icons/Download'
import EditIcon from '@components/icons/Edit'
import FileIcon from '@components/icons/FileIcon'
import ShareIcon from '@components/icons/ShareIcon'
import StructureSearchPanel from '@components/structure-search/StructureSearchPanel'
import useBackgroundTasksContext from '@contexts/background-tasks'
import KetcherModalProvider from '@contexts/ketcher-provider'
import { SystemProperties } from '@shared/constants'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'
import { getAmountTasksByFileId } from '@utils/file-store-utils'
import getHumanizedFileSize from '@utils/getHumanizedFileSize'
import pluralEnd from '@utils/plural-end'
import { fileNameValidationRules } from '@utils/validators'

interface FileProgressBarProps {
  text: string
  percent: number
}

interface CreateRemoveConfirmProps {
  title: string
  content: string
  onOk: () => void
}

interface CreateShareConfirmProps {
  title: React.ReactNode
  content: React.ReactNode
  onOk: () => void
}

const removeUploadedFile = async (file: UploadedFile) => {
  try {
    await filesStore.removeFile(file.id)
    message.success(`${file.fileName} deleted`)
  } catch (error) {
    if (isErrorHasMessage(error)) {
      const text = error.message === 'Failed to fetch' ? 'Deletion failed. Please try again' : error.message
      message.error(text)
    } else {
      throw error
    }
  }
}

const FileProgressBar = ({ text, percent }: FileProgressBarProps): ReactElement => (
  <div className="w-[120px] relative">
    <Progress percent={percent} showInfo={false} size="small" />
    <div className="absolute right-0 top-5 text-xs">{text}</div>
  </div>
)

const RemoveIcon = observer(({
  icon: Icon, disabled, onClick, alwaysVisible,
}: { icon: AntDIcon, disabled?: boolean, onClick: () => void, alwaysVisible?: boolean }): ReactElement => {
  const conditionalClasses: Record<string, boolean | undefined> = { disabled }

  if (alwaysVisible) conditionalClasses.visible = true

  return (
    <Icon
      className={cn('hover:text-danger', conditionalClasses)}
      onClick={onClick}
    />
  )
})

const createRemoveConfirm = ({ title, content, onOk }: CreateRemoveConfirmProps) => () => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleFilled />,
    content,
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'Cancel',
    cancelButtonProps: { ghost: true },
    onOk,
  })
}

const shareFileConfirm = ({ title, content, onOk }: CreateShareConfirmProps) => () => {
  Modal.confirm({
    title,
    icon: null,
    content,
    okText: 'Confirm',
    okType: 'primary',
    cancelText: 'Cancel',
    cancelButtonProps: { ghost: true },
    onOk,
  })
}

const StageText: Record<UploadedFileStage, string> = {
  UploadInit: 'Initializing...',
  UploadValidate: 'Validating...',
  UploadCreateIndex: 'Creating index...',
  UploadProperties: 'Preparing properties...',
  UploadProcess: 'Processing...',
  UploadInfo: 'Preparing info...',
  Uploaded: 'Uploaded',
}

const ProcessingFileCard = observer(({ file }: { file: UploadingFile | UploadedFile }): ReactElement => {
  const isUploading = file.status === 'UPLOADING'
  const { fileName } = file

  const percent = isUploading
    ? filesStore.uploadingProgress.percent
    : filesStore.uploadedFiles.find(f => f.id === file.id)!.stagePercent! * 100

  const uploadingTotal = isUploading ? getHumanizedFileSize({ bytes: filesStore.uploadingFile.fileBytes }) : null
  const uploadingLoaded = isUploading
    ? getHumanizedFileSize({ bytes: filesStore.uploadingProgress.loaded, showBytesSuffix: false })
    : null

  const progressText = useMemo(() => {
    if (file.status === 'UPLOADING') {
      return `${uploadingLoaded}/${uploadingTotal}`
    }

    return StageText[file.stage!] ?? StageText.UploadInit
  }, [file, uploadingLoaded, uploadingTotal])

  const removeConfirm = useMemo(() => createRemoveConfirm({
    title: isUploading ? 'Stop uploading' : 'Delete file',
    content: isUploading ? `Stop ${fileName} uploading?` : `All changes will be lost. Are you sure you want to delete ${fileName}?`,
    onOk: () => (isUploading ? filesStore.abortUploading() : removeUploadedFile(file as UploadedFile)),
  }), [file, fileName, isUploading])

  return (
    <div
      role="button"
      className={cn('files-grid file-card')}
    >
      <div />
      <div className="w-full truncate pr-2 text-left text-[16px] font-bold text-gray-60">{fileName}</div>

      <div className="col-end-[-1]">
        <div className="flex items-center justify-end">
          <FileProgressBar
            percent={percent}
            text={progressText}
          />

          <RemoveIcon
            icon={isUploading ? StopOutlined : DeleteFilled}
            alwaysVisible
            onClick={removeConfirm}
          />
        </div>
      </div>
    </div>
  )
})

const UploadedFileCard = observer(({ file }: { file: UploadedFile }): ReactElement => {
  const {
    id, fileName, moleculesInvalid, moleculesLibraryCount, propertiesCount, createdDate, modifiedDate,
  } = file

  const checked = filesStore.checkedFiles.has(id)
  const isMerging = filesStore.mergingFiles.has(id)
  const fileColumns = propertiesCount + SystemProperties.length
  const extension = fileName.slice(-4)
  const fileNameWithoutExtension = fileName.slice(0, -4)

  const isShareDisabled = !subscriptionStore.subscriptionPlanSettings.changeFileVisibility
  const isCopyDisabled = !subscriptionStore.subscriptionPlanSettings.createNewFile

  /// / Change date format
  const modifiedCreateDate = new Date(createdDate)
  const year = modifiedCreateDate.getFullYear().toString().slice(-2)
  const month = (modifiedCreateDate.getMonth() + 1).toString().padStart(2, '0')
  const day = modifiedCreateDate.getDate().toString().padStart(2, '0')
  const hours = modifiedCreateDate.getHours().toString().padStart(2, '0')
  const minutes = modifiedCreateDate.getMinutes().toString().padStart(2, '0')
  const modifiedCreateDateEx = `${month}/${day}/${year} ${hours}:${minutes}`

  const modifiedDateTime = new Date(modifiedDate)
  const yearModified = modifiedDateTime.getFullYear().toString().slice(-2)
  const monthModified = (modifiedDateTime.getMonth() + 1).toString().padStart(2, '0')
  const dayModified = modifiedDateTime.getDate().toString().padStart(2, '0')
  const hoursModified = modifiedDateTime.getHours().toString().padStart(2, '0')
  const minutesModified = modifiedDateTime.getMinutes().toString().padStart(2, '0')
  const dateModified = `${monthModified}/${dayModified}/${yearModified} ${hoursModified}:${minutesModified}`

  const [form] = Form.useForm()
  const [isRenaming, setIsRenaming] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const inputRef = useRef<InputRef | null>(null)

  useEffect(() => inputRef.current?.focus(), [isRenaming])

  const removeHandler = useCallback(() => {
    removeUploadedFile(file)
  }, [file])

  const shareHandler = useCallback(() => {
    filesStore.shareFile(file.id, !file.shared)
  }, [file])

  const removeConfirm = useMemo(() => createRemoveConfirm({
    title: 'Delete file',
    content: `All changes will be lost. Are you sure you want to delete ${fileName}?`,
    onOk: removeHandler,
  }), [removeHandler, fileName])

  const shareConfirm = useMemo(() => shareFileConfirm({
    title:
    (<>
      <span className="text-2xl">Share File</span>
     </>),
    content: (
      <>
        <p>
          All users of the system will be able to view and download{' '}
          <span className="font-bold">{fileName}</span> index.
        </p>          <br />
          <p>Do you want to proceed?</p>
      </>
    ),
    onOk: shareHandler,
  }), [shareHandler, fileName])

  const unshareConfirm = useMemo(() => shareFileConfirm({
    title:
    (<>
      <span className="text-2xl">Unshare File</span>
     </>),
    content: (
      <>
        <p>
          All users of the system will not be able to view and download{' '}
          <span className="font-bold">{fileName}</span> index.
        </p>          <br />
          <p>Do you want to proceed?</p>
      </>
    ),
    onOk: shareHandler,
  }), [shareHandler, fileName])

  const renameHandler = useCallback(async (newFileName: string) => {
    try {
      await filesStore.renameFile(id, newFileName)
    } catch (error) {
      message.error('Cannot rename file')
    }
  }, [id])

  const onEditClick = () => {
    setIsRenaming(true)
    form.setFieldsValue({ fileName: fileNameWithoutExtension })
  }

  const onCheckClick = (event: SyntheticEvent) => {
    const isToggle = event.target instanceof HTMLElement && event.target.dataset.toggle

    if (isToggle) {
      filesStore.toggleFileCheckStatus(file)
    }
  }

  const exportTaskData: ExportTaskData = {
    indexId: id,
    fileName: fileNameWithoutExtension,
    searchParams: filesStore.searchParams,
  }

  const exportSettings: ExportSettings = file.searchStat?.matched && filesStore.searchParams.type !== 'all'
    ? 'WithMatches'
    : 'NoSettings'

  return (
    <div
      role="button"
      data-toggle
      className={cn('files-grid file-card relative', { checked })}
      onClick={onCheckClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Checkbox
        data-toggle
        checked={checked}
      />
      <div className={cn('m-w-full inline-flex justify-center relative', !isRenaming && 'w-full')}>
        {isRenaming ? (
          <Form
            form={form}
            name="renameFile"
            onFinish={({ fileName: newFileName }: { fileName: string }) => {
              renameHandler(newFileName + extension)
              setIsRenaming(false)
            }}
            size="small"
          >
            <Form.Item
              name="fileName"
              className="mb-0 w-64"
              rules={fileNameValidationRules}
            >
              <Input
                ref={inputRef}
                autoComplete="off"
                bordered={false}
                className="rounded-none p-0 text-white text-[16px] font-bold !bg-secondary-50 dark:!bg-secondary-200"
                onBlur={() => {
                  setIsRenaming(false)
                }}
                onClick={(event: SyntheticEvent) => {
                  event.stopPropagation()
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <NavLink
            to={{
              pathname: `/file/${id}/editor`,
              state: { previousPage: 'Files' },
            }}
            title={fileName}
            draggable="false"
            className="w-full pr-2 text-left text-[16px] font-bold hover:text-primary"
          >
            <div className="truncate">{fileName}</div>
          </NavLink>
        )}
        {filesStore.isFileReadOnly(id) && <div className="absolute top-full left-0"><ReadOnlyState /></div>}
      </div>

      <span data-toggle>{modifiedCreateDateEx}</span>
      <span data-toggle>{dateModified}</span>
      <span data-toggle>{moleculesLibraryCount.toLocaleString()}</span>
      <span data-toggle>{fileColumns.toLocaleString()}</span>
      <span data-toggle className="text-danger">{moleculesInvalid > 0 ? moleculesInvalid : null}</span>

      <span data-toggle>
        {file.searchStat && (
          <>
            {
              match(file.searchStat.status)
                .with('IN_PROGRESS', () => <Spin className="leading-0 mx-1.5" size="small" />)
                .otherwise(() => null)
            }
            {
              match(file.searchStat.status)
                .with('ERROR', () => (
                  <Tooltip
                    placement="top"
                    title={(
                      <div className="text-center">
                        Something went wrong<br />
                        Please try again
                      </div>
                    )}
                  >
                    <ExclamationCircleFilled className="text-danger text-lg leading-0 align-baseline" />
                    <span
                      className="ml-1"
                      data-toggle
                    >
                      Search error
                    </span>
                  </Tooltip>
                ))
                .otherwise(searchStatus => (
                  <span
                    data-toggle
                    className={cn({ 'text-primary': searchStatus === 'COMPLETED' })}
                  >
                    {file.searchMatched ? file.searchMatched.toLocaleString() : 0}
                  </span>
                ))
            }
          </>
        )}
      </span>

      <div
        data-toggle
        className={cn(
          'justify-self-end flex items-center justify-end absolute pr-6 bg-icons-gradient h-full rounded-r-lg',
          { hidden: !isHovered },
        )}
      >
        {user.username === file.uploadedBy && (
          <Tooltip title="Edit">
            <span className={cn('file-card-icon', { disabled: isMerging })}>
              <EditIcon
                onClick={onEditClick}
              />
            </span>
          </Tooltip>
        )}
        <DownloadFile
          disabledClassName="text-gray-60"
          target={DownloadIcon}
          exportTaskData={exportTaskData}
          exportSettings={exportSettings}
          isInsideIndex={false}
        />
        <CopyFile
          disabled={isCopyDisabled}
          disabledClassName="text-gray-60 cursor-not-allowed"
          target={FileIcon}
          exportTaskData={exportTaskData}
          exportSettings={exportSettings}
          extension={extension}
          isInsideIndex={false}
        />

        {user.username === file.uploadedBy && (
          <Tooltip title="Delete">
            <span className="file-card-icon">
              <RemoveIcon
                disabled={isMerging}
                icon={DeleteFilled}
                onClick={removeConfirm}
              />
            </span>
          </Tooltip>
        )}
        <Tooltip title="Share">
          <span className={`file-card-icon ml-4 ${!isShareDisabled ? '' : 'cursor-not-allowed hover:text-gray-40'}`}>
            <ShareIcon
              className={`w-9 h-9 relative top-[-4px] ${file.shared && 'text-primary'}`}
              // eslint-disable-next-line max-len
              onClick={!file.shared && user.username === file.uploadedBy && !isShareDisabled ? shareConfirm : undefined}
            />
          </span>
        </Tooltip>
      </div>

      <Tooltip title={file.shared && user.username === file.uploadedBy
        ? 'Unshare' : 'Shared with other users index. You can not unshare it because it is not your index.'}
      >
          <span className={`absolute right-[8px] top-[19px] z-50 ${user.username === file.uploadedBy ? '' : 'cursor-not-allowed'}`}>
            <ShareIcon
              className={`w-9 h-9 ml-4  ${file.shared ? 'text-primary' : 'hidden'}`}
              onClick={file.shared && user.username === file.uploadedBy ? unshareConfirm : undefined}
            />
          </span>
      </Tooltip>

    </div>
  )
})

const FilesList = ({ isUploadDisabled }: { isUploadDisabled: boolean }): ReactElement => {
  const history = useHistory()
  const {
    allFilesCount,
    checkedFilesCount,
    hasCheckedFiles,
    searchByNameQuery,
    allFilesFiltered,
    allFilesFilteredCount,
    isAllVisibleChecked,
    isSomeChecked,
    sortBy,
    onCheckAllClick,
    hasSearchStats,
    structureSearchRunStatus,
    filesWithSearchErrorCount,
    filesWithSearchResultsCount,
    checkedFilesSharedCount,
  } = filesStore

  const [forceShowUploadZone, setForceShowUploadZone] = useState(false)
  const backgroundTasksStore = useBackgroundTasksContext()
  const isMergeDisabled = !subscriptionStore.subscriptionPlanSettings.mergeFiles

  const mergeTooltipText = checkedFilesCount > 1 ? null : 'Select files to merge'
  const mergeDisabledTooltipText = isMergeDisabled ? (
    <div className="text-center">
      Select files to merge. To work with multiple files upgrade your subscription
    </div>
  ) : mergeTooltipText

  const defaultMergeSettings: MergeSettings = useMemo(() => ({
    fileName: '',
    withProperties: true,
    matchedRows: true,
    withDeduplication: false,
  }), [])

  const [isMergeSettingsVisible, setIsMergeSettingsVisible] = useState(false)
  const [isMergeOverviewVisible, setIsMergeOverviewVisible] = useState(false)
  const [mergeSettings, setMergeSettings] = useState<MergeSettings>(defaultMergeSettings)

  const onFilesWrapperDragEnter = useCallback((e: DragEvent) => {
    const enteredNotFromNode = e.relatedTarget === null
    const enteredFromOutsideNode = e.currentTarget instanceof HTMLElement
      && e.relatedTarget instanceof HTMLElement
      && !e.currentTarget.contains(e.relatedTarget)

    const hasEntered = enteredNotFromNode || enteredFromOutsideNode

    if (!hasEntered) return

    setForceShowUploadZone(true)
  }, [])

  const onFilesWrapperDragLeave = useCallback((e: DragEvent) => {
    const leftToNotNode = e.relatedTarget === null
    const leftToOutsideNode = e.currentTarget instanceof HTMLElement
      && e.relatedTarget instanceof HTMLElement
      && !e.currentTarget.contains(e.relatedTarget)

    const hasLeft = leftToNotNode || leftToOutsideNode

    if (!hasLeft) return

    setForceShowUploadZone(false)
  }, [])

  const onDropCallback = useCallback(() => setForceShowUploadZone(false), [])

  const removeFilesHandler = () => {
    filesStore.checkedFiles.forEach(file => {
      removeUploadedFile(file)
    })
  }

  const removeFilesConfirm = () => {
    if (!hasCheckedFiles) return

    Modal.confirm({
      title: 'Delete forever?',
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          {checkedFilesSharedCount ? (
            <>
              Files shared by other users can not be deleted.
              Only {checkedFilesCount - checkedFilesSharedCount} of {checkedFilesCount} files will be deleted.
              This action can not be undone.
            </>
          ) : (
            <>
              Are you sure you want to delete {checkedFilesCount} file{pluralEnd(checkedFilesCount)}?<br />
              This action cannot be undone
            </>
          )}
        </>
      ),
      okText: `Delete forever ${checkedFilesSharedCount ? `${checkedFilesCount - checkedFilesSharedCount} of ${checkedFilesCount} files` : ''}`,
      okType: 'primary',
      cancelText: 'Cancel',
      cancelButtonProps: { ghost: true },
      onOk: removeFilesHandler,
    })
  }

  const mergeFilesConfirm = () => {
    if (checkedFilesCount < 2 || isMergeDisabled) return

    setIsMergeSettingsVisible(true)
  }

  const handleStorageLimitError = useCallback(() => {
    const { planName } = subscriptionStore.subscriptionPlanSettings
    const isKnowledgeEdition = planName === 'Knowledge Edition'

    Modal[isKnowledgeEdition ? 'error' : 'confirm']({
      title: 'Merge error',
      width: 415,
      icon: <ExclamationCircleFilled />,
      content: (
        <div className="mt-6">
          The new file exceeds the record limit for {planName}.
          Delete some files{isKnowledgeEdition ? '' : ' or upgrade your subscription'} to get more space.<br />
          Record limit: {subscriptionStore.subscriptionPlanSettings.storageLimit}
        </div>
      ),
      okText: isKnowledgeEdition ? 'Ok' : 'Change subscription',
      okType: 'primary',
      okButtonProps: { size: 'large' },
      cancelText: isKnowledgeEdition ? undefined : 'Cancel',
      cancelButtonProps: isKnowledgeEdition ? undefined : { ghost: true, size: 'large' },
      onOk: isKnowledgeEdition ? undefined : () => history.push(authorizedRoutes.SettingsSubscription.path),
    })
  }, [history])

  useEffect(() => {
    const removeErrorListener = subscribeBackgroundTask('background:error', (task, error) => {
      if (task.type === 'merge'
        && isApiCustomException<SubscriptionLimitErrorData>(error, 'SubscriptionLimitException')) {
        handleStorageLimitError()
      }
    })

    return () => {
      removeErrorListener()
    }
  }, [backgroundTasksStore, handleStorageLimitError])

  const createMergeTask = useCallback((settings: MergeSettings) => {
    const {
      fileName, withProperties, matchedRows, withDeduplication,
    } = settings

    let Ids = [...filesStore.checkedFilesIds]

    if (Ids.length === 0) {
      Ids = [...filesStore.checkedFiles.keys()]
    }

    const data: MergeTaskInitData = {
      fileName,
      withProperties,
      searchParams: matchedRows ? filesStore.searchParams : undefined,
      fileIds: Ids,
      withDeduplication,
    }

    backgroundTasksStore.createTask({
      type: 'merge',
      data,
    })
    filesStore.clearCheckedFilesIds()
  }, [backgroundTasksStore])

  const cancelMergeHandler = useCallback(() => {
    setMergeSettings(defaultMergeSettings)
    setIsMergeSettingsVisible(false)
    setIsMergeOverviewVisible(false)
  }, [defaultMergeSettings])

  const backMergeHandler = useCallback(() => {
    setIsMergeOverviewVisible(false)
    setIsMergeSettingsVisible(true)
  }, [])

  const mergeOverviewOkHandler = useCallback(() => {
    cancelMergeHandler()
    createMergeTask(mergeSettings)
  }, [cancelMergeHandler, createMergeTask, mergeSettings])

  const mergeSettingsOkHandler = (settings: MergeSettings) => {
    const { fileName, withProperties } = settings
    const SDF = '.sdf'
    const extension = fileName.slice(-4)

    const updatedSettings: MergeSettings = {
      ...settings,
      fileName: extension === SDF ? fileName : fileName + SDF,
    }

    if (withProperties) {
      setMergeSettings(updatedSettings)
      setIsMergeOverviewVisible(true)
    } else {
      createMergeTask(updatedSettings)
    }

    setIsMergeSettingsVisible(false)
  }

  const columns: { title: string, name: keyof UploadedFile }[] = [
    { title: 'Name ', name: 'fileName' },
    { title: 'Created', name: 'createdDate' },
    { title: 'Modified', name: 'modifiedDate' },
    { title: 'Rows', name: 'moleculesLibraryCount' },
    { title: 'Columns', name: 'propertiesCount' },
    { title: 'Invalid rows', name: 'moleculesInvalid' },
  ]

  if (hasSearchStats) {
    columns.push({ title: `Matches (${filesStore.searchStatsCount.toLocaleString()})`, name: 'searchMatched' })
  }

  const { sorting } = filesStore
  const isUploadZoneVisible = (forceShowUploadZone && !isUploadDisabled) || allFilesCount === 0

  const { withDeduplication } = mergeSettings

  return (
    <div className="flex-col-grow">
      <div className="mb-4 flex items-center">
        <div>{allFilesCount} file{pluralEnd(allFilesCount)} in total</div>

        {hasCheckedFiles && (
          <>
            <DotSeparator />
            {checkedFilesCount} file{pluralEnd(checkedFilesCount)} selected
          </>
        )}

        {searchByNameQuery && (
          <>
            <DotSeparator />
            {allFilesFilteredCount} file{pluralEnd(allFilesFilteredCount)} found
          </>
        )}

        {structureSearchRunStatus !== 'IDLE' && <DotSeparator />}
        {
          match(structureSearchRunStatus)
            .with('IDLE', () => null)
            .with('IN_PROGRESS', 'COMPLETED', currentStatus => (
              <div>
                {currentStatus === 'IN_PROGRESS' && <Spin className="mr-2 h-[14px]" size="small" />}
                <span>{filesWithSearchResultsCount} file{pluralEnd(filesWithSearchResultsCount)} matched</span>
                {currentStatus === 'IN_PROGRESS' && <span className="ml-1">so far</span>}
              </div>
            ))
            .with('ERROR', () => (
              <Tooltip
                placement="top"
                overlayInnerStyle={{ width: 300 }}
                title={(
                  <div className="text-center">
                    We were unable to search some of your files<br />
                    Please try again
                  </div>
                )}
              >
                <ExclamationCircleFilled className="text-danger text-lg leading-0 align-baseline" />
                <span
                  className="ml-2"
                >
                  {filesWithSearchErrorCount} search error{pluralEnd(filesWithSearchErrorCount)}
                </span>
              </Tooltip>
            ))
            .exhaustive()
        }

        <DotSeparator />
        <Tooltip placement="topLeft" title={mergeDisabledTooltipText}>
          <span
            role="button"
            className={cn(checkedFilesCount > 1 && !isMergeDisabled ? 'text-primary' : 'text-gray-40')}
            onClick={mergeFilesConfirm}
          >Merge selected
          </span>
        </Tooltip>

        <DotSeparator />
        <Tooltip placement="topLeft" title={hasCheckedFiles ? null : 'Select files to delete'}>
          <span
            role="button"
            className={cn(hasCheckedFiles ? 'text-primary' : 'text-gray-40')}
            onClick={removeFilesConfirm}
          >Delete
          </span>
        </Tooltip>
      </div>

      <div className="flex-col-grow">
        <div className="files-grid px-6 mb-4 text-sm">
          <Checkbox
            indeterminate={isSomeChecked}
            checked={isAllVisibleChecked}
            onClick={onCheckAllClick}
          />

          {columns.map(col => (
            <SortableColumnHeader
              key={col.name}
              title={col.title}
              isSortActive={sorting.colName === col.name}
              sortOrder={sorting.order}
              onClick={() => { sortBy(col.name) }}
            />
          ))}

          <div />
        </div>

        <div
          onDragEnter={onFilesWrapperDragEnter}
          onDragLeave={onFilesWrapperDragLeave}
          className="flex-col-grow"
        >
          <UploadZone
            visible={isUploadZoneVisible}
            onDropCallback={onDropCallback}
          >
            {(allFilesFiltered.map(file => {
              if (['UPLOADING', 'UPLOADED', 'PARSING_STARTED'].includes(file.status)) {
                return <ProcessingFileCard key={file.id} file={file} />
              }

              return <UploadedFileCard key={file.id} file={file as UploadedFile} />
            }))}
          </UploadZone>
        </div>
      </div>

      <MergeSettingsModal
        initialFormValues={mergeSettings}
        visible={isMergeSettingsVisible}
        onCancel={cancelMergeHandler}
        onOk={mergeSettingsOkHandler}
      />

      <MergeOverviewModal
        visible={isMergeOverviewVisible}
        onBack={backMergeHandler}
        onCancel={cancelMergeHandler}
        onOk={mergeOverviewOkHandler}
        hasDuplicates={withDeduplication}
      />
    </div>
  )
}
const ObservedFilesList = observer(FilesList)

const SearchAcrossFilesModeControls = observer((): ReactElement => {
  const changeMode = (e: RadioChangeEvent) => {
    filesStore.searchAcrossFilesMode = e.target.value
  }

  return (
    <div className="mb-3">
      <div className="text-gray-40 text-xs mb-2">Search across</div>

      <Radio.Group
        value={filesStore.searchAcrossFilesMode}
        defaultValue="all"
        onChange={changeMode}
      >
        <Radio
          value="all"
        >
          All files
        </Radio>

        <Radio
          value="selected"
          disabled={!filesStore.checkedFilesCount}
        >
          Selected files
        </Radio>
      </Radio.Group>
    </div>
  )
})

const StructureSearchWrapper = (): ReactElement => {
  const isSearchButtonDisabled = filesStore.searchAcrossFilesMode === 'all' ? false : !filesStore.checkedFiles.size
  const isStructureSearchFilesDisabled = !subscriptionStore.subscriptionPlanSettings.structureSearchFiles

  const resetSearchParams = useCallback((params: IndexSearchParams) => {
    filesStore.abortStructureSearch()
    filesStore.dropStructureSearchStats()
    filesStore.searchParams = params
  }, [])

  return (
    <SimpleInnerDrawer visible>
      <>
        <div className="font-bold text-2xl mb-3">
          Structure Search
        </div>

        <div
          className="text-gray-50 dark:text-gray-30 text-sm mb-4"
        >
          Search by structure across all or selected files
        </div>

        <StructureSearchPanel
          queryStructure={filesStore.queryStructure}
          isResetButtonDisabled={filesStore.isSearchParamsDefault}
          isSearchButtonDisabled={isSearchButtonDisabled}
          searchParams={filesStore.searchParams}
          slotPostSearchParams={<SearchAcrossFilesModeControls />}
          onSearchClick={filesStore.runBulkStructureSearch}
          onResetClick={resetSearchParams}
          onQueryStructureChange={queryStructure => { filesStore.queryStructure = queryStructure }}
          isStructureSearchFilesDisabled={isStructureSearchFilesDisabled}
        />
      </>
    </SimpleInnerDrawer>
  )
}

const ObservedStructureSearchWrapper = observer(StructureSearchWrapper)

const FilesPageContent = (): ReactElement => {
  const forbidUploadMoreThenOneFile = filesStore.uploadedFiles.length > 0
    && !subscriptionStore.subscriptionPlanSettings.uploadMultipleFiles
  const isUploadDisabled = filesStore.hasUploadingFile || forbidUploadMoreThenOneFile

  const isUploadDisabledText = isUploadDisabled ? (
    <div className="text-center">
      {filesStore.hasUploadingFile && 'Just one file can upload at the same time'}
      {/* eslint-disable-next-line max-len */}
      {forbidUploadMoreThenOneFile && 'Your current subscription allows only one file. To work with multiple files upgrade your subscription'}
    </div>
  ) : (
    <div className="text-center">
      Insert your SMILES / InChi file here or upload SD file
    </div>
  )

  const { searchByNameQuery, setSearchByNameQuery } = filesStore

  const onSearchInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByNameQuery(evt.target.value)
  }

  useEffect(() => {
    if (!filesStore.isSearchParamsDefault) {
      filesStore.runBulkStructureSearch(filesStore.searchParams)
    }

    return () => filesStore.abortStructureSearch()
  }, [])

  return (
    <div className="flex-grow flex">
      <ObservedStructureSearchWrapper />

      <div className="flex-col-grow py-6 px-5">
        <div className="mb-7 flex justify-between items-center">
          <Input
            value={searchByNameQuery}
            size="large"
            className="max-w-xs"
            placeholder="Search by file name"
            onChange={onSearchInputChange}
            suffix={
              searchByNameQuery.length
                ? <CloseOutlined onClick={() => setSearchByNameQuery('')} />
                : <SearchOutlined className="cursor-text" />
            }
          />

          <div className="flex justify-between items-center">
            <div className="mr-4 text-sm">
              <span className="text-gray-50 mr-1">Used storage:</span>
              <span>
                {/* eslint-disable-next-line max-len */}
                {filesStore.moleculesTotalCount.toLocaleString()} / {subscriptionStore.subscriptionPlanSettings.storageLimit.toLocaleString()} rows
              </span>
            </div>

            <UploadButton
              className="w-[200px]"
              disabled={isUploadDisabled}
              tooltipTitle={isUploadDisabledText}
            />
          </div>
        </div>

        <ObservedFilesList isUploadDisabled={isUploadDisabled} />
        <div className="mt-3">
          <EditorFooter />
        </div>
      </div>
    </div>
  )
}

const ObservedFilesPageContent = observer(FilesPageContent)

const FilesPage = (): ReactElement => {
  const backgroundTasksStore = useBackgroundTasksContext()

  useEffect(() => {
    filesStore.fetchFiles()
  }, [])

  useEffect(() => {
    const removeCompletedListener = subscribeBackgroundTask('background:completed', task => {
      if (task.type === 'merge' || task.type === 'copy') {
        filesStore.fetchMergedFileByTaskId(task.taskId)
      } else if (task.type === 'remove-duplications') {
        filesStore.fetchDeduplicatedFile(task.fileId, task.remained, task.removed)
      }
    })

    return () => removeCompletedListener()
  }, [backgroundTasksStore])

  useEffect(() => {
    filesStore.readOnlyFiles = getAmountTasksByFileId(backgroundTasksStore.runningTasks)
  }, [backgroundTasksStore.runningTasks])

  return (
    <KetcherModalProvider extension="none">

    <div className="flex flex-col bg-gray-10 dark:bg-transparent">
      <Header />

      {filesStore.isFilesFetching ? <SpinnerArea /> : (
        <div className="flex flex-col min-h-screen">
          <ObservedFilesPageContent />
        </div>
      )}
      <MarketingConsentModal />
      {backgroundTasksStore.removeDuplicatesTasks.map(task => (
        (!task.displayed && task.status === 'Done') && <RemoveDuplicatesModal key={task.id} task={task} />
      ))}
    </div>
    </KetcherModalProvider>

  )
}

export default observer(FilesPage)
