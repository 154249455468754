import {
  MouseEventHandler, ReactElement, useCallback, useMemo,
} from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Modal } from 'antd'

import { ExclamationCircleFilled } from '@ant-design/icons'

const BackToFileEditorButton = ({ fileId }: { fileId: string | null }): ReactElement => {
  const history = useHistory()
  const onConfirm = useCallback(() => history.push(`/file/${fileId}/editor`), [fileId, history])

  const closeVerificationModalContent = useMemo(() => (
    <>
      Are you sure you want to cancel rows adding?<br />
      All changes will be lost
    </>
  ), [])

  const openCloseVerificationModal = useCallback(() => Modal.confirm({
    title: 'Cancel rows Adding',
    icon: <ExclamationCircleFilled />,
    content: closeVerificationModalContent,
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'Cancel',
    cancelButtonProps: { type: 'text' },
    onOk: onConfirm,
  }), [closeVerificationModalContent, onConfirm])

  const onLinkClick = useCallback<MouseEventHandler>(evt => {
    evt.stopPropagation()
    evt.preventDefault()
    openCloseVerificationModal()
  }, [openCloseVerificationModal])

  return (
    <NavLink to={`/file/${fileId}/editor`} onClick={onLinkClick}>
      Back to the File
    </NavLink>
  )
}

export default BackToFileEditorButton
