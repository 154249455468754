import apiService from '@shared/services/api-service'

const fetchIndexProperties = async ({ id }: { id: string }): Promise<IndexProperty[]> => apiService.get<IndexProperty[]>(`/index/${id}/property`)

const updateIndexProperties = async (id: string, indexProperties: IndexProperty[]): Promise<{ status: string }> => {
  const body = JSON.stringify(indexProperties)

  return apiService.put<{ status: string }>(`/index/${id}/property`, {
    body,
  })
}

const indexSearch = async (
  searchParams: IndexSearchQueryParams,
  { abortController, timeout }: QueryOptionsWithAbortController = {},
): Promise<IndexSearchResult> => {
  const body = JSON.stringify(searchParams)

  return apiService.post<IndexSearchResult>('/index/search', {
    body,
    signal: abortController?.signal,
    timeout,
  })
}

const bulkIndexSearch = async (
  searchParams: BulkIndexSearchParams,
  { abortController, timeout }: QueryOptionsWithAbortController = {},
): Promise<BulkIndexSearchResult> => {
  const body = JSON.stringify(searchParams)

  return apiService.post<BulkIndexSearchResult>('/index/searchAll', {
    body,
    signal: abortController?.signal,
    timeout,
  })
}

const bulkIndexSearchNext = async (
  { searchIds }: BulkIndexSearchNextParams,
  { abortController, timeout }: QueryOptionsWithAbortController = {},
): Promise<BulkIndexSearchNextResult> => {
  const body = JSON.stringify(searchIds.map(searchId => ({
    searchId,
    limit: 200,
  })))

  return apiService.post<BulkIndexSearchResult>('/index/searchAllNext', {
    body,
    signal: abortController?.signal,
    timeout,
  })
}

async function getTaskResult<T = void>(id: string): Promise<T> {
  return apiService.get<T>(`/task/${id}/result`)
}

const deleteMolecules = async (id: string, selectedMoleculesParams: SelectedMoleculesParams): Promise<number> => {
  const body = JSON.stringify(selectedMoleculesParams)

  return apiService.delete<number>(`/index/${id}/molecule`, {
    body,
  })
}

const removeDuplicateMolecules = async (id: string): Promise<TaskProgress> => apiService.post<TaskProgress>(`/index/${id}/molecules/deduplicate`)

const bulkDeleteMolecules = async (
  id: string,
  selectedMoleculesParams: SelectedMoleculesParams,
): Promise<TaskProgress> => {
  const body = JSON.stringify({ moleculesRequest: selectedMoleculesParams })

  return apiService.delete<TaskProgress>(`/index/${id}/molecules`, {
    body,
  })
}

const updateMolecule = async (fileId: string, molecule: MoleculeUpdate): Promise<void> => apiService.put(
  `/index/${fileId}/molecule`,
  { json: molecule },
)

// eslint-disable-next-line max-len
const updateMolecules = async (fileId: string, moleculeChanges: MoleculeChangesRequest[]): Promise<TaskProgress> => apiService.patch(
  `/index/${fileId}/molecules`,
  { json: moleculeChanges },
)

const calculateStructure = async (data: DataExportRequest): Promise<TaskProgress> => apiService.post(
  '/molecule-formulas/generate',
  { json: data },
)

const indexSearchPolling = async (
  { searchId, limit }: SearchPollingParams,
  { abortController, timeout }: QueryOptionsWithAbortController = {},
): Promise<IndexSearchResult> => apiService.get<IndexSearchResult>(
  `/index/search?searchId=${searchId}&limit=${limit}`,
  { signal: abortController?.signal, timeout },
)

const addMolecules = async (
  fileId: string,
  newMolecules: Molecule[],
): Promise<{ status: string } | Molecule[]> => apiService.post<{ status: string } | Molecule[]>(
  `/index/${fileId}/molecule`,
  { json: newMolecules },
)

export {
  fetchIndexProperties,
  updateIndexProperties,
  indexSearch,
  addMolecules,
  deleteMolecules,
  getTaskResult,
  removeDuplicateMolecules,
  bulkDeleteMolecules,
  updateMolecule,
  updateMolecules,
  indexSearchPolling,
  bulkIndexSearch,
  bulkIndexSearchNext,
  calculateStructure,
}
