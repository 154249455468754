import {
  Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useRef, useState,
} from 'react'
import {
  Button, Checkbox, Form, Input, Modal, Radio,
  Tooltip,
} from 'antd'
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import FileStore from '@store/file'
import subscriptionStore from '@store/subscription'
import useBackgroundTasksContext from '@contexts/background-tasks'
import { fileNameValidationRules } from '@utils/validators'

export type ExportSettings = 'NoSettings' | 'WithMatches' | 'WithFiltersAndSelectedRows'

  interface FormFields {
    fileName: string
    withDeduplication: boolean
    withProperties: boolean
    searchType?: SearchType
  }

  interface CopyFileProps {
    target: React.ComponentType<ElementProps & {disabled: boolean}>
    disabled?: boolean
    disabledClassName?: Argument
    className?: Argument
    exportTaskData: ExportTaskData
    exportSettings?: ExportSettings
    fileStore?: FileStore
    extension: string
    isInsideIndex: boolean
  }

  interface CopyFileModalProps {
    visible: boolean
    setModalVisible: Dispatch<SetStateAction<boolean>>
    exportTaskData: ExportTaskData
    exportSettings: ExportSettings
    fileStore?: FileStore
    extension: string
  }

  interface RadioGroupData {
    text: string
    value: SearchType
    disabled: boolean
    visible: boolean
  }

  interface SearchTypeRadioGroupProps {
    items: RadioGroupData[]
    itemClassName?: string
  }

const SearchTypeRadioGroup = ({ items, itemClassName }: SearchTypeRadioGroupProps) => (
  <Form.Item name="searchType">
    <Radio.Group>
      {
          items.map(item => (item.visible && (
            <Radio
              className={itemClassName}
              key={item.value}
              disabled={item.disabled}
              value={item.value}
            >
              {item.text}
            </Radio>
          )))
        }
    </Radio.Group>
  </Form.Item>
)

const CopyFileModal = ({
  visible, setModalVisible, exportTaskData, exportSettings, fileStore, extension,
}: CopyFileModalProps): ReactElement => {
  const backgroundTasksStore = useBackgroundTasksContext()
  const [form] = Form.useForm()
  const {
    fileName, indexId, selectedMoleculesParams, searchParams,
  } = exportTaskData
  const formRef = useRef(null)
  const { subscriptionPlanSettings } = subscriptionStore

  const initialValues: FormFields = {
    fileName: `Copy ${fileName}`,
    withDeduplication: false,
    withProperties: true,
    searchType: 'all',
  }

  const closeModal = useCallback(() => setModalVisible(false), [setModalVisible])
  const afterClose = useCallback(() => form.resetFields(), [form])
  const handleCancel = useCallback(() => closeModal(), [closeModal])

  const copyFile = useCallback(async (options: FormFields) => {
    closeModal()

    let params

    if (options?.searchType === 'filter' || options?.searchType === 'matched') {
      params = { searchParams }
    }

    if (options?.searchType === 'selected') {
      params = { selectedMoleculesParams }
    }

    const searchType = options.searchType || 'all'

    backgroundTasksStore.createTask({
      type: 'copy',
      data: {
        searchType,
        ...params,
        fileId: indexId,
        fileName: `${options.fileName}${extension}`,
        withDeduplication: options.withDeduplication,
        withProperties: options.withProperties,
      },
    })
  }, [closeModal, backgroundTasksStore, indexId, extension, searchParams, selectedMoleculesParams])

  useEffect(
    () => {
      if (formRef.current) {
        form.setFieldsValue({ fileName })
      }
    },
    [form, fileName],
  )

  const matchesRadioGroupItems: RadioGroupData[] = [
    {
      text: 'All rows',
      value: 'all',
      visible: true,
      disabled: false,
    },
    {
      text: 'Matched rows',
      value: 'matched',
      visible: true,
      disabled: false,
    },
  ]

  const isFilterEnabled = searchParams?.queryStructure
      || searchParams?.filters?.length
      || searchParams?.sorting?.length

  const filtersAndSelectedRowsRadioGroupItems: RadioGroupData[] = [
    {
      text: 'All rows',
      value: 'all',
      visible: true,
      disabled: false,
    },
    {
      text: 'Selected items',
      value: 'selected',
      visible: subscriptionPlanSettings.downloadSelectedRows,
      disabled: fileStore?.selectedMoleculeCustomOrdersCount === 0,
    },
    {
      text: 'Matches (filtered results)',
      value: 'filter',
      visible: subscriptionPlanSettings.downloadSearchFilterResults,
      disabled: !isFilterEnabled,
    },
  ]

  return (
    <Modal
      width={400}
      open={visible}
      closable={false}
      afterClose={afterClose}
      centered
      destroyOnClose
      title={<div className="text-2xl">Create a File</div>}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel-button"
          ghost
          size="large"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="copy-button"
          type="primary"
          size="large"
          htmlType="submit"
          form="copyFile"
        >
          Create File
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="copyFile"
        initialValues={initialValues}
        onFinish={copyFile}
        ref={formRef}
        layout="vertical"
      >
        <Form.Item
          name="fileName"
          rules={fileNameValidationRules}
        >
          <Input
            size="large"
          />
        </Form.Item>

        {
            match(exportSettings)
              .with('NoSettings', () => null)
              .otherwise(() => <div className="text-gray-40 font-sm">Rows to a new file</div>)
          }
        {
            match(exportSettings)
              .with('NoSettings', () => null)
              .with('WithMatches', () => (
                <SearchTypeRadioGroup
                  items={matchesRadioGroupItems}
                  itemClassName="block"
                />
              ))
              .with('WithFiltersAndSelectedRows', () => (
                <SearchTypeRadioGroup
                  items={filtersAndSelectedRowsRadioGroupItems}
                  itemClassName="block"
                />
              ))
              .exhaustive()
          }

        <Form.Item
          label="Columns to a new file"
          name="withProperties"
          className="mb-5"
        >
          <Radio.Group>
            <Radio value className="block">All columns</Radio>
            <Radio value={false}>Structures only</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="withDeduplication"
          className="my-5 border-t border-solid border-gray-20"
          valuePropName="checked"
        >
          <Checkbox>Remove duplicates</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ObservedDownloadFileModal = observer(CopyFileModal)

const CopyFile = ({
  target: Target,
  className,
  exportTaskData,
  disabled = false,
  disabledClassName,
  exportSettings = 'NoSettings',
  fileStore,
  extension,
  isInsideIndex,
}: CopyFileProps): JSX.Element => {
  const [isModalVisible, setModalVisible] = useState(false)

  const onClick = useCallback(() => {
    setModalVisible(true)
  }, [setModalVisible])

  return (
    <>
      {isInsideIndex ? (
        <Target
          disabled={disabled}
          className={cn(className, disabled ? `${disabledClassName}` : '')}
          onClick={onClick}
        />
      )
        : (
          <Tooltip title="Make a copy">
            <span className="file-card-icon">
              <Target
                disabled={disabled}
                className={cn(className, disabled ? `${disabledClassName}` : '')}
                onClick={disabled ? undefined : onClick}
              />
            </span>
          </Tooltip>
        )}
      <ObservedDownloadFileModal
        fileStore={fileStore}
        visible={isModalVisible}
        setModalVisible={setModalVisible}
        exportTaskData={exportTaskData}
        exportSettings={exportSettings}
        extension={extension}
      />
    </>
  )
}

export default observer(CopyFile)
