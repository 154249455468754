import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import {
  makeAutoObservable, reaction, remove, runInAction, set,
} from 'mobx'
import { nanoid } from 'nanoid'

import { defaultSearchParams } from '@shared/constants'
import apiService, { isApiCustomException, isAuthError } from '@shared/services/api-service'
import eventBusService from '@shared/services/event-bus-service'
import { createSignalAbortablePolling } from '@shared/services/polling'
import createBackendRequestUrl from '@utils/createBackendRequestUrl'
import { bulkIndexSearch, bulkIndexSearchNext } from '@utils/http'
import {
  deleteFile, fetchFileDescription, fetchFiles as httpFetchFiles, shareFile,
} from '@utils/http/files'
import { showErrorMessage } from '@utils/messages'
import user from './user'

type SortingOrderTypes = 'asc' | 'desc' | null
type StructureSearchRunStatus = 'IDLE' | 'IN_PROGRESS' | 'COMPLETED' | 'ERROR'

const createIndexSearchState = ({ matched, filtered, inprogress }: IndexSearchResultStat) => ({
  matched,
  filtered,
  status: inprogress ? 'IN_PROGRESS' : 'COMPLETED',
})

const defaultUploadingProgress = { percent: 0, loaded: 0 }

const defaultUploadingFile: UploadingFile = {
  id: '',
  fileName: '',
  fileBytes: 0,
  status: 'UPLOADING',
}

const defaultSorting = Object.freeze({
  order: null as SortingOrderTypes,
  colName: null as (keyof UploadedFile | null),
})

// eslint-disable-next-line max-len
const sortFiles = (files: UploadedFile[]) => files.sort((fileA, fileB) => (new Date(fileA.createdDate) > new Date(fileB.createdDate) ? -1 : 1))

class FilesStore {
  private _uploadedFiles: UploadedFile[] = []
  private _isFilesFetching = true
  private _uploadingProgress: UploadingProgress = defaultUploadingProgress
  private _uploadingFile: UploadingFile = defaultUploadingFile
  private _checkedFiles: Map<string, UploadedFile> = new Map()
  private _mergingFiles: Set<string> = new Set()
  private _searchByNameQuery = ''
  private _sorting = defaultSorting
  private _queryStructure = ''
  private _searchAcrossFilesMode: SearchAcrossFilesMode = 'all'
  private _mergeResultFiles: Set<string> = new Set()
  private _structureSearchAbortController = new AbortController()
  private _uploadRequestAbortController = new AbortController()
  private _defaultSearchParams = defaultSearchParams
  private _searchParams: IndexSearchParams = { ...this._defaultSearchParams }
  private _structureSearchRunStatus: StructureSearchRunStatus = 'IDLE'
  private _readOnlyFieldsOperations: Map<string, number> = new Map()
  private _checkedFilesIds: Set<string> = new Set()

  constructor() {
    makeAutoObservable(this)

    reaction(
      () => this.checkedFilesCount,
      count => {
        if (count === 0 && this.searchAcrossFilesMode !== 'all') {
          this.searchAcrossFilesMode = 'all'
        } else if (count > 0 && this.searchAcrossFilesMode !== 'selected') {
          this.searchAcrossFilesMode = 'selected'
        }
      },
    )

    reaction(
      () => this.isSearchParamsDefault,
      isDefault => {
        if (isDefault) {
          this.dropStructureSearchStats()
        }
      },
    )
  }

  /* ********** UploadedFiles */
  async fetchFiles() {
    try {
      this._isFilesFetching = true
      const files = await httpFetchFiles()

      const filesWithoutMergeResults = files.filter(file => !this._mergeResultFiles.has(file.id))

      runInAction(() => {
        this._uploadedFiles = sortFiles(filesWithoutMergeResults)

        this._uploadedFiles.forEach(file => {
          if (this._checkedFiles.has(file.id)) {
            this._checkedFiles.set(file.id, file)
          }
        })
      })
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    } finally {
      runInAction(() => {
        this._isFilesFetching = false
      })
    }
  }

  async fetchMergedFileByTaskId(taskId: string) {
    try {
      const file = await apiService.get<UploadedFile>(`/merge/${taskId}`)

      runInAction(() => {
        this._uploadedFiles.push(file)
        sortFiles(this._uploadedFiles)
      })
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    }
  }

  async fetchDeduplicatedFile(fileId: string, remained: number, removed: number) {
    try {
      const file = await fetchFileDescription({ id: fileId })

      runInAction(() => {
        const fileToUpdate = this.uploadedFiles.find(fil => fil.id === fileId)

        if (fileToUpdate) {
          fileToUpdate.modifiedDate = file.modifiedDate
          fileToUpdate.moleculesLibraryCount = remained
          eventBusService.emit(
            'remove-duplicates-modal',
            { remained, removed } as RemoveDuplicateResult,
          )
        }
      })
    } catch (error) {
      if (isAuthError(error)) return
      throw error
    }
  }

  async removeFile(id: string) {
    const fileToRemove = this._uploadedFiles.find(file => file.id === id)

    if (fileToRemove) {
      await deleteFile({ id })
      runInAction(() => {
        this._uploadedFiles = this._uploadedFiles.filter(file => file.id !== id)
        this._checkedFiles.delete(id)
      })
    }
  }

  async shareFile(id: string, share: boolean) {
    const shareIndex = this._uploadedFiles.findIndex(file => file.id === id)

    if (shareIndex !== -1) {
      await shareFile({ id, share })

      runInAction(() => {
        this._uploadedFiles[shareIndex].shared = share
      })
    }
  }

  async renameFile(fileId: string, newFileName: string) {
    const renameIndex = this._uploadedFiles.findIndex(file => file.id === fileId)
    const { fileName } = this._uploadedFiles[renameIndex]

    try {
      if (renameIndex > -1) {
        runInAction(() => {
          this._uploadedFiles[renameIndex].fileName = newFileName
        })
      }

      const { modifiedDate } = await apiService.put<{ newName: string, modifiedDate: string }>(`/upload/${fileId}/rename`, { searchParams: { newFilename: newFileName } })

      runInAction(() => {
        this._uploadedFiles[renameIndex].modifiedDate = modifiedDate
      })
    } catch (error) {
      if (renameIndex > -1) {
        runInAction(() => {
          this._uploadedFiles[renameIndex].fileName = fileName
        })
      }

      throw (error)
    }
  }

  get mergingFiles(): Set<string> {
    return this._mergingFiles
  }

  set mergingFiles(filesIds: Set<string>) {
    this._mergingFiles = filesIds
  }

  set mergeResultFiles(files: Set<string>) {
    this._mergeResultFiles = files
  }

  overwriteFile(file: UploadedFile) {
    const index = this._uploadedFiles.findIndex(element => element.id === file.id)
    if (index > -1) this._uploadedFiles[index] = file
  }

  get uploadedFiles(): UploadedFile[] {
    return this._uploadedFiles
  }

  get isFilesFetching(): boolean {
    return this._isFilesFetching
  }

  get moleculesTotalCount() {
    return this.uploadedFiles.reduce((count, file) => {
      if (file.uploadedBy === user.username) {
        return (file.moleculesLibraryCount ?? 0) + count
      }
      return count
    }, 0)
  }
  /* ********** UploadedFiles */

  /* ********** UploadingFile */
  get hasUploadingFile() {
    return !!this._uploadingFile.fileName
  }

  setUploadingProgress({ percent, loaded }: { percent: number, loaded: number }) {
    this._uploadingProgress = { percent, loaded }
  }

  dropUploadingState() {
    this._uploadingProgress = defaultUploadingProgress
    this._uploadingFile = defaultUploadingFile
  }

  startUploading = async (file: File) => {
    this._uploadingFile = {
      id: nanoid(),
      fileName: file.name,
      fileBytes: file.size,
      status: 'UPLOADING',
    }

    const onProgress = (e: Progress) => {
      this.setUploadingProgress({
        percent: Math.floor((e.loaded / e.total) * 100),
        loaded: e.loaded,
      })
    }

    const path = createBackendRequestUrl('/upload')

    try {
      const uploadedFile = await apiService.uploadFile({
        path,
        file,
        onProgress,
        abortController: this._uploadRequestAbortController,
      })

      runInAction(() => {
        this._uploadedFiles.unshift(uploadedFile)
      })
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') return

      let errorMsg = ''

      if (isApiCustomException(error, 'MaxUploadSizeExceededException')
        || isApiCustomException(error, 'SubscriptionFileSizeLimitException')) {
        errorMsg = error.message
      }

      showErrorMessage(errorMsg, { duration: 10 })
    } finally {
      this.dropUploadingState()
    }
  }

  abortUploading() {
    this._uploadRequestAbortController.abort()
    this._uploadRequestAbortController = new AbortController()
  }

  get uploadingProgress(): UploadingProgress {
    return this._uploadingProgress
  }

  get uploadingFile(): UploadingFile {
    return this._uploadingFile
  }
  /* ********** UploadingFile */

  /* ********** All files */
  get allFiles(): (UploadingFile | UploadedFile)[] {
    return this.hasUploadingFile
      ? [this.uploadingFile, ...this.uploadedFiles]
      : this.uploadedFiles
  }

  get allFilesCount() {
    return this.allFiles.length
  }
  /* ********** All files */

  /* ********** Filter, sort and search files */
  setSearchByNameQuery = (query: string) => {
    this._searchByNameQuery = query
  }

  get searchByNameQuery() {
    return this._searchByNameQuery
  }

  get allFilesFiltered() {
    const filtered = this.searchByNameQuery === '' ? this.allFiles : this.allFiles
      .filter(file => file.fileName.toLocaleLowerCase().includes(this.searchByNameQuery.toLocaleLowerCase()))

    if (this._sorting.colName === null) return filtered

    const { colName } = this._sorting
    const sign = this._sorting.order === 'asc' ? 1 : -1

    return filtered.slice().sort((fileA, fileB) => {
      // Don't resort UPLOADING files
      if (fileA.status === 'UPLOADING') {
        if (fileB.status === 'UPLOADING') return 0
        return -1
      }

      // UPLOADING files always on the top
      if (fileB.status === 'UPLOADING') return 1

      let aValue = fileA[colName]
      let bValue = fileB[colName]

      if (typeof aValue === 'undefined') aValue = 0
      if (typeof bValue === 'undefined') bValue = -1

      if (colName === 'searchStat' || fileA[colName] === fileB[colName]) return 0

      return aValue > bValue ? sign : -sign
    })
  }

  get allFilesFilteredCount() {
    return this.allFilesFiltered.length
  }

  get visibleUploadedFiles() {
    return this.allFilesFiltered.filter(file => 'uploadedBy' in file) as UploadedFile[]
  }

  get visibleUploadedFilesCount() {
    return this.visibleUploadedFiles.length
  }

  sortBy = (colName: keyof UploadedFile) => {
    if (!this._sorting.colName || this._sorting.colName !== colName) {
      this._sorting = {
        colName,
        order: 'asc',
      }

      return
    }

    if (this._sorting.order === 'desc') {
      this._sorting = defaultSorting
      return
    }

    this._sorting = {
      colName,
      order: 'desc',
    }
  }

  get sorting() {
    return this._sorting
  }

  get searchParams() {
    return this._searchParams
  }

  set searchParams(searchParams) {
    this._searchParams = searchParams
  }

  get queryStructure() {
    return this._queryStructure
  }

  set queryStructure(queryStructure) {
    this._queryStructure = queryStructure
  }

  get searchAcrossFilesMode() {
    return this._searchAcrossFilesMode
  }

  set searchAcrossFilesMode(searchAcrossFilesMode) {
    this._searchAcrossFilesMode = searchAcrossFilesMode
  }

  get isSearchParamsDefault() {
    return isEqual(this._searchParams, this._defaultSearchParams)
  }

  get uploadedFilesMap() {
    return new Map(this._uploadedFiles.map(file => [file.id, file]))
  }

  get isCheckedFilesHaveSearchMatched() {
    let nonEmptyMatched = 0

    // eslint-disable-next-line no-restricted-syntax
    for (const checkedFile of this._checkedFiles.values()) {
      if (checkedFile.searchMatched == null) return false
      nonEmptyMatched += 1
    }

    if (nonEmptyMatched > 1) return true

    return false
  }

  getUploadedFileSearchStat(fileId: UploadedFile['id']) {
    return this._uploadedFiles.find(file => file.id === fileId)?.searchStat
  }

  dropStructureSearchStats() {
    runInAction(() => {
      this.uploadedFiles.forEach(file => {
        remove(file, 'searchStat')
        remove(file, 'searchMatched')
      })
    })
  }

  runBulkStructureSearch = async (searchParams: IndexSearchParams): Promise<void> => {
    this.abortStructureSearch()
    this.dropStructureSearchStats()
    this._searchParams = searchParams

    if (this.isSearchParamsDefault) {
      this._structureSearchRunStatus = 'IDLE'

      return
    }

    this._structureSearchRunStatus = 'IN_PROGRESS'

    const searchFilesMap = this.searchAcrossFilesMode === 'all' ? this.uploadedFilesMap : this._checkedFiles
    const searchFiles = [...searchFilesMap.values()]
    let notCompletedFiles = [...searchFiles]

    const actualizeNotCompletedFiles = (response: BulkIndexSearchResult, fileIdMapper?: Map<string, string>) => {
      notCompletedFiles.forEach(file => {
        const responseKey = fileIdMapper?.get(file.id) ?? file.id
        const fileResult = response[responseKey]
        if (!fileResult) return

        if (!isNil(fileResult.errors)) {
          remove(file, 'searchMatched')
          set(file, 'searchStat', { ...file.searchStat, status: 'ERROR' })
        } else {
          set(file, 'searchStat', createIndexSearchState(fileResult.stat))
          set(file, 'searchMatched', fileResult.stat.matched)
        }
      })
      return notCompletedFiles.filter(file => file?.searchStat?.status === 'IN_PROGRESS')
    }

    notCompletedFiles.forEach(file => {
      set(file, 'searchStat', { status: 'IN_PROGRESS' })
    })

    const fileIds = notCompletedFiles.map(file => file.id)
    const fileBytes = notCompletedFiles.map(file => Number(file.fileBytes))
    const uxNiceLimit = Math.ceil(Math.max(...fileBytes) / 750000)
    const limit = uxNiceLimit > 400 ? 400 : uxNiceLimit

    const preparedSearchParams = {
      ...searchParams,
      fileIds,
      limit,
    }

    try {
      const searchResponse = await bulkIndexSearch(
        preparedSearchParams,
        {
          abortController: this._structureSearchAbortController,
        },
      )
      runInAction(() => {
        notCompletedFiles = actualizeNotCompletedFiles(searchResponse)
      })

      if (notCompletedFiles.length !== 0) {
        const searchIdByFile = new Map<string, string>(
          Object.entries(searchResponse).map(([key, { searchId }]) => [key, searchId]),
        )

        await createSignalAbortablePolling(
          this._structureSearchAbortController.signal,
          {
            action: bulkIndexSearchNext,
            getActionParams: () => [{
              searchIds: notCompletedFiles.reduce((result, file) => {
                const searchId = searchResponse[file.id]?.searchId

                if (searchId) {
                  result.push(searchId)
                }
                return result
              }, [] as string[]),
            }, {
              abortController: this._structureSearchAbortController,
            }],
            options: {
              interval: 3000,
              validate: searchInfoResponse => {
                runInAction(() => {
                  notCompletedFiles = actualizeNotCompletedFiles(searchInfoResponse, searchIdByFile)
                })
                return notCompletedFiles.length === 0
              },
            },
          },
        )
      }

      runInAction(() => {
        const isError = searchFiles.some(file => file.searchStat?.status === 'ERROR')
        this._structureSearchRunStatus = isError ? 'ERROR' : 'COMPLETED'
      })
    } catch (error) {
      runInAction(() => {
        notCompletedFiles.forEach(file => {
          remove(file, 'searchMatched')
        })
        if (error instanceof Error && error.name === 'AbortError') return
        this._structureSearchRunStatus = 'ERROR'
        notCompletedFiles.forEach(file => {
          set(file, 'searchStat', { ...file.searchStat, status: 'ERROR' })
        })
      })
    }
  }

  get hasSearchStats() {
    return this.uploadedFiles.some(file => file.searchStat)
  }

  get searchStatsCount() {
    if (!this.hasSearchStats) return 0
    return this.uploadedFiles.reduce((count, file) => count + (file.searchMatched ?? 0), 0)
  }

  get structureSearchRunStatus() {
    return this._structureSearchRunStatus
  }

  get filesWithSearchErrorCount(): number {
    return this._uploadedFiles.filter(file => file?.searchStat?.status === 'ERROR').length
  }

  get filesWithSearchResultsCount(): number {
    return this._uploadedFiles
      .filter(file => file?.searchStat?.matched).length
  }

  abortStructureSearch(): void {
    this._structureSearchAbortController.abort()
    this._structureSearchAbortController = new AbortController()
    this._structureSearchRunStatus = 'IDLE'
  }
  /* ********** Filter, sort and search files */

  /* ********** CheckedFiles */
  get checkedFiles() {
    return this._checkedFiles
  }

  setCheckedFiles(files: UploadedFile[]) {
    this._checkedFiles = new Map(
      files.map(file => ([file.id, file])),
    )
  }

  get checkedFilesIds() {
    return this._checkedFilesIds
  }

  addCheckedFilesIds(checkedFilesIds: Set<string | undefined>) {
    this._checkedFilesIds.clear()
    checkedFilesIds.forEach((id: string | undefined) => {
      if (typeof id === 'string') {
        this._checkedFilesIds.add(id)
      }
    })
  }

  clearCheckedFilesIds() {
    return this._checkedFilesIds.clear()
  }

  toggleFileCheckStatus(uploadedFile: UploadedFile, forceUncheck?: boolean) {
    if (this._checkedFiles.has(uploadedFile.id) || forceUncheck) {
      this._checkedFiles.delete(uploadedFile.id)
    } else {
      this._checkedFiles.set(uploadedFile.id, uploadedFile)
    }
  }

  onCheckAllClick = () => {
    if (this.isAllVisibleChecked) {
      this.setCheckedFiles([])
      return
    }

    this.setCheckedFiles(this.visibleUploadedFiles)
  }

  get checkedFilesCount() {
    return this.checkedFiles.size
  }

  get checkedFilesSharedCount() {
    return [...this.checkedFiles.keys()]
      .map(fileId => this.uploadedFiles.find(file => file.id === fileId))
      .filter(file => file && file.uploadedBy !== user.username)
      .length
  }

  get hasCheckedFiles() {
    return Boolean(this.checkedFilesCount)
  }

  get isAllVisibleChecked() {
    return (this.checkedFilesCount === this.visibleUploadedFilesCount) && this.hasCheckedFiles
  }

  get isSomeChecked() {
    return this.checkedFilesCount > 0 && !this.isAllVisibleChecked
  }
  /* ********** CheckedFiles */

  /* ********** Common */
  get isSomeFileBeingProcessed() {
    return this.hasUploadingFile
      || this._uploadedFiles.some(file => file.status === 'PARSING_STARTED' || file.status === 'UPLOADED')
  }

  isFileReadOnly(fileId: string) {
    const amout = this._readOnlyFieldsOperations.get(fileId) ?? 0
    return amout > 0
  }

  set readOnlyFiles(value: Map<string, number>) {
    this._readOnlyFieldsOperations = value
  }

  alive() {
    return this.isSomeFileBeingProcessed
  }

  destroy(): void {
    this.setCheckedFiles([])
    this.abortUploading()
    this.dropUploadingState()
    this.dropStructureSearchStats()
    this.abortStructureSearch()
    this.searchParams = defaultSearchParams
  }
  /* ********** Common */
}

export default new FilesStore()
