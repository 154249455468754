import { createNanoEvents, Unsubscribe } from 'nanoevents'

const backgroundTaskEmmitor = createNanoEvents<BackgroundTasksCallbacks>()

export function subscribeBackgroundTask<Event extends keyof BackgroundTasksCallbacks>(
  event: Event,
  callback: BackgroundTasksCallbacks[Event],
): Unsubscribe {
  return backgroundTaskEmmitor.on(event, callback)
}

export default backgroundTaskEmmitor
