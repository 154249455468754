import { ReactElement } from 'react'

interface LabelProps {
  text: string
}

// TODO refactor to native ant label
const Label = ({ text }: LabelProps): ReactElement => (
  <div className="text-gray-30 dark:text-gray-40 text-xs mb-1">{text}</div>
)

export default Label
