import { ReactElement, useCallback, useState } from 'react'
import { Button, Form, Modal } from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import MarketingConsentCheckbox from '@components/settings/MarketingConsentCheckbox'
import { showErrorMessage } from '@utils/messages'

interface FormFields {
  consent: boolean
}

const FORM_NAME = 'marketingConsent'

const MarketingConsentModal = (): ReactElement => {
  const [visible, setVisible] = useState(!userStore.hasMarketingConsentBeenCollected)

  const onFinish = useCallback(async ({ consent }: FormFields) => {
    try {
      await userStore.updateMarketingConsent(consent)
    } catch (error) {
      showErrorMessage()
    } finally {
      setVisible(false)
    }
  }, [])

  const onCancel = useCallback(() => onFinish({ consent: false }), [onFinish])

  return (
    <Modal
      width={380}
      open={visible}
      closable={false}
      title={<div className="text-xl">Let&apos;s keep in touch!</div>}
      footer={[
        <div key="footer" className="flex">
          <Button
            className="flex-grow"
            ghost
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="flex-grow"
            size="large"
            type="primary"
            form={FORM_NAME}
            htmlType="submit"
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <div className="text-base">Get the latest updates on REPOSITORY by email!</div>
      <div className="text-base mt-6">Email: <span className="font-semibold">{userStore.email}</span></div>
      <Form
        name={FORM_NAME}
        className="mt-6"
        initialValues={{ consent: true }}
        onFinish={onFinish}
      >
        <MarketingConsentCheckbox />
      </Form>
    </Modal>
  )
}

export default observer(MarketingConsentModal)
