/* eslint-disable max-len */
import { ReactElement } from 'react'

const EditorFooterLogo = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 16" fill="none">
    <path d="M2.61904 5.78822C2.61904 5.06499 2.03274 4.4787 1.30952 4.4787C0.586291 4.4787 0 5.06499 0 5.78822V10.5811C0 11.3043 0.586291 11.8906 1.30952 11.8906C2.03274 11.8906 2.61904 11.3043 2.61904 10.5811V5.78822Z" fill="#56C999" />
    <path d="M6.49477 4.95008C6.49477 4.22686 5.90848 3.64056 5.18525 3.64056C4.46202 3.64056 3.87573 4.22686 3.87573 4.95008V13.9858C3.87573 14.709 4.46202 15.2953 5.18525 15.2953C5.90848 15.2953 6.49477 14.709 6.49477 13.9858V4.95008Z" fill="#56C999" />
    <path d="M10.3705 7.373C10.3705 6.64977 9.78421 6.06348 9.06098 6.06348C8.33776 6.06348 7.75146 6.64977 7.75146 7.373V12.8337C7.75146 13.5569 8.33776 14.1432 9.06098 14.1432C9.78421 14.1432 10.3705 13.5569 10.3705 12.8337V7.373Z" fill="#56C999" />
    <path d="M14.2471 1.30952C14.2471 0.586291 13.6608 0 12.9376 0C12.2143 0 11.6281 0.586291 11.6281 1.30952V12.0999C11.6281 12.8232 12.2143 13.4095 12.9376 13.4095C13.6608 13.4095 14.2471 12.8232 14.2471 12.0999V1.30952Z" fill="#56C999" />
    <path d="M18.1236 8.74745C18.1236 8.02422 17.5373 7.43793 16.814 7.43793C16.0908 7.43793 15.5045 8.02422 15.5045 8.74745V14.2998C15.5045 15.023 16.0908 15.6093 16.814 15.6093C17.5373 15.6093 18.1236 15.023 18.1236 14.2998V8.74745Z" fill="#56C999" />
    <path d="M22 2.60627C22 1.88304 21.4137 1.29675 20.6905 1.29675C19.9673 1.29675 19.381 1.88304 19.381 2.60627V11.2098C19.381 11.933 19.9673 12.5193 20.6905 12.5193C21.4137 12.5193 22 11.933 22 11.2098V2.60627Z" fill="#56C999" />
  </svg>
)

export default EditorFooterLogo
