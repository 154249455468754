import apiService from '@shared/services/api-service'

const signUp = async (email: string): Promise<SignUpResult> => {
  const body = JSON.stringify({ email })

  return apiService.post<SignUpResult>('/sign-up/create', {
    body,
    withoutAuth: true,
  })
}

const resendSignUpEmail = async (email: string): Promise<void> => {
  const body = JSON.stringify({ email })

  await apiService.post('/sign-up/resend', {
    body,
    withoutAuth: true,
  })
}

const confirmTemporary = async (email: string, temporaryPassword: string): Promise<TemporaryConfirmResult> => {
  const body = JSON.stringify({ email, temporaryPassword })

  return apiService.post<TemporaryConfirmResult>('/sign-up/confirm-temporary', {
    body,
    withoutAuth: true,
  })
}

const createAnAccount = async (createAnAccountData: CreateAnAccountData): Promise<AuthToken> => {
  const body = JSON.stringify({ ...createAnAccountData })

  return apiService.post<AuthToken>('/sign-up/confirm', {
    body,
    withoutAuth: true,
  })
}

const resetPassword = async (email: string): Promise<void> => {
  const body = JSON.stringify({ email })

  await apiService.post('/password/reset', {
    body,
    withoutAuth: true,
  })
}

const updatePassword = async (updatePasswordData: UpdatePasswordData): Promise<void> => {
  const body = JSON.stringify({ ...updatePasswordData })

  return apiService.post<void>('/password/reset-confirm', {
    body,
    withoutAuth: true,
  })
}

const getToken = async ({ email, password }: AuthorizeData): Promise<AuthToken> => {
  const body = JSON.stringify({ username: email, password })

  return apiService.post<AuthToken>('/login', {
    body,
  })
}

export {
  signUp,
  resendSignUpEmail,
  resetPassword,
  updatePassword,
  confirmTemporary,
  createAnAccount,
  getToken,
}
