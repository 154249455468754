import { GridApi } from 'ag-grid-community'

import isEnv from '@utils/isEnv'

export default class AgGridApiService {
  private _gridApi: GridApi | null = null

  setGridApi(gridApi: GridApi): void {
    this._gridApi = gridApi
  }

  reset(): void {
    this._gridApi = null
  }

  getSelectedRows<Row>(): Row[] {
    if (!this.validate(this._gridApi)) return []

    return this._gridApi.getSelectedRows()
  }

  selectAllLoaded(): void {
    if (!this.validate(this._gridApi)) return

    this._gridApi.forEachNode(
      node => node.data != null && !node.isSelected() && node.setSelected(true),
    )
  }

  deselectAllLoaded(): void {
    if (!this.validate(this._gridApi)) return

    this._gridApi.deselectAll()
  }

  private validate(gridApi: GridApi | null): gridApi is GridApi {
    if (gridApi == null) {
      if (isEnv('development')) {
        throw new Error(`[${this.constructor.name}] validation error - no GridAPI`)
      }
      return false
    }

    return true
  }
}

