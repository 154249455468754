import { useEffect } from 'react'
import { Tabs } from 'antd'
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'

import appStore from '@store/app'
import subscriptionStore from '@store/subscription'
import RegularPlanCardButtons from '@components/regular-plan-card/RegularPlanCardButtons'
import useFetchPaddlePlans from '@shared/hooks/useFetchPaddlePlans'
import PlanCardView from './PlanCardView'
import SpinnerArea from './SpinnerArea'
import VATFootnote from './VATFootnote'

interface PlansOverviewProps {
  className?: Argument
  alignSelf?: 'start' | 'center' | 'end'
}
const { TabPane } = Tabs
const billingCycles = [
  '1 Month',
  '1 Year',
]

const PlansOverview = ({ className, alignSelf = 'center' }: PlansOverviewProps): JSX.Element => {
  const { theme } = appStore
  const plans = useFetchPaddlePlans()

  const { fetchingSubscriptionData } = subscriptionStore
  const { subscriptionPlanSettings } = subscriptionStore

  useEffect(() => {
    subscriptionStore.fetchSubscriptionDetails()
  }, [])

  if (plans.loading || fetchingSubscriptionData) return <SpinnerArea className="h-full w-full mt-24" />
  if (subscriptionPlanSettings?.planName?.includes('Academic')) return <></>
  return (
    <div className={cn('flex flex-col', className)}>
      <Tabs tabBarStyle={{ alignSelf }}>
        {
            billingCycles.map((cycle, tabIndex) => (
              <TabPane
                key={cycle}
                tab={cycle}
              >
                <div className="pt-10 flex items-stretch justify-center">
                  {(tabIndex === 0 ? plans.monthly : plans.yearly).map((plan, index) => (
                    <PlanCardView
                      key={plan.id}
                      plan={plan}
                      header={(index % 3) + 1}
                      className="mr-5 last:mr-0"
                      badge={index === 1}
                      buttons={<RegularPlanCardButtons plan={plan} />}
                      theme={theme}
                    />
                  ))}
                </div>
              </TabPane>
            ))
          }
      </Tabs>
      <div>
        <VATFootnote className="mt-6 mb-6" />
      </div>
    </div>
  )
}

export default observer(PlansOverview)

