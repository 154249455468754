import { ReactElement, useCallback } from 'react'
import {
  Form, Input, message,
} from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { emailRegEx } from '@shared/constants'
import { isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import isApiValidationError from '@utils/is-api-validation-error'

const ResetPasswordForm = observer((): ReactElement => {
  const [form] = Form.useForm()
  const handleSubmit = useCallback(async ({ email }: { email: string }) => {
    try {
      await userStore.resetPassword(email)
    } catch (error) {
      if (isApiValidationError(error)) {
        handleApiValidation(form, error.data)
      }

      if (isErrorHasMessage(error)) {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }, [form])

  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      form={form}
    >
      <Form.Item
        label="Enter Your Email"
        name="email"
        rules={[
          { required: true, message: 'Email is required' },
          {
            pattern: emailRegEx, message: 'Please use valid email', validateTrigger: 'onSubmit',
          },
        ]}
      >
        <Input placeholder="Email" size="large" />
      </Form.Item>
      <SubmitButton label="Recover password" />
    </Form>
  )
})

export default ResetPasswordForm
