import { ReactElement, ReactNode } from 'react'
import { Popover } from 'antd'

interface PopoverAndTriggerButtonProps {
  PopoverContent: ReactNode
  ButtonElement: ReactNode
  visible?: boolean
  hidePopUp?: () => void
  destroyTooltipOnHide?: boolean
}

export interface PopoverProps {
  hidePopUp: () => void
  visible?: boolean
}

const PopoverAndTriggerButton = ({
  PopoverContent, ButtonElement, visible, hidePopUp, destroyTooltipOnHide = false,
}: PopoverAndTriggerButtonProps): ReactElement => (
  <Popover
    content={PopoverContent}
    trigger="click"
    placement="bottom"
    onOpenChange={hidePopUp}
    open={visible}
    destroyTooltipOnHide={destroyTooltipOnHide}
  >
    {ButtonElement}
  </Popover>
)

export default PopoverAndTriggerButton
