/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const CloudUpload = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M18.7541 15.8733C18.7241 15.8349 18.6856 15.8038 18.6418 15.7825C18.5979 15.7611 18.5498 15.75 18.501 15.75C18.4522 15.75 18.4041 15.7611 18.3602 15.7825C18.3164 15.8038 18.2779 15.8349 18.2479 15.8733L13.7479 21.5666C13.7108 21.614 13.6878 21.6709 13.6815 21.7307C13.6751 21.7905 13.6858 21.8509 13.7122 21.905C13.7386 21.9591 13.7797 22.0046 13.8308 22.0364C13.8818 22.0683 13.9408 22.0851 14.001 22.0849H16.9702V31.8242C16.9702 32.001 17.1148 32.1456 17.2916 32.1456H19.7023C19.8791 32.1456 20.0238 32.001 20.0238 31.8242V22.089H23.001C23.2702 22.089 23.4189 21.7796 23.2541 21.5706L18.7541 15.8733Z" />
    <path d="M30.5295 12.1548C28.6893 7.30119 24.0004 3.84985 18.508 3.84985C13.0156 3.84985 8.32679 7.29717 6.48661 12.1507C3.0433 13.0548 0.5 16.1927 0.5 19.9213C0.5 24.361 4.09598 27.957 8.5317 27.957H10.1429C10.3196 27.957 10.4643 27.8124 10.4643 27.6356V25.2249C10.4643 25.0481 10.3196 24.9034 10.1429 24.9034H8.5317C7.17768 24.9034 5.90402 24.365 4.9558 23.3887C4.01161 22.4164 3.50937 21.1066 3.55357 19.7485C3.58973 18.6878 3.95134 17.6914 4.60625 16.8516C5.27723 15.9958 6.21741 15.3731 7.26205 15.0958L8.78482 14.6981L9.3433 13.2275C9.68884 12.3115 10.171 11.4557 10.7777 10.6802C11.3766 9.91163 12.0861 9.23599 12.883 8.6753C14.5344 7.51414 16.479 6.89941 18.508 6.89941C20.5371 6.89941 22.4817 7.51414 24.133 8.6753C24.9326 9.2378 25.6397 9.9128 26.2384 10.6802C26.8451 11.4557 27.3272 12.3155 27.6728 13.2275L28.2272 14.694L29.746 15.0958C31.9237 15.6824 33.4464 17.6632 33.4464 19.9213C33.4464 21.2512 32.9281 22.5048 31.9879 23.4449C31.5269 23.9087 30.9784 24.2764 30.3742 24.5268C29.7701 24.7771 29.1223 24.9051 28.4683 24.9034H26.8571C26.6804 24.9034 26.5357 25.0481 26.5357 25.2249V27.6356C26.5357 27.8124 26.6804 27.957 26.8571 27.957H28.4683C32.904 27.957 36.5 24.361 36.5 19.9213C36.5 16.1967 33.9647 13.0628 30.5295 12.1548Z" />
  </svg>
)

export default CloudUpload
