import { ReactElement } from 'react'
import { Button, Empty } from 'antd'

import moleculeAdd from '@assets/images/molecule-add.png'

interface AddRowsEmptyStateProps {
  className?: string
  addNewRow(): void
}

const AddRowsEmptyState = ({ className, addNewRow: openKetcherModal }: AddRowsEmptyStateProps): ReactElement => (
  <Empty
    className={className}
    image={(
      <img
        src={moleculeAdd}
        alt="Add checmical molecule"
        style={{ width: 60, height: 'auto' }}
      />
    )}
    imageStyle={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
    }}
    description={(
      <div className="mt-3 dark:text-gray-30">
        No rows added. Create new rows, edit them and add to the dataset in bulk!
      </div>
    )}
  >
    <Button type="primary" className="mt-3" onClick={openKetcherModal}>
      + Create a new row
    </Button>
  </Empty>
)

export default AddRowsEmptyState
