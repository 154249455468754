import {
  FC, ReactNode, useEffect, useState,
} from 'react'
import { ConfigProvider } from 'antd'

const getAppTheme = () => {
  const styles = window.getComputedStyle(document.documentElement)

  const v = (n: string) => styles.getPropertyValue(n)

  return {
    token: {
      colorPrimary: v('--color-primary-default'),
      defaultColor: v('--color-primary-default'),
      colorSuccess: v('--color-primary-default'),
      colorText: v('--color-gray-70'),
      defaultGhostColor: v('--color-gray-70'),
      defaultGhostBorderColor: v('--color-gray-10'),
      ghostBg: v('--color-gray-10'),
      labelColor: v('--color-gray-40'),
      labelHeight: '14px', // ??
      labelFontSize: '12px',
      fontWeight: 600,
      borderRadius: 4,
      optionSelectedFontWeight: 400,
      lineHeight: 1.5,
      dotColorDisabled: v('--color-gray-20'),
      previewOperationColor: v('--color-gray-70'),
      colorTextDisabled: v('--color-gray-30'),
      colorBgContainerDisabled: v('--color-gray-10'),
      borderColorDisabled: v('--color-gray-10'),
      defaultShadow: 'none',
      primaryShadow: 'none',
      fontFamily: 'Hind Vadodara',
    },
  }
}

interface Props {
  children: ReactNode
}

const AppConfigProvider: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState(getAppTheme())

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setTheme(getAppTheme())
    })

    observer.observe(document.documentElement, { attributes: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <ConfigProvider theme={theme}>
      {children}
    </ConfigProvider>
  )
}

export default AppConfigProvider
