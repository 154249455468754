import throttle from 'lodash/throttle'

const THROTTLE_TIMEOUT = 60 * 1000

class WindowEventsActivityStore {
  private lastEventTime = Date.now()

  private events = [
    'mousemove',
    'keypress',
    'click',
    'load',
  ]

  private handler = throttle(() => {
    this.lastEventTime = Date.now()
  }, THROTTLE_TIMEOUT)

  setListeners() {
    this.events.forEach(event => window.addEventListener(event, this.handler))
  }

  clearListeners() {
    this.events.forEach(event => window.removeEventListener(event, this.handler))
  }

  alive() {
    const timePassed = Date.now() - this.lastEventTime

    return timePassed < THROTTLE_TIMEOUT
  }
}

export default new WindowEventsActivityStore()
