/* eslint-disable max-len */
import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import { MailOutlined } from '@ant-design/icons'
import { contactEmail, copyrightFooterDates } from '@shared/constants'
import EditorFooterLogo from './EditorFooterLogo'

const Divider = ({ className }: { className: string}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 2 2" fill="none" className={className}>
    <rect width="2" height="2" rx="1" />
  </svg>
)

const Quantori = ({ className }: { className: string}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" className={className}>
    <path d="M9.60967 12.4102C11.1519 12.4102 12.4021 11.1591 12.4021 9.61579C12.4021 8.07248 11.1519 6.82138 9.60967 6.82138C8.06743 6.82138 6.8172 8.07248 6.8172 9.61579C6.8172 11.1591 8.06743 12.4102 9.60967 12.4102Z" />
    <path d="M14.368 3.62886C14.2589 3.7647 14.2047 3.93658 14.2161 4.11051C14.2276 4.28443 14.3038 4.44772 14.4298 4.56806C15.3394 5.43068 15.999 6.52326 16.3389 7.73022C16.6789 8.93719 16.6865 10.2137 16.361 11.4246C16.361 11.4787 16.334 11.529 16.3185 11.5831C16.271 11.7493 16.2869 11.9272 16.3633 12.0823C16.4397 12.2373 16.5711 12.3583 16.7318 12.4218C16.8209 12.4558 16.9159 12.4713 17.0112 12.4673C17.1065 12.4634 17.1999 12.4401 17.2859 12.3989C17.3719 12.3577 17.4486 12.2994 17.5114 12.2276C17.5741 12.1558 17.6217 12.0719 17.651 11.9812C17.651 11.9193 17.6819 11.8614 17.7012 11.8034C18.0888 10.3487 18.0782 8.81652 17.6705 7.36739C17.2628 5.91827 16.4729 4.6056 15.3838 3.56702C15.3135 3.50079 15.2302 3.44995 15.1391 3.41774C15.0481 3.38553 14.9513 3.37264 14.855 3.37992C14.7587 3.38719 14.665 3.41446 14.5798 3.45998C14.4947 3.5055 14.4199 3.56828 14.3603 3.64432L14.368 3.62886Z" />
    <path d="M2.0472 8.50265C2.21737 8.52879 2.39116 8.49009 2.5342 8.39419C2.67723 8.29829 2.7791 8.15217 2.81967 7.98473C2.81967 7.93062 2.8467 7.87651 2.86215 7.82627C3.1829 6.61204 3.82509 5.50689 4.72101 4.62735C5.61694 3.74782 6.73341 3.12646 7.95272 2.8288C8.12102 2.78904 8.26832 2.6876 8.36556 2.5445C8.4628 2.40141 8.50292 2.22704 8.478 2.0558C8.46452 1.96036 8.43125 1.86879 8.38034 1.78697C8.32943 1.70515 8.26199 1.63487 8.18235 1.58065C8.10271 1.52643 8.01263 1.48946 7.91788 1.47211C7.82314 1.45477 7.72581 1.45742 7.63215 1.47991C6.17134 1.83332 4.83264 2.57401 3.75676 3.62411C2.68088 4.67421 1.90755 5.99494 1.51806 7.4475L1.47171 7.62529C1.44615 7.72101 1.44108 7.82105 1.45684 7.91887C1.4726 8.01669 1.50883 8.11007 1.56316 8.1929C1.61749 8.27573 1.6887 8.34614 1.77211 8.39952C1.85553 8.4529 1.94927 8.48804 2.0472 8.50265Z" />
    <path d="M12.4137 16.7351C12.3488 16.5729 12.2257 16.4406 12.0687 16.3642C11.9116 16.2877 11.7317 16.2726 11.564 16.3216C10.3377 16.68 9.03669 16.6948 7.80262 16.3642C6.56855 16.0337 5.44882 15.3705 4.56544 14.4471C4.44508 14.3203 4.28188 14.243 4.10766 14.2302C3.93343 14.2173 3.76066 14.2698 3.62303 14.3775C3.54704 14.4371 3.4843 14.5119 3.43881 14.5972C3.39332 14.6824 3.36607 14.7762 3.3588 14.8726C3.35154 14.9689 3.36441 15.0657 3.3966 15.1568C3.42879 15.2479 3.4796 15.3313 3.54578 15.4017C4.60782 16.5163 5.95614 17.3166 7.44281 17.7147C8.92947 18.1129 10.497 18.0936 11.9734 17.6589C12.0656 17.6309 12.151 17.5841 12.2241 17.5215C12.2973 17.4588 12.3566 17.3815 12.3984 17.2947C12.4401 17.2078 12.4634 17.1133 12.4667 17.0169C12.47 16.9206 12.4533 16.8247 12.4176 16.7351H12.4137Z" />
    <path d="M15.0323 17.2488C16.2439 17.2488 17.2261 16.2659 17.2261 15.0535C17.2261 13.841 16.2439 12.8582 15.0323 12.8582C13.8207 12.8582 12.8385 13.841 12.8385 15.0535C12.8385 16.2659 13.8207 17.2488 15.0323 17.2488Z" fill="#F5D010" />
    <path d="M2.19381 13.8102C3.40542 13.8102 4.38762 12.8273 4.38762 11.6149C4.38762 10.4024 3.40542 9.41956 2.19381 9.41956C0.982203 9.41956 0 10.4024 0 11.6149C0 12.8273 0.982203 13.8102 2.19381 13.8102Z" fill="#0094B7" />
    <path d="M11.5951 4.39066C12.8067 4.39066 13.7889 3.40778 13.7889 2.19533C13.7889 0.982883 12.8067 0 11.5951 0C10.3834 0 9.40125 0.982883 9.40125 2.19533C9.40125 3.40778 10.3834 4.39066 11.5951 4.39066Z" fill="#EF413D" />
  </svg>
)

const EditorFooter = (): ReactElement => (
  <div className="mt-3 flex items-center justify-between">
    <div className="flex justify-center items-center">
      <NavLink to={{ pathname: 'https://adroitdi.com' }} target="_blank">
        <div className="flex items-center">
          <EditorFooterLogo />
          <p className="text-sm ml-3">Adroit DI Ltd ⓒ {copyrightFooterDates}</p>
        </div>
      </NavLink>
      <Divider className="fill-[#393E48] dark:fill-[#fff] mx-4" />
      <NavLink to={{ pathname: 'https://quantori.com' }} target="_blank">
        <div className="flex items-center">
          <p className="text-sm">Powered by</p>
          <Quantori className="fill-[#393E48] dark:fill-[#fff] mx-2" />
          <p className="text-sm">Quantori</p>
        </div>
      </NavLink>
    </div>
    <a href={`mailto:${contactEmail}`} className="underline mr-2">
      <MailOutlined className="mr-2 primary-color" />
      {contactEmail}
    </a>
  </div>
)

export default EditorFooter
