import AddRows from '@pages/authorized/AddRows'
import FileEditor from '@pages/authorized/FileEditor'
import Files from '@pages/authorized/Files'
import Settings from '@pages/authorized/Settings'
import SubscriptionPlans from '@pages/authorized/SubscriptionPlans'

export default {
  Files: {
    path: '/',
    component: Files,
    label: 'Files',
  },
  FileEditor: {
    path: '/file/:id/editor',
    component: FileEditor,
    label: 'File editor',
  },
  AddRows: {
    path: '/file/:id/add-rows',
    component: AddRows,
    label: 'Add rows',
  },
  SettingsAccount: {
    path: '/settings/account',
    component: Settings,
    label: 'Account',
  },
  AcademicPlans: {
    path: '/settings/academic',
    component: Settings,
    label: 'Academic',
  },
  SettingsSubscription: {
    path: '/settings/subscription',
    component: Settings,
    label: 'Subscription',
  },
  SubscriptionPlans: {
    path: '/subscription/plans',
    component: SubscriptionPlans,
    label: 'SubscriptionPlans',
  },
}
