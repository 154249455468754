import apiService from '@shared/services/api-service'

export default class IndigoHttpService {
  static async renderMolString(
    bodyRenderMol: IndigoRenderParams,
    {
      abortController,
    }: QueryOptionsWithAbortController = {},
  ): Promise<string> {
    return apiService.post('/v2/indigo/render', {
      headers: {
        'Content-Type': apiService.contentTypeSVG,
      },
      json: bodyRenderMol,
      signal: abortController?.signal,
    })
  }

  static async renderBulkMolString(
    requestBodyRenderMol: IndigoRenderParams[],
    {
      abortController,
    }: QueryOptionsWithAbortController = {},
  ): Promise<{ [key: string]: {error: string, result: string} }> {
    return apiService.post('/v2/indigo/render_bulk', {
      json: requestBodyRenderMol,
      signal: abortController?.signal,
    })
  }

  static async deserializeMolStruct(
    structure: string,
    {
      abortController,
    }: QueryOptionsWithAbortController = {},
    extension: string,
  ): Promise<string> {
    let URL

    if (extension !== 'sdf') {
      URL = 'deserialize/smiles'
    } else {
      URL = 'deserialize'
    }

    return apiService.post<string>(`/v2/indigo/${URL}`, {
      headers: {
        'Content-Type': apiService.contentTypePlainText,
      },
      json: {
        struct: structure,
      },
      signal: abortController?.signal,
    })
  }
}
