/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Clock = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    className={cn('sdf-icon fill-current', className)}
    onClick={onClick}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.75781 9.15156L9.31094 9.76094C9.30122 9.77421 9.28897 9.78542 9.2749 9.79395C9.26083 9.80247 9.24522 9.80812 9.22896 9.81059C9.2127 9.81306 9.19611 9.81229 9.18014 9.80833C9.16418 9.80437 9.14916 9.79729 9.13594 9.7875L6.55156 7.90312C6.53546 7.89156 6.52238 7.8763 6.5134 7.85863C6.50443 7.84095 6.49983 7.82138 6.5 7.80156V3.5C6.5 3.43125 6.55625 3.375 6.625 3.375H7.37656C7.44531 3.375 7.50156 3.43125 7.50156 3.5V7.36719L9.72969 8.97812C9.78594 9.01719 9.79844 9.09531 9.75781 9.15156Z" />
  </svg>
)

export default Clock
