import { useCallback, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react'

import subscriptionStore from '@store/subscription'
import authorizedRoutes from '@router/authorized-routes'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AcademicPlansOverview from '@components/common/AcademicPlansOverview'
import Footer from '@components/common/Footer'
import Header from '@components/common/Header'
import PlansOverview from '@components/common/PlansOverview'
import SpinnerArea from '@components/common/SpinnerArea'
import usePaddle from '@shared/hooks/usePaddle'
import { CheckoutEventsCallbacks } from '@shared/services/paddle'

const onCheckoutSuccess = () => {
  const conversionId = Number.parseInt(window.SDFEConfig.REACT_APP_LINKED_IN_CONVERSION_ID || '', 10)

  if (window.lintrk && !Number.isNaN(conversionId)) {
    // eslint-disable-next-line camelcase
    window.lintrk('track', { conversion_id: conversionId })
  }
}

const SubscriptionPlans = ({ history }: RouteComponentProps): JSX.Element => {
  const [isRedirecting, setRedirecting] = useState(false)
  const [fetchError, setFetchError] = useState<string | null>(null)

  const onCheckoutComplete = useCallback(async () => {
    setRedirecting(true)
    setFetchError(null)

    try {
      await subscriptionStore.fetchWithRetry(() => subscriptionStore.fetchSubscriptionDetails(), 5, 2000)
      history.replace(authorizedRoutes.Files.path)
    } catch (error) {
      setFetchError('Failed to load subscription details. Please contact support at support@adroitdi.com.')
    } finally {
      setRedirecting(false)
    }
  }, [history])

  const checkoutCallbacks: CheckoutEventsCallbacks = useMemo(() => ({
    'checkout.success': onCheckoutSuccess,
    'checkout.complete': onCheckoutComplete,
  }), [onCheckoutComplete])

  usePaddle(checkoutCallbacks)

  if (isRedirecting) return <SpinnerArea className="h-full w-full" />

  return (
    <div className="flex flex-col overflow-x-hidden h-full">
      <Header />
      <main className="flex-col-grow items-center">
        <h1 className="text-3xl mt-6 font-bold text-center">
          Choose your Adroit REPOSITORY Subscription<br />
          24-hour free trial.
        </h1>
        <PlansOverview />
        {fetchError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-6 text-center"
            role="alert"
          >
            <span className="block sm:inline">{fetchError}</span>
          </div>
        )}
      </main>
      <Footer />
    </div>
  )
}
export default observer(SubscriptionPlans)
