import { FormInstance } from 'antd/lib/form/hooks/useForm'

const handleApiValidation = (form: FormInstance, apiValidationData: {[key: string]: string}): void => {
  const fieldsErrors = Object.entries(apiValidationData)
    .map(([key, value]) => ({ name: key, errors: [value] as string[] }))

  if (fieldsErrors.length) {
    form.setFields(fieldsErrors)
  }
}

export default handleApiValidation
