/* eslint-disable max-len */
import { ReactElement } from 'react'
import { Checkbox, Form } from 'antd'

import { privacyPolicyUrl, termsOfServiceUrl, termsOfUseUrl } from '@shared/constants'

interface AcceptLicenseAgreementProps {
  type?: 'signup' | 'subscribe'
}

const AcceptAcademicPlans = ({
  type = 'signup',
}: AcceptLicenseAgreementProps): ReactElement => (
  <>
    <span className="text-white text-sm">Academic Use Only. Fair use rules apply. For Profit must use standard license</span>
    <div className="border-b border-solid dark:border-gray-60 border-white border-opacity-20 w-full mt-4">           </div>
    <Form.Item
      name="agreement"
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error('You should give your consent'))
            }

            return Promise.resolve()
          },
          validateTrigger: 'onSubmit',
        },
      ]}
    >

      <Checkbox className="mr-2 mt-5 text-white">
        <span>
          I agree with
        </span>
        {
      type === 'signup'
        ? (
          <>
            <a
              href={termsOfUseUrl}
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            > Terms of Use
            </a>
            <span> and </span>
            <a
              href={privacyPolicyUrl}
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            > Privacy Policy
            </a>
          </>
        )
        : (
          <a
            href={termsOfServiceUrl}
            target="_blank"
            className="dark:text-primary dark:border-primary text-white border-solid border-b border-white !border-opacity-40 ml-1"
            rel="noreferrer"
          >Terms of Service
          </a>
        )

    }
      </Checkbox>
    </Form.Item>
  </>
)

export default AcceptAcademicPlans
