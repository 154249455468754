import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, message } from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import routes from '@router/authorized-routes'
import NewPasswordFieldGroup from '@components/guest-forms/components/NewPasswordFieldGroup'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import isApiValidationError from '@utils/is-api-validation-error'

const UpdatePasswordForm = observer((): ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { email = '' } = userStore.resetPasswordData

  const resendEmailHandle = useCallback(async () => {
    form.setFields([
      {
        name: 'verificationCode',
        errors: [],
      },
    ])

    try {
      await userStore.resetPassword(email)
    } catch (error) {
      message.error('Something went wrong')
    }
  }, [email, form])

  const handleSubmit = useCallback(
    async ({ verificationCode, password }: { verificationCode: string, password: string }) => {
      try {
        await userStore.updatePassword({ verificationCode, password, email })
        await userStore.authorize({ password, email })

        userStore.resetPasswordData = {}

        history.push(routes.Files.path)
      } catch (error) {
        if (isApiCustomException(error, 'ExpiredCodeException')) {
          form.setFields([{
            name: 'verificationCode',
            errors: ['Your code is expired. Please click Resend to get the new code'],
          }])
        } else if (isApiValidationError(error)) {
          handleApiValidation(form, error.data)
        } else if (isErrorHasMessage(error)) {
          message.error(error.message)
        } else {
          throw error
        }
      }
    },
    [history, form, email],
  )

  return (
    <>
      <p className="text-center mb-8">
        Please enter the verification code sent to {email}
      </p>
      <Form
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label="Verification  code"
          name="verificationCode"
          rules={[
            { required: true, message: 'Please input verification code' },
          ]}
        >
          <Input placeholder="Enter the code" size="large" autoComplete="off" />
        </Form.Item>
        <div className="min-h-full flex justify-between text-gray-70 dark:text-white mb-8 text-base">
          <div>Didn’t get the email?
            <span className="text-primary font-semibold" role="button" onClick={resendEmailHandle}> Resend</span>
          </div>
        </div>
        <p className="text-center mb-4 text-gray-70 dark:text-white text-base">
          Create a new password
        </p>
        <NewPasswordFieldGroup />
        <SubmitButton label="Update password" />
      </Form>
    </>
  )
})

export default UpdatePasswordForm
