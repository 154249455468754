import { ReactElement, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import guestRoutes from '@router/guest-routes'
import ResetPasswordForm from '@components/guest-forms/ResetPasswordForm'
import UpdatePasswordForm from '@components/guest-forms/UpdatePasswordForm'
import PageFormContainer from '@components/hoc/PageFormContainer'
import { LandingPath } from '@shared/constants'

const UpdatePasswordContent = observer((): ReactElement => (
  <>
    <h2 className="text-4xl font-bold text-center mb-4">Password Recovery</h2>
    <UpdatePasswordForm />
    <div className="min-h-full flex justify-between text-gray-70 dark:text-white mt-4">
      <div>I remember the password
        <Link className="text-primary font-semibold" to={guestRoutes.LogIn.path}> Sign In</Link>
      </div>
    </div>
  </>
))

const ResetPasswordContent = (): ReactElement => (
  <>
    <h2 className="text-3xl font-bold text-center mb-6">Password Recovery</h2>
    <p className="text-center text-gray-70 dark:text-white mb-8 max-w-sm mx-auto">
      Please enter the email address you registered with Adroit REPOSITORY and we will send you
      an email to reset your password
    </p>
    <ResetPasswordForm />
    <div className="min-h-full flex justify-between text-gray-70 dark:text-white mt-4">
      <div>Remember the password?
        <Link className="text-primary font-semibold" to={guestRoutes.LogIn.path}> Sign In</Link>
      </div>
    </div>
  </>
)

const PasswordRecovery = observer((): ReactElement => {
  const { email } = userStore.resetPasswordData
  const onEditEmailHandle = useCallback(() => {
    userStore.resetPasswordData = {}
  }, [])
  const editEmailButton = { label: 'Edit email', onClick: onEditEmailHandle }
  const backHomeButton = { label: 'Back to the homepage', path: LandingPath }
  const backButton = email ? editEmailButton : backHomeButton
  const Content = email ? UpdatePasswordContent : ResetPasswordContent

  return (
    <PageFormContainer testId="sign-up-page" backButton={backButton}>
      <Content />
    </PageFormContainer>
  )
})

export default PasswordRecovery
