import { IGetRowsParams } from 'ag-grid-community'
import { isEqual } from 'lodash'

import FileStore from '@store/file'
import {
  fileEditorGridAheadPages,
  fileEditorGridPageSize,
  fileEditorGridRequestTimeoutSec,
  fileEditorPageLoadMaxAttempts,
} from '@shared/constants'
import eventBusService from '@shared/services/event-bus-service'
import Logger from '@shared/services/logger'
import { indexSearch, indexSearchPolling } from '@utils/http'
import retryAsync from '@utils/retry-async'

enum RowCachePageStatus {
  Pending = 'pending',
  Loading = 'loading',
  Loaded = 'loaded',
  Ejected = 'ejected',
  Canceled = 'canceled',
  Failed = 'failed'
}

interface RowCachePage {
  status: RowCachePageStatus
  promise: Promise<RowCachePageStatus>
  page: number
  data?: MoleculeRow[]
  creationTime: number
  startTime?: number
  loadTime?: number
}

type FileEditorSearchParams = Pick<
  IndexSearchQueryParams,
  'type' | 'queryStructure' | 'filters' | 'similarity' | 'sorting'
>

export default class FileEditorTableDataSourceWithForwardCache implements FileEditorDataSource {
  readonly pageSize = fileEditorGridPageSize

  private readonly pageSizeMultiplierThreshold = 1000
  private readonly pageSizeMultiplier = 2
  private readonly logger: Logger

  private currentPage = 0
  private abortController = new AbortController()
  private pingSearchLoopTid: ReturnType<typeof setTimeout> | null = null
  private cache: Map<number, RowCachePage> = new Map()
  private queue: Promise<unknown> = Promise.resolve()
  private searchParams: FileEditorSearchParams | null = null
  private _searchId: string | null = null
  private _searchStat: IndexSearchResultStat | null = null
  private _loadedRows = 0
  private _waitForLoading = Promise.resolve()

  constructor(
    private fileStore: FileStore,
  ) {
    this.fileStore = fileStore
    this.logger = new Logger(this.constructor.name)
  }

  get aheadRows(): number {
    return this.pageSize * fileEditorGridAheadPages
  }

  private get requestTimeout(): number {
    return fileEditorGridRequestTimeoutSec * 1000
  }

  private set searchId(searchId: string | null) {
    this._searchId = searchId
    this.fileStore.searchId = searchId || undefined
  }

  private get searchId(): string | null {
    return this._searchId
  }

  private get loadedRows(): number {
    return this._loadedRows
  }

  private set loadedRows(loadedRowsCount: number) {
    this._loadedRows = loadedRowsCount
    this.fileStore.loadedRowsCount = loadedRowsCount
  }

  private get searchStat(): IndexSearchResultStat | null {
    return this._searchStat
  }

  private set searchStat(searchStat: IndexSearchResultStat | null) {
    this._searchStat = searchStat
    this.fileStore.searchStat = searchStat || undefined
  }

  private set lastRow(lastRow: number) {
    this.fileStore.lastRow = lastRow
  }

  destroy(): void {
    this.abortController.abort()
    this.resetCache()
  }

  resetCache(waitFor = Promise.resolve()): void {
    this.cache.clear()
    this.currentPage = 0
    this.searchStat = null
    this.queue = Promise.resolve()
    this.searchId = null
    this.searchParams = null
    this.lastRow = 0
    this.loadedRows = 0
    this.logger.log('[CACHE RESET]')
    this._waitForLoading = waitFor
  }

  async getRows(params: IGetRowsParams): Promise<void> {
    try {
      this.logger.log(
        'getRows() startRow=%s, endRow=%s, sorting=%s',
        params.startRow,
        params.endRow,
        JSON.stringify(params.sortModel),
      )

      if (this.fileStore.isEmptyFile) {
        this.logger.log('file is empty so passing empty data array and lastRow=0')
        params.successCallback([], 0)
        return
      }

      const page = (params.startRow / this.pageSize) + 1

      if (!this.cache.has(1)) this.saveSearchFilter(params)

      await this._waitForLoading
      this.ensurePagesQueueUpToPage(page)

      const cacheEntry = this.cache.get(page)

      if (!cacheEntry) throw new Error(`Page ${page} was not enqueued!`)

      const status = await cacheEntry.promise

      if (status === RowCachePageStatus.Canceled) {
        params.successCallback([])
        return
      }

      if (status === RowCachePageStatus.Failed) throw new Error('Page request has failed')

      const data = this.ejectCache(cacheEntry)
      const lastRow = this.computeLastRow()

      this.logger.log(
        // eslint-disable-next-line max-len
        'got %s row(s) for page=%s with startRow=%s, endRow=%s, filtered=%s, matched=%s, inprogress=%s, lastRow=%s',
        data?.length || 0,
        page,
        params.startRow,
        params.endRow,
        this.searchStat?.filtered,
        this.searchStat?.matched,
        this.searchStat?.inprogress,
        lastRow,
      )
      this.printCacheEntryTiming(page)

      params.successCallback(data, lastRow)
      this.lastRow = lastRow
      this.loadedRows += data.length
    } catch (err) {
      this.logger.error('Error on loading data:', err)
      params.failCallback()
    }
  }

  private computeLastRow(): number {
    let windowPageSize = fileEditorGridAheadPages
    if (this.loadedRows > this.pageSizeMultiplierThreshold) windowPageSize *= this.pageSizeMultiplier

    let currentWindowLastPage = Math.ceil(this.currentPage / windowPageSize) * windowPageSize
    if (currentWindowLastPage === this.currentPage) currentWindowLastPage += 1

    let currentWindowLastRow = currentWindowLastPage * this.pageSize

    if (this.searchStat && !this.searchStat.inprogress && this.searchStat.matched < currentWindowLastRow) {
      currentWindowLastRow = this.searchStat.matched
    }

    return currentWindowLastRow
  }

  private saveSearchFilter(params: IGetRowsParams) {
    const {
      filters = [], type, queryStructure, similarity,
    } = this.fileStore.searchParams

    const sorting = this.parseAgSorting(params.sortModel)

    this.searchParams = {
      queryStructure,
      similarity,
      sorting,
      filters,
      type,
    }

    this.logger.log('saved search params:', this.searchParams)

    if (!isEqual(sorting, this.fileStore.searchParams.sorting)) {
      this.fileStore.updateSorting(sorting)
      this.logger.log('updating sorting params in file settings')
    }
  }

  private ensurePagesQueueUpToPage(page: number) {
    this.logger.log('checking queue up to %s page..', page)

    for (let checkingPage = this.currentPage + 1; checkingPage <= page; checkingPage += 1) {
      const cacheEntry = this.createCacheRequest(checkingPage)
      this.queue = cacheEntry.promise
      this.cache.set(checkingPage, cacheEntry)
      this.currentPage = checkingPage
      this.logger.log('queued page %s', checkingPage)
    }
  }

  private createCacheRequest(page: number): RowCachePage {
    let cacheEntry: RowCachePage

    const promise = this.queue
      .then(async () => {
        this.logger.log('starting page %s loading...', page)

        return retryAsync(
          async () => {
            const limit = this.pageSize

            cacheEntry!.status = RowCachePageStatus.Loading
            cacheEntry!.startTime = Date.now()

            if (this.searchId == null) {
              if (!this.searchParams) throw new Error('Search filters was not set!')

              // TODO: check API token
              return indexSearch({
                ...this.searchParams,
                fileIds: [this.fileStore.fileId],
                limit,
              }, {
                abortController: this.abortController,
                timeout: this.requestTimeout,
              })
            }

            // TODO: check API token
            return indexSearchPolling({
              searchId: this.searchId,
              limit,
            }, {
              abortController: this.abortController,
              timeout: this.requestTimeout,
            })
          },
          fileEditorPageLoadMaxAttempts,
        )
      })
      .then(async ({ stat, molecules, searchId }) => {
        if (page === 1) this.searchId = searchId

        cacheEntry.data = molecules.map(this.getMoleculeMapper(page))
        const actualStat = await this.retryLostRowsFetching(cacheEntry, stat)
        cacheEntry.status = RowCachePageStatus.Loaded
        cacheEntry.loadTime = Date.now()
        this.searchStat = actualStat

        this.logger.log('page %s has loaded with %s molecules(s)!', page, molecules.length)

        return RowCachePageStatus.Loaded
      })
      .catch(err => {
        this.logger.error('page loading error:', err)

        if (cacheEntry.status !== RowCachePageStatus.Canceled) {
          cacheEntry.status = RowCachePageStatus.Failed
        }

        return RowCachePageStatus.Failed
      })

    cacheEntry = {
      page,
      status: RowCachePageStatus.Pending,
      promise,
      creationTime: Date.now(),
    }

    return cacheEntry
  }

  private async retryLostRowsFetching(
    cacheEntry: RowCachePage,
    stat: IndexSearchResultStat,
  ): Promise<IndexSearchResultStat> {
    if (!cacheEntry.data || !this.searchId) {
      return stat
    }

    const loadedMolecules = (cacheEntry.page - 1) * this.pageSize + cacheEntry.data.length
    const availableMolecules = stat.matched

    this.logger.log('loadedMolecules=%s; availableMolecules=%s', loadedMolecules, availableMolecules)

    // Last page check (when search on API has ended)
    if (!stat.inprogress) return stat

    const lostRowsCount = this.pageSize - cacheEntry.data?.length

    // Check is there are enough rows loaded
    if (lostRowsCount <= 0 || (lostRowsCount + loadedMolecules) === availableMolecules) {
      return stat
    }

    this.logger.log('have to load %s missed row(s) using polling', lostRowsCount)

    // Poll search to fetch latest found rows
    const freshStatAfterRetries = await retryAsync(async () => {
      if (!this.searchId || !cacheEntry.data) return null

      // TODO: check API token
      const { molecules, stat: freshStat } = await indexSearchPolling({
        searchId: this.searchId,
        limit: lostRowsCount,
      }, {
        abortController: this.abortController,
        timeout: this.requestTimeout,
      })

      cacheEntry.data.push(...molecules.map(this.getMoleculeMapper(cacheEntry.page)))

      return freshStat
    }, fileEditorPageLoadMaxAttempts)

    if (!freshStatAfterRetries) return stat

    return this.retryLostRowsFetching(cacheEntry, freshStatAfterRetries)
  }

  private printCacheEntryTiming(page: number) {
    const cacheEntry = this.cache.get(page)
    if (!cacheEntry) return
    const stats: string[] = []

    if (cacheEntry.startTime && cacheEntry.creationTime) {
      stats.push(`waiting: ${cacheEntry.startTime - cacheEntry.creationTime}ms`)
    }

    if (cacheEntry.loadTime && cacheEntry.startTime) {
      stats.push(`loading: ${cacheEntry.loadTime - cacheEntry.startTime}ms`)
    }

    this.logger.log(`----> page ${cacheEntry.page} timing: ${stats.join(', ')}`)
  }

  private ejectCache(cacheEntry: RowCachePage): MoleculeRow[] {
    if (!cacheEntry.data) {
      this.logger.error('Cache entry is empty:', cacheEntry)
      throw new Error(`Cache page=${cacheEntry.page} has no loaded data`)
    }

    const { data } = cacheEntry
    /* eslint-disable no-param-reassign */
    delete cacheEntry.data
    cacheEntry.status = RowCachePageStatus.Ejected
    /* eslint-enable no-param-reassign */

    eventBusService.emit('file:cache-eject')

    return data
  }

  private getMoleculeMapper(page: number) {
    return (molecule: Molecule, index: number): MoleculeRow => ({
      ...this.mapMoleculeRow(molecule, index),
      '#': page * this.pageSize + index + 1,
    })
  }

  private mapMoleculeRow = (mol: Molecule, index: number): MoleculeRow => ({
    ...mol.molproperties,
    '#': index + 1,
    id: mol.id,
    customOrder: mol.customOrder,
    Structure: mol.structure,
  })

  private parseAgSorting(sortModel: AgGridColumnSortModel[]) {
    return sortModel.map((sm: AgGridColumnSortModel): IndexSortingParams => ({
      fieldName: sm.colId,
      order: sm?.sort?.toUpperCase() as SDFSortingOrderType,
    }))
  }
}
