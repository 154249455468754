import { ReactElement, ReactNode } from 'react'
import cn, { Argument } from 'classnames'

interface CardProps {
  header: string
  className?: Argument
  children: ReactNode
}

const Card = ({ header, className = '', children }: CardProps): ReactElement => (
  <div className={cn('flex flex-col self-start p-6 bg-gray-10 rounded-xl w-80 dark:bg-gray-80', className)}>
    <h2 className="text-xl font-bold">{header}</h2>
    {children}
  </div>
)

export default Card
