import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'

interface PropertyFilter extends Omit<IndexSearchParamsPropertyFilter, 'filters'>{
  filters: Map<string, IndexSearchParamsPropertySubFilter>
}

class TableFilteringStore {
  private _filters: Map<string, PropertyFilter> = new Map()
  private _appliedFilters: IndexSearchParamsPropertyFilter[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get filtersAsArray(): IndexSearchParamsPropertyFilter[] {
    return Array.from(this._filters.values()).map(filterItem => ({
      ...filterItem,
      filters: Array.from(filterItem.filters.values()),
    }))
  }

  setFiltersFromArray(filtersArr: IndexSearchParamsPropertyFilter[]) {
    const filtersMap = new Map()

    filtersArr.forEach(filter => {
      const subFilters = new Map()

      filter.filters.forEach(subFilter => {
        subFilters.set(subFilter.subFilterId, subFilter)
      })

      filtersMap.set(filter.filterId, {
        ...filter,
        filters: subFilters,
      })
    })

    this._filters = filtersMap
  }

  get appliedFilters() {
    return this._appliedFilters
  }

  setAppliedFilters() {
    this._appliedFilters = this.filtersAsArray
  }

  addFilter(filterId?: string, propertyName?: string) {
    const id = filterId || nanoid()
    const subId = nanoid()

    const newSubFilter = new Map([[subId, {
      subFilterId: subId,
      filter: '' as const,
      operator: undefined,
      value: '',
      negation: false,
    }]])

    const newFilter = {
      filterId: id,
      property: propertyName || '',
      operator: this._filters.size > 0 ? 'AND' as FilterOperators : undefined,
      filters: newSubFilter,
      negation: false,
    }
    this._filters.set(id, newFilter)
  }

  set deleteFilter(filterId: string) {
    const isRemovingFirst = Array.from(this._filters.keys())[0] === filterId
    this._filters.delete(filterId)

    if (isRemovingFirst && this._filters.size > 0) {
      const nextFilter = Array.from(this._filters.values())[0]
      nextFilter.negation = false
    }
  }

  insertPropertyName(propertyName: string, filterId: string) {
    const currentFilter = this._filters.get(filterId)
    currentFilter!.property = propertyName
  }

  addSubFilter(filterId: string) {
    const subId = nanoid()

    const newSubFilter = {
      subFilterId: subId,
      filter: '' as const,
      operator: 'AND' as FilterOperators,
      value: '',
      negation: false,
    }

    const currentFilter = this._filters.get(filterId)

    if (!currentFilter) {
      throw new Error(`TableFilteringStore.addSubFilter(): no filter found by filterId=${filterId}`)
    }

    currentFilter.filters.set(subId, newSubFilter)
  }

  removeSubFilter(filterId: string, subFilterId: string) {
    const currentFilter = this._filters.get(filterId)

    if (!currentFilter) {
      throw new Error(`TableFilteringStore.removeSubFilter(): no filter found by filterId=${filterId}`)
    }

    const isRemovingFirst = Array.from(currentFilter.filters.keys())[0] === subFilterId
    currentFilter.filters.delete(subFilterId)

    if (isRemovingFirst && currentFilter.filters.size > 0) {
      const nextSubFilter = Array.from(currentFilter.filters.values())[0]
      nextSubFilter.negation = false
    }
  }

  resetSubFilter(filterId: string, subFilterId: string) {
    this._filters.get(filterId)?.filters!.set(subFilterId, {
      subFilterId,
      filter: '' as const,
      operator: undefined,
      value: '',
      negation: false,
    })
  }

  insertSubFilterValue(filterId: string, subFilterId: string, value: number | string) {
    this._filters.get(filterId)!.filters.get(subFilterId)!.value = value
  }

  addSubFilterCompareOperator(
    filterId: string,
    subFilterId: string,
    compareOperator: FilterCompareOperator,
  ) {
    this._filters.get(filterId)!.filters.get(subFilterId)!.filter = compareOperator

    if (compareOperator === 'EMPTY' || compareOperator === 'NONEMPTY') {
      this._filters.get(filterId)!.filters.get(subFilterId)!.value = ''
    }
  }

  changeFilterLogicOperator(filterId: string, operator: FilterOperators) {
    const currentFilterIndex = Array.from(this._filters.keys()).indexOf(filterId)

    const nextFilterId = Array.from(this._filters.keys())[currentFilterIndex + 1]

    this._filters.get(nextFilterId)!.operator = operator === 'NOT' ? 'AND' : operator
    this._filters.get(nextFilterId)!.negation = (operator === 'NOT')
  }

  changeSubFilterLogicOperator(filterId: string, subFilterId: string, operator?: FilterOperators) {
    const currentSubFilterIndex = Array.from(this._filters.get(filterId)!.filters.keys()).indexOf(subFilterId)
    const nextSubFilterId = Array.from(this._filters.get(filterId)!
      .filters.values())[currentSubFilterIndex + 1].subFilterId

    this._filters.get(filterId)!.filters.get(nextSubFilterId)!.operator = operator === 'NOT' ? 'AND' : operator
    this._filters.get(filterId)!.filters.get(nextSubFilterId)!.negation = (operator === 'NOT')
  }

  resetFilters() {
    this._filters = new Map()
    this._appliedFilters = []
  }
}

export default new TableFilteringStore()
