/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Plus = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M15.6769 9.74783L15.6769 8.2523H9.74777L9.74777 2.3232H8.25223L8.25223 8.2523H2.32314L2.32314 9.74783L8.25223 9.74783V15.6769H9.74777V9.74783L15.6769 9.74783Z" />
  </svg>
)

export default Plus
