import { useState } from 'react'
import {
  Button, Form,
} from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import PasswordInput from '@components/common/PasswordInput'
import { isErrorHasMessage } from '@shared/services/api-service'
import { showErrorMessage, showSuccessMessage } from '@utils/messages'

const ChangePasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const handleSubmit = async (data: { currentPassword: string, newPassword: string }) => {
    setLoading(true)

    try {
      await userStore.changePassword({
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      })

      form.resetFields()
      showSuccessMessage('Password was successfully changed!')
    } catch (error) {
      // TODO: better error messages naming?
      if (isErrorHasMessage(error) && error.message === 'INCORRECT_PASSWORD') {
        form.setFields([
          {
            name: 'currentPassword',
            errors: ['Incorrect password'],
          },
        ])

        return
      }

      showErrorMessage()
    } finally {
      setLoading(false)
    }
  }

  const validateMessages = {
    string: {
      min: 'Password length should be from 8 to 99 characters',
      max: 'Password length should be from 8 to 99 characters',
    },
    pattern: {
      mismatch: 'Password should contain lowercase Basic Latin letters, uppercase and number characters',
    },
  }

  return (
    <Form
      className="flex flex-col mt-4"
      requiredMark="optional"
      form={form}
      validateMessages={validateMessages}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        className="mb-3"
        label="Current password"
        name="currentPassword"
        rules={[
          {
            required: true,
            message: 'Please enter current password',
            validateTrigger: 'onSubmit',
          },
        ]}
      >
        <PasswordInput
          placeholder="Enter current password"
          size="large"
        />
      </Form.Item>
      <Form.Item
        className="mb-3"
        label="New password"
        name="newPassword"
        validateFirst
        rules={[
          {
            required: true,
            message: 'Please enter new password',
            validateTrigger: 'onSubmit',
          },
          { min: 8 },
          { max: 99 },
          { pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/ },
        ]}
      >
        <PasswordInput
          placeholder="Enter new password"
          size="large"
        />
      </Form.Item>
      <Form.Item
        className="mb-3"
        label="Confirm password"
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: 'Please confirm new password',
            validateTrigger: 'onSubmit',
          },
          ({ getFieldValue }) => ({
            validateTrigger: 'onSubmit',
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Passwords do not match'))
            },
          }),
        ]}
      >
        <PasswordInput
          placeholder="Confirm new password"
          size="large"
        />
      </Form.Item>
      <Form.Item className="self-end mt-3 mb-0">
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  )
}

export default observer(ChangePasswordForm)
