import {
  ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import AddRows from '@store/add-rows'
import FileStore from '@store/file'
import StructureImageStore from '@store/structure-image'
import AddRowPanel from '@components/add-row/AddRowPanel'
import AddRowsEmptyState from '@components/add-row/AddRowsEmptyState'
import AddRowTable from '@components/add-row/AddRowTable'
import Footer from '@components/common/Footer'
import Header from '@components/common/Header'
import AddRowsStoreProvider, { useAddRowsStore, useFileStore } from '@contexts/add-rows-context'
import { useKetcherContext } from '@contexts/ketcher-context'
import KetcherModalProvider from '@contexts/ketcher-provider'
import structureImagesCache from '@utils/structure-images-cache'

const ketcherModalId = 'addRow'

const AddRowsPage = observer((): ReactElement => {
  const addRowsStore = useAddRowsStore()
  const fileStore = useFileStore()

  const [currentMolRow, setCurrentMolRow] = useState<MoleculeRow | null>(null)

  const {
    addModal, removeModal, canAddModal,
  } = useKetcherContext()

  const history = useHistory()
  const goBackToFile = useCallback(
    () => history.push(`/file/${addRowsStore.fileId}/editor`),
    [addRowsStore.fileId, history],
  )

  const hideKetcherModal = () => {
    setCurrentMolRow(null)
    removeModal(ketcherModalId)
  }

  const saveMolStr = (molString: string | null) => {
    if (molString) {
      if (currentMolRow) {
        addRowsStore.setMolStructure(currentMolRow.customOrder, molString)
      } else {
        addRowsStore.addNewRow(molString)
      }
    }
    hideKetcherModal()
  }

  const openKetcherModal = () => {
    if (canAddModal(ketcherModalId)) {
      return
    }
    addModal({
      id: ketcherModalId,
      structure: '',
      onOk: saveMolStr,
      onCancel: hideKetcherModal,
    })
  }

  return (
    <div className="h-full flex flex-col">
      <Header fileStore={fileStore} />

      <div className="m-6 flex-col-grow">
        <div className="flex-col-grow">
          <AddRowPanel addNewRow={openKetcherModal} onSuccessfulMoleculesAdd={goBackToFile} />

          <div className="flex-grow">
            {addRowsStore.rowsCount < 1 ? (
              <AddRowsEmptyState className="mt-14" addNewRow={openKetcherModal} />
            ) : (
              <AddRowTable />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default function AddRowsPageWrapper(): ReactElement {
  const { id } = useParams<{ id: string }>()
  const addRowsStore = useMemo(() => new AddRows(id), [id])

  const fileStore = useMemo(() => {
    const fs = new FileStore(id)
    fs.fetchIndexProperties()
    fs.setFileDescription()
    return fs
  }, [id])

  useEffect(() => () => structureImagesCache.clear(StructureImageStore.imagesCache), [id])

  return (
    <KetcherModalProvider>
      <AddRowsStoreProvider stores={{ addRowsStore, fileStore }}>
        <AddRowsPage />
        <Footer />
      </AddRowsStoreProvider>
    </KetcherModalProvider>
  )
}
