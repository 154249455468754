import { ReactElement } from 'react'
import { observer } from 'mobx-react'

import tableFilteringStore from '@store/table-filtering'
import { PlusCircleFilled } from '@ant-design/icons'
import FilterLogicOperator from './FilterLogicOperator'
import PropertyItemFilter from './PropertyItemFilter'
import PropertyItemSubfilter from './PropertyItemSubfilter'

interface PropertyItemProps {
  filterId: string
  propertyName: string
  filterIndex: number
  columnsSelectOptions: AntSelectOptions[]
  deleteFilter: (filterIndex: number, filterId: string) => void
  insertPropertyName: (propertyName: string, filterIndex: number, filterId: string) => void
  insertSubFilterValue: (filterId: string, subFilterId: string, value: string | number) => void
  addCompareSubFilterOperator: (filterId: string, subFilterId: string, compareOperator: FilterCompareOperator) => void
  removeSubFilter: (filterId: string, subFilterId: string) => void
  filters: IndexSearchParamsPropertySubFilter[]
  clonedFilters: IndexSearchParamsPropertyFilter[]
}

const PropertyItem = observer(({
  filterId,
  propertyName,
  filterIndex,
  columnsSelectOptions,
  deleteFilter,
  insertPropertyName,
  insertSubFilterValue,
  addCompareSubFilterOperator,
  removeSubFilter,
  filters,
  clonedFilters,
}: PropertyItemProps): ReactElement => (
  <div className="flex mb-2">
    <div className="flex flex-col items-center w-1 ml-2 mr-5">
      <div className="bg-gray-50 h-full w-0.5 mb-px" />

      {clonedFilters[filterIndex + 1]
        ? <FilterLogicOperator filterId={filterId} filterIndex={filterIndex} filters={clonedFilters} />
        : (
          <PlusCircleFilled
            className="relative top-1 text-primary cursor-pointer text-xl leading-0"
            onClick={() => tableFilteringStore.addFilter()}
          />
        )}
    </div>

    <div className="flex flex-col w-full mb-3">
      <PropertyItemFilter
        filterId={filterId}
        filterIndex={filterIndex}
        columnsSelectOptions={columnsSelectOptions}
        deleteFilter={deleteFilter}
        insertPropertyName={insertPropertyName}
      />

      {filters.map((item, index) => (
        <PropertyItemSubfilter
          key={item.subFilterId}
          item={item}
          filterId={filterId}
          propertyName={propertyName}
          subFilterIndex={index}
          insertSubFilterValue={insertSubFilterValue}
          addCompareSubFilterOperator={addCompareSubFilterOperator}
          removeSubFilter={removeSubFilter}
          filters={filters}
        />
      ))}
    </div>
  </div>
))

export default PropertyItem
