import {
  ReactElement, useState,
} from 'react'

import AccountDetailsForm from '@components/settings/AccountDetailsForm'
import Card from '@components/settings/Card'
import ChangePasswordForm from '@components/settings/ChangePasswordForm'
import DeleteAccountModal from '@components/settings/DeleteAccountModal'
import KeepInTouchForm from '@components/settings/KeepInTouchForm'

const Account = (): ReactElement => {
  const [modalVisible, setModalVisible] = useState(false)

  const closeModal = () => setModalVisible(false)
  const openModal = () => setModalVisible(true)

  return (
    <div className="flex flex-col">
      <div className="flex">
        <DeleteAccountModal visible={modalVisible} handleCancel={closeModal} />
        <Card header="Account details">
          <AccountDetailsForm />
        </Card>
        <Card header="Change password" className="ml-10">
          <ChangePasswordForm />
        </Card>
        <Card header="Let’s keep in touch" className="ml-10">
          <KeepInTouchForm />
        </Card>
      </div>
      <span
        role="button"
        className="text-danger text-sm mt-7 mb-6 self-start"
        onClick={openModal}
      >
        Delete account
      </span>
    </div>
  )
}

export default Account
