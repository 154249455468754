import { useEffect } from 'react'
import {
  Button, Modal,
} from 'antd'
import { observer } from 'mobx-react'

import { subscribeBackgroundTask } from '@store/background-tasks'
import RemoveDuplicatesIcon from '@components/icons/RemoveDuplicates'
import useBackgroundTasksContext from '@contexts/background-tasks'
import { useFileStore } from '@contexts/file-edit-context'
import eventBusService from '@shared/services/event-bus-service'

interface RemoveDuplicateProps {
    disabled?: boolean
}

const RemoveDuplicates = ({ disabled }: RemoveDuplicateProps): JSX.Element => {
  const fileStore = useFileStore()
  const backgroundTasksStore = useBackgroundTasksContext()

  useEffect(() => {
    const resultSuccessListener = subscribeBackgroundTask('background:resultSuccess', task => {
      if (task.type === 'remove-duplications') {
        eventBusService.emit(
          'remove-duplicates-modal',
          { remained: task.remained, removed: task.removed } as RemoveDuplicateResult,
        )
        fileStore.refetchFileDescription()
          .then(() => fileStore.repeatSearchMolecules())
      }
    })

    return () => {
      resultSuccessListener()
    }
  }, [backgroundTasksStore, fileStore])

  const handleRemoveDuplicate = async () => {
    backgroundTasksStore.createTask({
      type: 'remove-duplications',
      data: {
        fileId: fileStore.fileId,
        fileName: fileStore.fileDescription!.fileName,
      },
    })
  }

  const RemoveDuplicatesContent = () => (
    <>
      <p>
        The Remove Duplicates feature operates specifically on the Structure column.
        When duplicates are removed, only the last occurrence of each structure in the list is retained,
        while all other identical structures are deleted.
      </p>
      <br />
      <p>
        It&apos;s important to note that removing duplicates permanently eliminates duplicate values.
        Therefore, it is advisable to make a copy of the original index before proceeding with
        the removal of duplicate values.
      </p>
    </>
  )

  const removeDuplicatesConfirm = () => {
    Modal.confirm({
      title: <div className="text-xl">Remove Duplicates</div>,
      className: 'rounded-2xl',
      content: <RemoveDuplicatesContent />,
      icon: null,
      okText: 'Proceed',
      okType: 'primary',
      cancelText: 'Back',
      cancelButtonProps: { type: 'text', className: 'w-32' },
      okButtonProps: { className: 'w-32' },
      width: 760,
      async onOk(close) {
        handleRemoveDuplicate()
        close()
      },
    })
  }

  return (
    <Button
      className="flex items-center font-normal"
      type="text"
      onClick={removeDuplicatesConfirm}
      disabled={disabled}
    >
      <RemoveDuplicatesIcon className="w-4 h-4 mr-2 text-primary dark:text-white" />
      Remove Duplicates
    </Button>
  )
}

export default observer(RemoveDuplicates)
