import { useCallback, useEffect, useState } from 'react'
import { ICellRendererParams, RowSelectedEvent } from 'ag-grid-community'

import AgGridLineNumberWithCheckbox from '@components/table/AgGridLineNumberWithCheckbox'

const AddRowLineNumberCell: React.FC<ICellRendererParams> = ({ value, node }) => {
  const [isSelected, setIsSelected] = useState(false)

  const handleNodeSelection = useCallback((s: boolean) => {
    node.setSelected(s)
    setIsSelected(s)
  }, [node])

  const handleSyncWithExternalChange = useCallback((evt: RowSelectedEvent) => {
    const externalIsSelected = evt.node.isSelected() || false

    setIsSelected(externalIsSelected)
  }, [])

  useEffect(() => {
    node.addEventListener('rowSelected', handleSyncWithExternalChange)

    return () => {
      node.removeEventListener('rowSelected', handleSyncWithExternalChange)
    }
  }, [node, handleSyncWithExternalChange])

  return <AgGridLineNumberWithCheckbox isSelected={isSelected} lineNumber={value} onChange={handleNodeSelection} />
}

export default AddRowLineNumberCell
