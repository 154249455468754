export default async function retryAsync<T>(cb: () => Promise<T>, maxAttempts: number): Promise<T> {
  let attempt = 1
  let loadingErr

  while (attempt > 0) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await cb()
    } catch (err) {
      if (maxAttempts > 0 && attempt > maxAttempts) {
        loadingErr = err
        break
      }
      attempt += 1
    }
  }

  throw loadingErr
}
