import { ReactElement } from 'react'
import {
  Button, Checkbox,
  Form, Input, Modal, Radio,
} from 'antd'
import { observer } from 'mobx-react'

import filesStore from '@store/files'
import { fileNameValidationRules } from '@utils/validators'

export interface MergeSettings {
  fileName: string
  withProperties: boolean
  matchedRows: boolean
  withDeduplication: boolean
}

interface MergeSettingsProps {
  initialValues?: MergeSettings
  onCancel: () => void
  onOk: (mergeSettings: MergeSettings) => void
}

interface MergeSettingsModalProps {
  visible: boolean
  initialFormValues?: MergeSettings
  onCancel: () => void
  onOk: (mergeSettings: MergeSettings) => void
}

const MergeSettings = observer(({ initialValues, onCancel, onOk }: MergeSettingsProps): ReactElement => (
  <div className="my-5">
    <div className="font-bold text-2xl mb-2">Merge settings</div>
    <Form
      onFinish={onOk}
      initialValues={initialValues}
      layout="vertical"
    >
      <Form.Item
        label="File name"
        name="fileName"
        rules={fileNameValidationRules}
        validateFirst
      >
        <Input size="large" />
      </Form.Item>

      {filesStore.isCheckedFilesHaveSearchMatched && (
        <Form.Item
          label="Rows to merge"
          name="matchedRows"
        >
          <Radio.Group>
            <Radio value className="block">Matched rows</Radio>
            <Radio value={false}>All rows</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item
        label="Columns to merge"
        name="withProperties"
        className="mb-5"
      >
        <Radio.Group>
          <Radio value className="block">All columns</Radio>
          <Radio value={false}>Structures only</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Additional options"
        name="withDeduplication"
        className="my-5"
        valuePropName="checked"
      >
        <Checkbox>Remove duplicates</Checkbox>
      </Form.Item>

      <div className="flex justify-end">
        <Button
          ghost
          size="large"
          className="mr-3"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          size="large"
          htmlType="submit"
        >
          Continue
        </Button>
      </div>
    </Form>
  </div>
))

const MergeSettingsModal = observer(({
  visible, initialFormValues, onCancel, onOk,
}: MergeSettingsModalProps): ReactElement => (
  <Modal
    width={360}
    open={visible}
    centered
    destroyOnClose
    closable={false}
    footer={null}
  >
    <MergeSettings
      initialValues={initialFormValues}
      onCancel={onCancel}
      onOk={onOk}
    />
  </Modal>
))

export default MergeSettingsModal
