import { useEffect } from 'react'
import { notification } from 'antd'
import { reaction } from 'mobx'

import filesStore from '@store/files'

const useWatchFilesRecordLimitError = (): void => {
  useEffect(() => {
    const dispose = reaction(
      () => filesStore.uploadedFiles.filter(file => file.fileParsingErrors
        && Object.keys(file.fileParsingErrors).length
        && Reflect.has(file.fileParsingErrors, -100)),
      filesWithRecordLimitError => {
        if (filesWithRecordLimitError.length === 0) return

        filesWithRecordLimitError.forEach(file => {
          filesStore.removeFile(file.id)

          notification.error({
            message: `${file.fileName} overload storage limit`,
            description: file.fileParsingErrors[-100],
            duration: 0,
          })
        })
      },
    )

    return () => dispose()
  }, [])
}

export default useWatchFilesRecordLimitError
