import {
  ChangeEvent, ReactElement, useMemo, useState,
} from 'react'
import {
  Button, Form, Input, Radio, Select,
} from 'antd'
import { observer } from 'mobx-react'

import tableFilteringStore from '@store/table-filtering'
import { DeleteFilled } from '@ant-design/icons'
import Plus from '@components/icons/Plus'
import { useFileStore } from '@contexts/file-edit-context'
import { inputNumberValidator } from '@utils/validators'

interface PropertyItemSubfilterProps {
  item: IndexSearchParamsPropertySubFilter
  filterId: string
  propertyName: string
  subFilterIndex: number
  insertSubFilterValue: (filterId: string, subFitlerId: string, value: string | number) => void
  addCompareSubFilterOperator: (filterId: string, subFilterId: string, compareOperator: FilterCompareOperator) => void
  removeSubFilter: (filterId: string, subFilterId: string) => void
  filters: IndexSearchParamsPropertySubFilter[]
}

const compareOperators: Record<IndexPropertyType, FilterCompareOperator[]> = {
  INTEGER: ['LESSTHAN', 'GREATERTHAN', 'EQUALS', 'EMPTY', 'NONEMPTY'],
  DECIMAL: ['LESSTHAN', 'GREATERTHAN', 'EQUALS', 'EMPTY', 'NONEMPTY'],
  DATE: ['LESSTHAN', 'GREATERTHAN', 'EQUALS', 'EMPTY', 'NONEMPTY'],
  STRING: ['EQUALS', 'CONTAINS', 'EMPTY', 'NONEMPTY'],
}

const PropertyItemSubfilter = observer(({
  item,
  filterId,
  propertyName,
  subFilterIndex,
  insertSubFilterValue,
  addCompareSubFilterOperator,
  removeSubFilter,
  filters,
}: PropertyItemSubfilterProps): ReactElement => {
  const fileStore = useFileStore()

  const [subFilterValue, setSubFilterValue] = useState<string>('')

  const compareOperatorsSelectOptions = useMemo(() => {
    const currentProperty = fileStore.indexProperties.find(property => property.name === propertyName)

    return currentProperty ? compareOperators[currentProperty.type].map(op => ({
      key: op,
      value: op,
      label: op,
    })) : []
  }, [fileStore.indexProperties, propertyName])

  const inputType = useMemo(() => {
    const currentProperty = fileStore.indexProperties.find(property => property.name === propertyName)

    if (!currentProperty) return 'STRING'
    return currentProperty.type
  }, [fileStore.indexProperties, propertyName])

  const filter = filters[subFilterIndex]?.filter

  const inputVisible = filter !== 'EMPTY' && filter !== 'NONEMPTY'

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value

    if (inputType !== 'STRING') {
      newValue = inputNumberValidator(newValue, subFilterValue)
    }

    insertSubFilterValue(filterId, item.subFilterId, newValue)
    setSubFilterValue(newValue)
  }

  return (
    <div key={item.subFilterId}>
      <div className="flex items-baseline">
        <Form.Item
          name={`compareOperator${filterId}${item.subFilterId}`}
          className="w-36 mr-2 -mb-2"
          rules={[
            {
              required: true,
              message: 'Please enter a compare operator',
            },
          ]}
        >
          <Select
            options={compareOperatorsSelectOptions}
            onSelect={(compareOperator: FilterCompareOperator) => {
              addCompareSubFilterOperator(filterId, item.subFilterId, compareOperator)
            }}
          />
        </Form.Item>

        <div className="flex items-baseline w-full">
          { inputVisible
            ? (
              <Form.Item
                name={`subFilterValue${filterId}${item.subFilterId}`}
                id={`subFilterValue${filterId}${item.subFilterId}`}
                className="w-full -mb-px"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a filter value',
                  },
                  inputType !== 'STRING' ? {
                    pattern: /^[-]?\d+(\.\d+)?$/,
                    message: 'This is not a valid number.',
                  } : {},
                ]}
              >
                <Input
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    insertSubFilterValue(filterId, item.subFilterId, (e.target.value).trim())
                  }}
                  type="text"
                  onChange={handleValueChange}
                />
              </Form.Item>
            ) : ''}

          <DeleteFilled
            style={{ visibility: subFilterIndex === 0 && filters.length === 1 ? 'hidden' : 'visible' }}
            className="ml-2 cursor-pointer text-gray-40 hover:text-danger"
            onClick={() => filters.length > 1
              && removeSubFilter(filterId, item.subFilterId)}
          />
        </div>
      </div>

      {filters[subFilterIndex + 1]
        ? (
          <div className="flex items-center my-3 text-white">
            <div className="text-gray-40 text-xs">Logical:</div>

            <div className="flex items-center w-full">
              <div className="flex items-center ml-2">
                <Radio
                  checked={filters[subFilterIndex + 1].negation === false
                    && filters[subFilterIndex + 1].operator === 'AND'}
                  onClick={() => tableFilteringStore.changeSubFilterLogicOperator(filterId, item.subFilterId, 'AND')}
                >And
                </Radio>

              </div>

              <div className="flex items-center">
                <Radio
                  checked={filters[subFilterIndex + 1].negation === false
                     && filters[subFilterIndex + 1].operator === 'OR'}
                  onClick={() => tableFilteringStore.changeSubFilterLogicOperator(filterId, item.subFilterId, 'OR')}
                >Or
                </Radio>
              </div>

              <div className="flex items-center">
                <Radio
                  checked={filters[subFilterIndex + 1].negation === true}
                  onClick={() => tableFilteringStore.changeSubFilterLogicOperator(filterId, item.subFilterId, 'NOT')}
                >Not
                </Radio>
              </div>
            </div>
          </div>
        )
        : (
          <Button
            className="flex items-center mt-3"
            type="text"
            onClick={() => tableFilteringStore.addSubFilter(filterId)}
          >
            <Plus className="mr-1" />

            Add
          </Button>
        )}
    </div>
  )
})

export default PropertyItemSubfilter
