import { makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'

import apiService from '@shared/services/api-service'

export interface MergeTaskDetails {
  type: 'Merge'
  details: {
    fileId: string
    fileIds: string
    fileName: string
    withProperties: string
    withDeduplication: boolean
  }
}

export interface MergeTaskInitData {
  fileName: string
  withProperties: boolean
  fileIds: string[]
  searchParams?: IndexSearchParams
  withDeduplication: boolean
}

export type MergeTaskProps = {
  type: 'merge'
  data: MergeTaskInitData
}

class MergeTask implements BackgroundTaskInterface {
  readonly id = nanoid()
  readonly type = 'merge'

  readonly fileName: string
  readonly withProperties: boolean
  readonly searchParams?: IndexSearchParams
  readonly withDeduplication: boolean

  indexes: string[]
  taskId = nanoid()
  mergeResultFileId = ''
  progress = 0
  status: BackgroundTaskStatus = 'Initial'

  abortController = new AbortController()

  constructor({
    fileName, withProperties, searchParams, fileIds: indexes, withDeduplication,
  }: MergeTaskInitData) {
    this.indexes = indexes
    this.fileName = fileName.trim()
    this.withProperties = withProperties
    this.searchParams = searchParams
    this.withDeduplication = withDeduplication

    makeObservable(this, {
      taskId: observable,
      mergeResultFileId: observable,
      status: observable,
      progress: observable,
      indexes: observable,
    })
  }

  fetchTaskId = async (): Promise<BackgroundFetchTaskId> => {
    const {
      withProperties,
      searchParams,
      indexes,
      fileName,
      abortController,
      withDeduplication,
    } = this

    const { taskId, status, messages } = await apiService.post<TaskProgress>('/merge', {
      json: {
        fileIds: indexes,
        fileName,
        withProperties,
        withDeduplication,
        searchStructure: searchParams,
      },
      signal: abortController.signal,
    })

    if (status === 'COMPLETED_WITH_ERROR') {
      throw new Error(messages.join(', '))
    }

    const file = await apiService.get<UploadedFile>(`/merge/${taskId}`)

    runInAction(() => {
      this.taskId = taskId
      this.mergeResultFileId = file.id
      this.indexes = indexes
    })
    return { taskId, status }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async fetchResult(): Promise<void> {}
}

export default MergeTask
