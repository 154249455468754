import isEnv from '@utils/isEnv'

export default class Logger {
  constructor(private prefix: string) {}

  log(arg1: string, ...args: unknown[]): void {
    if (isEnv('development')) return
    // eslint-disable-next-line no-console
    console.log(`[${this.prefix}] ${arg1}`, ...args)
  }

  error(arg1: string, ...restArgs: unknown[]): void {
    // eslint-disable-next-line no-console
    console.error(`[${this.prefix}] ${arg1}`, ...restArgs)
  }
}
