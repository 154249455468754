import { Modal } from 'antd'
import { observer } from 'mobx-react'

import BulkEdit from '@components/file/BulkEdit'
import { useFileStore } from '@contexts/file-edit-context'
import pluralEnd from '@utils/plural-end'

interface BulkEditModalProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const BulkEditModal = ({ visible, setVisible }: BulkEditModalProps) => {
  const fileStore = useFileStore()
  const fileCount = fileStore.selectedMoleculeCustomOrdersCount
  const title = `${fileCount} row${pluralEnd(fileCount)} selected`

  const closeModal = () => {
    setVisible(false)
  }

  return (
    <Modal
      title={title}
      open={visible}
      centered
      destroyOnClose
      onCancel={closeModal}
      footer={null}
      bodyStyle={{ paddingBottom: '16px' }}
    >
      <BulkEdit closeParent={closeModal} />
    </Modal>
  )
}

export default observer(BulkEditModal)
