/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import {
  ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react'
import {
  Button, Form,
  FormInstance,
  message,
  Select,
} from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react'
import { nanoid } from 'nanoid'

import tableFilteringStore from '@store/table-filtering'
import Close from '@components/icons/Close'
import { useFileStore, useTableManagementStore } from '@contexts/file-edit-context'
import { useKetcherContext } from '@contexts/ketcher-context'
import { isErrorHasMessage } from '@shared/services/api-service'
import PropertyItem from './PropertyItem'

interface FiltersFormProps {
  filters: IndexSearchParamsPropertyFilter[]
  form: FormInstance
  handleResetFilters: (isLastFilter: boolean) => void
}

const FiltersForm = ({
  filters, form, handleResetFilters,
}: FiltersFormProps): ReactElement => {
  const fileStore = useFileStore()
  const clonedFilters: IndexSearchParamsPropertyFilter[] = cloneDeep(filters)
  const tableManagementStore = useTableManagementStore()

  const [searchValue, setSearchValue] = useState<string>('')

  const columnsSelectOptions: AntSelectOptions[] = fileStore.indexProperties.map((item, index) => ({
    key: index + item.name,
    value: item.name,
    label: item.name,
  }))

  const filteredColumnsSelectOptions = useMemo(
    () => {
      const searchValueInLowerCase = searchValue.toLocaleLowerCase()

      return columnsSelectOptions
        .filter(option => option.label.toLocaleLowerCase()
          .includes(searchValueInLowerCase))
    },
    [columnsSelectOptions, searchValue],
  )

  // TODO: refactor form (form values are stored in two places now - it's wrong)
  // Actualizing form valeus when amout of columns is changed
  useEffect(() => {
    const indexValuesSet = new Set(fileStore.indexProperties.map(item => item.name))
    const newFilters: IndexSearchParamsPropertyFilter[] = []
    const filtersForReset: IndexSearchParamsPropertyFilter[] = []
    tableFilteringStore.filtersAsArray.forEach(filter => {
      const list = indexValuesSet.has(filter.property) ? newFilters : filtersForReset
      list.push(filter)
    })

    tableFilteringStore.setFiltersFromArray(newFilters)
    const fieldsForReset: string[] = []
    filtersForReset.forEach(filter => {
      fieldsForReset.push(`filterName${filter.filterId}`)

      filter.filters.forEach(subFilter => {
        fieldsForReset.push(`subFilterValue${filter.filterId}${subFilter.subFilterId}`)
        fieldsForReset.push(`compareOperator${filter.filterId}${subFilter.subFilterId}`)
      })
    })
    form.resetFields(fieldsForReset)
  }, [fileStore.indexProperties, form])

  const initialValues = tableFilteringStore.filtersAsArray.reduce<Record<string, string | number>>((acc, filter) => {
    acc[`filterName${filter.filterId}`] = filter.property

    filter.filters.forEach(subFilter => {
      acc[`subFilterValue${filter.filterId}${subFilter.subFilterId}`] = subFilter.value
      acc[`compareOperator${filter.filterId}${subFilter.subFilterId}`] = subFilter.filter
    })

    return acc
  }, {})
  const { clearModals } = useKetcherContext()

  const handleSubmitAsync = async () => {
    clearModals()

    try {
      await fileStore.searchMolecules({
        ...fileStore.searchParams,
        filters,
      })
      const filterPropertys = fileStore.searchParams.filters?.map(filter => filter.property)

      if (filterPropertys?.length) {
        tableManagementStore.filteredIndexProperties.forEach(property => {
          if (!filterPropertys?.includes(property.name)) {
            tableManagementStore.updateColumn(property, 'hidden', true)
          } else {
            tableManagementStore.updateColumn(property, 'hidden', false)
          }
        })
        fileStore.updateIndexProperties(tableManagementStore.indexProperties)
      }
      await fileStore.renderMatchedMolecules({ ...fileStore.searchParams, filters })

      tableFilteringStore.setAppliedFilters()
    } catch (error) {
      if (isErrorHasMessage(error) && error.message !== 'The user aborted a request.') {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }

  const removeSubFilter = (filterId: string, subFilterId: string) => {
    form.resetFields([
      `compareOperator${filterId}${subFilterId}`,
      `subFilterValue${filterId}${subFilterId}`,
    ])
    tableFilteringStore.removeSubFilter(filterId, subFilterId)
  }

  const insertSubFilterValue = (filterId: string, subFilterId: string, value: number | string) => {
    tableFilteringStore.insertSubFilterValue(filterId, subFilterId, value)

    form.setFieldsValue({ [`subFilterValue${filterId}${subFilterId}`]: value })
  }

  const addCompareSubFilterOperator = (
    filterId: string,
    subFilterId: string,
    compareOperator: FilterCompareOperator,
  ) => {
    form.setFieldsValue({
      [`compareOperator${filterId}${subFilterId}`]: compareOperator,
      [`subFilterValue${filterId}${subFilterId}`]: '',
    })

    tableFilteringStore.addSubFilterCompareOperator(filterId, subFilterId, compareOperator)
  }

  const addStartFilter = (propertyName: string) => {
    const filterId = nanoid()
    form.setFieldsValue({ [`filterName${filterId}`]: propertyName })

    tableFilteringStore.addFilter(filterId, propertyName)
  }

  const deleteFilter = (filterIndex: number, filterId: string) => {
    if (filterIndex === 0 && filters.length === 1) {
      handleResetFilters(true)

      return
    }

    const fieldNames = [`filterName${filterId}`]

    filters[filterIndex].filters.forEach(filter => {
      fieldNames.push(`compareOperator${filterId}${filter.subFilterId}`)
      fieldNames.push(`subFilterValue${filterId}${filter.subFilterId}`)
    })

    form.resetFields(fieldNames)
    tableFilteringStore.deleteFilter = filterId
  }

  const insertPropertyName = (propertyName: string, filterIndex: number, filterId: string) => {
    const currentProperty = fileStore.indexProperties.find(property => property.name === propertyName)
    const prevProperty = fileStore.indexProperties
      .find(property => property.name === filters[filterIndex].property)

    if (currentProperty?.type !== prevProperty?.type) {
      const subFieldsNames: string[] = []
      filters[filterIndex].filters.forEach((subFilter, subFilterIndex) => {
        const compareOperatorName = `compareOperator${filterId}${subFilter.subFilterId}`
        const subFilterValueName = `subFilterValue${filterId}${subFilter.subFilterId}`
        subFieldsNames.push(compareOperatorName)
        subFieldsNames.push(subFilterValueName)

        if (subFilterIndex === 0) {
          tableFilteringStore.resetSubFilter(filterId, subFilter.subFilterId)
        } else {
          tableFilteringStore.removeSubFilter(filterId, subFilter.subFilterId)
        }
      })

      form.resetFields(subFieldsNames)
    }
    form.setFieldsValue({
      [`filterName${filterId}`]: propertyName,
    })

    tableFilteringStore.insertPropertyName(propertyName, filterId)
  }

  const handleSelect = (propertyName: string) => {
    setSearchValue('')
    addStartFilter(propertyName)
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  if (filters.length === 0) {
    return (
      <Select
        disabled={fileStore.readOnly}
        showSearch
        className="w-full"
        placeholder="Choose a column"
        size="small"
        options={filteredColumnsSelectOptions}
        onSelect={(propertyName: string) => handleSelect(propertyName)}
        onSearch={(value: string) => setSearchValue(value)}
      />
    )
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmitAsync}
      size="small"
      initialValues={initialValues}
      className="pl-[7px]"
    >
      {filters.map(({ property, filters: propertyFilters, filterId }, filterIndex) => (
        <PropertyItem
          key={filterId}
          filterId={filterId}
          propertyName={property}
          filterIndex={filterIndex}
          columnsSelectOptions={columnsSelectOptions}
          deleteFilter={deleteFilter}
          insertPropertyName={insertPropertyName}
          insertSubFilterValue={insertSubFilterValue}
          addCompareSubFilterOperator={addCompareSubFilterOperator}
          removeSubFilter={removeSubFilter}
          filters={propertyFilters}
          clonedFilters={clonedFilters}
        />
      ))}

      <div className="flex gap-5 mt-5">
        <Button
          onClick={() => handleResetFilters(false)}
          ghost
          size="large"
          disabled={fileStore.readOnly}
          className="flex-1 flex items-center justify-center font-normal"
        >
          Reset

          <Close className="w-4 h-4 ml-1" />
        </Button>

        <Button
          type="primary"
          block
          size="large"
          disabled={fileStore.readOnly}
          loading={fileStore.isMoleculesUpdating}
          htmlType="submit"
          className="flex-1"
        >
          Apply
        </Button>
      </div>
    </Form>
  )
}

const ObservedFiltersForm = observer(FiltersForm)

const TableFiltering = () => {
  const fileStore = useFileStore()
  const [form] = Form.useForm()
  const filters: IndexSearchParamsPropertyFilter[] = tableFilteringStore.filtersAsArray
  const { clearModals } = useKetcherContext()
  const tableManagementStore = useTableManagementStore()

  useEffect(() => {
    if (!fileStore.fileMetaDataFetching && fileStore.searchParams.filters) {
      tableFilteringStore.setFiltersFromArray(fileStore.searchParams.filters)
      tableFilteringStore.setAppliedFilters()
    }
  }, [fileStore.fileMetaDataFetching, fileStore.searchParams.filters])

  const handleResetFilters = useCallback((isLastFilter: boolean) => {
    clearModals()
    form.resetFields()
    if (isLastFilter) tableFilteringStore.resetFilters()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filters: excludedFilters, ...searchParamsWithoutFilters } = fileStore.searchParams

    fileStore.searchMolecules({
      ...searchParamsWithoutFilters,
    })
    fileStore.renderMatchedMolecules({ ...searchParamsWithoutFilters })
    tableManagementStore.filteredIndexProperties.forEach(property => {
      tableManagementStore.updateColumn(property, 'hidden', false)
    })
    fileStore.updateIndexProperties(tableManagementStore.indexProperties)
  }, [clearModals, fileStore, form, tableManagementStore])

  return (
    <ObservedFiltersForm
      filters={filters}
      form={form}
      handleResetFilters={(isLastFilter: boolean) => handleResetFilters(isLastFilter)}
    />
  )
}

export default observer(TableFiltering)
