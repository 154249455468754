import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Form, Input, message,
} from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import routes from '@router/authorized-routes'
import NewPasswordFieldGroup from '@components/guest-forms/components/NewPasswordFieldGroup'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import isApiValidationError from '@utils/is-api-validation-error'

const CreateAnAccountForm = observer((): ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const handleSubmit = useCallback(
    async ({ password, firstName, lastName }: { password: string, firstName: string, lastName: string }) => {
      const { session = '', email = '', temporaryPassword = '' } = userStore.signUpData

      try {
        await userStore.createAnAccount({
          session, email, password, firstName, lastName,
        })
        history.push(routes.SubscriptionPlans.path)
      } catch (error) {
        if (isApiValidationError(error)) {
          handleApiValidation(form, error.data)
        }

        if (isApiCustomException(error, 'UserNotFoundException')
          || isApiCustomException(error, 'InvalidTemporaryPassword')) {
          await userStore.restoreSignUpFlowForUncreatedUser(email)
        }

        if (isApiCustomException(error, 'InvalidSession')) {
          await userStore.confirmTemporary(email, temporaryPassword)
          const refreshed = userStore.signUpData
          await userStore.createAnAccount({
            session: refreshed.session ?? '', email, password, firstName, lastName,
          })
          history.push(routes.Files.path)
          return
        }

        if (isErrorHasMessage(error)) {
          message.error(error.message)
        } else {
          throw error
        }
      }
    },
    [history, form],
  )

  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      form={form}
    >
      <NewPasswordFieldGroup />
      <Form.Item
        label="First name"
        name="firstName"
        rules={[
          { required: true, message: 'Please input first name', validateTrigger: 'onSubmit' },
        ]}
      >
        <Input
          placeholder="Enter your first name"
          size="large"
        />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="lastName"
        rules={[
          { required: true, message: 'Please input last name', validateTrigger: 'onSubmit' },
        ]}
      >
        <Input
          placeholder="Enter your last name"
          size="large"
        />
      </Form.Item>
      <SubmitButton label="Create an account" />
    </Form>
  )
})

export default CreateAnAccountForm
