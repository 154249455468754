/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const Lock = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    className={cn('sdf-icon fill-current', className)}
    width="16"
    height="18"
    viewBox="0 0 16 18"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M14.4287 8.03823H13.0626V3.53823C13.0626 2.11791 11.9115 0.966797 10.4912 0.966797H5.50907C4.08876 0.966797 2.93764 2.11791 2.93764 3.53823V8.03823H1.57157C1.21599 8.03823 0.928711 8.3255 0.928711 8.68108V16.3954C0.928711 16.7509 1.21599 17.0382 1.57157 17.0382H14.4287C14.7843 17.0382 15.0716 16.7509 15.0716 16.3954V8.68108C15.0716 8.3255 14.7843 8.03823 14.4287 8.03823ZM4.38407 3.53823C4.38407 2.91747 4.88831 2.41323 5.50907 2.41323H10.4912C11.112 2.41323 11.6162 2.91747 11.6162 3.53823V8.03823H4.38407V3.53823ZM13.6251 15.5918H2.37514V9.48465H13.6251V15.5918ZM7.43764 12.7994V13.8641C7.43764 13.9525 7.50996 14.0248 7.59836 14.0248H8.40193C8.49032 14.0248 8.56264 13.9525 8.56264 13.8641V12.7994C8.72847 12.6803 8.85225 12.5117 8.91615 12.3178C8.98006 12.124 8.9808 11.9148 8.91826 11.7205C8.85572 11.5261 8.73314 11.3567 8.56815 11.2364C8.40317 11.1162 8.20429 11.0514 8.00014 11.0514C7.796 11.0514 7.59712 11.1162 7.43213 11.2364C7.26714 11.3567 7.14456 11.5261 7.08202 11.7205C7.01949 11.9148 7.02022 12.124 7.08413 12.3178C7.14803 12.5117 7.27181 12.6803 7.43764 12.7994Z" />
  </svg>
)

export default Lock
