/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

interface AboutIconProps {
  className?: string
  color: string
}

const AboutIcon: React.FC<AboutIconProps> = ({ className, color }): ReactElement => (
  <svg
    className={cn(className)}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_77854_5528)">
      <circle cx="7" cy="7" r="6" stroke={color} strokeWidth="1.2" />
      <path d="M7 10.5V6.5" stroke={color} strokeWidth="1.2" />
      <rect x="6" y="3" width="2" height="2" rx="1" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_77854_5528">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AboutIcon
