import {
  CellStyle,
  ColDef, GetRowIdParams,
} from 'ag-grid-community'
import { Comparator } from 'ag-grid-community/dist/lib/filter/provided/scalarFilter'
import { isNil, upperCase } from 'lodash'

import {
  AG_GRID_TABLE_NON_EDITABLE_COLUMNS,
  AG_GRID_TABLE_NON_SORTABLE_COLUMNS,
} from '@shared/constants'

type SDFSorting = {order: SDFSortingOrderType, index: number}

const numericCellComparator = (value1: number, value2: number) => value1 - value2

export const mapIndexPropertyColDefToAgGridColDef = (
  indexPropertyColDefList: IndexProperty[],
  searchParams: Partial<IndexSearchParams>,
  readOnly: boolean,
):
  ColDef[] => indexPropertyColDefList.filter(col => col.name !== '#').map(col => {
  const sortMap = (searchParams.sorting || []).reduce(
    (map, sort, currentIndex) => map.set(
      sort.fieldName,
      { order: sort.order, index: currentIndex },
    ),
    new Map<string, SDFSorting>(),
  )
  let cellEditor: string | undefined
  let className: string | undefined
  let cellRenderer: string | undefined
  let width: number | undefined
  let comparator: Comparator<number> | undefined
  let headerComponent: string | undefined
  let colId = col.name
  let cellStyle: CellStyle | undefined

  const isStructure = col.name === 'Structure'
  const isSortable = !AG_GRID_TABLE_NON_SORTABLE_COLUMNS.has(col.name)
  const isNumeric = col.type === 'DECIMAL' || col.type === 'INTEGER'
  const isLink = col.weblink

  if (isStructure) {
    cellEditor = 'structureCellEditor'
    className = 'structure-cell'
    cellRenderer = 'structureRenderer'
    cellStyle = {
      padding: '10px 15px 10px 0',
    }
    width = 130
  } else if (isLink) {
    cellRenderer = 'linkRenderer'
  } else if (isNumeric) {
    cellEditor = 'numericCellEditor'
    comparator = numericCellComparator
  } else {
    cellEditor = 'stringCellEditor'
  }
  let initialSort: AgGridColumnSortModel['sort'] | undefined

  let sortIndex: number | undefined

  if (isSortable) {
    headerComponent = 'columnHeader'

    const savedSorting = sortMap.get(col.name)
    sortIndex = savedSorting?.index

    switch (savedSorting?.order) {
      case 'ASC': initialSort = 'asc'; break
      case 'DESC': initialSort = 'desc'; break
      default: break
    }
  }

  if (col.status) {
    headerComponent = 'newColumnHeader'
    colId = col.name + col.status
  }

  return {
    colId,
    width,
    cellClass: className,
    field: col.name,
    pinned: isStructure || col.fixed,
    lockPosition: isStructure,
    headerTooltip: col.name,
    suppressPaste: isStructure,
    headerName: col.name,
    cellRenderer,
    weblink: col.weblink,
    type: col.type,
    editable: !AG_GRID_TABLE_NON_EDITABLE_COLUMNS.has(col.name) && !col.status && !readOnly,
    sortable: !AG_GRID_TABLE_NON_SORTABLE_COLUMNS.has(col.name),
    cellEditor,
    headerComponent,
    headerComponentParams: {
      status: col.status,
    },
    initialSort,
    sortIndex,
    comparator,
    unSortIcon: true,
    tooltipValueGetter: () => 'Double click to edit cell',
    cellClassRules: {
      'cell-loader-placeholder': ({ data }) => data == null && col.name !== 'Structure',
      'animate-fade-in-out': ({ data }) => data == null,
      'animate-fade-in': ({ data }) => data != null,
    },
    cellStyle,
  }
})

const sortByIndex = (a: AgGridColumnSortModel, b: AgGridColumnSortModel) => {
  if (isNil(a) || isNil(b)) {
    return 0
  }

  return a.sortIndex! - b.sortIndex!
}

export const mapAgSortToIndexSortingParams = (agSort: AgGridColumnSortModel[] | undefined):
IndexSortingParams[] | undefined => agSort
  ?.sort(sortByIndex)
  .map<IndexSortingParams>(s => ({
    fieldName: s.colId,
    order: upperCase(s.sort!) as SDFSortingOrderType,
  }))

export const getRowId = (params: GetRowIdParams): string => params.data.customOrder
