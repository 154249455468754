import { memo } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { observer } from 'mobx-react'

import StructureImageStore from '@store/structure-image'
import StructureImage from '@components/file/StructureImage'
import { AG_GRID_TABLE_ROW_HEIGHT_PX } from '@shared/constants'
import useRenderStructure from '@shared/hooks/useRenderStructure'

interface StructureRendererProps extends ICellRendererParams {
  context: AgGridCellRenderingContext
}

const AddRowStructureRenderer: React.FC<StructureRendererProps> = observer(({ value, context }) => {
  const structure = value || ''
  const imageAsText = useRenderStructure(
    structure,
    StructureImageStore.getRenderStructureType(structure),
    context.queryStructure,
  )
  const error = StructureImageStore.imagesErrors[structure]

  return (
    <StructureImage
      imageAsText={imageAsText}
      height="100%"
      maxHeight={AG_GRID_TABLE_ROW_HEIGHT_PX}
      width={120}
      errorText={error}
    />
  )
})

export default memo(AddRowStructureRenderer)
