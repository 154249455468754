import { ReactElement, useEffect } from 'react'
import { message, notification } from 'antd'
import { match } from 'ts-pattern'

import { subscribeBackgroundTask } from '@store/background-tasks'
import { ExclamationCircleFilled } from '@ant-design/icons'
import Clock from '@components/icons/Clock'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'

const mergeKey = 'mergeStartKey'
const exportKey = 'exportStartKey'
const addColumnKey = 'addColumnStartKey'
const bulkEditKey = 'bulkEditStartKey'
const removeDuplicatesKey = 'removeDuplicatesStartKey'
const rangeSelectionKey = 'rangeSelectionStartKey'
const dataExportKey = 'dataExportStartKey'
const convertKey = 'convertStartKey'

const START_MESSAGE_DURATION = 4
const COMPLETED_MESSAGE_DURATION = 4
const ERROR_MESSAGE_DURATION = 4

const useTaskProgressMessages = (): void => {
  useEffect(() => {
    const removeStartedListener = subscribeBackgroundTask('background:started', task => {
      const [key, content] = match<BackgroundTaskType, [string, string]>(task.type)
        .with('merge', () => [mergeKey, 'Merge running in the background'])
        .with('export', () => [exportKey, 'Download running in the background'])
        .with('add-column', () => [addColumnKey, 'Adding a column'])
        .with('bulk-edit', () => [bulkEditKey, 'Bulk edit is running in the background'])
        .with('remove-duplications', () => [removeDuplicatesKey, 'De-duplication in progress'])
        .with('range-selection', () => [rangeSelectionKey, 'Update in progress'])
        .with('data-export', () => [dataExportKey, 'Export in progress'])
        .with('convert', () => [convertKey, 'Convert in progress'])
        .with('copy', () => [convertKey, 'File copy in progress'])
        .exhaustive()

      message.loading({
        content,
        key,
        duration: START_MESSAGE_DURATION,
        icon: <Clock className="inline-block w-4 h-4 relative top-px mr-2 text-warning" />,
      })
    })

    const removeCompletedListener = subscribeBackgroundTask('background:completed', task => {
      const [key, content] = match<BackgroundTaskType, [string, string]>(task.type)
        .with('merge', () => [mergeKey, 'Merge completed'])
        .with('export', () => [exportKey, 'Download completed'])
        .with('add-column', () => [addColumnKey, 'The new column has been added'])
        .with('bulk-edit', () => [bulkEditKey, 'Bulk edit completed'])
        .with('remove-duplications', () => [removeDuplicatesKey, 'De-duplication completed'])
        .with('range-selection', () => [rangeSelectionKey, 'Values updated'])
        .with('data-export', () => [dataExportKey, 'Export completed'])
        .with('convert', () => [convertKey, 'Convert completed'])
        .with('copy', () => [convertKey, `Copy of ${task.type === 'copy' && task.fileName} was created`])
        .exhaustive()

      message.success({ content, key, duration: COMPLETED_MESSAGE_DURATION })
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removeErrorListener = subscribeBackgroundTask('background:error', (task, error: any) => {
      match<BackgroundTaskType, void>(task.type)
        .with('merge', () => {
          message.destroy(mergeKey)

          const split = error?.message.split('\n')
          notification.error({
            message: split[0],
            description: split.map((elem: ReactElement, idx: number) => {
              if (idx === 0) return null
              // eslint-disable-next-line react/no-array-index-key
              return <div key={idx}>{elem}</div>
            }),
            icon: <ExclamationCircleFilled />,
            duration: ERROR_MESSAGE_DURATION,
          })
        })
        .with('export', () => {
          message.error({
            key: exportKey,
            content: 'Download error',
            duration: ERROR_MESSAGE_DURATION,
          })
        })
        .with('add-column', () => {
          if (isApiCustomException(error, 'AlreadyExistsException')) {
            message.error({
              content: 'Such a column already exists',
              key: addColumnKey,
              duration: ERROR_MESSAGE_DURATION,
            })
          } else if (error instanceof Error) {
            if (error.name === 'AbortError') return
            message.error({ content: error.message, key: addColumnKey, duration: ERROR_MESSAGE_DURATION })
          } else if (isErrorHasMessage(error)) {
            message.error({ content: error.message, key: addColumnKey, duration: ERROR_MESSAGE_DURATION })
          } else {
            throw error
          }
        })
        .with('bulk-edit', () => {
          if (isApiCustomException(error, 'MoleculeUpdateException')) {
            message.error({
              content: error.message,
              key: bulkEditKey,
              duration: ERROR_MESSAGE_DURATION,
            })
          } else {
            message.error({
              content: 'Bulk edit completed with errors',
              key: bulkEditKey,
              duration: ERROR_MESSAGE_DURATION,
            })
          }
        })
        .with('remove-duplications', () => {
          if (isApiCustomException(error, 'MoleculeUpdateException')) {
            message.error({
              content: error.message,
              key: bulkEditKey,
              duration: ERROR_MESSAGE_DURATION,
            })
          } else {
            message.error({
              content: 'De-duplication completed with errors',
              key: bulkEditKey,
              duration: ERROR_MESSAGE_DURATION,
            })
          }
        })
        .with('range-selection', () => {
          message.error({
            content: isApiCustomException(error, 'MoleculeUpdateException')
              ? error.message : 'WARNING: Cell values update completed with errors.',
            key: rangeSelectionKey,
            duration: ERROR_MESSAGE_DURATION,
          })
        })
        .with('data-export', () => {
          message.error({
            content: isApiCustomException(error, 'ExportDataException')
              ? error.message : 'Data export completed with errors.',
            key: rangeSelectionKey,
            duration: ERROR_MESSAGE_DURATION,
          })
        })
        .with('convert', () => {
          message.error({
            content: isApiCustomException(error, 'ConvertDataException')
              ? error.message : 'Convert completed with errors.',
            key: convertKey,
            duration: ERROR_MESSAGE_DURATION,
          })
        })
        .with('copy', () => {
          message.error({
            content: isApiCustomException(error, 'CopyDataException')
              ? error.message : 'Copy completed with errors.',
            key: convertKey,
            duration: ERROR_MESSAGE_DURATION,
          })
        })
        .exhaustive()
    })

    const removeAbortedListener = subscribeBackgroundTask('background:aborted', task => {
      const key = match<BackgroundTaskType, string>(task.type)
        .with('merge', () => mergeKey)
        .with('export', () => exportKey)
        .with('add-column', () => addColumnKey)
        .with('bulk-edit', () => bulkEditKey)
        .with('remove-duplications', () => removeDuplicatesKey)
        .with('range-selection', () => rangeSelectionKey)
        .with('data-export', () => dataExportKey)
        .with('convert', () => convertKey)
        .with('copy', () => convertKey)
        .exhaustive()

      message.destroy(key)
    })

    return () => {
      removeStartedListener()
      removeCompletedListener()
      removeErrorListener()
      removeAbortedListener()
    }
  }, [])
}

export default useTaskProgressMessages

