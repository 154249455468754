import { createContext, useContext } from 'react'
import upperFirst from 'lodash/upperFirst'

type UseKeyFormat<T = string> = `use${Capitalize<string & T>}`

type UseStores<T extends Record<string, unknown>> = {
    [key in keyof T as UseKeyFormat<key>]: () => T[key]
}

type ProviderProps<T extends Record<string, unknown>> = { children: JSX.Element | JSX.Element[], stores?: T }
type ProviderComponent<T extends Record<string, unknown>> = ({ children, stores }: ProviderProps<T>) => JSX.Element

type CreateStoreReturnType<T extends Record<string, unknown>> = { Provider: ProviderComponent<T> } & UseStores<T>

const createStoreContext = <T extends Record<string, unknown>>(
  initialStores: T,
  contextName = 'StoresContext',
): CreateStoreReturnType<T> => {
  const Context = createContext<T>(initialStores)

  const Provider: ProviderComponent<T> = ({ children, stores }) => (
    <Context.Provider value={stores ?? initialStores}>{children}</Context.Provider>
  )

  const useStores = Object.keys(initialStores).reduce((acc, key) => {
    const useStoreKey = `use${upperFirst(key)}` as UseKeyFormat<typeof key>

    const useStore = () => {
      const stores = useContext(Context)

      if (!stores) {
        throw new Error(`Use '${useStoreKey}' inside ${contextName}`)
      }

      return stores[key]
    }

    acc[useStoreKey] = useStore

    return acc
  }, {} as Record<string, () => unknown>) as UseStores<T>

  return {
    Provider,
    ...useStores,
  }
}

export default createStoreContext
