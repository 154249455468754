import { useEffect } from 'react'
import {
  Dropdown, Menu, Modal, Spin,
} from 'antd'
import cn from 'classnames'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import {
  BackgroundTask, ExportTask, isTaskRunning, subscribeBackgroundTask,
} from '@store/background-tasks'
import filesStore from '@store/files'
import { CheckCircleFilled, EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import Close from '@components/icons/Close'
import Stop from '@components/icons/Stop'
import useBackgroundTasksContext from '@contexts/background-tasks'
import useTaskProgressMessages from '@shared/hooks/useTaskProgressMessages'
import apiService from '@shared/services/api-service'
import createBackendRequestUrl from '@utils/createBackendRequestUrl'
import downloadFileByUrl from '@utils/downloadFileByUrl'
import pluralEnd from '@utils/plural-end'

const DROPDOWN_ID = 'background-tasks-dropdown'

const getExportTaskFileName = ({ fileName, zip }: ExportTask): string => `${fileName}.${zip ? 'zip' : 'sdf'}`

const getTaskType = (taskType: BackgroundTaskType) => match(taskType)
  .with('export', () => 'Download')
  .with('merge', () => 'Merge')
  .with('add-column', () => 'New Column')
  .with('bulk-edit', () => 'Bulk Edit')
  .with('remove-duplications', () => 'De-duplication')
  .with('range-selection', () => 'Copy/Cut-Paste')
  .with('data-export', () => 'Data Export')
  .with('convert', () => 'Convert')
  .with('copy', () => 'Copy')
  .exhaustive()

const getTaskDescription = (task: BackgroundTask) => match(task)
  .with({ type: 'export' }, getExportTaskFileName)
  .with({ type: 'merge' }, ({ fileName }) => fileName)
  .with({ type: 'add-column' }, ({ fileName, formula, column }) => `${formula || column.name} ${fileName}`)
  .with({ type: 'bulk-edit' }, () => 'Setting new values')
  .with({ type: 'remove-duplications' }, ({ fileName }) => fileName)
  .with({ type: 'range-selection' }, ({ fileName }) => fileName)
  .with({ type: 'data-export' }, ({ fileName }) => fileName)
  .with({ type: 'convert' }, ({ fileName }) => fileName)
  .with({ type: 'copy' }, ({ fileName }) => fileName)
  .exhaustive()

const BackgroundTaskEntry = observer(({ task }: { task: BackgroundTask }) => {
  const backgroundTasksStore = useBackgroundTasksContext()
  let modal: ReturnType<typeof Modal.confirm> | null = null

  useEffect(() => () => {
    const completedTaskStatuses: BackgroundTaskStatus[] = ['Done', 'Aborted', 'Error']

    if (modal && completedTaskStatuses.includes(task.status)) {
      modal.update({ visible: false })
      modal.destroy()
    }
  }, [modal, task.status])

  const confirmTaskAbort = (id: string) => {
    modal = Modal.confirm({
      width: 368,
      title: 'Process interruption',
      icon: <ExclamationCircleFilled className="text-xl !leading-0" />,
      content: 'Are you sure you want to interrupt the process?',
      okText: 'Interrupt',
      okType: 'primary',
      okButtonProps: { size: 'large' },
      cancelText: 'Cancel',
      cancelButtonProps: { ghost: true, size: 'large' },
      onOk: () => backgroundTasksStore.deleteTask(id),
      getContainer: () => document.getElementById(DROPDOWN_ID) || document.body,
    })
  }

  const isConfirmButtonDisabled = !task.taskId

  return (
    <div className="background-task-grid text-base mb-2 last:mb-0 leading-[normal]">
      {
        match(task.status)
          .with('Initial', 'Polling', () => <Spin className="flex justify-center" size="small" />)
          .with('Done', () => <CheckCircleFilled className="text-primary h-[18px]" />)
          .with('Error', () => <ExclamationCircleFilled className="text-danger h-[18px]" />)
          .otherwise(() => <span />)
      }
      <span className="ml-1 text-gray-40">{getTaskType(task.type)}:</span>
      <span className="truncate">{getTaskDescription(task)}</span>
      <span className="justify-self-end mr-2">
        {
          match(task.status)
            .with('Initial', 'Polling', () => `${Math.round(task.progress)}%`)
            .with('Error', () => 'Error')
            .otherwise(() => null)
        }
      </span>
      <div className="justify-self-end text-xs text-gray-60 dark:text-gray-30 cursor-pointer h-6">
        {
          match(task.status)
            .with('Initial', 'Polling', () => (
              <button
                type="button"
                disabled={isConfirmButtonDisabled}
                onClick={() => confirmTaskAbort(task.id)}
              >
                {
                  match(task.type)
                    .with('bulk-edit', () => null)
                    .otherwise(() => <Stop className={cn('h-5 w-5', { 'text-gray-30': isConfirmButtonDisabled })} />)
                }
              </button>
            ))
            .otherwise(() => (
              <Close
                className="h-5 w-5"
                onClick={() => backgroundTasksStore.deleteTask(task.id)}
              />
            ))
        }
      </div>
    </div>
  )
})

const BackgroundTasksIndicator = observer(() => {
  const backgroundTasksStore = useBackgroundTasksContext()
  const { tasks, tasksCount, runningTasksCount } = backgroundTasksStore
  useTaskProgressMessages()

  useEffect(() => {
    const dispose = autorun(() => {
      const mergeTasksInProgress = backgroundTasksStore.mergeTasks.filter(isTaskRunning)
      const copyTasksInProgress = backgroundTasksStore.copyTasks.filter(isTaskRunning)

      const mergingFiles = mergeTasksInProgress
        .reduce<string[]>((acc, el) => {
          acc.push(...el.indexes)
          return acc
        }, [])

      const mergeResultFiles = mergeTasksInProgress
        .map(task => task.mergeResultFileId)
        .filter(id => id.length > 0)
      const copyResultFiles = copyTasksInProgress
        .map(task => task.copyResultFileId)
        .filter(id => id.length > 0)

      filesStore.mergingFiles = new Set(mergingFiles)
      filesStore.mergeResultFiles = new Set([...mergeResultFiles, ...copyResultFiles])
    })

    return () => dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const removeCompletedListener = subscribeBackgroundTask('background:completed', async task => {
      if (task.type === 'export') {
        const { fileId, taskId } = task
        const { token } = await apiService.get<{ token: string }>(`/index/${fileId}/download-token`)
        const url = createBackendRequestUrl(`/index/${fileId}/download/${taskId}?token=${token}`)

        downloadFileByUrl({ url, fullName: getExportTaskFileName(task) })
      }
    })

    return () => {
      removeCompletedListener()
    }
  }, [backgroundTasksStore])

  const tasksStatuses = {
    isSomeTaskInProgress: runningTasksCount > 0,
    isSomeTaskWithError: tasks.some(task => task.status === 'Error'),
    areAllTasksWithErrors: tasks.every(task => task.status === 'Error'),
    areAllTasksCompleted: tasks.every(task => task.status === 'Done'),
  }

  if (tasksCount === 0) return null

  const menu = (
    <Menu
      id={DROPDOWN_ID}
      className="w-[410px] p-0 dark:bg-gray-80 dark:text-white overflow-y-hidden"
      selectable={false}
    >
      <div className="py-4 pl-4">
        <div className="text-base font-bold mb-3">Current processes:</div>
        <div className="pr-4 max-h-[16rem] overflow-y-auto">
          {backgroundTasksStore.tasks.slice().reverse().map(task => <BackgroundTaskEntry key={task.id} task={task} />)}
        </div>
      </div>
    </Menu>
  )

  return (
    <div className="mr-7 flex items-center text-gray-40">
      {
        match(tasksStatuses)
          .when(({ isSomeTaskInProgress }) => isSomeTaskInProgress, () => (
            <>
              <Spin className="leading-0" size="small" />
              <span className="ml-2">{runningTasksCount} process{pluralEnd(runningTasksCount, 'es')} in progress</span>
            </>
          ))
          .when(({ areAllTasksWithErrors }) => areAllTasksWithErrors, () => (
            <>
              <ExclamationCircleFilled className="text-danger" />
              <span className="ml-2">Process error</span>
            </>
          ))
          .when(({ isSomeTaskWithError }) => isSomeTaskWithError, () => (
            <>
              <ExclamationCircleFilled className="text-danger" />
              <span className="ml-2">Some processes completed with error</span>
            </>
          ))
          .when(({ areAllTasksCompleted }) => areAllTasksCompleted, () => (
            <>
              <CheckCircleFilled className="text-primary" />
              <span className="ml-2">All processes are completed</span>
            </>
          ))
          .run()
      }
      <Dropdown
        overlay={menu}
        trigger={['click']}
      >
        <EllipsisOutlined className="text-2xl ml-2" />
      </Dropdown>
    </div>
  )
})

export default BackgroundTasksIndicator
