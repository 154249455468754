import { ReactElement, useMemo, useState } from 'react'
import { Form, Select } from 'antd'
import { observer } from 'mobx-react'

import { DeleteFilled } from '@ant-design/icons'

interface PropertyItemFilterProps {
  filterId: string
  filterIndex: number
  columnsSelectOptions: AntSelectOptions[]
  deleteFilter: (filterIndex: number, filterId: string) => void
  insertPropertyName: (propertyName: string, filterIndex: number, filterId: string) => void
}

const PropertyItemFilter = observer(({
  filterId,
  filterIndex,
  columnsSelectOptions,
  deleteFilter,
  insertPropertyName,
}: PropertyItemFilterProps): ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('')

  const filteredColumnsSelectOptions = useMemo(
    () => {
      const searchValueInLowerCase = searchValue.toLocaleLowerCase()

      return columnsSelectOptions
        .filter(option => option.label.toLocaleLowerCase()
          .includes(searchValueInLowerCase))
    },
    [columnsSelectOptions, searchValue],
  )

  const handleSelect = (name: string, index: number, id: string) => {
    setSearchValue('')
    insertPropertyName(name, index, id)
  }

  return (
    <div className="flex items-baseline">
      <Form.Item
        name={`filterName${filterId}`}
        className="w-full max-w-[240px]"
        rules={[
          {
            required: true,
            message: 'Please enter a column name',
          },
        ]}
      >
        <Select
          showSearch
          options={filteredColumnsSelectOptions}
          onSelect={(name: string) => handleSelect(name, filterIndex, filterId)}
          onSearch={(value: string) => setSearchValue(value)}
        />
      </Form.Item>

      <DeleteFilled
        className="ml-2 cursor-pointer text-gray-40 hover:text-danger"
        onClick={() => deleteFilter(filterIndex, filterId)}
      />
    </div>
  )
})

export default PropertyItemFilter
