interface Reporter {
  alive(): boolean
}

export default class UserActivityRegistry {
  private reporters: Reporter[] = []

  register(reporter: Reporter): void {
    this.reporters.push(reporter)
  }

  unregister(reporter: Reporter): void {
    this.reporters = this.reporters.filter(r => r !== reporter)
  }

  clear(): void {
    this.reporters = []
  }

  get alive(): boolean {
    return this.reporters.some(reporter => reporter.alive())
  }
}
