import { useCallback } from 'react'
import { Checkbox } from 'antd'
import { observer } from 'mobx-react'

interface AgGridLineNumberWithCheckboxProps {
  isSelected: boolean
  lineNumber: number
  onChange: (isChecked: boolean) => void
}

const AgGridLineNumberWithCheckbox:
  React.FC<AgGridLineNumberWithCheckboxProps> = observer(({ isSelected, lineNumber, onChange }) => {
    const handleChange = useCallback(() => onChange(!isSelected), [onChange, isSelected])
    return (
      <div className="flex flex-row">
        <Checkbox checked={isSelected} onClick={handleChange} />
        <span className="ml-3">{lineNumber}</span>
      </div>
    )
  })

export default AgGridLineNumberWithCheckbox

