import { GetContextMenuItemsParams } from 'ag-grid-community'

// eslint-disable-next-line import/prefer-default-export
export const prepareRowDataForExport = (
  params: GetContextMenuItemsParams,
  rows: ExtendedRowNodeWithoutStructure<ExtendedRowNode>[],
): ExtendedRowNodeWithoutStructure<ExtendedRowNode>[] => rows.map((
  rowData: ExtendedRowNodeWithoutStructure<ExtendedRowNode>,
  index,
) => {
  const sortedColumns = params.columnApi.getColumns()

  const rowDataForExport = sortedColumns?.reduce<ExtendedRowNodeWithoutStructure<ExtendedRowNode>>((acc, column) => {
    const colId = column.getColId()

    if (colId === '#') {
      acc[colId] = index + 1
    } else if (colId === 'Structure') {
      acc.structure = rowData.Structure
    } else {
      acc[colId] = rowData[colId]
    }
    return acc
  }, {})

  return rowDataForExport
})
