import { ReactElement } from 'react'
import { Tooltip } from 'antd'

import Lock from '@components/icons/Lock'
import QuestionInCircle from '@components/icons/QuestionInCircle'

const ReadOnlyState = (): ReactElement => (
  <div className="flex flex-row items-center">
    <Lock className="mr-1 text-primary inline" />
    <span className="mr-1 text-sm">Read only</span>
    <Tooltip title="Index has 'Read only' state if it is shared or some processes are not completed">
      <div>
        <QuestionInCircle className="text-gray-40 cursor-pointer" />
      </div>
    </Tooltip>
  </div>
)

export default ReadOnlyState
