/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const CaretUp = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className={cn('sdf-icon fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M28.1953 22.222L16.6505 8.835C16.32 8.45181 15.6837 8.45181 15.3498 8.835L3.80492 22.222C3.37603 22.7211 3.76273 23.4524 4.45528 23.4524H27.545C28.2375 23.4524 28.6242 22.7211 28.1953 22.222Z" />
  </svg>
)

export default CaretUp
