import { makeAutoObservable } from 'mobx'

import filesStore from '@store/files'
import userStore from '@store/user'
import { isAuthError } from '@shared/services/api-service'
import getComponentsVersions from '@utils/http/versions'
import UserActivityRegistry from '@utils/userActivityRegistry'
import windowEventsActivityStore from '@utils/windowEventsActivityStore'

const INACTIVITY_TIMEOUT = 30 * 60 * 1000
const INTERVAL_TIMEOUT = 60 * 1000
const LAST_TIME_ALIVE = 'lastTimeAlive'

class AppStore {
  private _version = localStorage.getItem('version') ?? ''
  private _theme: Theme = (localStorage.getItem('theme') ?? 'dark') as Theme
  private isWindowPrefersDarkScheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  private inactivityTimer: ReturnType<typeof setInterval> | null = null
  public componentVersions: ComponentVersions = {
    indigoVersion: 'n/a',
    qdpApiVersion: 'n/a',
    elasticSearchVersion: 'n/a',
    databaseVersion: 'n/a',
    sdfProFEVersion: 'n/a',
    sdfProBEVersion: 'n/a',
    jvmVersion: 'n/a',
    akkaVersion: 'n/a',
  }

  readonly userActivityRegistry = new UserActivityRegistry()

  constructor() {
    makeAutoObservable(this)
  }

  async getComponentsVersions() {
    try {
      const versions = await getComponentsVersions()
      this.componentVersions = versions
    } catch (error) {
      if (isAuthError(error)) return

      throw error
    }
  }

  startInactivityTimer() {
    if (this.inactivityTimer) return

    windowEventsActivityStore.setListeners()
    this.userActivityRegistry.register(windowEventsActivityStore)
    this.userActivityRegistry.register(filesStore)

    localStorage.setItem(LAST_TIME_ALIVE, JSON.stringify(Date.now()))

    this.inactivityTimer = setInterval(() => {
      const now = Date.now()

      const { alive } = this.userActivityRegistry

      if (alive) {
        localStorage.setItem(LAST_TIME_ALIVE, JSON.stringify(now))
      }

      const lastTimeAlive = JSON.parse(localStorage.getItem(LAST_TIME_ALIVE) || 'null') || now
      const timePassed = now - lastTimeAlive

      if (timePassed > INACTIVITY_TIMEOUT) {
        this.clearInactivityTimer()
        userStore.signOut()
      }
    }, INTERVAL_TIMEOUT)
  }

  clearInactivityTimer() {
    if (this.inactivityTimer) clearInterval(this.inactivityTimer)
    this.inactivityTimer = null
    this.userActivityRegistry.clear()
    windowEventsActivityStore.clearListeners()
  }

  public themeAutoDetect(): void {
    const cachedTheme = localStorage.getItem('theme')

    if (cachedTheme) this.setTheme(cachedTheme as 'dark' | 'light')
    else if (this.isWindowPrefersDarkScheme) this.setTheme('dark')
    else this.setTheme('light')
  }

  public toggleTheme(): void {
    const isCurrentThemeDark = document.documentElement.classList.contains('dark')
    const isCurrentThemeLight = document.documentElement.classList.contains('light')
    const isThemeUnset = !isCurrentThemeDark && !isCurrentThemeLight
    let themeName: Theme

    if (isThemeUnset) {
      themeName = this.isWindowPrefersDarkScheme ? 'dark' : 'light'
    } else {
      themeName = isCurrentThemeDark ? 'light' : 'dark'
    }

    this.setTheme(themeName)
  }

  public setTheme(theme: Theme, saveTheme = true): void {
    document.documentElement.classList.add(theme)

    if (theme === 'dark') {
      this.removeThemeHtmlClass('light')
    } else {
      this.removeThemeHtmlClass('dark')
    }

    if (saveTheme) {
      this._theme = theme
      localStorage.setItem('theme', theme)
    }
  }

  public restoreCurrentTheme(): void {
    this.setTheme(this._theme)
  }

  private removeThemeHtmlClass(theme: Theme): void {
    document.documentElement.classList.remove(theme)
  }

  get version(): string {
    return this._version
  }

  get theme(): Theme {
    return this._theme
  }
}

export default new AppStore()
