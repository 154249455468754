/* eslint-disable max-len */
import { ReactElement } from 'react'
import cn from 'classnames'

const RemoveDuplicates = ({ className, onClick }: ElementProps): ReactElement => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={cn('fill-current', className)}
  >
    <path d="M6 1.5H12C13.6569 1.5 15 2.84315 15 4.5V10.5" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 6C1.5 4.89543 2.39543 4 3.5 4H10.5C11.6046 4 12.5 4.89543 12.5 6V13C12.5 14.1046 11.6046 15 10.5 15H3.5C2.39543 15 1.5 14.1046 1.5 13V6ZM3.81946 12.3731C3.55097 12.1046 3.55097 11.6693 3.81946 11.4008L5.764 9.45628L3.81947 7.51174C3.55098 7.24326 3.55098 6.80796 3.81947 6.53947C4.08795 6.27099 4.52325 6.27099 4.79174 6.53947L6.73627 8.48401L8.68082 6.53946C8.9493 6.27098 9.3846 6.27098 9.65309 6.53946C9.92158 6.80795 9.92158 7.24325 9.65309 7.51173L7.70855 9.45628L9.6531 11.4008C9.92158 11.6693 9.92158 12.1046 9.6531 12.3731C9.38461 12.6416 8.94931 12.6416 8.68083 12.3731L6.73627 10.4285L4.79173 12.3731C4.52325 12.6416 4.08794 12.6416 3.81946 12.3731Z"
    />
  </svg>
)

export default RemoveDuplicates
