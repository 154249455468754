import { createNanoEvents } from 'nanoevents'

import { fetchSubscriptionDetails } from '@utils/http/subscription'
import loadScript from '@utils/loadScript'
import apiService from './api-service'

interface ProrateCostsPreview {
  immediatePayment: {
    amount: string
  }
}

interface StartCheckoutParams {
  email?: string
  product?: number
  override?: string
  passthrough?: string
}

export type CheckoutEvent =
  | 'checkout.success'
  | 'checkout.complete'

export type CheckoutEventsCallbacks = {
  [key in CheckoutEvent]?: () => void
}

const config: PaddleCheckoutOption = {
  method: 'overlay',
  disableLogout: true,
}

const PADDLE_NOT_READY_ERROR = 'Something wrong with Paddle. Try again later.'

class PaddleService {
  private isLoaded = false
  private isCheckoutInProgress = false
  private emitter = createNanoEvents<CheckoutEventsCallbacks>()

  readonly vendor = Number.parseInt(window.SDFEConfig.REACT_APP_PADDLE_VENDOR_ID || '', 10)
  readonly isSandbox = window.SDFEConfig.REACT_APP_PADDLE_IS_SANDBOX === 'true'

  on<Event extends keyof CheckoutEventsCallbacks>(event: Event, callback: CheckoutEventsCallbacks[Event]) {
    return this.emitter.on(event, callback)
  }

  private init = async () => {
    try {
      if (this.isLoaded) return

      await loadScript('https://cdn.paddle.com/paddle/paddle.js')
      this.setup()

      this.isLoaded = true
    } catch (error) {
      throw new Error(PADDLE_NOT_READY_ERROR)
    }
  }

  private setup = () => {
    if (this.isSandbox) window.Paddle.Environment.set('sandbox')

    window.Paddle.Setup({
      vendor: this.vendor,
      eventCallback: ({ event, eventData }: PaddleSetupEventCallbackParams) => {
        if (event === 'Checkout.Close') {
          this.isCheckoutInProgress = false
        }

        if (event === 'Checkout.Complete') {
          this.emitter.emit('checkout.success')
        }

        // not sure if eventData always has checkout.completed
        // there is no docs for this
        if (event === 'Checkout.Close' && eventData?.checkout?.completed === true) {
          this.emitter.emit('checkout.complete')
        }
      },
    })
  }

  private loadOverlay = (params: StartCheckoutParams) => {
    window.Paddle.Checkout.open({
      ...config,
      ...params,
    })
  }

  cancelSubscription = async () => {
    if (this.isCheckoutInProgress) return

    this.isCheckoutInProgress = true

    try {
      await this.init()

      const { cancelUrl } = await fetchSubscriptionDetails()

      if (!cancelUrl) {
        throw new Error('Cancel url not found')
      }

      this.loadOverlay({ override: cancelUrl })
    } catch (error) {
      this.isCheckoutInProgress = false

      throw error
    }
  }

  changePaymentMethod = async () => {
    if (this.isCheckoutInProgress) return

    this.isCheckoutInProgress = true

    try {
      await this.init()

      const { updateUrl } = await fetchSubscriptionDetails()

      if (!updateUrl) {
        throw new Error('Update url not found')
      }

      this.loadOverlay({ override: updateUrl })
    } catch (error) {
      this.isCheckoutInProgress = false

      throw error
    }
  }

  // this method handles subscription with and without trial period
  createSubscription = async ({ email, product }: { email: string, product: number }) => {
    if (this.isCheckoutInProgress) return

    this.isCheckoutInProgress = true

    try {
      await this.init()

      const { url } = await apiService.post<{ url: string }>('/subscription/paddle/paylink', {
        json: {
          productId: product,
          customerEmail: email,
        },
      })

      this.loadOverlay({ override: url, passthrough: email })
    } catch (error) {
      this.isCheckoutInProgress = false

      throw error
    }
  }

  changeSubscription = async ({ email, product }: { email: string, product: number }) => {
    await apiService.post<{ url: string }>('/subscription/paddle/update', {
      json: {
        newPlanId: product,
        cognitoEmail: email,
      },
    })
  }

  previewProrateCosts = async ({ email, product }: { email: string, product: number }) => {
    const { immediatePayment } = await apiService.post<ProrateCostsPreview>('/subscription/paddle/update-preview', {
      json: {
        newPlanId: product,
        cognitoEmail: email,
      },
    })

    return Number.parseFloat(immediatePayment.amount)
  }
}

export default new PaddleService()
