const CalculatedColumnIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33333 15H5.08333V12.6667H7.41667V10.9167H5.08333V8.58333H3.33333V10.9167H1V12.6667H3.33333V15ZM9.16667
      14.125H15V12.375H9.16667V14.125ZM9.16667 11.2083H15V9.45833H9.16667V11.2083ZM10.45 6.775L12.0833 5.14167L13.7167
      6.775L14.9417 5.55L13.3083 3.85833L14.9417 2.225L13.7167 1L12.0833 2.63333L10.45 1L9.225
      2.225L10.8583 3.85833L9.225
      5.55L10.45 6.775ZM1.29167 4.73333H7.125V2.98333H1.29167V4.73333Z"
      fill="#989EAA"
    />
  </svg>
)

export default CalculatedColumnIcon
