import {
  ChangeEvent, KeyboardEvent, ReactElement, useEffect, useMemo, useState,
} from 'react'
import { useDebounceFn } from 'ahooks'
import {
  Button, Form, Input, Modal,
} from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react'

import {
  CloseOutlined, InfoCircleOutlined, SearchOutlined, UndoOutlined,
} from '@ant-design/icons'
import useBackgroundTasksContext from '@contexts/background-tasks'
import { useAgGridApiService, useFileStore } from '@contexts/file-edit-context'
import pluralEnd from '@utils/plural-end'
import { inputNumberValidator } from '@utils/validators'

interface ChangeCandidate {
  name: string
  value?: MolpropertyValue
  initialValue?: MolpropertyValue
  isMixed?: boolean
  isForceClear?: boolean
  type: IndexPropertyType
}

interface BulkEditProps {
  closeParent?: () => void
}

const FilteredColumn = ({
  property, onChange, onKeyUp, onReset,
}: {
  property: ChangeCandidate
  onChange: (property: ChangeCandidate, value?: MolpropertyValue | undefined, isForceClear?: boolean) => void
  onKeyUp: (e: KeyboardEvent, property: ChangeCandidate) => void
  onReset: (property: ChangeCandidate) => void
}): ReactElement => {
  const { run: handleChange } = useDebounceFn(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(property, e.target.value)
    },
    { wait: 100 },
  )

  const { run: handleKeyUp } = useDebounceFn(
    e => {
      onKeyUp(e, property)
    },
    { wait: 100 },
  )

  return (
    <div className="flex">
      <div
        className="text-gray-40 py-1.5 truncate flex-shrink-0"
        style={{ width: 220 }}
        title={property.name}
      >
        {property.name}
      </div>

      <Form.Item
        className="flex items-center py-1.5 mb-1 w-full mx-3"
        name={property.name}
        normalize={property.type !== 'STRING' ? inputNumberValidator : undefined}
        rules={[
          property.type !== 'STRING' ? {
            pattern: /^[-]?\d+(\.\d+)?$/,
            message: 'This value is not a valid number.',
          } : {},
        ]}
      >
        <Input
          defaultValue={property.value || ''}
          size="small"
          type="text"
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder={property.isMixed && !property.isForceClear ? 'Mixed' : ''}
          suffix={(
            <UndoOutlined
              role="button"
              className={cn(
                (property.isMixed && property.isForceClear) || (property.initialValue !== property.value)
                  ? 'hover:text-primary'
                  : 'hidden',
              )}
              onClick={() => onReset(property)}
            />
          )}
        />
      </Form.Item>
    </div>
  )
}

const BulkEdit = ({ closeParent }: BulkEditProps) => {
  const fileStore = useFileStore()
  const agGridApiService = useAgGridApiService()
  const backgroundTasksStore = useBackgroundTasksContext()
  const [form] = Form.useForm()
  const [searchValue, setSearchValue] = useState('')
  const [changed, setChanged] = useState<{ count: number, properties: Molproperties }>({ count: 0, properties: {} })

  const defaultChangeCandidates = useMemo<ChangeCandidate[]>(() => {
    const selectedMolecules = agGridApiService.getSelectedRows<MoleculeRow>()

    return fileStore.indexProperties.map(prop => {
      const allValuesEqual = selectedMolecules
        .every((mol, i, arr) => mol[prop.name] === arr[0][prop.name])

      const initialValue: MolpropertyValue = allValuesEqual ? selectedMolecules[0][prop.name] : ''

      return {
        name: prop.name,
        initialValue,
        value: initialValue,
        isMixed: !allValuesEqual,
        isForceClear: false,
        type: prop.type,
      }
    })
  }, [agGridApiService, fileStore.indexProperties])

  const [propertiesChangeCandidates, setPropertiesChangeCandidates] = useState(defaultChangeCandidates)

  const filteredColumns = useMemo(
    () => {
      const searchValueLowCase = searchValue.toLocaleLowerCase().trim()
      return propertiesChangeCandidates
        .filter(col => col.name.toLocaleLowerCase().trim().includes(searchValueLowCase))
    },
    [propertiesChangeCandidates, searchValue],
  )

  const recalculateChanged = () => {
    setChanged(
      propertiesChangeCandidates.reduce((prev, current) => {
        const isChanged = current.initialValue !== current.value || (current.isMixed && current.isForceClear)

        const properties = isChanged ? {
          [current.name]: current.value,
        } : {}

        return {
          count: prev.count + (isChanged ? 1 : 0),
          properties: {
            ...prev.properties,
            ...properties,
          },
        }
      }, { count: 0, properties: {} }),
    )
  }

  useEffect(recalculateChanged, [propertiesChangeCandidates])

  const editValue = (
    property: typeof defaultChangeCandidates[0],
    value?: MolpropertyValue,
    isForceClear = false,
  ) => {
    let newValue = value

    if (property.type !== 'STRING') {
      newValue = inputNumberValidator(newValue, property.value)
    }

    setPropertiesChangeCandidates(
      localCandidates => localCandidates.map(
        candidate => {
          if (candidate.name !== property.name) return candidate
          return { ...candidate, value: newValue, isForceClear }
        },
      ),
    )
  }

  const onPropertyInputKeyUp = (e: KeyboardEvent, property: typeof defaultChangeCandidates[0]) => {
    if (property.isMixed && ['Backspace', 'Delete'].includes(e.key)) {
      editValue(property, (e.target as HTMLInputElement).value, true)
    }
  }

  const handleReset = (property: typeof defaultChangeCandidates[0]) => {
    form.resetFields([property.name])
    editValue(property, property.initialValue)
  }

  const onCancel = () => {
    if (closeParent) closeParent()
  }

  const onApply = () => {
    backgroundTasksStore.createTask({
      type: 'bulk-edit',
      data: {
        indexId: fileStore.fileId,
        properties: changed.properties,
        selectedMolecules: fileStore.selectedMoleculesParams,
      },
    })

    if (closeParent) closeParent()
  }

  const applyConfirm = () => {
    const fileCount = fileStore.selectedMoleculeCustomOrdersCount

    Modal.confirm({
      title: 'Edit rows',
      icon: <InfoCircleOutlined className="!text-primary" />,
      content: `Are you sure you want to change ${changed.count} ${changed.count === 1 ? 'property' : 'properties'} in ${fileCount} row${pluralEnd(fileCount)}?`,
      width: 480,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'Cancel',
      cancelButtonProps: { type: 'text' },
      onOk: onApply,
    })
  }

  return (
    <>
      <Input
        prefix={<SearchOutlined />}
        suffix={<CloseOutlined onClick={() => setSearchValue('')} />}
        value={searchValue}
        placeholder="Search columns"
        className="mb-6"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
      />

      <div className="mb-4 dark:text-gray-30">Change data in the selected rows</div>

      <Form
        size="small"
        onFinish={applyConfirm}
        form={form}
      >
        <div className="w-full h-56 overflow-auto">
          {filteredColumns.length === 0 && (
            <div className="flex w-full justify-center">There is nothing to show</div>
          )}

          {filteredColumns.map(property => (
            <FilteredColumn
              key={property.name}
              property={property}
              onChange={editValue}
              onKeyUp={onPropertyInputKeyUp}
              onReset={handleReset}
            />
          ))}

        </div>

        <div className="flex justify-end mt-5">
          <Button type="text" onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            htmlType="submit"
            className="ml-2"
            disabled={changed.count === 0}
          >
            Apply
          </Button>
        </div>
      </Form>
    </>
  )
}

export default observer(BulkEdit)
