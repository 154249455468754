import { GridApi } from 'ag-grid-community'

// eslint-disable-next-line import/prefer-default-export
export const getRowsFromDataGrid = (
  gridApi?: GridApi,
  rowsCount = 0,
): ExtendedRowNodeWithoutStructure<ExtendedRowNode>[] => {
  const allRowData = Array.from({ length: rowsCount }, (_, i) => {
    const rowNode = gridApi?.getDisplayedRowAtIndex(i)
    return rowNode ? rowNode.data : null
  }).filter(Boolean)

  return allRowData
}
