/* eslint-disable no-param-reassign */
import { makeAutoObservable, runInAction } from 'mobx'
import { match } from 'ts-pattern'

import FileStore from '@store/file'
import apiService from '@shared/services/api-service'
import emitter from './emitter'
import AddColumnTask from './tasks/add-column-task'
import BulkEditTask from './tasks/bulk-edit-task'
import ConvertTask from './tasks/convert-task'
import CopyTask from './tasks/copy-task'
import DataExportTask from './tasks/data-export-task'
import ExportTask from './tasks/export-task'
import MergeTask from './tasks/merge-task'
import RangeSelectionTask from './tasks/range-selection-task'
import RemoveDuplicationsTask from './tasks/remove-duplicate-task'
import {
  BackgroundTask, BeforeFilterRestoreTaskData, NewBackgroundTaskProps, RestoreTaskData,
} from './types'
import {
  isBulkEditTask, isConvertTask,
  isCopyTask,
  isDataExportTask, isExportTask, isMergeTask, isRemoveDuplicationsTask, isTaskRunning,
} from './utills'

export class BackgroundTasksStore {
  private _tasks: BackgroundTask[] = []

  constructor() {
    makeAutoObservable<BackgroundTasksStore>(this)
  }

  private async initPollingRequest({ taskId, step, abortController }: {
    taskId: string
    step: (percent: number) => void
    abortController: AbortController
  }): Promise<TaskProgress> {
    const validate = ({ status, percent }: TaskProgress) => {
      step(percent)

      return status === 'COMPLETED_WITH_ERROR' || status === 'COMPLETED'
    }

    const response = await apiService.createPollingRequest({
      url: `/task/${taskId}/status`,
      options: {
        signal: abortController.signal,
      },
      interval: 3000,
      validate,
    })

    if (response.status === 'COMPLETED_WITH_ERROR') throw new Error(response.messages.join(', '))

    return response
  }

  private actualizeTaskResult = async (task: BackgroundTask): Promise<void> => {
    try {
      await task.fetchResult()
      emitter.emit('background:resultSuccess', task)
    } catch (error) {
      emitter.emit('background:resultFailed', task, error)
      throw Error
    }
  }

  private startTask = async (task: BackgroundTask): Promise<void> => {
    let currentTaskId = task.taskId

    try {
      if (task.status === 'Initial') {
        emitter.emit('background:started', task)
        const { status, taskId } = await task.fetchTaskId()
        currentTaskId = taskId

        const taskStatus = status === 'COMPLETED' ? 'Done' : 'Polling'

        if (taskStatus === 'Done') {
          await this.actualizeTaskResult(task)
        }

        runInAction(() => {
          task.taskId = currentTaskId
          task.status = taskStatus
        })
      }

      if (task.status === 'Polling' && currentTaskId) {
        await this.initPollingRequest({
          taskId: currentTaskId,
          step: percent => runInAction(() => {
            task.progress = percent * 100
          }),
          abortController: task.abortController,
        })

        await this.actualizeTaskResult(task)
        runInAction(() => { task.status = 'Done' })
      }

      if (task.status === 'Done') {
        runInAction(() => {
          task.progress = 100
        })

        emitter.emit('background:completed', task)
        emitter.emit('background:settled', task)
      }
    } catch (error) {
      const wasAborted = error instanceof Error && error.name === 'AbortError'

      if (wasAborted) {
        runInAction(() => { task.status = 'Aborted' })
        emitter.emit('background:aborted', task)
      } else {
        runInAction(() => { task.status = 'Error' })
        emitter.emit('background:error', task, error)
      }

      emitter.emit('background:settled', task, error)
    }
  }

  // NOTE: some details may not contain data needed to restore task precisely or data fields may have different type.
  // in this case default values are used.
  // it is done because not all data is necessary to process task when it is completed.
  private restoreTask = (data: RestoreTaskData, fileStore: FileStore): BackgroundTask => {
    const { taskId, currentStatus } = data
    const task = match(data)
      .with({ type: 'Export' }, ({ details }) => new ExportTask({ ...details, fileId: details.fileId }))
      .with({ type: 'Copy' }, ({ details }) => {
        const copyTask = new CopyTask({
          ...details,
          fileId: details.fileIds,
        })

        copyTask.copyResultFileId = details.fileId
        return copyTask
      })
      .with({ type: 'Merge' }, ({ details }) => {
        const {
          fileId, fileName, fileIds, withProperties, withDeduplication,
        } = details

        const mergeTask = new MergeTask({
          fileName,
          withProperties: withProperties === 'true',
          fileIds: fileIds.split(','),
          withDeduplication,
        })

        mergeTask.mergeResultFileId = fileId
        return mergeTask
      })
      .with({ type: 'BulkUpdate' }, ({ details }) => {
        const {
          defaultValue, deleted, fileId, fileName, hidden, isFormula, name, type, id,
        } = details

        const column: IndexProperty = {
          defaultValue,
          deleted: deleted === 'true',
          hidden: hidden === 'true',
          name,
          type,
          weblink: false,
          id,
        }

        return new AddColumnTask({
          column,
          formula: isFormula === 'true' ? name : '',
          fileId,
          fileName,
          fileStore,
        })
      })
      .with({ type: 'BulkEdit' }, ({ details }) => new BulkEditTask({
        ...details,
        properties: {},
      }))
      .with({ type: 'Deduplication' }, ({ details }) => new RemoveDuplicationsTask(details))
      .with({ type: 'RangeSelection' }, ({ details }) => new RangeSelectionTask(details))
      .with({ type: 'DataExport' }, ({ details }) => new DataExportTask(details))
      .with({ type: 'Convert' }, ({ details }) => new ConvertTask(details))
      .exhaustive()

    task.taskId = taskId
    task.status = match<TaskStatus, BackgroundTaskStatus>(currentStatus)
      .with('IN_PROGRESS', 'INITIATED', () => 'Polling')
      .with('COMPLETED', () => 'Done')
      .with('COMPLETED_WITH_ERROR', () => 'Error')
      .exhaustive()

    return task
  }

  createTask = (taskProps: NewBackgroundTaskProps): void => {
    const task = this.createInstance(taskProps)

    this.startTask(task)
    this._tasks.push(task)
  }

  abortTask = (task: BackgroundTask): void => {
    task.abortController.abort()
  }

  markAsDisplayedDeduplicationTask(id: string): void {
    const deduplicationTask = this.tasks.find(task => task.id === id) as unknown as RemoveDuplicationsTask

    if (deduplicationTask) {
      runInAction(() => {
        deduplicationTask.displayed = true
      })
    }
  }

  deleteTask = (id: string): void => {
    const task = this._tasks.find(t => t.id === id)

    if (!task?.id) {
      return
    }

    if (['Initial', 'Polling'].includes(task.status)) {
      this.abortTask(task)

      const url = match(task.type)
        .with('merge', () => 'merge')
        .with('copy', () => 'merge')
        .with('add-column', () => 'index/property')
        .with('convert', () => 'index/property')
        .otherwise(() => 'task')

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      apiService.delete(`/${url}/${task.taskId}`).catch(() => {})
    }

    this._tasks = this._tasks.filter(t => t.id !== id)
  }

  getTaskById(id: string): BackgroundTask | null {
    const task = this.tasks.find(t => t.id === id)

    return task || null
  }

  private createInstance = (newTaskProps: NewBackgroundTaskProps): BackgroundTask => match(newTaskProps)
    .with({ type: 'export' }, ({ data }) => new ExportTask(data))
    .with({ type: 'copy' }, ({ data }) => new CopyTask(data))
    .with({ type: 'merge' }, ({ data }) => new MergeTask(data))
    .with({ type: 'add-column' }, ({ data }) => new AddColumnTask(data))
    .with({ type: 'bulk-edit' }, ({ data }) => new BulkEditTask(data))
    .with({ type: 'remove-duplications' }, ({ data }) => new RemoveDuplicationsTask(data))
    .with({ type: 'range-selection' }, ({ data }) => new RangeSelectionTask(data))
    .with({ type: 'data-export' }, ({ data }) => new DataExportTask(data))
    .with({ type: 'convert' }, ({ data }) => new ConvertTask(data))
    .exhaustive()

  restoreTasks = async (fileStore: FileStore): Promise<void> => {
    try {
      const tasks = await apiService.get<BeforeFilterRestoreTaskData[]>('/task/')
      // eslint-disable-next-line max-len
      const filteredTasks: RestoreTaskData[] = tasks.filter(({ currentStatus, type }) => currentStatus === 'INITIATED' || (currentStatus === 'IN_PROGRESS' && type !== 'Upload')) as RestoreTaskData[]

      runInAction(() => {
        this._tasks = filteredTasks.map(data => this.restoreTask(data, fileStore))
      })

      this._tasks.forEach(task => this.startTask(task))
    } catch (error) {
      runInAction(() => {
        this._tasks = []
      })
    }
  }

  alive(): boolean {
    return this._tasks.length > 0
  }

  get tasks(): BackgroundTask[] {
    return this._tasks
  }

  get exportTasks(): ExportTask[] {
    return this._tasks.filter(isExportTask)
  }

  get dataExportTasks(): DataExportTask[] {
    return this._tasks.filter(isDataExportTask)
  }

  get mergeTasks(): MergeTask[] {
    return this._tasks.filter(isMergeTask)
  }

  get bulkEditTasks(): BulkEditTask[] {
    return this._tasks.filter(isBulkEditTask)
  }

  get convertTasks(): ConvertTask[] {
    return this._tasks.filter(isConvertTask)
  }

  get removeDuplicatesTasks(): RemoveDuplicationsTask[] {
    return this._tasks.filter(isRemoveDuplicationsTask)
  }

  get copyTasks(): CopyTask[] {
    return this._tasks.filter(isCopyTask)
  }

  get tasksCount(): number {
    return this._tasks.length
  }

  get runningTasks(): BackgroundTask[] {
    return this._tasks.filter(isTaskRunning)
  }

  get runningDataExportTasks(): BackgroundTask[] {
    return this.dataExportTasks.filter(isTaskRunning)
  }

  get runningTasksCount(): number {
    return this.runningTasks.length
  }

  get filesIds(): Set<string> {
    const ids = this._tasks.filter(isTaskRunning).reduce<string[]>((acc, task) => {
      const taskIds = match(task)
        .with({ type: 'export' }, ({ fileId: indexId }) => [indexId])
        .with({ type: 'copy' }, ({ fileId: indexId }) => [indexId])
        .with({ type: 'merge' }, ({ indexes }) => indexes)
        .with({ type: 'add-column' }, ({ fileId }) => [fileId])
        .with({ type: 'bulk-edit' }, ({ indexId }) => [indexId])
        .with({ type: 'remove-duplications' }, ({ fileId }) => [fileId])
        .with({ type: 'range-selection' }, ({ fileId }) => [fileId])
        .with({ type: 'data-export' }, ({ fileId }) => [fileId])
        .with({ type: 'convert' }, ({ fileId }) => [fileId])
        .exhaustive()

      acc.push(...taskIds)

      return acc
    }, [])

    return new Set(ids)
  }

  destroy = (): void => {
    this._tasks.forEach(this.abortTask)
  }
}

export default new BackgroundTasksStore()
