import {
  ReactElement, useCallback, useEffect, useMemo, useRef,
  useState,
} from 'react'
import {
  CellClassParams, ColDef, GridApi, ITooltipParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, message, Modal } from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react'

import filesStore from '@store/files'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { AG_GRID_TABLE_ROW_HEIGHT_PX } from '@shared/constants'
import useAgGridThemeClassName from '@shared/hooks/ag-grid/useAgGridThemeClassName'
import { fetchIndexProperties } from '@utils/http'

interface MergeOverviewProps {
  hasDuplicates?: boolean
}

interface MergeOverviewModalProps extends MergeOverviewProps {
  visible: boolean
  onBack: () => void
  onCancel: () => void
  onOk: () => void
}
const MergeOverview = observer(({ hasDuplicates }: MergeOverviewProps): ReactElement => {
  const checkedFiles = useMemo(() => [...filesStore.checkedFiles.keys()], [])
  const [mergingFilesIndexProperties, setMergingFilesIndexProperties] = useState<IndexProperty[][]>([])

  const gridApiRef = useRef<GridApi | null>(null)

  useEffect(() => {
    Promise.all(checkedFiles.map(id => fetchIndexProperties({ id })))
      .then(setMergingFilesIndexProperties)
      .catch(error => message.error(error?.message))
  }, [checkedFiles])

  const columns = useMemo(() => {
    const headerNames = new Map<string, string[]>()

    mergingFilesIndexProperties.forEach((mergingFileIndexProperties, idx) => {
      mergingFileIndexProperties.forEach(mergingFileIndexProperty => {
        const filesIds = headerNames.get(mergingFileIndexProperty.name) ?? []
        filesIds.push(checkedFiles[idx])

        headerNames.set(mergingFileIndexProperty.name, filesIds)
      })
    })

    return [...headerNames].sort(([field1], [field2]) => {
      const first = headerNames.get(field1)
      const second = headerNames.get(field2)

      if (!first || !second) return 0
      return second.length - first.length
    })
  }, [mergingFilesIndexProperties, checkedFiles])

  const columnDefs = useMemo<ColDef[]>(() => [
    {
      headerName: 'File name',
      field: 'filename',
      rowDrag: true,
      pinned: true,
      width: 170,
      tooltipValueGetter: ({ value }: ITooltipParams) => value,
    },
    ...columns.map(([field]) => ({
      field,
      headerTooltip: field,
      width: 120,
      headerName: field,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: ({ value }: any) => (value
        ? <CheckOutlined className="text-primary" /> : <CloseOutlined className="text-danger" />),
      cellClassRules: {
        'cell-loader-placeholder': ({ value }: CellClassParams) => value == null,
        'animate-fade-in-out': ({ value }: CellClassParams) => value == null,
        'animate-fade-in': ({ value }: CellClassParams) => value != null,
      },
    })),
  ], [columns])

  const rowData = useMemo(() => {
    const rows: { [field: string]: string }[] = []
    filesStore.checkedFiles.forEach(checkedFile => {
      rows.push({
        filename: checkedFile.fileName,
        ...columns.reduce((previousValue, [field, filesIds]) => ({
          ...previousValue,
          [field]: filesIds.includes(checkedFile.id),
        }), {}),
      })
    })

    return rows
  }, [columns])

  const gridOptions = useMemo(() => ({
    defaultColDef: {
      resizable: true,
    },
    rowDragManaged: true,
    onGridReady: (params: { api: GridApi<UploadedFile> | null }) => {
      gridApiRef.current = params.api
    },
  }), [])

  const onRowDragEnd = useCallback(event => {
    const { node, overIndex } = event
    const newRowData = [...rowData]
    newRowData.splice(node.rowIndex, 1)
    newRowData.splice(overIndex, 0, node.data)

    const displayedRows = newRowData.map((rowDataItem, index) => {
      const displayedRow = gridApiRef.current?.getDisplayedRowAtIndex(index)
      return displayedRow?.data || null
    })

    const files = [...filesStore.checkedFiles]
    const result = files.map(item => ({ id: item[0], fileName: item[1].fileName }))

    const updatedResult = displayedRows.map(displayedRow => {
      const matchingItem = result.find(item => item.fileName === displayedRow.filename)
      return matchingItem || null
    })

    const updatedResultIdsSet = new Set(updatedResult.map(item => item?.id))
    filesStore.addCheckedFilesIds(updatedResultIdsSet)
  }, [rowData])

  const themeClassName = useAgGridThemeClassName()

  const description: JSX.Element = hasDuplicates ? (
    <div className="text-base mb-4">
      <p className="mb-4">
        The Merge function is
        designed to combine indices into a single unified dataset.
        When “Remove duplicates” is selected, Merge algorithm initially groups duplicate
        molecules from all indices based on the Structure column. Each set is then examined
        to identify identical Properties. The system retains only the unique values of these
        properties and assigns them to columns named “Property”, ”Property v_2”, ”Property v_3”...
      </p>
      <p>
        This process ensures that all duplicates are eliminated while preserving
        all unique properties and their corresponding data without any loss.
      </p>
    </div>
  ) : (
    <p className="text-base mb-4">
      The Merge function is designed to combine multiple indices into a new single unified dataset.
      When no additional options are selected, Merge will merge all the selected indices.
      Additionally, if there are columns with identical names in the selected indices,
      Merge will combine them and unified dataset will contain only unique columns names.
    </p>
  )

  return (
    <div className="mt-7 mx-4">
      <div className="font-bold text-xl mb-3">Merge overview</div>
      {description}
      <div className={cn('ag-grid h-[195px] merge-grid', themeClassName)}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          rowHeight={AG_GRID_TABLE_ROW_HEIGHT_PX}
          onRowDragEnd={onRowDragEnd}
          gridOptions={gridOptions}
        />
      </div>
    </div>
  )
})

const MergeOverviewModal = observer(({
  visible, onBack, onCancel, onOk, hasDuplicates = false,
}: MergeOverviewModalProps): ReactElement => (
  <Modal
    className="!w-3/5 rounded-2xl"
    open={visible}
    centered
    destroyOnClose
    onCancel={onCancel}
    footer={[
      <div key="footer" className="mx-4 my-5">
        <Button
          ghost
          size="large"
          className="mr-1"
          onClick={onBack}
        >
          Back
        </Button>

        <Button
          type="primary"
          size="large"
          onClick={onOk}
        >
          Merge files
        </Button>
      </div>,
    ]}
  >
    <MergeOverview hasDuplicates={hasDuplicates} />
  </Modal>
))

export default MergeOverviewModal
