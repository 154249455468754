import { ReactElement } from 'react'

import BackButton from '@components/common/BackButton'
import GuestHeader from '@components/common/GuestHeader'

interface PageFormContainerProps {
  children: ReactElement[] | ReactElement
  testId: string
  backButton?: BackButton
}

const PageFormContainer = (props: PageFormContainerProps): ReactElement => {
  const { children, testId, backButton } = props

  const backButtonComponent = backButton
    ? (
      <div className="mt-3">
        <BackButton label={backButton?.label} path={backButton?.path} onClick={backButton?.onClick} />
      </div>
    )
    : <></>

  return (
    <div data-testid={testId} className="max-w-6xl px-4 mx-auto min-h-full">
      <GuestHeader />
      { backButtonComponent }
      <div className="min-h-full flex justify-center items-center">
        <div className="max-w-xl w-full sm:mt-32 mt-20">
          { children }
        </div>
      </div>
    </div>
  )
}

export default PageFormContainer
