const ColumnMenuIcon: React.FC = () => (
  <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_77939_69492)">
      <rect width="28" height="16" rx="4" fill="white" />
      <path
        d="M14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284
          6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z"
        fill="#393E48"
      />
      <path
        d="M19 9.5C19.8284 9.5 20.5 8.82843 20.5 8C20.5 7.17157 19.8284
          6.5 19 6.5C18.1716 6.5 17.5 7.17157 17.5 8C17.5 8.82843 18.1716 9.5 19 9.5Z"
        fill="#393E48"
      />
      <path
        d="M9 9.5C9.82843 9.5 10.5 8.82843 10.5 8C10.5 7.17157 9.82843 6.5
          9 6.5C8.17157 6.5 7.5 7.17157 7.5 8C7.5 8.82843 8.17157 9.5 9 9.5Z"
        fill="#393E48"
      />
      <rect x="0.5" y="0.5" width="27" height="15" rx="3.5" stroke="#C0C7D5" />
    </g>
    <defs>
      <filter
        id="filter0_d_77939_69492"
        x="0"
        y="0"
        width="28"
        height="18"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77939_69492" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_77939_69492" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default ColumnMenuIcon
