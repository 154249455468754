import apiService from '@shared/services/api-service'

const changePassword = async (data: {oldPassword: string, newPassword: string}): Promise<{status: string}> => {
  const body = JSON.stringify(data)

  return apiService.post<{status: string}>('/password/change', {
    body,
  })
}

const fetchAccountDetails = async (): Promise<UserAccountDetails> => apiService.get<UserAccountDetails>('/profile')

const updateAccountDetails = async (data: UserAccountDetails): Promise<{status: string}> => {
  const body = JSON.stringify(data)

  return apiService.put<{status: string}>('/profile', {
    body,
  })
}

// eslint-disable-next-line max-len
const deleteAccount = async (password: string): Promise<void> => apiService.delete<void>('/profile', { json: { password } })

export {
  changePassword,
  fetchAccountDetails,
  updateAccountDetails,
  deleteAccount,
}
