/* eslint-disable react/jsx-props-no-spreading */
import {
  ChangeEvent, FC, ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react'
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd'
import { useToggle } from 'ahooks'
import {
  Button, Input, message,
  Popconfirm,
} from 'antd'
import captitalize from 'lodash/capitalize'
import { observer } from 'mobx-react'

import subscriptionStore from '@store/subscription'
import {
  CloseOutlined, EyeFilled, PlusCircleOutlined, SearchOutlined, SettingOutlined,
} from '@ant-design/icons'
import PopoverAndTriggerButton, { PopoverProps } from '@components/common/PopoverAndTriggerButton'
import Chevron from '@components/icons/Chevron'
import Clock from '@components/icons/Clock'
import { useFileStore, useTableManagementStore } from '@contexts/file-edit-context'
import { showErrorMessage } from '@utils/messages'
import pluralEnd from '@utils/plural-end'
import AddRowsNavLinkButton from './AddRowsNavLinkButton'
import TableManagementColumnItem from './TableManagementColumnItem'

export interface SetColumn {
  index: number
  column: IndexProperty
}

interface TableManagementProps {
  disabled: boolean
}

const deleteColumnMessageKey = 'deletingColumnMessageKey'

const TableManagementPopover = observer(({ visible, hidePopUp }: PopoverProps) => {
  const fileStore = useFileStore()
  const tableManagementStore = useTableManagementStore()
  const [confirmForApplyVisible, setConfirmForApplyVisible] = useState(false)
  const [markedAsDeletedCount, setMarkedAsDeletedCount] = useState(0)
  const colWord = `column${pluralEnd(markedAsDeletedCount)}`
  const isAddColumnDisabled = useMemo(
    () => fileStore.readOnly
      || fileStore.isAddingColumnStatusExist
      || !subscriptionStore.subscriptionPlanSettings.addColumn,
    [fileStore.readOnly, fileStore.isAddingColumnStatusExist],
  )
  const isAddRowsDisabled = !subscriptionStore.subscriptionPlanSettings.addRows

  const handleOnDragEnd = useCallback((result: DropResult) => {
    const items = Array.from(tableManagementStore.indexProperties)

    const [reorderItem] = items.splice(result.source.index, 1)

    if (result.destination) {
      items.splice(result.destination.index, 0, reorderItem)
    }

    tableManagementStore.indexProperties = items
  }, [tableManagementStore])

  const hidePopconfirm = () => setConfirmForApplyVisible(false)

  const applyChanges = useCallback(async () => {
    hidePopconfirm()

    try {
      if (markedAsDeletedCount > 0) {
        message.loading({
          content: `${captitalize(colWord)} deleting`,
          key: deleteColumnMessageKey,
          duration: 0,
          icon: <Clock className="inline-block w-4 h-4 relative top-px mr-2 text-warning" />,
        })
      }
      await fileStore.updateIndexProperties(tableManagementStore.indexProperties)
      hidePopUp()
    } catch (error) {
      showErrorMessage()
    } finally {
      message.destroy(deleteColumnMessageKey)
    }
  }, [markedAsDeletedCount, fileStore, tableManagementStore.indexProperties, hidePopUp, colWord])

  const confirmForApply = () => {
    const markAsDeletedCountLocal = tableManagementStore.indexProperties.filter(column => column.deleted).length

    if (markAsDeletedCountLocal === 0) {
      applyChanges()
      return
    }

    setMarkedAsDeletedCount(markAsDeletedCountLocal)
    setConfirmForApplyVisible(true)
  }

  const setSearchValue = useCallback((searchValue: string) => {
    tableManagementStore.nameFilter = searchValue
  }, [tableManagementStore])

  const handleClickAddColumn = () => {
    if (isAddColumnDisabled) return

    const newColumn: IndexProperty = {
      name: 'New Column',
      type: 'STRING',
      deleted: false,
      hidden: false,
      fixed: 'left',
      status: 'adding',
      weblink: false,
      isFormula: false,
    }

    fileStore.indexProperties = [newColumn, ...fileStore.indexProperties]

    hidePopUp()
  }

  const handleClickShowAll = () => {
    tableManagementStore.filteredIndexProperties.forEach(property => {
      tableManagementStore.updateColumn(property, 'hidden', false)
    })
  }

  const isDragDisabled = !!tableManagementStore.nameFilter || tableManagementStore.filteredIndexProperties.length === 1

  useEffect(() => {
    if (visible === true) {
      setSearchValue('')
    } else {
      setConfirmForApplyVisible(false)
    }
  }, [visible, setSearchValue])

  useEffect(() => () => {
    tableManagementStore.indexProperties = fileStore.indexPropertiesWithoutNewColumn
  }, [tableManagementStore, fileStore.indexPropertiesWithoutNewColumn])

  return (
    <div style={{ width: 390 }}>
      <div className="font-bold text-base mb-5">Table Management</div>

      <Button
        ghost
        className="mr-3 inline-flex items-center font-normal"
        disabled={isAddColumnDisabled}
        onClick={handleClickAddColumn}
      >
        <PlusCircleOutlined className="text-primary" />
        Add a column
      </Button>

      <AddRowsNavLinkButton fileId={fileStore.fileId} disabled={fileStore.readOnly || isAddRowsDisabled} />

      <Button
        ghost
        className="ml-3 inline-flex items-center font-normal"
        onClick={handleClickShowAll}
      >
        <EyeFilled className="text-gray-40" />
        Show all
      </Button>

      <Input
        prefix={<SearchOutlined />}
        suffix={<CloseOutlined onClick={() => setSearchValue('')} />}
        value={tableManagementStore.nameFilter}
        placeholder="Search columns"
        className="mt-5 cursor-text"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
      />

      <div className="max-h-56 overflow-auto mt-5 mb-2 pt-1">
        <DragDropContext onDragEnd={(result: DropResult) => handleOnDragEnd(result)}>
          <Droppable droppableId="droppable-1">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tableManagementStore.filteredIndexProperties
                  .map((indexProperty, index) => (
                    <Draggable
                      key={indexProperty.name}
                      draggableId={`${indexProperty.name}draggeble`}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {props => (
                        <div
                          ref={props.innerRef}
                          {...props.draggableProps}
                          {...props.dragHandleProps}
                        >
                          <TableManagementColumnItem
                            indexProperty={indexProperty}
                            isDragDisabled={isDragDisabled}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {tableManagementStore.filteredIndexProperties.length === 0
          && <div className="flex w-full justify-center">There is nothing to show</div>}
      </div>

      <div className="text-danger text-sm">
        {tableManagementStore.validationError}
      </div>

      <Popconfirm
        title={`Selected ${colWord} will be deleted. Are you sure you want to delete ${markedAsDeletedCount} ${colWord}?`}
        open={confirmForApplyVisible}
        onConfirm={applyChanges}
        onCancel={hidePopconfirm}
        okType="primary"
        cancelButtonProps={{ ghost: true }}
        okText="Yes"
        cancelText="Cancel"
      >
        <Button
          type="primary"
          className="mt-2 mb-1 w-full"
          size="middle"
          loading={fileStore.isIndexPropertiesUpdating}
          onClick={confirmForApply}
          disabled={fileStore.readOnly}
        >
          Apply
        </Button>
      </Popconfirm>
    </div>
  )
})

const TableManagementButton: FC<ButtonProps> = ({ onClick, disabled }) => (
  <Button disabled={disabled} onClick={onClick} type="text" className="flex items-center font-normal">
    <SettingOutlined className="text-primary dark:text-white" />
    Table Management
    <Chevron className="ml-2" />
  </Button>
)

const TableManagement: FC<TableManagementProps> = ({ disabled }): ReactElement => {
  const [isPopoverVisible, { toggle: toggleIsPopoverVisible }] = useToggle()

  return (
    <PopoverAndTriggerButton
      PopoverContent={(
        <TableManagementPopover
          visible={isPopoverVisible}
          hidePopUp={toggleIsPopoverVisible}
        />
      )}
      ButtonElement={<TableManagementButton disabled={disabled} onClick={toggleIsPopoverVisible} />}
      visible={isPopoverVisible}
      hidePopUp={toggleIsPopoverVisible}
      destroyTooltipOnHide
    />
  )
}

export default TableManagement
