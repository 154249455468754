import {
  ChangeEvent, ReactElement, useCallback, useState,
} from 'react'
import {
  Form,
} from 'antd'
import { ValidateStatus } from 'antd/es/form/FormItem'

import PasswordInput from '@components/common/PasswordInput'

const validatePassword = (
  value: string,
): { validateStatus: ValidateStatus, errorMsg: string | null } => {
  if (value.length < 8 || value.length > 99) {
    return {
      validateStatus: 'error',
      errorMsg: 'Password length should be from 8 to 99 characters',
    }
  }

  if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
    return {
      validateStatus: 'error',
      errorMsg: 'Password should contain lowercase Basic Latin letters, uppercase and number characters',
    }
  }
  return {
    validateStatus: 'success',
    errorMsg: null,
  }
}

const NewPasswordFieldGroup = (): ReactElement => {
  const [password, setPassword] = useState<{
    value: string
    validateStatus?: ValidateStatus
    errorMsg?: string | null
  }>({
    value: '',
  })

  const onPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setPassword({
      validateStatus: 'success',
      errorMsg: null,
      value,
    })
  }, [])

  const onBlur = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setPassword({
      ...password,
      ...validatePassword(value),
    })
  }, [password])

  const tips = 'Minimum of 8 characters, mixed case, at least one digit'

  return (
    <>
      <Form.Item
        label="Password"
        name="password"
        help={password.errorMsg || tips}
        validateStatus={password.validateStatus}
        rules={[
          { min: 8, validateTrigger: 'onSubmit' },
          { max: 99, validateTrigger: 'onSubmit' },
          { pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, validateTrigger: 'onSubmit' },
          { required: true },
        ]}
      >
        <PasswordInput
          placeholder="Enter new password"
          autoComplete="new-password"
          value={password.value}
          onChange={onPasswordChange}
          size="large"
          onBlur={onBlur}
        />
      </Form.Item>
      <Form.Item
        label="Confirm the password"
        name="confirm"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Passwords do not match'))
            },
          }),
        ]}
      >
        <PasswordInput
          placeholder="Confirm the password"
          autoComplete="new-password"
          size="large"
        />
      </Form.Item>
    </>
  )
}

export default NewPasswordFieldGroup
