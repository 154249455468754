import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import LinkedInIcon from '@components/icons/LinkedIn'
import QuantoriLogo from '@components/icons/QuantoriLogo'
import {
  contactEmail,
  copyrightFooterDates,
  priceOfService,
  privacyPolicyUrl,
  productOfService,
  termsOfServiceUrl,
  termsOfUseUrl,
} from '@shared/constants'
import AndroitDI from './AndroitDI'

const Footer = (): ReactElement => (
  <footer className="px-6 pt-14 pb-12 flex justify-between border-t border-gray-70 mt-auto">
    <div className="flex justify-start">
      <div>
        <NavLink to={{ pathname: 'https://adroitdi.com' }} target="_blank">
          <AndroitDI className="dark:fill-[#fff] fill-[#56C999]" />
        </NavLink>
        <p className="mt-4">
          ⓒ {copyrightFooterDates} <span className="text-primary">Adroit DI Ltd</span>. All
          rights Reserved
        </p>
      </div>
      <div>
        <div className="border-l border-gray-70 ml-9 pl-10">
          <NavLink to={{ pathname: 'https://quantori.com' }} target="_blank">
            <QuantoriLogo />
          </NavLink>
          <p className="mt-4">
            Powered by <span className="text-primary">Quantori</span>
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="flex justify-between gap-10 ml-10">
        <a
          href={productOfService}
          className="dark:text-gray-30 text-[#393e48] inline-block"
          target="_blank"
          rel="noreferrer"
        >
          Product
        </a>
        <a
          href={priceOfService}
          className="dark:text-gray-30 text-[#393e48] inline-block"
          target="_blank"
          rel="noreferrer"
        >
          Pricing
        </a>
        <a
          href={termsOfServiceUrl}
          className="dark:text-gray-30 text-[#393e48] inline-block"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        <a
          href={privacyPolicyUrl}
          className="dark:text-gray-30 text-[#393e48] inline-block"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href={termsOfUseUrl}
          className="dark:text-gray-30 text-[#393e48] inline-block"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </div>
      <div className="flex justify-end">
        <a href={`mailto:${contactEmail}`} className="mt-6 mr-4 underline">
          {contactEmail}
        </a>
        <a
          href="https://www.linkedin.com/company/adroitdi"
          target="_blank"
          className="mt-6 flex justify-end"
          rel="noreferrer"
        >
          <LinkedInIcon />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
